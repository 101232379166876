import appHelpers from '@helpers/app-urls';
import api from '@services/api';
import {
  InitializeKyxActionRequestDto,
  UpdateKyxActionRequestDto,
  VerificationApi,
  VerifyActionStatusDto,
} from '@services/generated';
import { handleErrors } from '@services/helpers';
import { rootApi } from '@store/root-api';

const API_URL = appHelpers.getEndpointUrl();

const verificationClient = new VerificationApi(
  undefined,
  new URL(API_URL, process.env.REACT_API_URL).href,
  api,
);

export interface SumSubState {
  sdkToken: string;
  timestamp: string;
  idUserIdAction: string;
}

export const verificationApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    getSumsubToken: builder.query<SumSubState, InitializeKyxActionRequestDto>({
      queryFn: async data => {
        const res = await handleErrors(() =>
          verificationClient.initializeOrGetIdUserActionV2(data),
        );
        if (res.error) return res;

        const sumSubState = {
          sdkToken: res.data.fields.sdkToken,
          timestamp: res.data.fields.timestamp,
          idUserIdAction: res.data.idUserActionId,
        };

        return { data: sumSubState, error: undefined, meta: res.meta };
      },
    }),
    updateUserAction: builder.mutation<
      VerifyActionStatusDto[],
      UpdateKyxActionRequestDto
    >({
      queryFn: data =>
        handleErrors(() =>
          verificationClient.updateIdUserActionReference(data),
        ),
    }),
  }),
});

export const { useGetSumsubTokenQuery, useUpdateUserActionMutation } =
  verificationApi;
