import * as React from 'react';
/* @ts-ignore */
import { useIdleTimer } from 'react-idle-timer';
import { useTimer } from 'use-timer';
import { useSelector } from 'react-redux';
import useLogout from 'hooks/use-logout';
import InactivityTimerView from './InactivityTimerView';
import wait from '../../utils/wait';
import { RootState } from '../../store/reducer';
import messagesDictionary from './messages';

const TIMEOUT = Number.parseInt(import.meta.env.VITE_TIMEOUT as string, 10);
const TIMEOUT_COUNTDOWN = Number.parseInt(
  import.meta.env.VITE_TIMEOUT_COUNTDOWN as string,
  10,
);

const InactivityTimer = () => {
  const logout = useLogout();

  const [isIdle, setIsIdle] = React.useState(false);

  const { start, reset, pause, time } = useTimer({
    endTime: TIMEOUT_COUNTDOWN,
    onTimeOver: () => {
      logout();
    },
  });

  const { language } = useSelector((state: RootState) => state.language);

  const messages = messagesDictionary[language];

  useIdleTimer({
    timeout: 1000 * TIMEOUT,
    onIdle: () => {
      setIsIdle(true);
      start();
    },
    debounce: 500,
  });

  async function onCancel() {
    setIsIdle(false);
    pause();
    // wait while modal closes before resetting timeout
    await wait(1000);
    reset();
  }

  return (
    <InactivityTimerView
      show={isIdle}
      timeout={TIMEOUT}
      onCancel={onCancel}
      messages={messages}
      cancellationCountdown={TIMEOUT_COUNTDOWN - time}
    />
  );
};

export default InactivityTimer;
