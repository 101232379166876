import cdnApi from './cdn-api';
import { Graph } from '../entities/graphs';
import { TimeFrameKey } from '../entities/time-frame';
import Bugsnag from '@bugsnag/js';

// eslint-disable-next-line import/prefer-default-export
export async function getGraphs(
  code: string,
  currency: string,
  timeFrame: TimeFrameKey,
): Promise<Graph> {
  // TODO: Revert ALFI bypass when labs rates are properly ready
  try {
    const { data } = await cdnApi.get(
      `/${
        code === 'ALFI' ? 'data/labs' : import.meta.env.VITE_CDN_GRAPH_STORAGE
      }/graphs/${code}/${currency}/rates_${timeFrame}.json`,
    );

    return {
      baseAsset: data.BaseAsset,
      counterAsset: data.CounterAsset,
      interval: data.Interval,
      generatedAt: data.GeneratedAt,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      values: data.Values.map((d: any) => ({
        timeStamp: d.TimeStamp,
        date: d.Date,
        value: d.Value,
        normalisedValue: d.NormalisedValue,
        change: d.Change,
      })),
    };
  } catch (Exception: any) {
    Bugsnag.notify(Exception);

    return {
      baseAsset: currency,
      counterAsset: code,
      interval: Number(timeFrame),
      generatedAt: new Date(),
      values: [],
    };
  }
}
