import React, { useEffect, useState } from 'react';
import CustomLoader from '../components/CustomLoader';

export default function asyncComponent(importComponent: any) {
  return (props: any) => {
    const [component, setComponent] = useState<any | null>(null);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
      setMounted(true);
      return () => {
        setComponent(null);
        setMounted(false);
      };
    }, []);

    // @ts-ignore
    useEffect(() => {
      async function componentFunc() {
        const { default: ImportedComponent } = await importComponent();
        if (mounted) setComponent(<ImportedComponent {...props} />);
      }

      componentFunc();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mounted]);

    return component || <CustomLoader page />;
  };
}
