/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/ban-ts-ignore */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from 'react';
import usePageConfig from 'hooks/use-page-config';
import pkg from '../../../package.json';
import UserAgent from './UserAgent';
import AppVersion from './AppVersion';

const AppInfo = ({
  isVisible = false,
  showVersion = true,
  showUserAgent = true,
  showUserAgentDEBUG,
}: {
  isVisible?: boolean;
  showVersion?: boolean;
  showUserAgent?: boolean;
  showUserAgentDEBUG?: boolean;
}) => {
  //  NOTE: This is to fetch the page_config.json at app startup
  usePageConfig();

  const AppName = () => (
    <div className="p-4" style={{ borderLeft: '1px solid #fff' }}>
      <strong>App: </strong>
      <span>
        {pkg.name}@{pkg.version}
      </span>
    </div>
  );
  // #endregion

  return (
    <div
      style={{
        borderRight: '1px solid #fff',
        borderBottom: isVisible ? '1px solid #fff' : 'none',
      }}
      className="flex flex-col text-header-text w-full md:w-1/2 lg:w-1/3"
    >
      {isVisible && <AppName />}
      {isVisible && <hr />}

      <AppVersion isVisible={isVisible && showVersion} />
      <UserAgent
        isVisible={isVisible && showUserAgent}
        showUserAgentDEBUG={showUserAgentDEBUG}
      />
    </div>
  );
};

export default AppInfo;
