/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductSuggestion, MetricsTypes } from '../../entities/products';

interface ProductMetricsType {
  metricsData: MetricsTypes;
  lastFetched: Date;
  asset: string;
}

export type Products = {
  suggestions?: ProductSuggestion[];
  loading: boolean;
  loadingMetrics?: boolean;
  error: string;
  productMetrics: { [key: string]: ProductMetricsType };
};

export const defaultProductsState: Products = {
  loading: false,
  error: '',
  loadingMetrics: false,
  productMetrics: {},
  suggestions: [],
};

const productsSuggest = createSlice({
  name: 'products-suggest',
  initialState: defaultProductsState,
  reducers: {
    setSuggestionsLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setSuggestionsError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    setSuggestionsData(
      state,
      action: PayloadAction<{ data: ProductSuggestion[] }>,
    ) {
      // TODO: must be better way with lodash.
      if (!state.suggestions) {
        state.suggestions = [];
      }
      state.suggestions = action.payload.data;
    },
    setProductMetricsData(state, action) {
      state.productMetrics = action.payload;
    },
    setProductMetricsLoading(state, action: PayloadAction<boolean>) {
      state.loadingMetrics = action.payload;
    },
  },
});

export const {
  setSuggestionsLoading,
  setSuggestionsError,
  setSuggestionsData,
  setProductMetricsData,
  setProductMetricsLoading,
} = productsSuggest.actions;

export default productsSuggest.reducer;
