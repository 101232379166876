/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { useEffect, useRef } from 'react';
import imageLoadError from 'helpers/image-load-error';
import clsx from 'clsx';
// eslint-disable-next-line import/no-duplicates
import colors from 'styles/colors';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducer';
import { Asset, ProductV2 } from 'entities/productsV2';
import { displayProductCode } from 'utils/products';
import cornerLockIcon from './lock-icon.svg';
import appHelpers from '../../helpers/app-urls';
// eslint-disable-next-line import/no-duplicates
import theme from '../../styles/colors';
import { GradientOrColorView } from 'components/AppDomain/GradientOrColorView';

const cdnURL = appHelpers.getCDNUrl();

interface CurrencyIconProps {
  /**
   * - fetches the corresponding Crypto/Theme/Currency/Bundle icon from the CDN
   * - one of **('BTC', 'ETH', 'ZAR', 'GBP', 'RVX10', 'CBDX', etc.)**
   * - country codes also work (e.g. 'US', 'GB', 'ZA', 'EU', 'CH')
   */
  currencyCode?: string | null;
  //adding this so it matches the props with the app
  code?: string | null;
  /** className prop to override the container <div />. The child <img /> fills the parent with 'w-full h-auto'  */
  className?: string;
  color?: string;
  cornerIcon?: boolean;
  constituent?: boolean;
  inactive?: boolean;
  isVault?: boolean;
  // NOTE: would be better to actually define the size with numbers, to be able to calculate
  isLarge?: boolean;
  isLockedAsset?: boolean;
  style?: any;
}

const CurrencyIcon = ({
  className = 'w-10 h-10',
  isVault = false,
  cornerIcon = false,
  currencyCode,
  code,
  color,
  constituent,
  inactive,
  isLarge,
  isLockedAsset,
  style,
}: CurrencyIconProps) => {
  const upperCaseCode = (currencyCode ?? code)?.toUpperCase();
  const lowerCaseCode = (currencyCode ?? code)?.toLowerCase();

  // #region STORE
  const list = useSelector((state: RootState) => state.productsV2.assets);
  const asset = list.find((p: Asset) => p.code === upperCaseCode);
  const productColor =
    asset?.colors?.primaryStart && asset?.colors?.primaryEnd
      ? { start: asset?.colors?.primaryStart, end: asset?.colors?.primaryEnd }
      : asset?.colors?.primary;
  // #endregion

  // #region STATE
  let backgroundColor = productColor ?? colors.sky;
  let icon = `${cdnURL}assets/products/icons/${lowerCaseCode}.svg`;

  const fallbackIcon = `${cdnURL}assets/products/constituents/dark/fallback.svg`;
  const iconElement = useRef<any>();

  const displayCode =
    currencyCode !== null ? displayProductCode(lowerCaseCode || '') : '';

  const isBundle = asset?.typeCode?.toUpperCase() === 'BUNDLE';
  const isCrypto = asset?.typeCode?.toUpperCase() === 'CRYPTO';
  const isFiat = asset?.typeCode?.toUpperCase() === 'FIAT';
  const isTheme = asset?.typeCode?.toUpperCase() === 'THEME';
  // SET ICON & COLOR

  if (isBundle) {
    backgroundColor = productColor || theme.rvx;
  }

  if (isTheme) {
    backgroundColor = productColor || theme.violet;
  }

  if (constituent) {
    icon = `${cdnURL}assets/products/constituents/dark/${lowerCaseCode}.svg`;
    backgroundColor = 'transparent';
  }

  if (isVault) {
    backgroundColor = theme.rvxVault;
  }

  if (isLockedAsset) {
    backgroundColor = colors.darkGrey;
  }

  if (isFiat) {
    backgroundColor = 'transparent';
  }
  // #endregion

  // #region LIFECYCLE
  useEffect(() => {
    if (!constituent) return;
    const iconImg = new Image();
    iconImg.onerror = () => {
      iconImg.src = fallbackIcon;
    };

    iconImg.onload = () => {
      if (iconElement.current)
        iconElement.current.style.backgroundImage = `url(${iconImg.src})`;
    };

    iconImg.src = icon;
  }, [iconElement, currencyCode, constituent, icon, fallbackIcon]);
  // #endregion

  return (
    <GradientOrColorView
      style={{
        backgroundSize: constituent ? 'contain' : '',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        ...style,
      }}
      colorOrGradient={backgroundColor}
      className={`relative h-10 w-10 rounded-full flex items-center justify-center ${className || ''} ${isLarge ? 'w-24 h-24' : ''}`}>
      {constituent ? 
        <img
          src={icon}
          alt={`${displayCode}-icon`}
          onError={imageLoadError}
          className={clsx('w-full h-auto', {
            'opacity-50': inactive,
          })}
        />
       : (
        <>
          <img
            src={icon}
            alt={`${displayCode}-icon`}
            onError={imageLoadError}
            className={clsx('w-full h-auto', {
              'opacity-50': inactive,
            })}
          />
          {cornerIcon && (
            <img
              src={cornerLockIcon}
              alt="lock-icon"
              className={`absolute -top-2 -left-2 ${
                isLarge ? 'w-12 h-12' : 'w-5 h-5'
              }`}
            />
          )}
        </>
      )}
    </GradientOrColorView>
  );
};

export default CurrencyIcon;
