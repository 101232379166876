import React from 'react';
import Tippy from '@tippyjs/react';
import { roundArrow } from 'tippy.js';
import 'tippy.js/dist/svg-arrow.css';
import 'tippy.js/animations/shift-away.css';
import styled from 'styled-components';
import clsx from 'clsx';

interface TooltipProps {
  content?: React.ReactNode;
  children?: React.ReactElement<any> | any;
  className?: string;
  interactive?: boolean;
  darkIcon?: boolean;
  darkBg?: boolean;
  color?: string;
  // Added any type because Tippy expects the delay to be number | null types
  // However, you can specify the delay to be of types [number | null, number | null] too
  // That is uesed to change the show and hide delays where null is the default value
  delay?: number | null | any;
  id: string;
  offset?: any;
  placement?: any;
}

const Tooltip = ({
  children,
  darkIcon,
  darkBg,
  color,
  className,
  content,
  delay,
  id,
  interactive,
  offset,
  placement,
}: TooltipProps) => {
  const TippyTooltip = styled(Tippy)`
    background: ${color ||
    (darkIcon || darkBg ? 'var(--input-background)' : 'white')};
    padding: 12px;
    color: ${darkIcon ? 'white' : 'var(--input-text)'};
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    border-radius: 0.375rem;
    font-size: 0.875rem;
    line-height: 1.25rem;

    &.tippy-box .tippy-svg-arrow {
      fill: ${color ||
      (darkIcon || darkBg ? 'var(--input-background)' : 'white')}!important;
    }
  `;

  return (
    <TippyTooltip
      key={id}
      content={content}
      interactive={interactive}
      trigger="mouseenter"
      touch
      arrow={roundArrow}
      allowHTML
      delay={[null, !delay ? null : delay]}
      className={clsx(className)}
      followCursor={false}
      animation="shift-away"
      placement={placement || 'top'}
      offset={offset || 0}
    >
      {children}
    </TippyTooltip>
  );
};

export default Tooltip;
