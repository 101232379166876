/* tslint:disable */
/* eslint-disable */
/**
 * Revix.Api.Dashboard Api
 * Revix Public Api
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AccessTokenDto
 */
export interface AccessTokenDto {
    /**
     * 
     * @type {string}
     * @memberof AccessTokenDto
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenDto
     */
    'tokenExpiry'?: string | null;
}
/**
 * 
 * @export
 * @interface AggregatedPortfolioQueryResponse
 */
export interface AggregatedPortfolioQueryResponse {
    /**
     * 
     * @type {number}
     * @memberof AggregatedPortfolioQueryResponse
     */
    'portfolioBalance': number;
    /**
     * 
     * @type {number}
     * @memberof AggregatedPortfolioQueryResponse
     */
    'cashBalance': number;
    /**
     * 
     * @type {number}
     * @memberof AggregatedPortfolioQueryResponse
     */
    'cashReserved': number;
    /**
     * 
     * @type {boolean}
     * @memberof AggregatedPortfolioQueryResponse
     */
    'canDeposit': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AggregatedPortfolioQueryResponse
     */
    'canWithdraw'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AggregatedPortfolioQueryResponse
     */
    'canLock'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AggregatedPortfolioQueryResponse
     */
    'canUnlock'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof AggregatedPortfolioQueryResponse
     */
    'portfolioGainPercentage': number;
    /**
     * 
     * @type {number}
     * @memberof AggregatedPortfolioQueryResponse
     */
    'portfolioGainAmount': number;
    /**
     * 
     * @type {Array<ProductHoldings>}
     * @memberof AggregatedPortfolioQueryResponse
     */
    'productHoldings': Array<ProductHoldings>;
    /**
     * 
     * @type {GetRewardsDetailsQueryResponse}
     * @memberof AggregatedPortfolioQueryResponse
     */
    'rewardsDetailsQueryResponse': GetRewardsDetailsQueryResponse;
}
/**
 * 
 * @export
 * @interface AmountBlockDto
 */
export interface AmountBlockDto {
    /**
     * 
     * @type {boolean}
     * @memberof AmountBlockDto
     */
    'isPercentage': boolean;
    /**
     * 
     * @type {number}
     * @memberof AmountBlockDto
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface AssetAggregateDto
 */
export interface AssetAggregateDto {
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'typeCode': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetAggregateDto
     */
    'categoryCodes': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'currencyCode': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'symbol': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'iconUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'alertAssetCode': string;
    /**
     * 
     * @type {number}
     * @memberof AssetAggregateDto
     */
    'alertMinValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AssetAggregateDto
     */
    'reservesMax'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AssetAggregateDto
     */
    'reservesUpper'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AssetAggregateDto
     */
    'reservesRecommended'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AssetAggregateDto
     */
    'reservesLower'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AssetAggregateDto
     */
    'reservesMin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AssetAggregateDto
     */
    'transactionMin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AssetAggregateDto
     */
    'transactionMax'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssetAggregateDto
     */
    'isTracked'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssetAggregateDto
     */
    'isBase'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssetAggregateDto
     */
    'isDisplay'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssetAggregateDto
     */
    'isInternal'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetAggregateDto
     */
    'aliases': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'longName': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'pluralName': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AssetAggregateDto
     */
    'colors': { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof AssetAggregateDto
     */
    'navigation'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssetAggregateDto
     */
    'hasFacts'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'keywords': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'methodology': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'factsheet': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'whitepaper': string;
    /**
     * 
     * @type {boolean}
     * @memberof AssetAggregateDto
     */
    'active'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'launchedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'createdAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AssetAggregateDto
     */
    'displayOrder'?: number | null;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof AssetAggregateDto
     */
    'tags': Array<Tag>;
    /**
     * 
     * @type {Array<AssetTag>}
     * @memberof AssetAggregateDto
     */
    'assetTags': Array<AssetTag>;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'network': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'disclaimer': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'constituents': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'targetWeight': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'distributions': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'endpointId': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'holdings': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'referralCode': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'totalExpenseRatio': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateDto
     */
    'rebalancingFrequency': string;
}
/**
 * 
 * @export
 * @interface AssetAggregateFullDto
 */
export interface AssetAggregateFullDto {
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'typeCode': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetAggregateFullDto
     */
    'categoryCodes': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'currencyCode': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'symbol': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'iconUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'alertAssetCode': string;
    /**
     * 
     * @type {number}
     * @memberof AssetAggregateFullDto
     */
    'alertMinValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AssetAggregateFullDto
     */
    'reservesMax'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AssetAggregateFullDto
     */
    'reservesUpper'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AssetAggregateFullDto
     */
    'reservesRecommended'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AssetAggregateFullDto
     */
    'reservesLower'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AssetAggregateFullDto
     */
    'reservesMin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AssetAggregateFullDto
     */
    'transactionMin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AssetAggregateFullDto
     */
    'transactionMax'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssetAggregateFullDto
     */
    'isTracked'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssetAggregateFullDto
     */
    'isBase'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssetAggregateFullDto
     */
    'isDisplay'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssetAggregateFullDto
     */
    'isInternal'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetAggregateFullDto
     */
    'aliases': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'longName': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'pluralName': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AssetAggregateFullDto
     */
    'colors': { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof AssetAggregateFullDto
     */
    'navigation'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssetAggregateFullDto
     */
    'hasFacts'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'keywords': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'methodology': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'factsheet': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'whitepaper': string;
    /**
     * 
     * @type {boolean}
     * @memberof AssetAggregateFullDto
     */
    'active'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'launchedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'createdAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AssetAggregateFullDto
     */
    'displayOrder'?: number | null;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof AssetAggregateFullDto
     */
    'tags': Array<Tag>;
    /**
     * 
     * @type {Array<AssetTag>}
     * @memberof AssetAggregateFullDto
     */
    'assetTags': Array<AssetTag>;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'network': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'disclaimer': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'constituents': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'targetWeight': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'distributions': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'endpointId': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'holdings': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'referralCode': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'totalExpenseRatio': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAggregateFullDto
     */
    'rebalancingFrequency': string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof AssetAggregateFullDto
     */
    'productCopy': { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface AssetTag
 */
export interface AssetTag {
    /**
     * 
     * @type {string}
     * @memberof AssetTag
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AssetTag
     */
    'displayName': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AssetTag
     */
    'colors': { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface BankCountryDto
 */
export interface BankCountryDto {
    /**
     * 
     * @type {string}
     * @memberof BankCountryDto
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof BankCountryDto
     */
    'countryName': string;
}
/**
 * 
 * @export
 * @interface BaseUserResponseDto
 */
export interface BaseUserResponseDto {
    /**
     * 
     * @type {string}
     * @memberof BaseUserResponseDto
     */
    'userId'?: string | null;
}
/**
 * 
 * @export
 * @interface CashBalanceDto
 */
export interface CashBalanceDto {
    /**
     * 
     * @type {string}
     * @memberof CashBalanceDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CashBalanceDto
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof CashBalanceDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CashBalanceDto
     */
    'symbol': string;
    /**
     * 
     * @type {number}
     * @memberof CashBalanceDto
     */
    'units': number;
    /**
     * 
     * @type {string}
     * @memberof CashBalanceDto
     */
    'displaySymbol': string;
    /**
     * 
     * @type {number}
     * @memberof CashBalanceDto
     */
    'displayUnits'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CashBalanceDto
     */
    'reservedUnits': number;
}
/**
 * 
 * @export
 * @interface ChangeEmailRequest
 */
export interface ChangeEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangeEmailRequest
     */
    'existingPassword': string;
    /**
     * 
     * @type {string}
     * @memberof ChangeEmailRequest
     */
    'newEmail': string;
}
/**
 * 
 * @export
 * @interface ChangePasswordRequest
 */
export interface ChangePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordRequest
     */
    'existingPassword': string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordRequest
     */
    'newPassword': string;
}
/**
 * 
 * @export
 * @interface ClientApplicationSso
 */
export interface ClientApplicationSso {
    /**
     * 
     * @type {string}
     * @memberof ClientApplicationSso
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ClientApplicationSso
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ClientApplicationSso
     */
    'clientApplicationCode': string;
    /**
     * 
     * @type {string}
     * @memberof ClientApplicationSso
     */
    'ssoProvider': string;
    /**
     * 
     * @type {string}
     * @memberof ClientApplicationSso
     */
    'ssoProviderCode': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClientApplicationSso
     */
    'clientId': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ClientApplicationSso
     */
    'metadataAddress': string;
    /**
     * 
     * @type {number}
     * @memberof ClientApplicationSso
     */
    'metadataAutomaticRefreshIntervalInHours'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClientApplicationSso
     */
    'metadataRefreshIntervalInSeconds'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClientApplicationSso
     */
    'active'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ClientApplicationSso
     */
    'createdAt'?: string | null;
}
/**
 * 
 * @export
 * @interface CompletedDepositsWithdrawalsDto
 */
export interface CompletedDepositsWithdrawalsDto {
    /**
     * 
     * @type {string}
     * @memberof CompletedDepositsWithdrawalsDto
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof CompletedDepositsWithdrawalsDto
     */
    'dateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletedDepositsWithdrawalsDto
     */
    'orderTypeName': string;
    /**
     * 
     * @type {string}
     * @memberof CompletedDepositsWithdrawalsDto
     */
    'orderReference': string;
    /**
     * 
     * @type {number}
     * @memberof CompletedDepositsWithdrawalsDto
     */
    'amountAsFromProduct': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedDepositsWithdrawalsDto
     */
    'amountAsFunctionalProduct': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedDepositsWithdrawalsDto
     */
    'feesAsFromProduct': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedDepositsWithdrawalsDto
     */
    'feesAsFunctionalProduct': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedDepositsWithdrawalsDto
     */
    'amountAfterFees': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedDepositsWithdrawalsDto
     */
    'amountAfterFeesAsFunctionalProduct': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedDepositsWithdrawalsDto
     */
    'currentPrice': number;
    /**
     * 
     * @type {string}
     * @memberof CompletedDepositsWithdrawalsDto
     */
    'symbol': string;
    /**
     * 
     * @type {string}
     * @memberof CompletedDepositsWithdrawalsDto
     */
    'fromProductCode': string;
    /**
     * 
     * @type {string}
     * @memberof CompletedDepositsWithdrawalsDto
     */
    'toProductCode': string;
}
/**
 * 
 * @export
 * @interface CompletedDepositsWithdrawalsDtoPagedList
 */
export interface CompletedDepositsWithdrawalsDtoPagedList {
    /**
     * 
     * @type {Array<CompletedDepositsWithdrawalsDto>}
     * @memberof CompletedDepositsWithdrawalsDtoPagedList
     */
    'items'?: Array<CompletedDepositsWithdrawalsDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CompletedDepositsWithdrawalsDtoPagedList
     */
    'itemsTotal': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedDepositsWithdrawalsDtoPagedList
     */
    'itemsPerPage': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedDepositsWithdrawalsDtoPagedList
     */
    'pageCurrent': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedDepositsWithdrawalsDtoPagedList
     */
    'pagesTotal': number;
}
/**
 * 
 * @export
 * @interface CompletedTransactionHistoryDto
 */
export interface CompletedTransactionHistoryDto {
    /**
     * 
     * @type {string}
     * @memberof CompletedTransactionHistoryDto
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof CompletedTransactionHistoryDto
     */
    'dateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletedTransactionHistoryDto
     */
    'orderTypeName': string;
    /**
     * 
     * @type {number}
     * @memberof CompletedTransactionHistoryDto
     */
    'amountAsFromProduct': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedTransactionHistoryDto
     */
    'amountAsFunctionalProduct': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedTransactionHistoryDto
     */
    'feesAsFromProduct': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedTransactionHistoryDto
     */
    'feesAsFunctionalProduct': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedTransactionHistoryDto
     */
    'amountReceived': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedTransactionHistoryDto
     */
    'amountReceivedAsFunctionalProduct': number;
    /**
     * 
     * @type {string}
     * @memberof CompletedTransactionHistoryDto
     */
    'fromSymbol': string;
    /**
     * 
     * @type {string}
     * @memberof CompletedTransactionHistoryDto
     */
    'toSymbol': string;
    /**
     * 
     * @type {number}
     * @memberof CompletedTransactionHistoryDto
     */
    'orderReference': number;
    /**
     * 
     * @type {TransactionAdjustmentDetailsDto}
     * @memberof CompletedTransactionHistoryDto
     */
    'transactionAdjustmentDetails': TransactionAdjustmentDetailsDto;
}
/**
 * 
 * @export
 * @interface CompletedTransactionHistoryDtoPagedList
 */
export interface CompletedTransactionHistoryDtoPagedList {
    /**
     * 
     * @type {Array<CompletedTransactionHistoryDto>}
     * @memberof CompletedTransactionHistoryDtoPagedList
     */
    'items'?: Array<CompletedTransactionHistoryDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CompletedTransactionHistoryDtoPagedList
     */
    'itemsTotal': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedTransactionHistoryDtoPagedList
     */
    'itemsPerPage': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedTransactionHistoryDtoPagedList
     */
    'pageCurrent': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedTransactionHistoryDtoPagedList
     */
    'pagesTotal': number;
}
/**
 * 
 * @export
 * @interface CompletedVaultTransactionDto
 */
export interface CompletedVaultTransactionDto {
    /**
     * 
     * @type {string}
     * @memberof CompletedVaultTransactionDto
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof CompletedVaultTransactionDto
     */
    'dateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletedVaultTransactionDto
     */
    'orderType': CompletedVaultTransactionDtoOrderTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CompletedVaultTransactionDto
     */
    'orderTypeName': string;
    /**
     * 
     * @type {number}
     * @memberof CompletedVaultTransactionDto
     */
    'amountBeforeFee': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedVaultTransactionDto
     */
    'amountBeforeFeeAsFunctionalProduct': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedVaultTransactionDto
     */
    'fee': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedVaultTransactionDto
     */
    'feeAsFunctionalProduct': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedVaultTransactionDto
     */
    'amountAfterFees': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedVaultTransactionDto
     */
    'amountAfterFeesAsFunctionalProduct': number;
    /**
     * 
     * @type {string}
     * @memberof CompletedVaultTransactionDto
     */
    'functionalSymbol': string;
    /**
     * 
     * @type {string}
     * @memberof CompletedVaultTransactionDto
     */
    'customerFunctionalProduct': string;
    /**
     * 
     * @type {string}
     * @memberof CompletedVaultTransactionDto
     */
    'productCode': string;
    /**
     * 
     * @type {boolean}
     * @memberof CompletedVaultTransactionDto
     */
    'isBonus': boolean;
}

export const CompletedVaultTransactionDtoOrderTypeEnum = {
    All: 'All',
    Lock: 'Lock',
    Unlock: 'Unlock'
} as const;

export type CompletedVaultTransactionDtoOrderTypeEnum = typeof CompletedVaultTransactionDtoOrderTypeEnum[keyof typeof CompletedVaultTransactionDtoOrderTypeEnum];

/**
 * 
 * @export
 * @interface CompletedVaultTransactionDtoPagedList
 */
export interface CompletedVaultTransactionDtoPagedList {
    /**
     * 
     * @type {Array<CompletedVaultTransactionDto>}
     * @memberof CompletedVaultTransactionDtoPagedList
     */
    'items'?: Array<CompletedVaultTransactionDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CompletedVaultTransactionDtoPagedList
     */
    'itemsTotal': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedVaultTransactionDtoPagedList
     */
    'itemsPerPage': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedVaultTransactionDtoPagedList
     */
    'pageCurrent': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedVaultTransactionDtoPagedList
     */
    'pagesTotal': number;
}
/**
 * 
 * @export
 * @interface Country
 */
export interface Country {
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'dialCode': string;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'baseCurrencyCode': string;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'displayCurrencyCode': string;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'iconUrl': string;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'timeZone': string;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'alpha3Code': string;
}
/**
 * 
 * @export
 * @interface CreateCryptoWithdrawDto
 */
export interface CreateCryptoWithdrawDto {
    /**
     * 
     * @type {number}
     * @memberof CreateCryptoWithdrawDto
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof CreateCryptoWithdrawDto
     */
    'quotedFeeAmount': number;
    /**
     * 
     * @type {string}
     * @memberof CreateCryptoWithdrawDto
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCryptoWithdrawDto
     */
    'productCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCryptoWithdrawDto
     */
    'otpFlowTypeCode': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateCryptoWithdrawDto
     */
    'twoFactorPins': { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof CreateCryptoWithdrawDto
     */
    'acceptAbsolution'?: boolean | null;
}
/**
 * 
 * @export
 * @interface CreateFiatDepositDto
 */
export interface CreateFiatDepositDto {
    /**
     * 
     * @type {number}
     * @memberof CreateFiatDepositDto
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof CreateFiatDepositDto
     */
    'quotedFee'?: number | null;
}
/**
 * 
 * @export
 * @interface CreateFiatWithdrawDto
 */
export interface CreateFiatWithdrawDto {
    /**
     * 
     * @type {number}
     * @memberof CreateFiatWithdrawDto
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof CreateFiatWithdrawDto
     */
    'quotedFeeAmount': number;
    /**
     * 
     * @type {string}
     * @memberof CreateFiatWithdrawDto
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFiatWithdrawDto
     */
    'productCode': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateFiatWithdrawDto
     */
    'twoFactorPins': { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof CreateFiatWithdrawDto
     */
    'acceptAbsolution'?: boolean | null;
}
/**
 * 
 * @export
 * @interface CreateRecurringBuyRequest
 */
export interface CreateRecurringBuyRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateRecurringBuyRequest
     */
    'productCode': string;
    /**
     * 
     * @type {number}
     * @memberof CreateRecurringBuyRequest
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof CreateRecurringBuyRequest
     */
    'interval': CreateRecurringBuyRequestIntervalEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateRecurringBuyRequest
     */
    'dayOfMonth'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRecurringBuyRequest
     */
    'dayOfWeek'?: CreateRecurringBuyRequestDayOfWeekEnum | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateRecurringBuyRequest
     */
    'executeImmediately': boolean;
}

export const CreateRecurringBuyRequestIntervalEnum = {
    Weekly: 'Weekly',
    Monthly: 'Monthly'
} as const;

export type CreateRecurringBuyRequestIntervalEnum = typeof CreateRecurringBuyRequestIntervalEnum[keyof typeof CreateRecurringBuyRequestIntervalEnum];
export const CreateRecurringBuyRequestDayOfWeekEnum = {
    Sunday: 'Sunday',
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday'
} as const;

export type CreateRecurringBuyRequestDayOfWeekEnum = typeof CreateRecurringBuyRequestDayOfWeekEnum[keyof typeof CreateRecurringBuyRequestDayOfWeekEnum];

/**
 * 
 * @export
 * @interface CreatedCryptoDepositDetailsDto
 */
export interface CreatedCryptoDepositDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof CreatedCryptoDepositDetailsDto
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof CreatedCryptoDepositDetailsDto
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof CreatedCryptoDepositDetailsDto
     */
    'tag'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreatedCryptoDepositDetailsDto
     */
    'minimumDeposit': number;
}
/**
 * 
 * @export
 * @interface CredentialDto
 */
export interface CredentialDto {
    /**
     * 
     * @type {string}
     * @memberof CredentialDto
     */
    'credential': string;
}
/**
 * 
 * @export
 * @interface CryptoDepositProductDto
 */
export interface CryptoDepositProductDto {
    /**
     * 
     * @type {string}
     * @memberof CryptoDepositProductDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CryptoDepositProductDto
     */
    'ticker': string;
    /**
     * 
     * @type {string}
     * @memberof CryptoDepositProductDto
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof CryptoDepositProductDto
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof CryptoDepositProductDto
     */
    'pendingOrderId'?: string | null;
    /**
     * 
     * @type {StablecoinConversionData}
     * @memberof CryptoDepositProductDto
     */
    'stablecoinConversionData': StablecoinConversionData;
}
/**
 * 
 * @export
 * @interface CryptoWithdrawProductDataDto
 */
export interface CryptoWithdrawProductDataDto {
    /**
     * 
     * @type {number}
     * @memberof CryptoWithdrawProductDataDto
     */
    'balance': number;
    /**
     * 
     * @type {number}
     * @memberof CryptoWithdrawProductDataDto
     */
    'customerFiatBalance'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CryptoWithdrawProductDataDto
     */
    'minimumAmount': number;
    /**
     * 
     * @type {Array<AmountBlockDto>}
     * @memberof CryptoWithdrawProductDataDto
     */
    'amountBlocks': Array<AmountBlockDto>;
    /**
     * 
     * @type {Array<FeeStructureDto>}
     * @memberof CryptoWithdrawProductDataDto
     */
    'fees': Array<FeeStructureDto>;
    /**
     * 
     * @type {CyptoWalletAccountDto}
     * @memberof CryptoWithdrawProductDataDto
     */
    'walletAccount': CyptoWalletAccountDto;
}
/**
 * 
 * @export
 * @interface Currency
 */
export interface Currency {
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'symbol': string;
}
/**
 * 
 * @export
 * @interface CustomerPersonalDataDto
 */
export interface CustomerPersonalDataDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerPersonalDataDto
     */
    'birthCountryCode': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPersonalDataDto
     */
    'birthCity': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPersonalDataDto
     */
    'residencyCountryCode': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPersonalDataDto
     */
    'residencyCity': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPersonalDataDto
     */
    'streetName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPersonalDataDto
     */
    'streetNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPersonalDataDto
     */
    'zipCode': string;
}
/**
 * 
 * @export
 * @interface CyptoWalletAccountDto
 */
export interface CyptoWalletAccountDto {
    /**
     * 
     * @type {string}
     * @memberof CyptoWalletAccountDto
     */
    'customerAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof CyptoWalletAccountDto
     */
    'referenceName': string;
    /**
     * 
     * @type {string}
     * @memberof CyptoWalletAccountDto
     */
    'address': string;
}
/**
 * 
 * @export
 * @interface DetailedRewardDetailsDto
 */
export interface DetailedRewardDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof DetailedRewardDetailsDto
     */
    'referralCode': string;
    /**
     * 
     * @type {number}
     * @memberof DetailedRewardDetailsDto
     */
    'rewardsBalance': number;
    /**
     * 
     * @type {number}
     * @memberof DetailedRewardDetailsDto
     */
    'rewardsBalanceInFiat': number;
    /**
     * 
     * @type {string}
     * @memberof DetailedRewardDetailsDto
     */
    'rewardsBalanceFiatSymbol': string;
    /**
     * 
     * @type {number}
     * @memberof DetailedRewardDetailsDto
     */
    'stars': number;
    /**
     * 
     * @type {string}
     * @memberof DetailedRewardDetailsDto
     */
    'currentTierName': string;
    /**
     * 
     * @type {string}
     * @memberof DetailedRewardDetailsDto
     */
    'nextTierName': string;
    /**
     * 
     * @type {number}
     * @memberof DetailedRewardDetailsDto
     */
    'rewardsPointsRequiredForNextTier': number;
    /**
     * 
     * @type {number}
     * @memberof DetailedRewardDetailsDto
     */
    'rewardsPointsRequiredToRedeem': number;
    /**
     * 
     * @type {Array<RewardsDetailsDto>}
     * @memberof DetailedRewardDetailsDto
     */
    'rewardsDetails': Array<RewardsDetailsDto>;
    /**
     * 
     * @type {number}
     * @memberof DetailedRewardDetailsDto
     */
    'totalLifeTimePointsBalance': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DetailedRewardDetailsDto
     */
    'nextTierBenefits': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DetailedRewardDetailsDto
     */
    'currentTierBenefits': Array<string>;
}
/**
 * 
 * @export
 * @interface DetailedRewardsHistoryItemDto
 */
export interface DetailedRewardsHistoryItemDto {
    /**
     * 
     * @type {string}
     * @memberof DetailedRewardsHistoryItemDto
     */
    'dateTime': string;
    /**
     * 
     * @type {string}
     * @memberof DetailedRewardsHistoryItemDto
     */
    'rewardName': string;
    /**
     * 
     * @type {number}
     * @memberof DetailedRewardsHistoryItemDto
     */
    'rewardAmount': number;
    /**
     * 
     * @type {number}
     * @memberof DetailedRewardsHistoryItemDto
     */
    'rewardAmountInFunctional': number;
    /**
     * 
     * @type {number}
     * @memberof DetailedRewardsHistoryItemDto
     */
    'bitcoinRedeemedAmount': number;
    /**
     * 
     * @type {number}
     * @memberof DetailedRewardsHistoryItemDto
     */
    'runningTotal': number;
    /**
     * 
     * @type {string}
     * @memberof DetailedRewardsHistoryItemDto
     */
    'rewardsTierName': string;
    /**
     * 
     * @type {number}
     * @memberof DetailedRewardsHistoryItemDto
     */
    'rewardTierStarCount': number;
    /**
     * 
     * @type {string}
     * @memberof DetailedRewardsHistoryItemDto
     */
    'symbol': string;
}
/**
 * 
 * @export
 * @interface DetailedRewardsHistoryItemDtoPagedList
 */
export interface DetailedRewardsHistoryItemDtoPagedList {
    /**
     * 
     * @type {Array<DetailedRewardsHistoryItemDto>}
     * @memberof DetailedRewardsHistoryItemDtoPagedList
     */
    'items'?: Array<DetailedRewardsHistoryItemDto> | null;
    /**
     * 
     * @type {number}
     * @memberof DetailedRewardsHistoryItemDtoPagedList
     */
    'itemsTotal': number;
    /**
     * 
     * @type {number}
     * @memberof DetailedRewardsHistoryItemDtoPagedList
     */
    'itemsPerPage': number;
    /**
     * 
     * @type {number}
     * @memberof DetailedRewardsHistoryItemDtoPagedList
     */
    'pageCurrent': number;
    /**
     * 
     * @type {number}
     * @memberof DetailedRewardsHistoryItemDtoPagedList
     */
    'pagesTotal': number;
}
/**
 * 
 * @export
 * @interface EuroBankAccountDto
 */
export interface EuroBankAccountDto {
    /**
     * 
     * @type {string}
     * @memberof EuroBankAccountDto
     */
    'accountId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EuroBankAccountDto
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof EuroBankAccountDto
     */
    'bankName': string;
    /**
     * 
     * @type {string}
     * @memberof EuroBankAccountDto
     */
    'swift': string;
    /**
     * 
     * @type {string}
     * @memberof EuroBankAccountDto
     */
    'iban': string;
    /**
     * 
     * @type {Array<BankCountryDto>}
     * @memberof EuroBankAccountDto
     */
    'countries': Array<BankCountryDto>;
}
/**
 * 
 * @export
 * @interface EuroFiatWithdrawProductDataDto
 */
export interface EuroFiatWithdrawProductDataDto {
    /**
     * 
     * @type {number}
     * @memberof EuroFiatWithdrawProductDataDto
     */
    'balance': number;
    /**
     * 
     * @type {number}
     * @memberof EuroFiatWithdrawProductDataDto
     */
    'minimumAmount': number;
    /**
     * 
     * @type {Array<AmountBlockDto>}
     * @memberof EuroFiatWithdrawProductDataDto
     */
    'amountBlocks': Array<AmountBlockDto>;
    /**
     * 
     * @type {Array<FeeStructureDto>}
     * @memberof EuroFiatWithdrawProductDataDto
     */
    'fees': Array<FeeStructureDto>;
    /**
     * 
     * @type {EuroBankAccountDto}
     * @memberof EuroFiatWithdrawProductDataDto
     */
    'bankAccount': EuroBankAccountDto;
}
/**
 * 
 * @export
 * @interface FeatureFlag
 */
export interface FeatureFlag {
    /**
     * 
     * @type {string}
     * @memberof FeatureFlag
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureFlag
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureFlag
     */
    'displayMessage': string;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureFlag
     */
    'active'?: boolean | null;
}
/**
 * 
 * @export
 * @interface FeeStructureDto
 */
export interface FeeStructureDto {
    /**
     * 
     * @type {string}
     * @memberof FeeStructureDto
     */
    'feeType': string;
    /**
     * 
     * @type {number}
     * @memberof FeeStructureDto
     */
    'feeAmount': number;
    /**
     * 
     * @type {number}
     * @memberof FeeStructureDto
     */
    'minFeeAmount': number;
    /**
     * 
     * @type {number}
     * @memberof FeeStructureDto
     */
    'discount': number;
    /**
     * 
     * @type {number}
     * @memberof FeeStructureDto
     */
    'rangeStart'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FeeStructureDto
     */
    'rangeEnd'?: number | null;
}
/**
 * 
 * @export
 * @interface FiatDepositProductDataDto
 */
export interface FiatDepositProductDataDto {
    /**
     * 
     * @type {number}
     * @memberof FiatDepositProductDataDto
     */
    'minimumAmount': number;
    /**
     * 
     * @type {number}
     * @memberof FiatDepositProductDataDto
     */
    'maximumAmount': number;
    /**
     * 
     * @type {boolean}
     * @memberof FiatDepositProductDataDto
     */
    'hideDepositInstructions': boolean;
    /**
     * 
     * @type {Array<AmountBlockDto>}
     * @memberof FiatDepositProductDataDto
     */
    'amountBlocks': Array<AmountBlockDto>;
    /**
     * 
     * @type {Array<FeeStructureDto>}
     * @memberof FiatDepositProductDataDto
     */
    'feeStructures': Array<FeeStructureDto>;
}
/**
 * 
 * @export
 * @interface ForgotPasswordRequest
 */
export interface ForgotPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordRequest
     */
    'recaptcha': string;
}
/**
 * 
 * @export
 * @interface Gains
 */
export interface Gains {
    /**
     * 
     * @type {number}
     * @memberof Gains
     */
    'gainPercentage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Gains
     */
    'gainAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Gains
     */
    'rate'?: number | null;
}
/**
 * 
 * @export
 * @interface GbpFiatWithdrawProductDataDto
 */
export interface GbpFiatWithdrawProductDataDto {
    /**
     * 
     * @type {number}
     * @memberof GbpFiatWithdrawProductDataDto
     */
    'balance': number;
    /**
     * 
     * @type {number}
     * @memberof GbpFiatWithdrawProductDataDto
     */
    'minimumAmount': number;
    /**
     * 
     * @type {Array<AmountBlockDto>}
     * @memberof GbpFiatWithdrawProductDataDto
     */
    'amountBlocks': Array<AmountBlockDto>;
    /**
     * 
     * @type {Array<FeeStructureDto>}
     * @memberof GbpFiatWithdrawProductDataDto
     */
    'fees': Array<FeeStructureDto>;
    /**
     * 
     * @type {UkBankAccountDto}
     * @memberof GbpFiatWithdrawProductDataDto
     */
    'bankAccount': UkBankAccountDto;
}
/**
 * 
 * @export
 * @interface GetGainLossesQueryResponse
 */
export interface GetGainLossesQueryResponse {
    /**
     * 
     * @type {number}
     * @memberof GetGainLossesQueryResponse
     */
    'portfolioGainPercentage': number;
    /**
     * 
     * @type {number}
     * @memberof GetGainLossesQueryResponse
     */
    'portfolioGainAmount': number;
    /**
     * 
     * @type {Array<ProductHoldingsGainsLosses>}
     * @memberof GetGainLossesQueryResponse
     */
    'productHoldingsGainsLosses': Array<ProductHoldingsGainsLosses>;
}
/**
 * 
 * @export
 * @interface GetPendingsOrdersSimpleQueryResponse
 */
export interface GetPendingsOrdersSimpleQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof GetPendingsOrdersSimpleQueryResponse
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetPendingsOrdersSimpleQueryResponse
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof GetPendingsOrdersSimpleQueryResponse
     */
    'orderActionTypeName': string;
    /**
     * 
     * @type {number}
     * @memberof GetPendingsOrdersSimpleQueryResponse
     */
    'orderActionTypeValue': number;
}
/**
 * 
 * @export
 * @interface GetProductsAndHoldingsQueryResponse
 */
export interface GetProductsAndHoldingsQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'productName': string;
    /**
     * 
     * @type {string}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'productTypeName': string;
    /**
     * 
     * @type {string}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'productType': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'isBuyable'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'isSellable'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'minTrade'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'isDepositable'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'isWithdrawable'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'minWithdrawal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'amount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'symbol': string;
    /**
     * 
     * @type {string}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'redeemsTo': string;
    /**
     * 
     * @type {number}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'minDefiRedeem'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'stakesTo': string;
    /**
     * 
     * @type {number}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'minDefiStake'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'wac'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'isFunctional'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'isDisplay'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'apy'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'canRedeem'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'currentTierName': string;
    /**
     * 
     * @type {string}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'nextTierName': string;
    /**
     * 
     * @type {number}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'minDeposit'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'canLock'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'canUnlock'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'lifetimeRewardsPoints'?: number | null;
    /**
     * 
     * @type {StablecoinAutoConversion}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'stablecoinConversionData': StablecoinAutoConversion;
    /**
     * 
     * @type {string}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'migratedFrom': string;
    /**
     * 
     * @type {string}
     * @memberof GetProductsAndHoldingsQueryResponse
     */
    'lastUpdated'?: string | null;
}
/**
 * 
 * @export
 * @interface GetRewardsDetailsQueryResponse
 */
export interface GetRewardsDetailsQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof GetRewardsDetailsQueryResponse
     */
    'currentRewardTierName': string;
    /**
     * 
     * @type {number}
     * @memberof GetRewardsDetailsQueryResponse
     */
    'lifetimeRewardsPoints': number;
    /**
     * 
     * @type {number}
     * @memberof GetRewardsDetailsQueryResponse
     */
    'pointsForNextTier': number;
    /**
     * 
     * @type {number}
     * @memberof GetRewardsDetailsQueryResponse
     */
    'referrerRewardsPointsAsFunctional': number;
    /**
     * 
     * @type {number}
     * @memberof GetRewardsDetailsQueryResponse
     */
    'referrerRewardsPoints': number;
    /**
     * 
     * @type {number}
     * @memberof GetRewardsDetailsQueryResponse
     */
    'referralDepositAmountAsFunctional': number;
    /**
     * 
     * @type {number}
     * @memberof GetRewardsDetailsQueryResponse
     */
    'referralCount': number;
    /**
     * 
     * @type {boolean}
     * @memberof GetRewardsDetailsQueryResponse
     */
    'dedicatedAccountManager'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof GetRewardsDetailsQueryResponse
     */
    'rewardTierDiscountPercentage'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetRewardsDetailsQueryResponse
     */
    'rewardsTierBenefits': Array<string>;
}
/**
 * 
 * @export
 * @interface InitializeKyxActionRequestDto
 */
export interface InitializeKyxActionRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InitializeKyxActionRequestDto
     */
    'userIp': string;
    /**
     * 
     * @type {string}
     * @memberof InitializeKyxActionRequestDto
     */
    'userCountry': string;
    /**
     * 
     * @type {string}
     * @memberof InitializeKyxActionRequestDto
     */
    'consentObtained': string;
    /**
     * 
     * @type {string}
     * @memberof InitializeKyxActionRequestDto
     */
    'consentObtainedAt': string;
}
/**
 * 
 * @export
 * @interface IntBankAccountDto
 */
export interface IntBankAccountDto {
    /**
     * 
     * @type {string}
     * @memberof IntBankAccountDto
     */
    'accountId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntBankAccountDto
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof IntBankAccountDto
     */
    'accountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof IntBankAccountDto
     */
    'bankName': string;
    /**
     * 
     * @type {string}
     * @memberof IntBankAccountDto
     */
    'accountType': string;
    /**
     * 
     * @type {string}
     * @memberof IntBankAccountDto
     */
    'branchCode': string;
    /**
     * 
     * @type {string}
     * @memberof IntBankAccountDto
     */
    'ibanOrSwift': string;
    /**
     * 
     * @type {Array<BankCountryDto>}
     * @memberof IntBankAccountDto
     */
    'countries': Array<BankCountryDto>;
}
/**
 * 
 * @export
 * @interface LinkWithSsoRequestDto
 */
export interface LinkWithSsoRequestDto {
    /**
     * 
     * @type {string}
     * @memberof LinkWithSsoRequestDto
     */
    'clientApplicationSsoCode': string;
    /**
     * 
     * @type {string}
     * @memberof LinkWithSsoRequestDto
     */
    'ssoJwt': string;
}
/**
 * 
 * @export
 * @interface LinkWithSsoResponseDto
 */
export interface LinkWithSsoResponseDto {
    /**
     * 
     * @type {string}
     * @memberof LinkWithSsoResponseDto
     */
    'userId'?: string | null;
}
/**
 * 
 * @export
 * @interface LinkedDepositAvailableBankDto
 */
export interface LinkedDepositAvailableBankDto {
    /**
     * 
     * @type {string}
     * @memberof LinkedDepositAvailableBankDto
     */
    'bankId': string;
    /**
     * 
     * @type {string}
     * @memberof LinkedDepositAvailableBankDto
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof LinkedDepositAvailableBankDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof LinkedDepositAvailableBankDto
     */
    'logoUrl': string;
}
/**
 * 
 * @export
 * @interface LinkedDepositCustomerAccountCompleteRequest
 */
export interface LinkedDepositCustomerAccountCompleteRequest {
    /**
     * 
     * @type {string}
     * @memberof LinkedDepositCustomerAccountCompleteRequest
     */
    'linkedDepositCustomerAccountId': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LinkedDepositCustomerAccountCompleteRequest
     */
    'customData': { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface LinkedDepositCustomerAccountDto
 */
export interface LinkedDepositCustomerAccountDto {
    /**
     * 
     * @type {string}
     * @memberof LinkedDepositCustomerAccountDto
     */
    'linkedDepositCustomerAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof LinkedDepositCustomerAccountDto
     */
    'referenceName': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LinkedDepositCustomerAccountDto
     */
    'data': { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof LinkedDepositCustomerAccountDto
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof LinkedDepositCustomerAccountDto
     */
    'providerCode': string;
}
/**
 * 
 * @export
 * @interface LinkedDepositCustomerAccountFriendlyNameRequest
 */
export interface LinkedDepositCustomerAccountFriendlyNameRequest {
    /**
     * 
     * @type {string}
     * @memberof LinkedDepositCustomerAccountFriendlyNameRequest
     */
    'linkedDepositCustomerAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof LinkedDepositCustomerAccountFriendlyNameRequest
     */
    'friendlyName': string;
}
/**
 * 
 * @export
 * @interface LinkedDepositCustomerAccountProviderDto
 */
export interface LinkedDepositCustomerAccountProviderDto {
    /**
     * 
     * @type {string}
     * @memberof LinkedDepositCustomerAccountProviderDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof LinkedDepositCustomerAccountProviderDto
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof LinkedDepositCustomerAccountProviderDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface LinkedDepositFiatDepositProductDataDto
 */
export interface LinkedDepositFiatDepositProductDataDto {
    /**
     * 
     * @type {number}
     * @memberof LinkedDepositFiatDepositProductDataDto
     */
    'minimumAmount': number;
    /**
     * 
     * @type {number}
     * @memberof LinkedDepositFiatDepositProductDataDto
     */
    'maximumAmount': number;
    /**
     * 
     * @type {Array<AmountBlockDto>}
     * @memberof LinkedDepositFiatDepositProductDataDto
     */
    'amountBlocks': Array<AmountBlockDto>;
    /**
     * 
     * @type {Array<FeeStructureDto>}
     * @memberof LinkedDepositFiatDepositProductDataDto
     */
    'feeStructures': Array<FeeStructureDto>;
    /**
     * 
     * @type {number}
     * @memberof LinkedDepositFiatDepositProductDataDto
     */
    'maximumDepositAmount': number;
    /**
     * 
     * @type {number}
     * @memberof LinkedDepositFiatDepositProductDataDto
     */
    'calculatedMaximumDepositAmount': number;
}
/**
 * 
 * @export
 * @interface LoginMethodDto
 */
export interface LoginMethodDto {
    /**
     * 
     * @type {string}
     * @memberof LoginMethodDto
     */
    'type': string;
    /**
     * 
     * @type {boolean}
     * @memberof LoginMethodDto
     */
    'enabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoginMethodDto
     */
    'canEnable': boolean;
}
/**
 * 
 * @export
 * @interface ModifyEuroBankAccountDto
 */
export interface ModifyEuroBankAccountDto {
    /**
     * 
     * @type {string}
     * @memberof ModifyEuroBankAccountDto
     */
    'accountId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModifyEuroBankAccountDto
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof ModifyEuroBankAccountDto
     */
    'accountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ModifyEuroBankAccountDto
     */
    'bankName': string;
    /**
     * 
     * @type {string}
     * @memberof ModifyEuroBankAccountDto
     */
    'accountType': string;
    /**
     * 
     * @type {string}
     * @memberof ModifyEuroBankAccountDto
     */
    'swift': string;
    /**
     * 
     * @type {string}
     * @memberof ModifyEuroBankAccountDto
     */
    'iban': string;
}
/**
 * 
 * @export
 * @interface ModifyIntBankAccountDto
 */
export interface ModifyIntBankAccountDto {
    /**
     * 
     * @type {string}
     * @memberof ModifyIntBankAccountDto
     */
    'accountId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModifyIntBankAccountDto
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof ModifyIntBankAccountDto
     */
    'accountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ModifyIntBankAccountDto
     */
    'bankName': string;
    /**
     * 
     * @type {string}
     * @memberof ModifyIntBankAccountDto
     */
    'accountType': string;
    /**
     * 
     * @type {string}
     * @memberof ModifyIntBankAccountDto
     */
    'branchCode': string;
    /**
     * 
     * @type {string}
     * @memberof ModifyIntBankAccountDto
     */
    'ibanOrSwift': string;
}
/**
 * 
 * @export
 * @interface ModifySaBankAccountDto
 */
export interface ModifySaBankAccountDto {
    /**
     * 
     * @type {string}
     * @memberof ModifySaBankAccountDto
     */
    'accountId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModifySaBankAccountDto
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof ModifySaBankAccountDto
     */
    'accountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ModifySaBankAccountDto
     */
    'bankName': string;
    /**
     * 
     * @type {string}
     * @memberof ModifySaBankAccountDto
     */
    'accountType': string;
    /**
     * 
     * @type {string}
     * @memberof ModifySaBankAccountDto
     */
    'branchCode': string;
}
/**
 * 
 * @export
 * @interface ModifyUkBankAccountDto
 */
export interface ModifyUkBankAccountDto {
    /**
     * 
     * @type {string}
     * @memberof ModifyUkBankAccountDto
     */
    'accountId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModifyUkBankAccountDto
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof ModifyUkBankAccountDto
     */
    'accountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ModifyUkBankAccountDto
     */
    'bankName': string;
    /**
     * 
     * @type {string}
     * @memberof ModifyUkBankAccountDto
     */
    'accountType': string;
    /**
     * 
     * @type {string}
     * @memberof ModifyUkBankAccountDto
     */
    'sortCode': string;
}
/**
 * 
 * @export
 * @interface OrderResponseDto
 */
export interface OrderResponseDto {
    /**
     * 
     * @type {string}
     * @memberof OrderResponseDto
     */
    'orderId': string;
}
/**
 * 
 * @export
 * @interface OtpActivateRequest
 */
export interface OtpActivateRequest {
    /**
     * 
     * @type {string}
     * @memberof OtpActivateRequest
     */
    'otp': string;
    /**
     * 
     * @type {string}
     * @memberof OtpActivateRequest
     */
    'otpFlowTypeCode': string;
}
/**
 * 
 * @export
 * @interface OtpDeactivateRequest
 */
export interface OtpDeactivateRequest {
    /**
     * 
     * @type {string}
     * @memberof OtpDeactivateRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof OtpDeactivateRequest
     */
    'otp': string;
}
/**
 * 
 * @export
 * @interface OtpInitiateEmailRequest
 */
export interface OtpInitiateEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof OtpInitiateEmailRequest
     */
    'otpFlowTypeCode': string;
}
/**
 * 
 * @export
 * @interface OtpInitiateRequest
 */
export interface OtpInitiateRequest {
    /**
     * 
     * @type {string}
     * @memberof OtpInitiateRequest
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof OtpInitiateRequest
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof OtpInitiateRequest
     */
    'otpFlowTypeCode': string;
}
/**
 * 
 * @export
 * @interface OtpSendElevatedRequest
 */
export interface OtpSendElevatedRequest {
    /**
     * 
     * @type {string}
     * @memberof OtpSendElevatedRequest
     */
    'otpFlowTypeCode': string;
}
/**
 * 
 * @export
 * @interface OtpSendEmailRequest
 */
export interface OtpSendEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof OtpSendEmailRequest
     */
    'otpFlowTypeCode': string;
}
/**
 * 
 * @export
 * @interface OtpSendSmsRequest
 */
export interface OtpSendSmsRequest {
    /**
     * 
     * @type {string}
     * @memberof OtpSendSmsRequest
     */
    'otpFlowTypeCode': string;
}
/**
 * 
 * @export
 * @interface PendingCryptoWithdrawOrderDto
 */
export interface PendingCryptoWithdrawOrderDto {
    /**
     * 
     * @type {string}
     * @memberof PendingCryptoWithdrawOrderDto
     */
    'productCode': string;
    /**
     * 
     * @type {string}
     * @memberof PendingCryptoWithdrawOrderDto
     */
    'productName': string;
    /**
     * 
     * @type {string}
     * @memberof PendingCryptoWithdrawOrderDto
     */
    'orderReference': string;
    /**
     * 
     * @type {string}
     * @memberof PendingCryptoWithdrawOrderDto
     */
    'orderId': string;
    /**
     * 
     * @type {number}
     * @memberof PendingCryptoWithdrawOrderDto
     */
    'orderAmount': number;
    /**
     * 
     * @type {number}
     * @memberof PendingCryptoWithdrawOrderDto
     */
    'orderAmountInDisplayCurrency': number;
    /**
     * 
     * @type {number}
     * @memberof PendingCryptoWithdrawOrderDto
     */
    'amountAvailable': number;
    /**
     * 
     * @type {string}
     * @memberof PendingCryptoWithdrawOrderDto
     */
    'address': string;
}
/**
 * 
 * @export
 * @interface PendingFiatDepositDetailsDto
 */
export interface PendingFiatDepositDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'depositType': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'paymentMethod': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'productCode': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'productName': string;
    /**
     * 
     * @type {number}
     * @memberof PendingFiatDepositDetailsDto
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'paymentReference': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'accountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'accountType': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'bankName': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'branchCode': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'companyCode': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'currencyCode': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'iban': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'recipient': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'sortCode': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'swiftCode': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'bankAddress1': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'bankAddress2': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'bankAddress3': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'bankPostalCode': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'bankCountry': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'companyAddress1': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'companyAddress2': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'companyAddress3': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'companyPostalCode': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'companyCountry': string;
    /**
     * 
     * @type {number}
     * @memberof PendingFiatDepositDetailsDto
     */
    'linkedAccountDepositFee': number;
    /**
     * 
     * @type {number}
     * @memberof PendingFiatDepositDetailsDto
     */
    'linkedAccountDepositAmountAfterFees': number;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'linkedAccountFriendlyName': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'linkedAccountAccountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'linkedAccountBank': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatDepositDetailsDto
     */
    'linkedAccountProviderCode': string;
    /**
     * 
     * @type {number}
     * @memberof PendingFiatDepositDetailsDto
     */
    'quotedFee'?: number | null;
}
/**
 * 
 * @export
 * @interface PendingFiatWithdrawOrderDto
 */
export interface PendingFiatWithdrawOrderDto {
    /**
     * 
     * @type {string}
     * @memberof PendingFiatWithdrawOrderDto
     */
    'productCode': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatWithdrawOrderDto
     */
    'productName': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatWithdrawOrderDto
     */
    'orderReference': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatWithdrawOrderDto
     */
    'orderId': string;
    /**
     * 
     * @type {number}
     * @memberof PendingFiatWithdrawOrderDto
     */
    'orderAmount': number;
    /**
     * 
     * @type {number}
     * @memberof PendingFiatWithdrawOrderDto
     */
    'amountAvailable': number;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatWithdrawOrderDto
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatWithdrawOrderDto
     */
    'recipient': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatWithdrawOrderDto
     */
    'accountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatWithdrawOrderDto
     */
    'accountType': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatWithdrawOrderDto
     */
    'bank': string;
    /**
     * 
     * @type {string}
     * @memberof PendingFiatWithdrawOrderDto
     */
    'branchCode': string;
    /**
     * 
     * @type {number}
     * @memberof PendingFiatWithdrawOrderDto
     */
    'fee': number;
    /**
     * 
     * @type {number}
     * @memberof PendingFiatWithdrawOrderDto
     */
    'amountReceived': number;
}
/**
 * 
 * @export
 * @interface PendingOrdersDto
 */
export interface PendingOrdersDto {
    /**
     * 
     * @type {string}
     * @memberof PendingOrdersDto
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof PendingOrdersDto
     */
    'dateTime': string;
    /**
     * 
     * @type {string}
     * @memberof PendingOrdersDto
     */
    'orderTypeName': string;
    /**
     * 
     * @type {number}
     * @memberof PendingOrdersDto
     */
    'amountAsFromProduct': number;
    /**
     * 
     * @type {number}
     * @memberof PendingOrdersDto
     */
    'amountAsFunctionalProduct': number;
    /**
     * 
     * @type {number}
     * @memberof PendingOrdersDto
     */
    'feesAsFromProduct': number;
    /**
     * 
     * @type {number}
     * @memberof PendingOrdersDto
     */
    'feesAsFunctionalProduct': number;
    /**
     * 
     * @type {number}
     * @memberof PendingOrdersDto
     */
    'amountAfterFeesAsFromProduct': number;
    /**
     * 
     * @type {number}
     * @memberof PendingOrdersDto
     */
    'amountAfterFeesAsFunctionalProduct': number;
    /**
     * 
     * @type {boolean}
     * @memberof PendingOrdersDto
     */
    'popExistsForDeposit': boolean;
    /**
     * 
     * @type {string}
     * @memberof PendingOrdersDto
     */
    'symbol': string;
    /**
     * 
     * @type {string}
     * @memberof PendingOrdersDto
     */
    'productCode': string;
    /**
     * 
     * @type {number}
     * @memberof PendingOrdersDto
     */
    'currentPrice': number;
}
/**
 * 
 * @export
 * @interface PendingOrdersDtoPagedList
 */
export interface PendingOrdersDtoPagedList {
    /**
     * 
     * @type {Array<PendingOrdersDto>}
     * @memberof PendingOrdersDtoPagedList
     */
    'items'?: Array<PendingOrdersDto> | null;
    /**
     * 
     * @type {number}
     * @memberof PendingOrdersDtoPagedList
     */
    'itemsTotal': number;
    /**
     * 
     * @type {number}
     * @memberof PendingOrdersDtoPagedList
     */
    'itemsPerPage': number;
    /**
     * 
     * @type {number}
     * @memberof PendingOrdersDtoPagedList
     */
    'pageCurrent': number;
    /**
     * 
     * @type {number}
     * @memberof PendingOrdersDtoPagedList
     */
    'pagesTotal': number;
}
/**
 * 
 * @export
 * @interface PerformanceValueDto
 */
export interface PerformanceValueDto {
    /**
     * 
     * @type {number}
     * @memberof PerformanceValueDto
     */
    'timeStamp': number;
    /**
     * 
     * @type {string}
     * @memberof PerformanceValueDto
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof PerformanceValueDto
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof PerformanceValueDto
     */
    'normalisedValue': number;
    /**
     * 
     * @type {number}
     * @memberof PerformanceValueDto
     */
    'change': number;
}
/**
 * 
 * @export
 * @interface PortfolioDurationTimespanDto
 */
export interface PortfolioDurationTimespanDto {
    /**
     * 
     * @type {number}
     * @memberof PortfolioDurationTimespanDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioDurationTimespanDto
     */
    'days': number;
    /**
     * 
     * @type {string}
     * @memberof PortfolioDurationTimespanDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PortfolioHoldingsDto
 */
export interface PortfolioHoldingsDto {
    /**
     * 
     * @type {Array<PortfolioProductHoldingsDto>}
     * @memberof PortfolioHoldingsDto
     */
    'portfolioProductHoldings': Array<PortfolioProductHoldingsDto>;
    /**
     * 
     * @type {RewardsDetailedDto}
     * @memberof PortfolioHoldingsDto
     */
    'rewards': RewardsDetailedDto;
}
/**
 * 
 * @export
 * @interface PortfolioProductHoldingsDto
 */
export interface PortfolioProductHoldingsDto {
    /**
     * 
     * @type {string}
     * @memberof PortfolioProductHoldingsDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioProductHoldingsDto
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioProductHoldingsDto
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof PortfolioProductHoldingsDto
     */
    'units': number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioProductHoldingsDto
     */
    'displayUnits': number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioProductHoldingsDto
     */
    'gainLoss': number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioProductHoldingsDto
     */
    'gainLossValue': number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioProductHoldingsDto
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioProductHoldingsDto
     */
    'latestPrice': number;
    /**
     * 
     * @type {boolean}
     * @memberof PortfolioProductHoldingsDto
     */
    'canInvest': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PortfolioProductHoldingsDto
     */
    'canSell': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PortfolioProductHoldingsDto
     */
    'canDeposit': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PortfolioProductHoldingsDto
     */
    'canWithdraw': boolean;
    /**
     * 
     * @type {number}
     * @memberof PortfolioProductHoldingsDto
     */
    'reservedUnits': number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioProductHoldingsDto
     */
    'reservedUnitsAsDisplayProduct': number;
    /**
     * 
     * @type {boolean}
     * @memberof PortfolioProductHoldingsDto
     */
    'isStakable': boolean;
    /**
     * 
     * @type {number}
     * @memberof PortfolioProductHoldingsDto
     */
    'savingsPercentage'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PortfolioProductHoldingsDto
     */
    'migratedFrom': string;
    /**
     * 
     * @type {Array<ReservedDetails>}
     * @memberof PortfolioProductHoldingsDto
     */
    'reservedDetails': Array<ReservedDetails>;
}
/**
 * 
 * @export
 * @interface PortfolioSummaryDto
 */
export interface PortfolioSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof PortfolioSummaryDto
     */
    'cashFundsAvailable': number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSummaryDto
     */
    'reservedCashFunds': number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSummaryDto
     */
    'portfolioValue': number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSummaryDto
     */
    'portfolioValueChange': number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSummaryDto
     */
    'portfolioPercentageChange': number;
    /**
     * 
     * @type {boolean}
     * @memberof PortfolioSummaryDto
     */
    'canWithdraw': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PortfolioSummaryDto
     */
    'canDeposit': boolean;
    /**
     * 
     * @type {Array<ProductHoldingPercentageDto>}
     * @memberof PortfolioSummaryDto
     */
    'portfolioHoldingsBreakdown': Array<ProductHoldingPercentageDto>;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductHoldingPercentageDto
 */
export interface ProductHoldingPercentageDto {
    /**
     * 
     * @type {string}
     * @memberof ProductHoldingPercentageDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductHoldingPercentageDto
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ProductHoldingPercentageDto
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof ProductHoldingPercentageDto
     */
    'percentage': number;
}
/**
 * 
 * @export
 * @interface ProductHoldings
 */
export interface ProductHoldings {
    /**
     * 
     * @type {GetProductsAndHoldingsQueryResponse}
     * @memberof ProductHoldings
     */
    'holdings': GetProductsAndHoldingsQueryResponse;
    /**
     * 
     * @type {Gains}
     * @memberof ProductHoldings
     */
    'gains': Gains;
    /**
     * 
     * @type {number}
     * @memberof ProductHoldings
     */
    'fiatValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductHoldings
     */
    'vaultGains'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductHoldings
     */
    'breakdownPercentage'?: number | null;
    /**
     * 
     * @type {Array<ReservedAmount>}
     * @memberof ProductHoldings
     */
    'reservedAmounts': Array<ReservedAmount>;
}
/**
 * 
 * @export
 * @interface ProductHoldingsGainsLosses
 */
export interface ProductHoldingsGainsLosses {
    /**
     * 
     * @type {string}
     * @memberof ProductHoldingsGainsLosses
     */
    'productCode': string;
    /**
     * 
     * @type {number}
     * @memberof ProductHoldingsGainsLosses
     */
    'gainPercentage': number;
    /**
     * 
     * @type {number}
     * @memberof ProductHoldingsGainsLosses
     */
    'gainAmount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductHoldingsGainsLosses
     */
    'rate': number;
    /**
     * 
     * @type {string}
     * @memberof ProductHoldingsGainsLosses
     */
    'lastUpdated'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductTransactablePairDto
 */
export interface ProductTransactablePairDto {
    /**
     * 
     * @type {string}
     * @memberof ProductTransactablePairDto
     */
    'fromProductCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductTransactablePairDto
     */
    'toProductCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductTransactablePairDto
     */
    'transactType'?: ProductTransactablePairDtoTransactTypeEnum | null;
}

export const ProductTransactablePairDtoTransactTypeEnum = {
    SellsTo: 'SellsTo',
    Buys: 'Buys'
} as const;

export type ProductTransactablePairDtoTransactTypeEnum = typeof ProductTransactablePairDtoTransactTypeEnum[keyof typeof ProductTransactablePairDtoTransactTypeEnum];

/**
 * 
 * @export
 * @interface ProductVaultDto
 */
export interface ProductVaultDto {
    /**
     * 
     * @type {string}
     * @memberof ProductVaultDto
     */
    'productCode': string;
    /**
     * 
     * @type {string}
     * @memberof ProductVaultDto
     */
    'productName': string;
    /**
     * 
     * @type {number}
     * @memberof ProductVaultDto
     */
    'vaultValue': number;
    /**
     * 
     * @type {number}
     * @memberof ProductVaultDto
     */
    'apy': number;
    /**
     * 
     * @type {number}
     * @memberof ProductVaultDto
     */
    'gains': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductVaultDto
     */
    'pendingLock': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductVaultDto
     */
    'pendingUnlock': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductVaultDto
     */
    'reservedAmount': number;
}
/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'msisdn': string;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    'msisdnConfirmed'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    'emailConfirmed'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'nationalID': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'passport': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'customIdentifier': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'externalIdentifier': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'dateOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'languageCode': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'companyCode': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'verificationStatus': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'statusCode': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'domain': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'source': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'promoCode': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'hearAbout': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Profile
     */
    'twoFactorServices': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'bankAccountType': string;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    'loginNotification'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    'revixRoundUp'?: boolean | null;
    /**
     * 
     * @type {Array<LoginMethodDto>}
     * @memberof Profile
     */
    'loginMethods': Array<LoginMethodDto>;
    /**
     * 
     * @type {TotpStatusDto}
     * @memberof Profile
     */
    'twoFactorStatus': TotpStatusDto;
    /**
     * 
     * @type {Currency}
     * @memberof Profile
     */
    'baseCurrency': Currency;
    /**
     * 
     * @type {Currency}
     * @memberof Profile
     */
    'displayCurrency': Currency;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    'active'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'termsAcceptedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'createdAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Profile
     */
    'minimumTradingAmount'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    'hasAccountCooldown'?: boolean | null;
}
/**
 * 
 * @export
 * @interface ProfileHearAboutDto
 */
export interface ProfileHearAboutDto {
    /**
     * 
     * @type {string}
     * @memberof ProfileHearAboutDto
     */
    'hearAbout': string;
}
/**
 * 
 * @export
 * @interface ProfileStatusDto
 */
export interface ProfileStatusDto {
    /**
     * 
     * @type {Profile}
     * @memberof ProfileStatusDto
     */
    'profile': Profile;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileStatusDto
     */
    'registered': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileStatusDto
     */
    'emailVerified': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileStatusDto
     */
    'detailsCompleted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileStatusDto
     */
    'termsAccepted': boolean;
    /**
     * 
     * @type {AccessTokenDto}
     * @memberof ProfileStatusDto
     */
    'accessToken': AccessTokenDto;
}
/**
 * 
 * @export
 * @interface ProfileUpdateDto
 */
export interface ProfileUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof ProfileUpdateDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUpdateDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUpdateDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUpdateDto
     */
    'msisdn': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUpdateDto
     */
    'nationalID': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUpdateDto
     */
    'passport': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUpdateDto
     */
    'dateOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileUpdateDto
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUpdateDto
     */
    'hearAbout': string;
}
/**
 * 
 * @export
 * @interface PromoCodeRequest
 */
export interface PromoCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof PromoCodeRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface PromoCodeResponse
 */
export interface PromoCodeResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PromoCodeResponse
     */
    'valid'?: boolean | null;
}
/**
 * 
 * @export
 * @interface RFQTradeQuoteDataDto
 */
export interface RFQTradeQuoteDataDto {
    /**
     * 
     * @type {string}
     * @memberof RFQTradeQuoteDataDto
     */
    'orderId': string;
    /**
     * 
     * @type {number}
     * @memberof RFQTradeQuoteDataDto
     */
    'approximateBaseFeeInFromProduct': number;
    /**
     * 
     * @type {number}
     * @memberof RFQTradeQuoteDataDto
     */
    'baseFeeInFunctionalProduct': number;
    /**
     * 
     * @type {number}
     * @memberof RFQTradeQuoteDataDto
     */
    'discountPercentage': number;
    /**
     * 
     * @type {number}
     * @memberof RFQTradeQuoteDataDto
     */
    'approximateFinalFeeInFromProduct': number;
    /**
     * 
     * @type {number}
     * @memberof RFQTradeQuoteDataDto
     */
    'finalFeeInFunctionalProduct': number;
    /**
     * 
     * @type {string}
     * @memberof RFQTradeQuoteDataDto
     */
    'rewardTierCode': string;
    /**
     * 
     * @type {string}
     * @memberof RFQTradeQuoteDataDto
     */
    'rewardTierName': string;
    /**
     * 
     * @type {number}
     * @memberof RFQTradeQuoteDataDto
     */
    'fromAmount': number;
    /**
     * 
     * @type {number}
     * @memberof RFQTradeQuoteDataDto
     */
    'fromAmountInFunctionalProduct': number;
    /**
     * 
     * @type {number}
     * @memberof RFQTradeQuoteDataDto
     */
    'approximateToAmount': number;
    /**
     * 
     * @type {number}
     * @memberof RFQTradeQuoteDataDto
     */
    'toAmountInFunctionalProduct': number;
    /**
     * 
     * @type {number}
     * @memberof RFQTradeQuoteDataDto
     */
    'approximateFromToPrice': number;
    /**
     * 
     * @type {string}
     * @memberof RFQTradeQuoteDataDto
     */
    'expiresAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RFQTradeQuoteDataDto
     */
    'currentTime': string;
}
/**
 * 
 * @export
 * @interface RFQTradeQuoteSelectionDataDto
 */
export interface RFQTradeQuoteSelectionDataDto {
    /**
     * 
     * @type {number}
     * @memberof RFQTradeQuoteSelectionDataDto
     */
    'balanceInDisplayCurrency': number;
    /**
     * 
     * @type {number}
     * @memberof RFQTradeQuoteSelectionDataDto
     */
    'balanceInUnits': number;
    /**
     * 
     * @type {string}
     * @memberof RFQTradeQuoteSelectionDataDto
     */
    'fromProductCode': string;
    /**
     * 
     * @type {string}
     * @memberof RFQTradeQuoteSelectionDataDto
     */
    'fromProductName': string;
    /**
     * 
     * @type {string}
     * @memberof RFQTradeQuoteSelectionDataDto
     */
    'functionalProductSymbol': string;
    /**
     * 
     * @type {string}
     * @memberof RFQTradeQuoteSelectionDataDto
     */
    'toProductCode': string;
    /**
     * 
     * @type {string}
     * @memberof RFQTradeQuoteSelectionDataDto
     */
    'toProductName': string;
    /**
     * 
     * @type {string}
     * @memberof RFQTradeQuoteSelectionDataDto
     */
    'toProductSymbol': string;
    /**
     * 
     * @type {number}
     * @memberof RFQTradeQuoteSelectionDataDto
     */
    'approximateFunctionalToFromProductRate': number;
    /**
     * 
     * @type {number}
     * @memberof RFQTradeQuoteSelectionDataDto
     */
    'approximateFromProductToToProductRate': number;
    /**
     * 
     * @type {number}
     * @memberof RFQTradeQuoteSelectionDataDto
     */
    'minimumAmount': number;
    /**
     * 
     * @type {Array<AmountBlockDto>}
     * @memberof RFQTradeQuoteSelectionDataDto
     */
    'amountBlocks': Array<AmountBlockDto>;
}
/**
 * 
 * @export
 * @interface RFQTradeResultDto
 */
export interface RFQTradeResultDto {
    /**
     * 
     * @type {string}
     * @memberof RFQTradeResultDto
     */
    'orderId': string;
    /**
     * 
     * @type {SmartTradeResultV3Dto}
     * @memberof RFQTradeResultDto
     */
    'smartTradeResult': SmartTradeResultV3Dto;
    /**
     * 
     * @type {number}
     * @memberof RFQTradeResultDto
     */
    'baseFeeInFromProduct': number;
    /**
     * 
     * @type {number}
     * @memberof RFQTradeResultDto
     */
    'baseFeeInFunctionalProduct': number;
    /**
     * 
     * @type {number}
     * @memberof RFQTradeResultDto
     */
    'discountPercentage': number;
    /**
     * 
     * @type {number}
     * @memberof RFQTradeResultDto
     */
    'finalFeeInFromProduct': number;
    /**
     * 
     * @type {number}
     * @memberof RFQTradeResultDto
     */
    'finalFeeInFunctionalProduct': number;
    /**
     * 
     * @type {string}
     * @memberof RFQTradeResultDto
     */
    'rewardTierCode': string;
    /**
     * 
     * @type {string}
     * @memberof RFQTradeResultDto
     */
    'rewardTierName': string;
    /**
     * 
     * @type {number}
     * @memberof RFQTradeResultDto
     */
    'fromAmount': number;
    /**
     * 
     * @type {number}
     * @memberof RFQTradeResultDto
     */
    'fromAmountInFunctionalProduct': number;
    /**
     * 
     * @type {number}
     * @memberof RFQTradeResultDto
     */
    'toAmount': number;
    /**
     * 
     * @type {number}
     * @memberof RFQTradeResultDto
     */
    'toAmountInFunctionalProduct': number;
}
/**
 * 
 * @export
 * @interface RebalanceBreakdown
 */
export interface RebalanceBreakdown {
    /**
     * 
     * @type {number}
     * @memberof RebalanceBreakdown
     */
    'units': number;
    /**
     * 
     * @type {number}
     * @memberof RebalanceBreakdown
     */
    'percentage': number;
}
/**
 * 
 * @export
 * @interface RebalanceBreakdownDto
 */
export interface RebalanceBreakdownDto {
    /**
     * 
     * @type {string}
     * @memberof RebalanceBreakdownDto
     */
    'productCode': string;
    /**
     * 
     * @type {RebalanceBreakdown}
     * @memberof RebalanceBreakdownDto
     */
    'beforeRebalance': RebalanceBreakdown;
    /**
     * 
     * @type {number}
     * @memberof RebalanceBreakdownDto
     */
    'change': number;
    /**
     * 
     * @type {RebalanceBreakdown}
     * @memberof RebalanceBreakdownDto
     */
    'afterRebalance': RebalanceBreakdown;
}
/**
 * 
 * @export
 * @interface RecurringBuyCreateResponse
 */
export interface RecurringBuyCreateResponse {
    /**
     * 
     * @type {string}
     * @memberof RecurringBuyCreateResponse
     */
    'recurringBuyId': string;
    /**
     * 
     * @type {number}
     * @memberof RecurringBuyCreateResponse
     */
    'amountPurchased'?: number | null;
}
/**
 * 
 * @export
 * @interface RecurringBuyResponse
 */
export interface RecurringBuyResponse {
    /**
     * 
     * @type {string}
     * @memberof RecurringBuyResponse
     */
    'recurringBuyId': string;
    /**
     * 
     * @type {string}
     * @memberof RecurringBuyResponse
     */
    'productCode': string;
    /**
     * 
     * @type {boolean}
     * @memberof RecurringBuyResponse
     */
    'paused': boolean;
    /**
     * 
     * @type {number}
     * @memberof RecurringBuyResponse
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof RecurringBuyResponse
     */
    'lastExecutionStatus'?: RecurringBuyResponseLastExecutionStatusEnum | null;
    /**
     * 
     * @type {string}
     * @memberof RecurringBuyResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof RecurringBuyResponse
     */
    'lastExecutionTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecurringBuyResponse
     */
    'nextExecutionDate': string;
    /**
     * 
     * @type {string}
     * @memberof RecurringBuyResponse
     */
    'interval': RecurringBuyResponseIntervalEnum;
    /**
     * 
     * @type {number}
     * @memberof RecurringBuyResponse
     */
    'dayOfMonth'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RecurringBuyResponse
     */
    'dayOfWeek'?: RecurringBuyResponseDayOfWeekEnum | null;
    /**
     * 
     * @type {number}
     * @memberof RecurringBuyResponse
     */
    'successfulExecutions': number;
    /**
     * 
     * @type {number}
     * @memberof RecurringBuyResponse
     */
    'totalInvestedAmount': number;
}

export const RecurringBuyResponseLastExecutionStatusEnum = {
    Success: 'Success',
    InsufficientFunds: 'InsufficientFunds',
    UnknownError: 'UnknownError'
} as const;

export type RecurringBuyResponseLastExecutionStatusEnum = typeof RecurringBuyResponseLastExecutionStatusEnum[keyof typeof RecurringBuyResponseLastExecutionStatusEnum];
export const RecurringBuyResponseIntervalEnum = {
    Weekly: 'Weekly',
    Monthly: 'Monthly'
} as const;

export type RecurringBuyResponseIntervalEnum = typeof RecurringBuyResponseIntervalEnum[keyof typeof RecurringBuyResponseIntervalEnum];
export const RecurringBuyResponseDayOfWeekEnum = {
    Sunday: 'Sunday',
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday'
} as const;

export type RecurringBuyResponseDayOfWeekEnum = typeof RecurringBuyResponseDayOfWeekEnum[keyof typeof RecurringBuyResponseDayOfWeekEnum];

/**
 * 
 * @export
 * @interface RedeemRewardsResponseDto
 */
export interface RedeemRewardsResponseDto {
    /**
     * 
     * @type {string}
     * @memberof RedeemRewardsResponseDto
     */
    'orderId': string;
    /**
     * 
     * @type {number}
     * @memberof RedeemRewardsResponseDto
     */
    'bitcoinAmountReceived': number;
    /**
     * 
     * @type {number}
     * @memberof RedeemRewardsResponseDto
     */
    'amountInDisplayCurrency': number;
}
/**
 * 
 * @export
 * @interface ReferralsHistoryDto
 */
export interface ReferralsHistoryDto {
    /**
     * 
     * @type {string}
     * @memberof ReferralsHistoryDto
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof ReferralsHistoryDto
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof ReferralsHistoryDto
     */
    'referralName': string;
    /**
     * 
     * @type {string}
     * @memberof ReferralsHistoryDto
     */
    'statusName': string;
    /**
     * 
     * @type {string}
     * @memberof ReferralsHistoryDto
     */
    'statusCode': string;
    /**
     * 
     * @type {string}
     * @memberof ReferralsHistoryDto
     */
    'minAmountSymbol': string;
    /**
     * 
     * @type {number}
     * @memberof ReferralsHistoryDto
     */
    'minAmount': number;
}
/**
 * 
 * @export
 * @interface ReferralsHistoryDtoPagedList
 */
export interface ReferralsHistoryDtoPagedList {
    /**
     * 
     * @type {Array<ReferralsHistoryDto>}
     * @memberof ReferralsHistoryDtoPagedList
     */
    'items'?: Array<ReferralsHistoryDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ReferralsHistoryDtoPagedList
     */
    'itemsTotal': number;
    /**
     * 
     * @type {number}
     * @memberof ReferralsHistoryDtoPagedList
     */
    'itemsPerPage': number;
    /**
     * 
     * @type {number}
     * @memberof ReferralsHistoryDtoPagedList
     */
    'pageCurrent': number;
    /**
     * 
     * @type {number}
     * @memberof ReferralsHistoryDtoPagedList
     */
    'pagesTotal': number;
}
/**
 * 
 * @export
 * @interface RegisterRequestDto
 */
export interface RegisterRequestDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterRequestDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequestDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequestDto
     */
    'recaptcha': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequestDto
     */
    'promoCode': string;
}
/**
 * 
 * @export
 * @interface RegisterResponseDto
 */
export interface RegisterResponseDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterResponseDto
     */
    'userId'?: string | null;
}
/**
 * 
 * @export
 * @interface RegisterWithSsoRequestDto
 */
export interface RegisterWithSsoRequestDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterWithSsoRequestDto
     */
    'clientApplicationSsoCode': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterWithSsoRequestDto
     */
    'ssoJwt': string;
}
/**
 * 
 * @export
 * @interface RegisterWithSsoResponseDto
 */
export interface RegisterWithSsoResponseDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterWithSsoResponseDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterWithSsoResponseDto
     */
    'success'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterWithSsoResponseDto
     */
    'twoFactorRequired'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterWithSsoResponseDto
     */
    'twoFactorRegistrationRequired'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterWithSsoResponseDto
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterWithSsoResponseDto
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterWithSsoResponseDto
     */
    'tokenExpires'?: string | null;
}
/**
 * 
 * @export
 * @interface ReservedAmount
 */
export interface ReservedAmount {
    /**
     * 
     * @type {string}
     * @memberof ReservedAmount
     */
    'orderActionTypeName': string;
    /**
     * 
     * @type {number}
     * @memberof ReservedAmount
     */
    'amount': number;
}
/**
 * 
 * @export
 * @interface ReservedDetails
 */
export interface ReservedDetails {
    /**
     * 
     * @type {string}
     * @memberof ReservedDetails
     */
    'orderActionTypeName': string;
    /**
     * 
     * @type {number}
     * @memberof ReservedDetails
     */
    'reservedAmount': number;
    /**
     * 
     * @type {number}
     * @memberof ReservedDetails
     */
    'reservedAmountAsDisplayProduct': number;
}
/**
 * 
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    'credential': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    'newPassword': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    'totp': string;
}
/**
 * 
 * @export
 * @interface ResultDto
 */
export interface ResultDto {
    /**
     * 
     * @type {string}
     * @memberof ResultDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResultDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResultDto
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ResultDto
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface RewardTierDto
 */
export interface RewardTierDto {
    /**
     * 
     * @type {boolean}
     * @memberof RewardTierDto
     */
    'current': boolean;
    /**
     * 
     * @type {string}
     * @memberof RewardTierDto
     */
    'tierName': string;
    /**
     * 
     * @type {number}
     * @memberof RewardTierDto
     */
    'requiredRewardsAmount': number;
    /**
     * 
     * @type {number}
     * @memberof RewardTierDto
     */
    'starsCount': number;
    /**
     * 
     * @type {number}
     * @memberof RewardTierDto
     */
    'percentOff'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RewardTierDto
     */
    'benefits': Array<string>;
}
/**
 * 
 * @export
 * @interface RewardsDetailedDto
 */
export interface RewardsDetailedDto {
    /**
     * 
     * @type {string}
     * @memberof RewardsDetailedDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RewardsDetailedDto
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof RewardsDetailedDto
     */
    'units': number;
    /**
     * 
     * @type {string}
     * @memberof RewardsDetailedDto
     */
    'currentTierName': string;
    /**
     * 
     * @type {string}
     * @memberof RewardsDetailedDto
     */
    'nextTierName': string;
    /**
     * 
     * @type {number}
     * @memberof RewardsDetailedDto
     */
    'minRewardsToRedeem': number;
    /**
     * 
     * @type {boolean}
     * @memberof RewardsDetailedDto
     */
    'canRedeem': boolean;
}
/**
 * 
 * @export
 * @interface RewardsDetailsDto
 */
export interface RewardsDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof RewardsDetailsDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof RewardsDetailsDto
     */
    'rewardId': number;
    /**
     * 
     * @type {string}
     * @memberof RewardsDetailsDto
     */
    'rewardName': string;
    /**
     * 
     * @type {number}
     * @memberof RewardsDetailsDto
     */
    'points': number;
    /**
     * 
     * @type {number}
     * @memberof RewardsDetailsDto
     */
    'customersTotalPoints': number;
    /**
     * 
     * @type {boolean}
     * @memberof RewardsDetailsDto
     */
    'displayCount': boolean;
}
/**
 * 
 * @export
 * @interface SaBankAccountDto
 */
export interface SaBankAccountDto {
    /**
     * 
     * @type {string}
     * @memberof SaBankAccountDto
     */
    'accountId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaBankAccountDto
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof SaBankAccountDto
     */
    'accountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SaBankAccountDto
     */
    'bankName': string;
    /**
     * 
     * @type {string}
     * @memberof SaBankAccountDto
     */
    'accountType': string;
    /**
     * 
     * @type {string}
     * @memberof SaBankAccountDto
     */
    'branchCode': string;
    /**
     * 
     * @type {Array<BankCountryDto>}
     * @memberof SaBankAccountDto
     */
    'countries': Array<BankCountryDto>;
}
/**
 * 
 * @export
 * @interface SmartTradeResultV3Dto
 */
export interface SmartTradeResultV3Dto {
    /**
     * 
     * @type {number}
     * @memberof SmartTradeResultV3Dto
     */
    'estimatePrice': number;
    /**
     * 
     * @type {number}
     * @memberof SmartTradeResultV3Dto
     */
    'actualPrice': number;
    /**
     * 
     * @type {number}
     * @memberof SmartTradeResultV3Dto
     */
    'savingsAsPercentage': number;
}
/**
 * 
 * @export
 * @interface StablecoinAutoConversion
 */
export interface StablecoinAutoConversion {
    /**
     * 
     * @type {string}
     * @memberof StablecoinAutoConversion
     */
    'targetConversionCode': string;
    /**
     * 
     * @type {string}
     * @memberof StablecoinAutoConversion
     */
    'targetConversionName': string;
    /**
     * 
     * @type {boolean}
     * @memberof StablecoinAutoConversion
     */
    'showConversionNotice': boolean;
}
/**
 * 
 * @export
 * @interface StablecoinConversionData
 */
export interface StablecoinConversionData {
    /**
     * 
     * @type {boolean}
     * @memberof StablecoinConversionData
     */
    'showConversionNotice'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof StablecoinConversionData
     */
    'targetConversionCode': string;
    /**
     * 
     * @type {string}
     * @memberof StablecoinConversionData
     */
    'targetConversionName': string;
}
/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'code': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Tag
     */
    'displayNames': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Tag
     */
    'colors': { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface TotpActivateRequest
 */
export interface TotpActivateRequest {
    /**
     * 
     * @type {string}
     * @memberof TotpActivateRequest
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TotpActivateRequest
     */
    'idNumber': string;
    /**
     * 
     * @type {string}
     * @memberof TotpActivateRequest
     */
    'totp': string;
}
/**
 * 
 * @export
 * @interface TotpDisableRequest
 */
export interface TotpDisableRequest {
    /**
     * 
     * @type {string}
     * @memberof TotpDisableRequest
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TotpDisableRequest
     */
    'totp': string;
}
/**
 * 
 * @export
 * @interface TotpStatusDto
 */
export interface TotpStatusDto {
    /**
     * 
     * @type {string}
     * @memberof TotpStatusDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TotpStatusDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TotpStatusDto
     */
    'registered'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TotpStatusDto
     */
    'activated'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TotpStatusDto
     */
    'locked'?: boolean | null;
}
/**
 * 
 * @export
 * @interface TradeProductDto
 */
export interface TradeProductDto {
    /**
     * 
     * @type {string}
     * @memberof TradeProductDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TradeProductDto
     */
    'ticker': string;
    /**
     * 
     * @type {string}
     * @memberof TradeProductDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof TradeProductDto
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof TradeProductDto
     */
    'balanceFiat': number;
    /**
     * 
     * @type {number}
     * @memberof TradeProductDto
     */
    'balance': number;
}
/**
 * 
 * @export
 * @interface TransactionAdjustmentDetailsDto
 */
export interface TransactionAdjustmentDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof TransactionAdjustmentDetailsDto
     */
    'balanceBefore': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionAdjustmentDetailsDto
     */
    'balanceBeforeInFunctionalProduct': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionAdjustmentDetailsDto
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionAdjustmentDetailsDto
     */
    'amountInFunctionalProduct': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionAdjustmentDetailsDto
     */
    'balanceAfter': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionAdjustmentDetailsDto
     */
    'balanceAfterInFunctionalProduct': number;
    /**
     * 
     * @type {string}
     * @memberof TransactionAdjustmentDetailsDto
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface UkBankAccountDto
 */
export interface UkBankAccountDto {
    /**
     * 
     * @type {string}
     * @memberof UkBankAccountDto
     */
    'accountId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UkBankAccountDto
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof UkBankAccountDto
     */
    'accountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UkBankAccountDto
     */
    'bankName': string;
    /**
     * 
     * @type {string}
     * @memberof UkBankAccountDto
     */
    'accountType': string;
    /**
     * 
     * @type {string}
     * @memberof UkBankAccountDto
     */
    'sortCode': string;
    /**
     * 
     * @type {Array<BankCountryDto>}
     * @memberof UkBankAccountDto
     */
    'countries': Array<BankCountryDto>;
}
/**
 * 
 * @export
 * @interface UpdateKyxActionRequestDto
 */
export interface UpdateKyxActionRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateKyxActionRequestDto
     */
    'userActionId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateKyxActionRequestDto
     */
    'providerReference': string;
}
/**
 * 
 * @export
 * @interface UsdFiatWithdrawProductDataDto
 */
export interface UsdFiatWithdrawProductDataDto {
    /**
     * 
     * @type {number}
     * @memberof UsdFiatWithdrawProductDataDto
     */
    'balance': number;
    /**
     * 
     * @type {number}
     * @memberof UsdFiatWithdrawProductDataDto
     */
    'minimumAmount': number;
    /**
     * 
     * @type {Array<AmountBlockDto>}
     * @memberof UsdFiatWithdrawProductDataDto
     */
    'amountBlocks': Array<AmountBlockDto>;
    /**
     * 
     * @type {Array<FeeStructureDto>}
     * @memberof UsdFiatWithdrawProductDataDto
     */
    'fees': Array<FeeStructureDto>;
    /**
     * 
     * @type {IntBankAccountDto}
     * @memberof UsdFiatWithdrawProductDataDto
     */
    'bankAccount': IntBankAccountDto;
}
/**
 * 
 * @export
 * @interface UserTermDto
 */
export interface UserTermDto {
    /**
     * 
     * @type {string}
     * @memberof UserTermDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserTermDto
     */
    'showOnRegistration'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserTermDto
     */
    'mandatory'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserTermDto
     */
    'accepted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface VaspDto
 */
export interface VaspDto {
    /**
     * 
     * @type {string}
     * @memberof VaspDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VaspDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface VerificationActionOption
 */
export interface VerificationActionOption {
    /**
     * 
     * @type {string}
     * @memberof VerificationActionOption
     */
    'field': string;
    /**
     * 
     * @type {string}
     * @memberof VerificationActionOption
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof VerificationActionOption
     */
    'display': string;
    /**
     * 
     * @type {number}
     * @memberof VerificationActionOption
     */
    'points'?: number | null;
}
/**
 * 
 * @export
 * @interface VerificationInitializeOrGetIdUserActionResponseDto
 */
export interface VerificationInitializeOrGetIdUserActionResponseDto {
    /**
     * 
     * @type {string}
     * @memberof VerificationInitializeOrGetIdUserActionResponseDto
     */
    'idUserActionId': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof VerificationInitializeOrGetIdUserActionResponseDto
     */
    'fields': { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface VerificationLevel
 */
export interface VerificationLevel {
    /**
     * 
     * @type {string}
     * @memberof VerificationLevel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof VerificationLevel
     */
    'extendsLevelCode': string;
    /**
     * 
     * @type {string}
     * @memberof VerificationLevel
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof VerificationLevel
     */
    'active'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof VerificationLevel
     */
    'createdAt'?: string | null;
}
/**
 * 
 * @export
 * @interface VerificationSubmissionFieldDto
 */
export interface VerificationSubmissionFieldDto {
    /**
     * 
     * @type {string}
     * @memberof VerificationSubmissionFieldDto
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof VerificationSubmissionFieldDto
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface VerificationTier
 */
export interface VerificationTier {
    /**
     * 
     * @type {string}
     * @memberof VerificationTier
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof VerificationTier
     */
    'verificationLevelCode': string;
    /**
     * 
     * @type {string}
     * @memberof VerificationTier
     */
    'countryCode': string;
    /**
     * 
     * @type {boolean}
     * @memberof VerificationTier
     */
    'active'?: boolean | null;
}
/**
 * 
 * @export
 * @interface VerificationTierStatus
 */
export interface VerificationTierStatus {
    /**
     * 
     * @type {string}
     * @memberof VerificationTierStatus
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VerificationTierStatus
     */
    'countryCode': string;
    /**
     * 
     * @type {VerificationTier}
     * @memberof VerificationTierStatus
     */
    'tier': VerificationTier;
    /**
     * 
     * @type {VerificationLevel}
     * @memberof VerificationTierStatus
     */
    'requiredLevel': VerificationLevel;
    /**
     * 
     * @type {Array<VerificationLevel>}
     * @memberof VerificationTierStatus
     */
    'dependentLevels': Array<VerificationLevel>;
    /**
     * 
     * @type {Array<VerifyActionStatusDto>}
     * @memberof VerificationTierStatus
     */
    'actions': Array<VerifyActionStatusDto>;
    /**
     * 
     * @type {boolean}
     * @memberof VerificationTierStatus
     */
    'started': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VerificationTierStatus
     */
    'completed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VerificationTierStatus
     */
    'active': boolean;
}
/**
 * 
 * @export
 * @interface VerifyActionStatusDto
 */
export interface VerifyActionStatusDto {
    /**
     * 
     * @type {string}
     * @memberof VerifyActionStatusDto
     */
    'actionCode': string;
    /**
     * 
     * @type {string}
     * @memberof VerifyActionStatusDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof VerifyActionStatusDto
     */
    'userMessage': string;
    /**
     * 
     * @type {Array<VerificationActionOption>}
     * @memberof VerifyActionStatusDto
     */
    'userOptions': Array<VerificationActionOption>;
    /**
     * 
     * @type {number}
     * @memberof VerifyActionStatusDto
     */
    'points'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VerifyActionStatusDto
     */
    'providerActionCode': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VerifyActionStatusDto
     */
    'requiredSubmissionFields': Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof VerifyActionStatusDto
     */
    'completedSubmissionFields': { [key: string]: string; };
    /**
     * 
     * @type {Array<string>}
     * @memberof VerifyActionStatusDto
     */
    'requiredVerificationFields': Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof VerifyActionStatusDto
     */
    'completedValidationFields': { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof VerifyActionStatusDto
     */
    'manualVerification'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VerifyActionStatusDto
     */
    'attachmentRequired'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof VerifyActionStatusDto
     */
    'attachmentName': string;
    /**
     * 
     * @type {string}
     * @memberof VerifyActionStatusDto
     */
    'statusCode': string;
    /**
     * 
     * @type {boolean}
     * @memberof VerifyActionStatusDto
     */
    'completed'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof VerifyActionStatusDto
     */
    'completedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VerifyActionStatusDto
     */
    'approved'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof VerifyActionStatusDto
     */
    'approvedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VerifyActionStatusDto
     */
    'rejected'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof VerifyActionStatusDto
     */
    'rejectedReason': string;
    /**
     * 
     * @type {string}
     * @memberof VerifyActionStatusDto
     */
    'rejectedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VerifyActionStatusDto
     */
    'startedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface VerifySubmissionDto
 */
export interface VerifySubmissionDto {
    /**
     * 
     * @type {Array<VerificationSubmissionFieldDto>}
     * @memberof VerifySubmissionDto
     */
    'fields': Array<VerificationSubmissionFieldDto>;
}
/**
 * 
 * @export
 * @interface WalletDto
 */
export interface WalletDto {
    /**
     * 
     * @type {string}
     * @memberof WalletDto
     */
    'accountId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WalletDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof WalletDto
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof WalletDto
     */
    'productCode': string;
    /**
     * 
     * @type {boolean}
     * @memberof WalletDto
     */
    'isHosted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof WalletDto
     */
    'institutionId'?: string | null;
}
/**
 * 
 * @export
 * @interface WithdrawDetailsDto
 */
export interface WithdrawDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof WithdrawDetailsDto
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof WithdrawDetailsDto
     */
    'reference': string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawDetailsDto
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawDetailsDto
     */
    'time': string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawDetailsDto
     */
    'recipient': string;
    /**
     * 
     * @type {number}
     * @memberof WithdrawDetailsDto
     */
    'fee': number;
    /**
     * 
     * @type {number}
     * @memberof WithdrawDetailsDto
     */
    'amountReceived': number;
}
/**
 * 
 * @export
 * @interface WithdrawProductDto
 */
export interface WithdrawProductDto {
    /**
     * 
     * @type {string}
     * @memberof WithdrawProductDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawProductDto
     */
    'ticker': string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawProductDto
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof WithdrawProductDto
     */
    'balance': number;
    /**
     * 
     * @type {number}
     * @memberof WithdrawProductDto
     */
    'balanceCustomerFiat'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WithdrawProductDto
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ZarFiatWithdrawProductDataDto
 */
export interface ZarFiatWithdrawProductDataDto {
    /**
     * 
     * @type {number}
     * @memberof ZarFiatWithdrawProductDataDto
     */
    'balance': number;
    /**
     * 
     * @type {number}
     * @memberof ZarFiatWithdrawProductDataDto
     */
    'minimumAmount': number;
    /**
     * 
     * @type {Array<AmountBlockDto>}
     * @memberof ZarFiatWithdrawProductDataDto
     */
    'amountBlocks': Array<AmountBlockDto>;
    /**
     * 
     * @type {Array<FeeStructureDto>}
     * @memberof ZarFiatWithdrawProductDataDto
     */
    'fees': Array<FeeStructureDto>;
    /**
     * 
     * @type {SaBankAccountDto}
     * @memberof ZarFiatWithdrawProductDataDto
     */
    'bankAccount': SaBankAccountDto;
}

/**
 * BuyApi - axios parameter creator
 * @export
 */
export const BuyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all products that can buy the selected product
         * @param {string} targetProductCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availableProducts: async (targetProductCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'targetProductCode' is not null or undefined
            assertParamExists('availableProducts', 'targetProductCode', targetProductCode)
            const localVarPath = `/buy/{targetProductCode}/products`
                .replace(`{${"targetProductCode"}}`, encodeURIComponent(String(targetProductCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel a pending buy trade order
         * @param {string} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancel: async (orderId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/buy/quote/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirm and complete the buy using the order id
         * @param {string} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        complete: async (orderId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/buy/quote/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get buy quote data
         * @param {string} [fromProductCode] 
         * @param {string} [toProductCode] 
         * @param {number} [amount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuyQuote: async (fromProductCode?: string, toProductCode?: string, amount?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/buy/quote/initiate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fromProductCode !== undefined) {
                localVarQueryParameter['fromProductCode'] = fromProductCode;
            }

            if (toProductCode !== undefined) {
                localVarQueryParameter['toProductCode'] = toProductCode;
            }

            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get buy quote selection data
         * @param {string} [fromProductCode] 
         * @param {string} [toProductCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuyQuoteData: async (fromProductCode?: string, toProductCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/buy/quote/selection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fromProductCode !== undefined) {
                localVarQueryParameter['fromProductCode'] = fromProductCode;
            }

            if (toProductCode !== undefined) {
                localVarQueryParameter['toProductCode'] = toProductCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh the buy quote using the order id
         * @param {string} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh: async (orderId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/buy/quote/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BuyApi - functional programming interface
 * @export
 */
export const BuyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BuyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all products that can buy the selected product
         * @param {string} targetProductCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async availableProducts(targetProductCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TradeProductDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.availableProducts(targetProductCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BuyApi.availableProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Cancel a pending buy trade order
         * @param {string} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancel(orderId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancel(orderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BuyApi.cancel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Confirm and complete the buy using the order id
         * @param {string} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async complete(orderId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RFQTradeResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.complete(orderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BuyApi.complete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get buy quote data
         * @param {string} [fromProductCode] 
         * @param {string} [toProductCode] 
         * @param {number} [amount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBuyQuote(fromProductCode?: string, toProductCode?: string, amount?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RFQTradeQuoteDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBuyQuote(fromProductCode, toProductCode, amount, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BuyApi.getBuyQuote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get buy quote selection data
         * @param {string} [fromProductCode] 
         * @param {string} [toProductCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBuyQuoteData(fromProductCode?: string, toProductCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RFQTradeQuoteSelectionDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBuyQuoteData(fromProductCode, toProductCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BuyApi.getBuyQuoteData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Refresh the buy quote using the order id
         * @param {string} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refresh(orderId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RFQTradeQuoteDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refresh(orderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BuyApi.refresh']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BuyApi - factory interface
 * @export
 */
export const BuyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BuyApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all products that can buy the selected product
         * @param {string} targetProductCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availableProducts(targetProductCode: string, options?: any): AxiosPromise<Array<TradeProductDto>> {
            return localVarFp.availableProducts(targetProductCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel a pending buy trade order
         * @param {string} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancel(orderId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.cancel(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirm and complete the buy using the order id
         * @param {string} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        complete(orderId?: string, options?: any): AxiosPromise<RFQTradeResultDto> {
            return localVarFp.complete(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get buy quote data
         * @param {string} [fromProductCode] 
         * @param {string} [toProductCode] 
         * @param {number} [amount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuyQuote(fromProductCode?: string, toProductCode?: string, amount?: number, options?: any): AxiosPromise<RFQTradeQuoteDataDto> {
            return localVarFp.getBuyQuote(fromProductCode, toProductCode, amount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get buy quote selection data
         * @param {string} [fromProductCode] 
         * @param {string} [toProductCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuyQuoteData(fromProductCode?: string, toProductCode?: string, options?: any): AxiosPromise<RFQTradeQuoteSelectionDataDto> {
            return localVarFp.getBuyQuoteData(fromProductCode, toProductCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh the buy quote using the order id
         * @param {string} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh(orderId?: string, options?: any): AxiosPromise<RFQTradeQuoteDataDto> {
            return localVarFp.refresh(orderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BuyApi - object-oriented interface
 * @export
 * @class BuyApi
 * @extends {BaseAPI}
 */
export class BuyApi extends BaseAPI {
    /**
     * 
     * @summary Get all products that can buy the selected product
     * @param {string} targetProductCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyApi
     */
    public availableProducts(targetProductCode: string, options?: RawAxiosRequestConfig) {
        return BuyApiFp(this.configuration).availableProducts(targetProductCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel a pending buy trade order
     * @param {string} [orderId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyApi
     */
    public cancel(orderId?: string, options?: RawAxiosRequestConfig) {
        return BuyApiFp(this.configuration).cancel(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Confirm and complete the buy using the order id
     * @param {string} [orderId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyApi
     */
    public complete(orderId?: string, options?: RawAxiosRequestConfig) {
        return BuyApiFp(this.configuration).complete(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get buy quote data
     * @param {string} [fromProductCode] 
     * @param {string} [toProductCode] 
     * @param {number} [amount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyApi
     */
    public getBuyQuote(fromProductCode?: string, toProductCode?: string, amount?: number, options?: RawAxiosRequestConfig) {
        return BuyApiFp(this.configuration).getBuyQuote(fromProductCode, toProductCode, amount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get buy quote selection data
     * @param {string} [fromProductCode] 
     * @param {string} [toProductCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyApi
     */
    public getBuyQuoteData(fromProductCode?: string, toProductCode?: string, options?: RawAxiosRequestConfig) {
        return BuyApiFp(this.configuration).getBuyQuoteData(fromProductCode, toProductCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh the buy quote using the order id
     * @param {string} [orderId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyApi
     */
    public refresh(orderId?: string, options?: RawAxiosRequestConfig) {
        return BuyApiFp(this.configuration).refresh(orderId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ContactApi - axios parameter creator
 * @export
 */
export const ContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {string} [phone] 
         * @param {string} [radioService] 
         * @param {Array<string>} [assets] 
         * @param {string} [otherAsset] 
         * @param {string} [transactionValue] 
         * @param {string} [radioContact] 
         * @param {string} [recaptchaResponse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privateClient: async (firstName?: string, lastName?: string, email?: string, phone?: string, radioService?: string, assets?: Array<string>, otherAsset?: string, transactionValue?: string, radioContact?: string, recaptchaResponse?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/contact/privateClient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (firstName !== undefined) { 
                localVarFormParams.append('firstName', firstName as any);
            }
    
            if (lastName !== undefined) { 
                localVarFormParams.append('lastName', lastName as any);
            }
    
            if (email !== undefined) { 
                localVarFormParams.append('email', email as any);
            }
    
            if (phone !== undefined) { 
                localVarFormParams.append('phone', phone as any);
            }
    
            if (radioService !== undefined) { 
                localVarFormParams.append('radioService', radioService as any);
            }
                if (assets) {
                assets.forEach((element) => {
                    localVarFormParams.append('assets', element as any);
                })
            }

    
            if (otherAsset !== undefined) { 
                localVarFormParams.append('otherAsset', otherAsset as any);
            }
    
            if (transactionValue !== undefined) { 
                localVarFormParams.append('transactionValue', transactionValue as any);
            }
    
            if (radioContact !== undefined) { 
                localVarFormParams.append('radioContact', radioContact as any);
            }
    
            if (recaptchaResponse !== undefined) { 
                localVarFormParams.append('recaptchaResponse', recaptchaResponse as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {string} [phone] 
         * @param {string} [radioTax] 
         * @param {string} [specifyCryptoPlatform] 
         * @param {string} [transactionsCount] 
         * @param {string} [recaptchaResponse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tax: async (firstName?: string, lastName?: string, email?: string, phone?: string, radioTax?: string, specifyCryptoPlatform?: string, transactionsCount?: string, recaptchaResponse?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/contact/tax`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (firstName !== undefined) { 
                localVarFormParams.append('firstName', firstName as any);
            }
    
            if (lastName !== undefined) { 
                localVarFormParams.append('lastName', lastName as any);
            }
    
            if (email !== undefined) { 
                localVarFormParams.append('email', email as any);
            }
    
            if (phone !== undefined) { 
                localVarFormParams.append('phone', phone as any);
            }
    
            if (radioTax !== undefined) { 
                localVarFormParams.append('radioTax', radioTax as any);
            }
    
            if (specifyCryptoPlatform !== undefined) { 
                localVarFormParams.append('specifyCryptoPlatform', specifyCryptoPlatform as any);
            }
    
            if (transactionsCount !== undefined) { 
                localVarFormParams.append('transactionsCount', transactionsCount as any);
            }
    
            if (recaptchaResponse !== undefined) { 
                localVarFormParams.append('recaptchaResponse', recaptchaResponse as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactApi - functional programming interface
 * @export
 */
export const ContactApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {string} [phone] 
         * @param {string} [radioService] 
         * @param {Array<string>} [assets] 
         * @param {string} [otherAsset] 
         * @param {string} [transactionValue] 
         * @param {string} [radioContact] 
         * @param {string} [recaptchaResponse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async privateClient(firstName?: string, lastName?: string, email?: string, phone?: string, radioService?: string, assets?: Array<string>, otherAsset?: string, transactionValue?: string, radioContact?: string, recaptchaResponse?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.privateClient(firstName, lastName, email, phone, radioService, assets, otherAsset, transactionValue, radioContact, recaptchaResponse, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactApi.privateClient']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {string} [phone] 
         * @param {string} [radioTax] 
         * @param {string} [specifyCryptoPlatform] 
         * @param {string} [transactionsCount] 
         * @param {string} [recaptchaResponse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tax(firstName?: string, lastName?: string, email?: string, phone?: string, radioTax?: string, specifyCryptoPlatform?: string, transactionsCount?: string, recaptchaResponse?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tax(firstName, lastName, email, phone, radioTax, specifyCryptoPlatform, transactionsCount, recaptchaResponse, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactApi.tax']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ContactApi - factory interface
 * @export
 */
export const ContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {string} [phone] 
         * @param {string} [radioService] 
         * @param {Array<string>} [assets] 
         * @param {string} [otherAsset] 
         * @param {string} [transactionValue] 
         * @param {string} [radioContact] 
         * @param {string} [recaptchaResponse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privateClient(firstName?: string, lastName?: string, email?: string, phone?: string, radioService?: string, assets?: Array<string>, otherAsset?: string, transactionValue?: string, radioContact?: string, recaptchaResponse?: string, options?: any): AxiosPromise<void> {
            return localVarFp.privateClient(firstName, lastName, email, phone, radioService, assets, otherAsset, transactionValue, radioContact, recaptchaResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {string} [phone] 
         * @param {string} [radioTax] 
         * @param {string} [specifyCryptoPlatform] 
         * @param {string} [transactionsCount] 
         * @param {string} [recaptchaResponse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tax(firstName?: string, lastName?: string, email?: string, phone?: string, radioTax?: string, specifyCryptoPlatform?: string, transactionsCount?: string, recaptchaResponse?: string, options?: any): AxiosPromise<void> {
            return localVarFp.tax(firstName, lastName, email, phone, radioTax, specifyCryptoPlatform, transactionsCount, recaptchaResponse, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactApi - object-oriented interface
 * @export
 * @class ContactApi
 * @extends {BaseAPI}
 */
export class ContactApi extends BaseAPI {
    /**
     * 
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {string} [email] 
     * @param {string} [phone] 
     * @param {string} [radioService] 
     * @param {Array<string>} [assets] 
     * @param {string} [otherAsset] 
     * @param {string} [transactionValue] 
     * @param {string} [radioContact] 
     * @param {string} [recaptchaResponse] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public privateClient(firstName?: string, lastName?: string, email?: string, phone?: string, radioService?: string, assets?: Array<string>, otherAsset?: string, transactionValue?: string, radioContact?: string, recaptchaResponse?: string, options?: RawAxiosRequestConfig) {
        return ContactApiFp(this.configuration).privateClient(firstName, lastName, email, phone, radioService, assets, otherAsset, transactionValue, radioContact, recaptchaResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {string} [email] 
     * @param {string} [phone] 
     * @param {string} [radioTax] 
     * @param {string} [specifyCryptoPlatform] 
     * @param {string} [transactionsCount] 
     * @param {string} [recaptchaResponse] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public tax(firstName?: string, lastName?: string, email?: string, phone?: string, radioTax?: string, specifyCryptoPlatform?: string, transactionsCount?: string, recaptchaResponse?: string, options?: RawAxiosRequestConfig) {
        return ContactApiFp(this.configuration).tax(firstName, lastName, email, phone, radioTax, specifyCryptoPlatform, transactionsCount, recaptchaResponse, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a EUR bank account
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {ModifyEuroBankAccountDto} [modifyEuroBankAccountDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBankDetailsEur: async (_2fa?: string, otpFlowTypeCode?: string, modifyEuroBankAccountDto?: ModifyEuroBankAccountDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/bankAccount/euro`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (_2fa != null) {
                localVarHeaderParameter['2fa'] = String(_2fa);
            }

            if (otpFlowTypeCode != null) {
                localVarHeaderParameter['otpFlowTypeCode'] = String(otpFlowTypeCode);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modifyEuroBankAccountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an INT bank account
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {ModifyIntBankAccountDto} [modifyIntBankAccountDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBankDetailsInternational: async (_2fa?: string, otpFlowTypeCode?: string, modifyIntBankAccountDto?: ModifyIntBankAccountDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/bankAccount/int`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (_2fa != null) {
                localVarHeaderParameter['2fa'] = String(_2fa);
            }

            if (otpFlowTypeCode != null) {
                localVarHeaderParameter['otpFlowTypeCode'] = String(otpFlowTypeCode);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modifyIntBankAccountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a SA bank account
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {ModifySaBankAccountDto} [modifySaBankAccountDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBankDetailsSa: async (_2fa?: string, otpFlowTypeCode?: string, modifySaBankAccountDto?: ModifySaBankAccountDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/bankAccount/sa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (_2fa != null) {
                localVarHeaderParameter['2fa'] = String(_2fa);
            }

            if (otpFlowTypeCode != null) {
                localVarHeaderParameter['otpFlowTypeCode'] = String(otpFlowTypeCode);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modifySaBankAccountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a UK bank account
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {ModifyUkBankAccountDto} [modifyUkBankAccountDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBankDetailsUk: async (_2fa?: string, otpFlowTypeCode?: string, modifyUkBankAccountDto?: ModifyUkBankAccountDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/bankAccount/uk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (_2fa != null) {
                localVarHeaderParameter['2fa'] = String(_2fa);
            }

            if (otpFlowTypeCode != null) {
                localVarHeaderParameter['otpFlowTypeCode'] = String(otpFlowTypeCode);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modifyUkBankAccountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerPersonalDataDto} [customerPersonalDataDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdatePersonalData: async (customerPersonalDataDto?: CustomerPersonalDataDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/personalData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerPersonalDataDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new user\'s Crypto wallet.  If no ProductCode is passed in the object body, the value of ProductCode is defaulted to \"BTC\".
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {WalletDto} [walletDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWallet: async (_2fa?: string, otpFlowTypeCode?: string, walletDto?: WalletDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/wallet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (_2fa != null) {
                localVarHeaderParameter['2fa'] = String(_2fa);
            }

            if (otpFlowTypeCode != null) {
                localVarHeaderParameter['otpFlowTypeCode'] = String(otpFlowTypeCode);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(walletDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a users EURO bank account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankAccountEur: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/bankAccount/euro`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a users INT bank account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankAccountInt: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/bankAccount/int`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a users SA bank account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankAccountSa: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/bankAccount/sa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a users UK bank account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankAccountUk: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/bankAccount/uk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalData: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/personalData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a users wallet for a specific ProductCode.  If no productCode is passed in the query string, the value of productCode is defaulted to \"BTC\".
         * @param {string} [productCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWallet: async (productCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/wallet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productCode !== undefined) {
                localVarQueryParameter['productCode'] = productCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a EUR bank account
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {ModifyEuroBankAccountDto} [modifyEuroBankAccountDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBankDetailsEur: async (_2fa?: string, otpFlowTypeCode?: string, modifyEuroBankAccountDto?: ModifyEuroBankAccountDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/bankAccount/euro`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (_2fa != null) {
                localVarHeaderParameter['2fa'] = String(_2fa);
            }

            if (otpFlowTypeCode != null) {
                localVarHeaderParameter['otpFlowTypeCode'] = String(otpFlowTypeCode);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modifyEuroBankAccountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a UK bank account
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {ModifyUkBankAccountDto} [modifyUkBankAccountDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBankDetailsGbp: async (_2fa?: string, otpFlowTypeCode?: string, modifyUkBankAccountDto?: ModifyUkBankAccountDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/bankAccount/uk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (_2fa != null) {
                localVarHeaderParameter['2fa'] = String(_2fa);
            }

            if (otpFlowTypeCode != null) {
                localVarHeaderParameter['otpFlowTypeCode'] = String(otpFlowTypeCode);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modifyUkBankAccountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an INT bank account
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {ModifyIntBankAccountDto} [modifyIntBankAccountDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBankDetailsInternational: async (_2fa?: string, otpFlowTypeCode?: string, modifyIntBankAccountDto?: ModifyIntBankAccountDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/bankAccount/int`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (_2fa != null) {
                localVarHeaderParameter['2fa'] = String(_2fa);
            }

            if (otpFlowTypeCode != null) {
                localVarHeaderParameter['otpFlowTypeCode'] = String(otpFlowTypeCode);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modifyIntBankAccountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a SA bank account
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {ModifySaBankAccountDto} [modifySaBankAccountDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBankDetailsSa: async (_2fa?: string, otpFlowTypeCode?: string, modifySaBankAccountDto?: ModifySaBankAccountDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/bankAccount/sa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (_2fa != null) {
                localVarHeaderParameter['2fa'] = String(_2fa);
            }

            if (otpFlowTypeCode != null) {
                localVarHeaderParameter['otpFlowTypeCode'] = String(otpFlowTypeCode);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modifySaBankAccountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing user\'s Crypto wallet.  If no ProductCode is passed in the object body, the value of ProductCode is defaulted to \"BTC\".
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {WalletDto} [walletDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWallet: async (_2fa?: string, otpFlowTypeCode?: string, walletDto?: WalletDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/wallet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (_2fa != null) {
                localVarHeaderParameter['2fa'] = String(_2fa);
            }

            if (otpFlowTypeCode != null) {
                localVarHeaderParameter['otpFlowTypeCode'] = String(otpFlowTypeCode);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(walletDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a EUR bank account
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {ModifyEuroBankAccountDto} [modifyEuroBankAccountDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBankDetailsEur(_2fa?: string, otpFlowTypeCode?: string, modifyEuroBankAccountDto?: ModifyEuroBankAccountDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EuroBankAccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBankDetailsEur(_2fa, otpFlowTypeCode, modifyEuroBankAccountDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.createBankDetailsEur']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create an INT bank account
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {ModifyIntBankAccountDto} [modifyIntBankAccountDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBankDetailsInternational(_2fa?: string, otpFlowTypeCode?: string, modifyIntBankAccountDto?: ModifyIntBankAccountDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntBankAccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBankDetailsInternational(_2fa, otpFlowTypeCode, modifyIntBankAccountDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.createBankDetailsInternational']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a SA bank account
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {ModifySaBankAccountDto} [modifySaBankAccountDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBankDetailsSa(_2fa?: string, otpFlowTypeCode?: string, modifySaBankAccountDto?: ModifySaBankAccountDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaBankAccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBankDetailsSa(_2fa, otpFlowTypeCode, modifySaBankAccountDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.createBankDetailsSa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a UK bank account
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {ModifyUkBankAccountDto} [modifyUkBankAccountDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBankDetailsUk(_2fa?: string, otpFlowTypeCode?: string, modifyUkBankAccountDto?: ModifyUkBankAccountDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UkBankAccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBankDetailsUk(_2fa, otpFlowTypeCode, modifyUkBankAccountDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.createBankDetailsUk']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CustomerPersonalDataDto} [customerPersonalDataDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdatePersonalData(customerPersonalDataDto?: CustomerPersonalDataDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdatePersonalData(customerPersonalDataDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.createOrUpdatePersonalData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a new user\'s Crypto wallet.  If no ProductCode is passed in the object body, the value of ProductCode is defaulted to \"BTC\".
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {WalletDto} [walletDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWallet(_2fa?: string, otpFlowTypeCode?: string, walletDto?: WalletDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWallet(_2fa, otpFlowTypeCode, walletDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.createWallet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a users EURO bank account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankAccountEur(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EuroBankAccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBankAccountEur(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.getBankAccountEur']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a users INT bank account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankAccountInt(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntBankAccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBankAccountInt(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.getBankAccountInt']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a users SA bank account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankAccountSa(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaBankAccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBankAccountSa(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.getBankAccountSa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a users UK bank account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankAccountUk(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UkBankAccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBankAccountUk(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.getBankAccountUk']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonalData(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerPersonalDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonalData(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.getPersonalData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a users wallet for a specific ProductCode.  If no productCode is passed in the query string, the value of productCode is defaulted to \"BTC\".
         * @param {string} [productCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWallet(productCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWallet(productCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.getWallet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a EUR bank account
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {ModifyEuroBankAccountDto} [modifyEuroBankAccountDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBankDetailsEur(_2fa?: string, otpFlowTypeCode?: string, modifyEuroBankAccountDto?: ModifyEuroBankAccountDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EuroBankAccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBankDetailsEur(_2fa, otpFlowTypeCode, modifyEuroBankAccountDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.updateBankDetailsEur']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a UK bank account
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {ModifyUkBankAccountDto} [modifyUkBankAccountDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBankDetailsGbp(_2fa?: string, otpFlowTypeCode?: string, modifyUkBankAccountDto?: ModifyUkBankAccountDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UkBankAccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBankDetailsGbp(_2fa, otpFlowTypeCode, modifyUkBankAccountDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.updateBankDetailsGbp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update an INT bank account
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {ModifyIntBankAccountDto} [modifyIntBankAccountDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBankDetailsInternational(_2fa?: string, otpFlowTypeCode?: string, modifyIntBankAccountDto?: ModifyIntBankAccountDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntBankAccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBankDetailsInternational(_2fa, otpFlowTypeCode, modifyIntBankAccountDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.updateBankDetailsInternational']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a SA bank account
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {ModifySaBankAccountDto} [modifySaBankAccountDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBankDetailsSa(_2fa?: string, otpFlowTypeCode?: string, modifySaBankAccountDto?: ModifySaBankAccountDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaBankAccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBankDetailsSa(_2fa, otpFlowTypeCode, modifySaBankAccountDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.updateBankDetailsSa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update an existing user\'s Crypto wallet.  If no ProductCode is passed in the object body, the value of ProductCode is defaulted to \"BTC\".
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {WalletDto} [walletDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWallet(_2fa?: string, otpFlowTypeCode?: string, walletDto?: WalletDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWallet(_2fa, otpFlowTypeCode, walletDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.updateWallet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a EUR bank account
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {ModifyEuroBankAccountDto} [modifyEuroBankAccountDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBankDetailsEur(_2fa?: string, otpFlowTypeCode?: string, modifyEuroBankAccountDto?: ModifyEuroBankAccountDto, options?: any): AxiosPromise<EuroBankAccountDto> {
            return localVarFp.createBankDetailsEur(_2fa, otpFlowTypeCode, modifyEuroBankAccountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an INT bank account
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {ModifyIntBankAccountDto} [modifyIntBankAccountDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBankDetailsInternational(_2fa?: string, otpFlowTypeCode?: string, modifyIntBankAccountDto?: ModifyIntBankAccountDto, options?: any): AxiosPromise<IntBankAccountDto> {
            return localVarFp.createBankDetailsInternational(_2fa, otpFlowTypeCode, modifyIntBankAccountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a SA bank account
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {ModifySaBankAccountDto} [modifySaBankAccountDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBankDetailsSa(_2fa?: string, otpFlowTypeCode?: string, modifySaBankAccountDto?: ModifySaBankAccountDto, options?: any): AxiosPromise<SaBankAccountDto> {
            return localVarFp.createBankDetailsSa(_2fa, otpFlowTypeCode, modifySaBankAccountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a UK bank account
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {ModifyUkBankAccountDto} [modifyUkBankAccountDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBankDetailsUk(_2fa?: string, otpFlowTypeCode?: string, modifyUkBankAccountDto?: ModifyUkBankAccountDto, options?: any): AxiosPromise<UkBankAccountDto> {
            return localVarFp.createBankDetailsUk(_2fa, otpFlowTypeCode, modifyUkBankAccountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerPersonalDataDto} [customerPersonalDataDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdatePersonalData(customerPersonalDataDto?: CustomerPersonalDataDto, options?: any): AxiosPromise<void> {
            return localVarFp.createOrUpdatePersonalData(customerPersonalDataDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new user\'s Crypto wallet.  If no ProductCode is passed in the object body, the value of ProductCode is defaulted to \"BTC\".
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {WalletDto} [walletDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWallet(_2fa?: string, otpFlowTypeCode?: string, walletDto?: WalletDto, options?: any): AxiosPromise<WalletDto> {
            return localVarFp.createWallet(_2fa, otpFlowTypeCode, walletDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a users EURO bank account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankAccountEur(options?: any): AxiosPromise<EuroBankAccountDto> {
            return localVarFp.getBankAccountEur(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a users INT bank account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankAccountInt(options?: any): AxiosPromise<IntBankAccountDto> {
            return localVarFp.getBankAccountInt(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a users SA bank account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankAccountSa(options?: any): AxiosPromise<SaBankAccountDto> {
            return localVarFp.getBankAccountSa(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a users UK bank account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankAccountUk(options?: any): AxiosPromise<UkBankAccountDto> {
            return localVarFp.getBankAccountUk(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalData(options?: any): AxiosPromise<CustomerPersonalDataDto> {
            return localVarFp.getPersonalData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a users wallet for a specific ProductCode.  If no productCode is passed in the query string, the value of productCode is defaulted to \"BTC\".
         * @param {string} [productCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWallet(productCode?: string, options?: any): AxiosPromise<WalletDto> {
            return localVarFp.getWallet(productCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a EUR bank account
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {ModifyEuroBankAccountDto} [modifyEuroBankAccountDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBankDetailsEur(_2fa?: string, otpFlowTypeCode?: string, modifyEuroBankAccountDto?: ModifyEuroBankAccountDto, options?: any): AxiosPromise<EuroBankAccountDto> {
            return localVarFp.updateBankDetailsEur(_2fa, otpFlowTypeCode, modifyEuroBankAccountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a UK bank account
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {ModifyUkBankAccountDto} [modifyUkBankAccountDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBankDetailsGbp(_2fa?: string, otpFlowTypeCode?: string, modifyUkBankAccountDto?: ModifyUkBankAccountDto, options?: any): AxiosPromise<UkBankAccountDto> {
            return localVarFp.updateBankDetailsGbp(_2fa, otpFlowTypeCode, modifyUkBankAccountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an INT bank account
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {ModifyIntBankAccountDto} [modifyIntBankAccountDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBankDetailsInternational(_2fa?: string, otpFlowTypeCode?: string, modifyIntBankAccountDto?: ModifyIntBankAccountDto, options?: any): AxiosPromise<IntBankAccountDto> {
            return localVarFp.updateBankDetailsInternational(_2fa, otpFlowTypeCode, modifyIntBankAccountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a SA bank account
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {ModifySaBankAccountDto} [modifySaBankAccountDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBankDetailsSa(_2fa?: string, otpFlowTypeCode?: string, modifySaBankAccountDto?: ModifySaBankAccountDto, options?: any): AxiosPromise<SaBankAccountDto> {
            return localVarFp.updateBankDetailsSa(_2fa, otpFlowTypeCode, modifySaBankAccountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing user\'s Crypto wallet.  If no ProductCode is passed in the object body, the value of ProductCode is defaulted to \"BTC\".
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {WalletDto} [walletDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWallet(_2fa?: string, otpFlowTypeCode?: string, walletDto?: WalletDto, options?: any): AxiosPromise<WalletDto> {
            return localVarFp.updateWallet(_2fa, otpFlowTypeCode, walletDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * 
     * @summary Create a EUR bank account
     * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
     * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
     * @param {ModifyEuroBankAccountDto} [modifyEuroBankAccountDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public createBankDetailsEur(_2fa?: string, otpFlowTypeCode?: string, modifyEuroBankAccountDto?: ModifyEuroBankAccountDto, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).createBankDetailsEur(_2fa, otpFlowTypeCode, modifyEuroBankAccountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an INT bank account
     * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
     * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
     * @param {ModifyIntBankAccountDto} [modifyIntBankAccountDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public createBankDetailsInternational(_2fa?: string, otpFlowTypeCode?: string, modifyIntBankAccountDto?: ModifyIntBankAccountDto, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).createBankDetailsInternational(_2fa, otpFlowTypeCode, modifyIntBankAccountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a SA bank account
     * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
     * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
     * @param {ModifySaBankAccountDto} [modifySaBankAccountDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public createBankDetailsSa(_2fa?: string, otpFlowTypeCode?: string, modifySaBankAccountDto?: ModifySaBankAccountDto, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).createBankDetailsSa(_2fa, otpFlowTypeCode, modifySaBankAccountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a UK bank account
     * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
     * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
     * @param {ModifyUkBankAccountDto} [modifyUkBankAccountDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public createBankDetailsUk(_2fa?: string, otpFlowTypeCode?: string, modifyUkBankAccountDto?: ModifyUkBankAccountDto, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).createBankDetailsUk(_2fa, otpFlowTypeCode, modifyUkBankAccountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerPersonalDataDto} [customerPersonalDataDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public createOrUpdatePersonalData(customerPersonalDataDto?: CustomerPersonalDataDto, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).createOrUpdatePersonalData(customerPersonalDataDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new user\'s Crypto wallet.  If no ProductCode is passed in the object body, the value of ProductCode is defaulted to \"BTC\".
     * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
     * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
     * @param {WalletDto} [walletDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public createWallet(_2fa?: string, otpFlowTypeCode?: string, walletDto?: WalletDto, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).createWallet(_2fa, otpFlowTypeCode, walletDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a users EURO bank account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getBankAccountEur(options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getBankAccountEur(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a users INT bank account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getBankAccountInt(options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getBankAccountInt(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a users SA bank account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getBankAccountSa(options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getBankAccountSa(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a users UK bank account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getBankAccountUk(options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getBankAccountUk(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getPersonalData(options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getPersonalData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a users wallet for a specific ProductCode.  If no productCode is passed in the query string, the value of productCode is defaulted to \"BTC\".
     * @param {string} [productCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getWallet(productCode?: string, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getWallet(productCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a EUR bank account
     * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
     * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
     * @param {ModifyEuroBankAccountDto} [modifyEuroBankAccountDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public updateBankDetailsEur(_2fa?: string, otpFlowTypeCode?: string, modifyEuroBankAccountDto?: ModifyEuroBankAccountDto, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).updateBankDetailsEur(_2fa, otpFlowTypeCode, modifyEuroBankAccountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a UK bank account
     * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
     * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
     * @param {ModifyUkBankAccountDto} [modifyUkBankAccountDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public updateBankDetailsGbp(_2fa?: string, otpFlowTypeCode?: string, modifyUkBankAccountDto?: ModifyUkBankAccountDto, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).updateBankDetailsGbp(_2fa, otpFlowTypeCode, modifyUkBankAccountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an INT bank account
     * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
     * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
     * @param {ModifyIntBankAccountDto} [modifyIntBankAccountDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public updateBankDetailsInternational(_2fa?: string, otpFlowTypeCode?: string, modifyIntBankAccountDto?: ModifyIntBankAccountDto, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).updateBankDetailsInternational(_2fa, otpFlowTypeCode, modifyIntBankAccountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a SA bank account
     * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
     * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
     * @param {ModifySaBankAccountDto} [modifySaBankAccountDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public updateBankDetailsSa(_2fa?: string, otpFlowTypeCode?: string, modifySaBankAccountDto?: ModifySaBankAccountDto, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).updateBankDetailsSa(_2fa, otpFlowTypeCode, modifySaBankAccountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing user\'s Crypto wallet.  If no ProductCode is passed in the object body, the value of ProductCode is defaulted to \"BTC\".
     * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
     * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
     * @param {WalletDto} [walletDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public updateWallet(_2fa?: string, otpFlowTypeCode?: string, walletDto?: WalletDto, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).updateWallet(_2fa, otpFlowTypeCode, walletDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DepositCryptoApi - axios parameter creator
 * @export
 */
export const DepositCryptoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all available crypto products that can be deposited
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availableProducts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/deposit/crypto/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User can cancel an order
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancel: async (orderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('cancel', 'orderId', orderId)
            const localVarPath = `/deposit/crypto/{orderId}/cancel`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a crypto deposit
         * @param {string} productCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeposit: async (productCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productCode' is not null or undefined
            assertParamExists('createDeposit', 'productCode', productCode)
            const localVarPath = `/deposit/crypto/{productCode}`
                .replace(`{${"productCode"}}`, encodeURIComponent(String(productCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        depositMade: async (orderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('depositMade', 'orderId', orderId)
            const localVarPath = `/deposit/crypto/{orderId}/depositMade`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a crypto deposit details
         * @param {string} productCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeposit: async (productCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productCode' is not null or undefined
            assertParamExists('getDeposit', 'productCode', productCode)
            const localVarPath = `/deposit/crypto/{productCode}`
                .replace(`{${"productCode"}}`, encodeURIComponent(String(productCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Hide the stablecoin coversion notice for a specified product
         * @param {string} productcode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hideStablecoinConversionNotice: async (productcode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productcode' is not null or undefined
            assertParamExists('hideStablecoinConversionNotice', 'productcode', productcode)
            const localVarPath = `/deposit/crypto/hideStablecoinNotice/{productcode}`
                .replace(`{${"productcode"}}`, encodeURIComponent(String(productcode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepositCryptoApi - functional programming interface
 * @export
 */
export const DepositCryptoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepositCryptoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all available crypto products that can be deposited
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async availableProducts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CryptoDepositProductDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.availableProducts(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepositCryptoApi.availableProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary User can cancel an order
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancel(orderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancel(orderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepositCryptoApi.cancel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a crypto deposit
         * @param {string} productCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDeposit(productCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedCryptoDepositDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDeposit(productCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepositCryptoApi.createDeposit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async depositMade(orderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.depositMade(orderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepositCryptoApi.depositMade']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a crypto deposit details
         * @param {string} productCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeposit(productCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedCryptoDepositDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeposit(productCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepositCryptoApi.getDeposit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Hide the stablecoin coversion notice for a specified product
         * @param {string} productcode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hideStablecoinConversionNotice(productcode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hideStablecoinConversionNotice(productcode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepositCryptoApi.hideStablecoinConversionNotice']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DepositCryptoApi - factory interface
 * @export
 */
export const DepositCryptoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepositCryptoApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all available crypto products that can be deposited
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availableProducts(options?: any): AxiosPromise<Array<CryptoDepositProductDto>> {
            return localVarFp.availableProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User can cancel an order
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancel(orderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancel(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a crypto deposit
         * @param {string} productCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeposit(productCode: string, options?: any): AxiosPromise<CreatedCryptoDepositDetailsDto> {
            return localVarFp.createDeposit(productCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        depositMade(orderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.depositMade(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a crypto deposit details
         * @param {string} productCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeposit(productCode: string, options?: any): AxiosPromise<CreatedCryptoDepositDetailsDto> {
            return localVarFp.getDeposit(productCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Hide the stablecoin coversion notice for a specified product
         * @param {string} productcode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hideStablecoinConversionNotice(productcode: string, options?: any): AxiosPromise<void> {
            return localVarFp.hideStablecoinConversionNotice(productcode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DepositCryptoApi - object-oriented interface
 * @export
 * @class DepositCryptoApi
 * @extends {BaseAPI}
 */
export class DepositCryptoApi extends BaseAPI {
    /**
     * 
     * @summary Get all available crypto products that can be deposited
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositCryptoApi
     */
    public availableProducts(options?: RawAxiosRequestConfig) {
        return DepositCryptoApiFp(this.configuration).availableProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User can cancel an order
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositCryptoApi
     */
    public cancel(orderId: string, options?: RawAxiosRequestConfig) {
        return DepositCryptoApiFp(this.configuration).cancel(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a crypto deposit
     * @param {string} productCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositCryptoApi
     */
    public createDeposit(productCode: string, options?: RawAxiosRequestConfig) {
        return DepositCryptoApiFp(this.configuration).createDeposit(productCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositCryptoApi
     */
    public depositMade(orderId: string, options?: RawAxiosRequestConfig) {
        return DepositCryptoApiFp(this.configuration).depositMade(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a crypto deposit details
     * @param {string} productCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositCryptoApi
     */
    public getDeposit(productCode: string, options?: RawAxiosRequestConfig) {
        return DepositCryptoApiFp(this.configuration).getDeposit(productCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Hide the stablecoin coversion notice for a specified product
     * @param {string} productcode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositCryptoApi
     */
    public hideStablecoinConversionNotice(productcode: string, options?: RawAxiosRequestConfig) {
        return DepositCryptoApiFp(this.configuration).hideStablecoinConversionNotice(productcode, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DepositFiatApi - axios parameter creator
 * @export
 */
export const DepositFiatApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary User can cancel an order
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancel: async (orderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('cancel', 'orderId', orderId)
            const localVarPath = `/deposit/fiat/{orderId}/cancel`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a fiat deposit
         * @param {CreateFiatDepositDto} [createFiatDepositDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFiatDeposit: async (createFiatDepositDto?: CreateFiatDepositDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/deposit/fiat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFiatDepositDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get data specific to the customer\'s functional fiat product for the deposit flow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiatDepositProductData: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/deposit/fiat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get data specific to the customer\'s functional fiat product for the LinkedDeposit deposit flow
         * @param {string} linkedDepositCustomerAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkedDepositFiatDepositProductData: async (linkedDepositCustomerAccountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'linkedDepositCustomerAccountId' is not null or undefined
            assertParamExists('getLinkedDepositFiatDepositProductData', 'linkedDepositCustomerAccountId', linkedDepositCustomerAccountId)
            const localVarPath = `/deposit/fiat/linkedDeposit/{linkedDepositCustomerAccountId}`
                .replace(`{${"linkedDepositCustomerAccountId"}}`, encodeURIComponent(String(linkedDepositCustomerAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pending fiat deposit orders for the customer including the payment method
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingFiatDepositOrderDetails: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/deposit/fiat/pending`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hideDepositInstructions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/deposit/fiat/hideInstructions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Specify whether a payment has been made, or will be made later
         * @param {string} [orderId] 
         * @param {boolean} [paymentMade] 
         * @param {File} [popFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentMade: async (orderId?: string, paymentMade?: boolean, popFile?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/deposit/fiat/paymentMade`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (orderId !== undefined) { 
                localVarFormParams.append('OrderId', orderId as any);
            }
    
            if (paymentMade !== undefined) { 
                localVarFormParams.append('PaymentMade', String(paymentMade) as any);
            }
    
            if (popFile !== undefined) { 
                localVarFormParams.append('PopFile', popFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepositFiatApi - functional programming interface
 * @export
 */
export const DepositFiatApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepositFiatApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary User can cancel an order
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancel(orderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancel(orderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepositFiatApi.cancel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a fiat deposit
         * @param {CreateFiatDepositDto} [createFiatDepositDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFiatDeposit(createFiatDepositDto?: CreateFiatDepositDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFiatDeposit(createFiatDepositDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepositFiatApi.createFiatDeposit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get data specific to the customer\'s functional fiat product for the deposit flow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFiatDepositProductData(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FiatDepositProductDataDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFiatDepositProductData(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepositFiatApi.getFiatDepositProductData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get data specific to the customer\'s functional fiat product for the LinkedDeposit deposit flow
         * @param {string} linkedDepositCustomerAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLinkedDepositFiatDepositProductData(linkedDepositCustomerAccountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LinkedDepositFiatDepositProductDataDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLinkedDepositFiatDepositProductData(linkedDepositCustomerAccountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepositFiatApi.getLinkedDepositFiatDepositProductData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get pending fiat deposit orders for the customer including the payment method
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPendingFiatDepositOrderDetails(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PendingFiatDepositDetailsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPendingFiatDepositOrderDetails(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepositFiatApi.getPendingFiatDepositOrderDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hideDepositInstructions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hideDepositInstructions(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepositFiatApi.hideDepositInstructions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Specify whether a payment has been made, or will be made later
         * @param {string} [orderId] 
         * @param {boolean} [paymentMade] 
         * @param {File} [popFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentMade(orderId?: string, paymentMade?: boolean, popFile?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentMade(orderId, paymentMade, popFile, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepositFiatApi.paymentMade']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DepositFiatApi - factory interface
 * @export
 */
export const DepositFiatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepositFiatApiFp(configuration)
    return {
        /**
         * 
         * @summary User can cancel an order
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancel(orderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancel(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a fiat deposit
         * @param {CreateFiatDepositDto} [createFiatDepositDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFiatDeposit(createFiatDepositDto?: CreateFiatDepositDto, options?: any): AxiosPromise<OrderResponseDto> {
            return localVarFp.createFiatDeposit(createFiatDepositDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get data specific to the customer\'s functional fiat product for the deposit flow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiatDepositProductData(options?: any): AxiosPromise<Array<FiatDepositProductDataDto>> {
            return localVarFp.getFiatDepositProductData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get data specific to the customer\'s functional fiat product for the LinkedDeposit deposit flow
         * @param {string} linkedDepositCustomerAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkedDepositFiatDepositProductData(linkedDepositCustomerAccountId: string, options?: any): AxiosPromise<Array<LinkedDepositFiatDepositProductDataDto>> {
            return localVarFp.getLinkedDepositFiatDepositProductData(linkedDepositCustomerAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pending fiat deposit orders for the customer including the payment method
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingFiatDepositOrderDetails(options?: any): AxiosPromise<Array<PendingFiatDepositDetailsDto>> {
            return localVarFp.getPendingFiatDepositOrderDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hideDepositInstructions(options?: any): AxiosPromise<void> {
            return localVarFp.hideDepositInstructions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Specify whether a payment has been made, or will be made later
         * @param {string} [orderId] 
         * @param {boolean} [paymentMade] 
         * @param {File} [popFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentMade(orderId?: string, paymentMade?: boolean, popFile?: File, options?: any): AxiosPromise<void> {
            return localVarFp.paymentMade(orderId, paymentMade, popFile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DepositFiatApi - object-oriented interface
 * @export
 * @class DepositFiatApi
 * @extends {BaseAPI}
 */
export class DepositFiatApi extends BaseAPI {
    /**
     * 
     * @summary User can cancel an order
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositFiatApi
     */
    public cancel(orderId: string, options?: RawAxiosRequestConfig) {
        return DepositFiatApiFp(this.configuration).cancel(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a fiat deposit
     * @param {CreateFiatDepositDto} [createFiatDepositDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositFiatApi
     */
    public createFiatDeposit(createFiatDepositDto?: CreateFiatDepositDto, options?: RawAxiosRequestConfig) {
        return DepositFiatApiFp(this.configuration).createFiatDeposit(createFiatDepositDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get data specific to the customer\'s functional fiat product for the deposit flow
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositFiatApi
     */
    public getFiatDepositProductData(options?: RawAxiosRequestConfig) {
        return DepositFiatApiFp(this.configuration).getFiatDepositProductData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get data specific to the customer\'s functional fiat product for the LinkedDeposit deposit flow
     * @param {string} linkedDepositCustomerAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositFiatApi
     */
    public getLinkedDepositFiatDepositProductData(linkedDepositCustomerAccountId: string, options?: RawAxiosRequestConfig) {
        return DepositFiatApiFp(this.configuration).getLinkedDepositFiatDepositProductData(linkedDepositCustomerAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get pending fiat deposit orders for the customer including the payment method
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositFiatApi
     */
    public getPendingFiatDepositOrderDetails(options?: RawAxiosRequestConfig) {
        return DepositFiatApiFp(this.configuration).getPendingFiatDepositOrderDetails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositFiatApi
     */
    public hideDepositInstructions(options?: RawAxiosRequestConfig) {
        return DepositFiatApiFp(this.configuration).hideDepositInstructions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Specify whether a payment has been made, or will be made later
     * @param {string} [orderId] 
     * @param {boolean} [paymentMade] 
     * @param {File} [popFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositFiatApi
     */
    public paymentMade(orderId?: string, paymentMade?: boolean, popFile?: File, options?: RawAxiosRequestConfig) {
        return DepositFiatApiFp(this.configuration).paymentMade(orderId, paymentMade, popFile, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FeatureFlagApi - axios parameter creator
 * @export
 */
export const FeatureFlagApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check if deposits allowed, 200 ok, 400 fail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allowDeposits: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/featureFlags/deposits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if registrations allowed, 200 ok, 400 fail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allowRegistrations: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/featureFlags/registrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if savings vaults are allowed, 200 ok, 400 fail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allowSavingsVault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/featureFlags/savingsvaults`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if trading allowed, 200 ok, 400 fail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allowTrading: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/featureFlags/trades`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if withdrawals allowed, 200 ok, 400 fail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allowWithdrawal: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/featureFlags/withdrawals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of all features
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/featureFlags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureFlagApi - functional programming interface
 * @export
 */
export const FeatureFlagApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeatureFlagApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check if deposits allowed, 200 ok, 400 fail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allowDeposits(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeatureFlag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allowDeposits(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureFlagApi.allowDeposits']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Check if registrations allowed, 200 ok, 400 fail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allowRegistrations(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeatureFlag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allowRegistrations(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureFlagApi.allowRegistrations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Check if savings vaults are allowed, 200 ok, 400 fail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allowSavingsVault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeatureFlag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allowSavingsVault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureFlagApi.allowSavingsVault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Check if trading allowed, 200 ok, 400 fail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allowTrading(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeatureFlag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allowTrading(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureFlagApi.allowTrading']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Check if withdrawals allowed, 200 ok, 400 fail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allowWithdrawal(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeatureFlag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allowWithdrawal(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureFlagApi.allowWithdrawal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Return list of all features
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeatureFlag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureFlagApi.list']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FeatureFlagApi - factory interface
 * @export
 */
export const FeatureFlagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeatureFlagApiFp(configuration)
    return {
        /**
         * 
         * @summary Check if deposits allowed, 200 ok, 400 fail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allowDeposits(options?: any): AxiosPromise<Array<FeatureFlag>> {
            return localVarFp.allowDeposits(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if registrations allowed, 200 ok, 400 fail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allowRegistrations(options?: any): AxiosPromise<Array<FeatureFlag>> {
            return localVarFp.allowRegistrations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if savings vaults are allowed, 200 ok, 400 fail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allowSavingsVault(options?: any): AxiosPromise<Array<FeatureFlag>> {
            return localVarFp.allowSavingsVault(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if trading allowed, 200 ok, 400 fail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allowTrading(options?: any): AxiosPromise<Array<FeatureFlag>> {
            return localVarFp.allowTrading(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if withdrawals allowed, 200 ok, 400 fail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allowWithdrawal(options?: any): AxiosPromise<Array<FeatureFlag>> {
            return localVarFp.allowWithdrawal(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of all features
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(options?: any): AxiosPromise<Array<FeatureFlag>> {
            return localVarFp.list(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeatureFlagApi - object-oriented interface
 * @export
 * @class FeatureFlagApi
 * @extends {BaseAPI}
 */
export class FeatureFlagApi extends BaseAPI {
    /**
     * 
     * @summary Check if deposits allowed, 200 ok, 400 fail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureFlagApi
     */
    public allowDeposits(options?: RawAxiosRequestConfig) {
        return FeatureFlagApiFp(this.configuration).allowDeposits(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if registrations allowed, 200 ok, 400 fail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureFlagApi
     */
    public allowRegistrations(options?: RawAxiosRequestConfig) {
        return FeatureFlagApiFp(this.configuration).allowRegistrations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if savings vaults are allowed, 200 ok, 400 fail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureFlagApi
     */
    public allowSavingsVault(options?: RawAxiosRequestConfig) {
        return FeatureFlagApiFp(this.configuration).allowSavingsVault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if trading allowed, 200 ok, 400 fail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureFlagApi
     */
    public allowTrading(options?: RawAxiosRequestConfig) {
        return FeatureFlagApiFp(this.configuration).allowTrading(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if withdrawals allowed, 200 ok, 400 fail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureFlagApi
     */
    public allowWithdrawal(options?: RawAxiosRequestConfig) {
        return FeatureFlagApiFp(this.configuration).allowWithdrawal(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of all features
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureFlagApi
     */
    public list(options?: RawAxiosRequestConfig) {
        return FeatureFlagApiFp(this.configuration).list(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LinkedDepositCustomerAccountApi - axios parameter creator
 * @export
 */
export const LinkedDepositCustomerAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Complete a LinkedDepositCustomerAccount from a Pending state to a Complete state for a User.  Stitch requires dictionary values: \"Code\"
         * @param {LinkedDepositCustomerAccountCompleteRequest} [linkedDepositCustomerAccountCompleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        complete: async (linkedDepositCustomerAccountCompleteRequest?: LinkedDepositCustomerAccountCompleteRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/linkedDepositCustomerAccount/complete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(linkedDepositCustomerAccountCompleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all LinkedDepositCustomerAccountProviders for a country
         * @param {string} countryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlLinkedDepositCustomerAccountProviders: async (countryCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryCode' is not null or undefined
            assertParamExists('getAlLinkedDepositCustomerAccountProviders', 'countryCode', countryCode)
            const localVarPath = `/linkedDepositCustomerAccount/providers/{countryCode}`
                .replace(`{${"countryCode"}}`, encodeURIComponent(String(countryCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all LinkedDepositCustomerAccounts for a User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLinkedDepositCustomerAccounts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/linkedDepositCustomerAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific LinkedDepositCustomerAccount
         * @param {string} linkedDepositCustomerAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkedDepositCustomerAccount: async (linkedDepositCustomerAccountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'linkedDepositCustomerAccountId' is not null or undefined
            assertParamExists('getLinkedDepositCustomerAccount', 'linkedDepositCustomerAccountId', linkedDepositCustomerAccountId)
            const localVarPath = `/linkedDepositCustomerAccount/{linkedDepositCustomerAccountId}`
                .replace(`{${"linkedDepositCustomerAccountId"}}`, encodeURIComponent(String(linkedDepositCustomerAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the supported bank acounts for a provider by country
         * @param {string} providerCode 
         * @param {string} countryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportedBanks: async (providerCode: string, countryCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerCode' is not null or undefined
            assertParamExists('getSupportedBanks', 'providerCode', providerCode)
            // verify required parameter 'countryCode' is not null or undefined
            assertParamExists('getSupportedBanks', 'countryCode', countryCode)
            const localVarPath = `/linkedDepositCustomerAccount/provider/{providerCode}/banks/{countryCode}`
                .replace(`{${"providerCode"}}`, encodeURIComponent(String(providerCode)))
                .replace(`{${"countryCode"}}`, encodeURIComponent(String(countryCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Initialize a LinkedDepositCustomerAccount in a Pending state for a User
         * @param {string} providerCode 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initialize: async (providerCode: string, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerCode' is not null or undefined
            assertParamExists('initialize', 'providerCode', providerCode)
            const localVarPath = `/linkedDepositCustomerAccount/initialize/{providerCode}`
                .replace(`{${"providerCode"}}`, encodeURIComponent(String(providerCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unlink a Linked Deposit Customer Account
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkLinkedDepositCustomerAccount: async (accountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('unlinkLinkedDepositCustomerAccount', 'accountId', accountId)
            const localVarPath = `/linkedDepositCustomerAccount/unlink/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the friendly name of a LinkedDepositCustomerAccount
         * @param {LinkedDepositCustomerAccountFriendlyNameRequest} [linkedDepositCustomerAccountFriendlyNameRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFriendlyName: async (linkedDepositCustomerAccountFriendlyNameRequest?: LinkedDepositCustomerAccountFriendlyNameRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/linkedDepositCustomerAccount/updateFriendlyName`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(linkedDepositCustomerAccountFriendlyNameRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LinkedDepositCustomerAccountApi - functional programming interface
 * @export
 */
export const LinkedDepositCustomerAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LinkedDepositCustomerAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Complete a LinkedDepositCustomerAccount from a Pending state to a Complete state for a User.  Stitch requires dictionary values: \"Code\"
         * @param {LinkedDepositCustomerAccountCompleteRequest} [linkedDepositCustomerAccountCompleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async complete(linkedDepositCustomerAccountCompleteRequest?: LinkedDepositCustomerAccountCompleteRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkedDepositCustomerAccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.complete(linkedDepositCustomerAccountCompleteRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkedDepositCustomerAccountApi.complete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all LinkedDepositCustomerAccountProviders for a country
         * @param {string} countryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAlLinkedDepositCustomerAccountProviders(countryCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LinkedDepositCustomerAccountProviderDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAlLinkedDepositCustomerAccountProviders(countryCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkedDepositCustomerAccountApi.getAlLinkedDepositCustomerAccountProviders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all LinkedDepositCustomerAccounts for a User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllLinkedDepositCustomerAccounts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LinkedDepositCustomerAccountDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllLinkedDepositCustomerAccounts(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkedDepositCustomerAccountApi.getAllLinkedDepositCustomerAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a specific LinkedDepositCustomerAccount
         * @param {string} linkedDepositCustomerAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLinkedDepositCustomerAccount(linkedDepositCustomerAccountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkedDepositCustomerAccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLinkedDepositCustomerAccount(linkedDepositCustomerAccountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkedDepositCustomerAccountApi.getLinkedDepositCustomerAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all the supported bank acounts for a provider by country
         * @param {string} providerCode 
         * @param {string} countryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSupportedBanks(providerCode: string, countryCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LinkedDepositAvailableBankDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSupportedBanks(providerCode, countryCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkedDepositCustomerAccountApi.getSupportedBanks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Initialize a LinkedDepositCustomerAccount in a Pending state for a User
         * @param {string} providerCode 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initialize(providerCode: string, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkedDepositCustomerAccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initialize(providerCode, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkedDepositCustomerAccountApi.initialize']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Unlink a Linked Deposit Customer Account
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlinkLinkedDepositCustomerAccount(accountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unlinkLinkedDepositCustomerAccount(accountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkedDepositCustomerAccountApi.unlinkLinkedDepositCustomerAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update the friendly name of a LinkedDepositCustomerAccount
         * @param {LinkedDepositCustomerAccountFriendlyNameRequest} [linkedDepositCustomerAccountFriendlyNameRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFriendlyName(linkedDepositCustomerAccountFriendlyNameRequest?: LinkedDepositCustomerAccountFriendlyNameRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFriendlyName(linkedDepositCustomerAccountFriendlyNameRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkedDepositCustomerAccountApi.updateFriendlyName']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LinkedDepositCustomerAccountApi - factory interface
 * @export
 */
export const LinkedDepositCustomerAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LinkedDepositCustomerAccountApiFp(configuration)
    return {
        /**
         * 
         * @summary Complete a LinkedDepositCustomerAccount from a Pending state to a Complete state for a User.  Stitch requires dictionary values: \"Code\"
         * @param {LinkedDepositCustomerAccountCompleteRequest} [linkedDepositCustomerAccountCompleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        complete(linkedDepositCustomerAccountCompleteRequest?: LinkedDepositCustomerAccountCompleteRequest, options?: any): AxiosPromise<LinkedDepositCustomerAccountDto> {
            return localVarFp.complete(linkedDepositCustomerAccountCompleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all LinkedDepositCustomerAccountProviders for a country
         * @param {string} countryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlLinkedDepositCustomerAccountProviders(countryCode: string, options?: any): AxiosPromise<Array<LinkedDepositCustomerAccountProviderDto>> {
            return localVarFp.getAlLinkedDepositCustomerAccountProviders(countryCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all LinkedDepositCustomerAccounts for a User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLinkedDepositCustomerAccounts(options?: any): AxiosPromise<Array<LinkedDepositCustomerAccountDto>> {
            return localVarFp.getAllLinkedDepositCustomerAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a specific LinkedDepositCustomerAccount
         * @param {string} linkedDepositCustomerAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkedDepositCustomerAccount(linkedDepositCustomerAccountId: string, options?: any): AxiosPromise<LinkedDepositCustomerAccountDto> {
            return localVarFp.getLinkedDepositCustomerAccount(linkedDepositCustomerAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the supported bank acounts for a provider by country
         * @param {string} providerCode 
         * @param {string} countryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportedBanks(providerCode: string, countryCode: string, options?: any): AxiosPromise<Array<LinkedDepositAvailableBankDto>> {
            return localVarFp.getSupportedBanks(providerCode, countryCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Initialize a LinkedDepositCustomerAccount in a Pending state for a User
         * @param {string} providerCode 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initialize(providerCode: string, source?: string, options?: any): AxiosPromise<LinkedDepositCustomerAccountDto> {
            return localVarFp.initialize(providerCode, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unlink a Linked Deposit Customer Account
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkLinkedDepositCustomerAccount(accountId: string, options?: any): AxiosPromise<void> {
            return localVarFp.unlinkLinkedDepositCustomerAccount(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the friendly name of a LinkedDepositCustomerAccount
         * @param {LinkedDepositCustomerAccountFriendlyNameRequest} [linkedDepositCustomerAccountFriendlyNameRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFriendlyName(linkedDepositCustomerAccountFriendlyNameRequest?: LinkedDepositCustomerAccountFriendlyNameRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateFriendlyName(linkedDepositCustomerAccountFriendlyNameRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LinkedDepositCustomerAccountApi - object-oriented interface
 * @export
 * @class LinkedDepositCustomerAccountApi
 * @extends {BaseAPI}
 */
export class LinkedDepositCustomerAccountApi extends BaseAPI {
    /**
     * 
     * @summary Complete a LinkedDepositCustomerAccount from a Pending state to a Complete state for a User.  Stitch requires dictionary values: \"Code\"
     * @param {LinkedDepositCustomerAccountCompleteRequest} [linkedDepositCustomerAccountCompleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkedDepositCustomerAccountApi
     */
    public complete(linkedDepositCustomerAccountCompleteRequest?: LinkedDepositCustomerAccountCompleteRequest, options?: RawAxiosRequestConfig) {
        return LinkedDepositCustomerAccountApiFp(this.configuration).complete(linkedDepositCustomerAccountCompleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all LinkedDepositCustomerAccountProviders for a country
     * @param {string} countryCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkedDepositCustomerAccountApi
     */
    public getAlLinkedDepositCustomerAccountProviders(countryCode: string, options?: RawAxiosRequestConfig) {
        return LinkedDepositCustomerAccountApiFp(this.configuration).getAlLinkedDepositCustomerAccountProviders(countryCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all LinkedDepositCustomerAccounts for a User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkedDepositCustomerAccountApi
     */
    public getAllLinkedDepositCustomerAccounts(options?: RawAxiosRequestConfig) {
        return LinkedDepositCustomerAccountApiFp(this.configuration).getAllLinkedDepositCustomerAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a specific LinkedDepositCustomerAccount
     * @param {string} linkedDepositCustomerAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkedDepositCustomerAccountApi
     */
    public getLinkedDepositCustomerAccount(linkedDepositCustomerAccountId: string, options?: RawAxiosRequestConfig) {
        return LinkedDepositCustomerAccountApiFp(this.configuration).getLinkedDepositCustomerAccount(linkedDepositCustomerAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all the supported bank acounts for a provider by country
     * @param {string} providerCode 
     * @param {string} countryCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkedDepositCustomerAccountApi
     */
    public getSupportedBanks(providerCode: string, countryCode: string, options?: RawAxiosRequestConfig) {
        return LinkedDepositCustomerAccountApiFp(this.configuration).getSupportedBanks(providerCode, countryCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Initialize a LinkedDepositCustomerAccount in a Pending state for a User
     * @param {string} providerCode 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkedDepositCustomerAccountApi
     */
    public initialize(providerCode: string, source?: string, options?: RawAxiosRequestConfig) {
        return LinkedDepositCustomerAccountApiFp(this.configuration).initialize(providerCode, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unlink a Linked Deposit Customer Account
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkedDepositCustomerAccountApi
     */
    public unlinkLinkedDepositCustomerAccount(accountId: string, options?: RawAxiosRequestConfig) {
        return LinkedDepositCustomerAccountApiFp(this.configuration).unlinkLinkedDepositCustomerAccount(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the friendly name of a LinkedDepositCustomerAccount
     * @param {LinkedDepositCustomerAccountFriendlyNameRequest} [linkedDepositCustomerAccountFriendlyNameRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkedDepositCustomerAccountApi
     */
    public updateFriendlyName(linkedDepositCustomerAccountFriendlyNameRequest?: LinkedDepositCustomerAccountFriendlyNameRequest, options?: RawAxiosRequestConfig) {
        return LinkedDepositCustomerAccountApiFp(this.configuration).updateFriendlyName(linkedDepositCustomerAccountFriendlyNameRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PendingOrdersApi - axios parameter creator
 * @export
 */
export const PendingOrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [productCodes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingOrdersSimple: async (productCodes?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pending-orders/simple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productCodes !== undefined) {
                localVarQueryParameter['productCodes'] = productCodes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PendingOrdersApi - functional programming interface
 * @export
 */
export const PendingOrdersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PendingOrdersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [productCodes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPendingOrdersSimple(productCodes?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetPendingsOrdersSimpleQueryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPendingOrdersSimple(productCodes, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PendingOrdersApi.getPendingOrdersSimple']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PendingOrdersApi - factory interface
 * @export
 */
export const PendingOrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PendingOrdersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [productCodes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingOrdersSimple(productCodes?: string, options?: any): AxiosPromise<Array<GetPendingsOrdersSimpleQueryResponse>> {
            return localVarFp.getPendingOrdersSimple(productCodes, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PendingOrdersApi - object-oriented interface
 * @export
 * @class PendingOrdersApi
 * @extends {BaseAPI}
 */
export class PendingOrdersApi extends BaseAPI {
    /**
     * 
     * @param {string} [productCodes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendingOrdersApi
     */
    public getPendingOrdersSimple(productCodes?: string, options?: RawAxiosRequestConfig) {
        return PendingOrdersApiFp(this.configuration).getPendingOrdersSimple(productCodes, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PortfolioApi - axios parameter creator
 * @export
 */
export const PortfolioApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        balances: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/portfolio/balances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerTimespans: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/portfolio/timespans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [time] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holdings: async (time?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/portfolio/holdings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (time !== undefined) {
                localVarQueryParameter['time'] = time;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [time] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performance: async (time?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/portfolio/performance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (time !== undefined) {
                localVarQueryParameter['time'] = time;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productDeFiVault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/portfolio/vaults`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [time] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        summary: async (time?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/portfolio/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (time !== undefined) {
                localVarQueryParameter['time'] = time;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortfolioApi - functional programming interface
 * @export
 */
export const PortfolioApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PortfolioApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async balances(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CashBalanceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.balances(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PortfolioApi.balances']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerTimespans(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioDurationTimespanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerTimespans(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PortfolioApi.customerTimespans']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [time] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holdings(time?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioHoldingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holdings(time, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PortfolioApi.holdings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [time] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performance(time?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerformanceValueDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.performance(time, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PortfolioApi.performance']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productDeFiVault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductVaultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productDeFiVault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PortfolioApi.productDeFiVault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [time] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async summary(time?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioSummaryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.summary(time, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PortfolioApi.summary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PortfolioApi - factory interface
 * @export
 */
export const PortfolioApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PortfolioApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        balances(options?: any): AxiosPromise<Array<CashBalanceDto>> {
            return localVarFp.balances(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerTimespans(options?: any): AxiosPromise<PortfolioDurationTimespanDto> {
            return localVarFp.customerTimespans(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [time] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holdings(time?: number, options?: any): AxiosPromise<PortfolioHoldingsDto> {
            return localVarFp.holdings(time, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [time] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performance(time?: number, options?: any): AxiosPromise<PerformanceValueDto> {
            return localVarFp.performance(time, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productDeFiVault(options?: any): AxiosPromise<Array<ProductVaultDto>> {
            return localVarFp.productDeFiVault(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [time] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        summary(time?: number, options?: any): AxiosPromise<PortfolioSummaryDto> {
            return localVarFp.summary(time, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PortfolioApi - object-oriented interface
 * @export
 * @class PortfolioApi
 * @extends {BaseAPI}
 */
export class PortfolioApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public balances(options?: RawAxiosRequestConfig) {
        return PortfolioApiFp(this.configuration).balances(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public customerTimespans(options?: RawAxiosRequestConfig) {
        return PortfolioApiFp(this.configuration).customerTimespans(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [time] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public holdings(time?: number, options?: RawAxiosRequestConfig) {
        return PortfolioApiFp(this.configuration).holdings(time, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [time] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public performance(time?: number, options?: RawAxiosRequestConfig) {
        return PortfolioApiFp(this.configuration).performance(time, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public productDeFiVault(options?: RawAxiosRequestConfig) {
        return PortfolioApiFp(this.configuration).productDeFiVault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [time] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public summary(time?: number, options?: RawAxiosRequestConfig) {
        return PortfolioApiFp(this.configuration).summary(time, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PortfolioV2Api - axios parameter creator
 * @export
 */
export const PortfolioV2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} timeInterval 
         * @param {string} [productCodes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregated: async (timeInterval: number, productCodes?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timeInterval' is not null or undefined
            assertParamExists('getAggregated', 'timeInterval', timeInterval)
            const localVarPath = `/portfolio/v2/aggregated/{timeInterval}`
                .replace(`{${"timeInterval"}}`, encodeURIComponent(String(timeInterval)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productCodes !== undefined) {
                localVarQueryParameter['productCodes'] = productCodes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} timeInterval 
         * @param {string} [productCodes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioAndHoldingsGainsLosses: async (timeInterval: number, productCodes?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timeInterval' is not null or undefined
            assertParamExists('getPortfolioAndHoldingsGainsLosses', 'timeInterval', timeInterval)
            const localVarPath = `/portfolio/v2/all-gains/{timeInterval}`
                .replace(`{${"timeInterval"}}`, encodeURIComponent(String(timeInterval)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productCodes !== undefined) {
                localVarQueryParameter['productCodes'] = productCodes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [productCodes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsAndHoldings: async (productCodes?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/portfolio/v2/products-and-holdings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productCodes !== undefined) {
                localVarQueryParameter['productCodes'] = productCodes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [productCodes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReservedAmounts: async (productCodes?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/portfolio/v2/reserved-amounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productCodes !== undefined) {
                localVarQueryParameter['productCodes'] = productCodes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRewardsDetails: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/portfolio/v2/rewards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [productCodes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVaultGains: async (productCodes?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/portfolio/v2/vault-gains`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productCodes !== undefined) {
                localVarQueryParameter['productCodes'] = productCodes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortfolioV2Api - functional programming interface
 * @export
 */
export const PortfolioV2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PortfolioV2ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} timeInterval 
         * @param {string} [productCodes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAggregated(timeInterval: number, productCodes?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregatedPortfolioQueryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAggregated(timeInterval, productCodes, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PortfolioV2Api.getAggregated']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} timeInterval 
         * @param {string} [productCodes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortfolioAndHoldingsGainsLosses(timeInterval: number, productCodes?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetGainLossesQueryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortfolioAndHoldingsGainsLosses(timeInterval, productCodes, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PortfolioV2Api.getPortfolioAndHoldingsGainsLosses']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [productCodes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductsAndHoldings(productCodes?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetProductsAndHoldingsQueryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsAndHoldings(productCodes, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PortfolioV2Api.getProductsAndHoldings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [productCodes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReservedAmounts(productCodes?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReservedAmounts(productCodes, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PortfolioV2Api.getReservedAmounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRewardsDetails(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRewardsDetails(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PortfolioV2Api.getRewardsDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [productCodes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVaultGains(productCodes?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVaultGains(productCodes, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PortfolioV2Api.getVaultGains']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PortfolioV2Api - factory interface
 * @export
 */
export const PortfolioV2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PortfolioV2ApiFp(configuration)
    return {
        /**
         * 
         * @param {number} timeInterval 
         * @param {string} [productCodes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregated(timeInterval: number, productCodes?: string, options?: any): AxiosPromise<AggregatedPortfolioQueryResponse> {
            return localVarFp.getAggregated(timeInterval, productCodes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} timeInterval 
         * @param {string} [productCodes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioAndHoldingsGainsLosses(timeInterval: number, productCodes?: string, options?: any): AxiosPromise<Array<GetGainLossesQueryResponse>> {
            return localVarFp.getPortfolioAndHoldingsGainsLosses(timeInterval, productCodes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [productCodes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsAndHoldings(productCodes?: string, options?: any): AxiosPromise<Array<GetProductsAndHoldingsQueryResponse>> {
            return localVarFp.getProductsAndHoldings(productCodes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [productCodes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReservedAmounts(productCodes?: string, options?: any): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.getReservedAmounts(productCodes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRewardsDetails(options?: any): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.getRewardsDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [productCodes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVaultGains(productCodes?: string, options?: any): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.getVaultGains(productCodes, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PortfolioV2Api - object-oriented interface
 * @export
 * @class PortfolioV2Api
 * @extends {BaseAPI}
 */
export class PortfolioV2Api extends BaseAPI {
    /**
     * 
     * @param {number} timeInterval 
     * @param {string} [productCodes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioV2Api
     */
    public getAggregated(timeInterval: number, productCodes?: string, options?: RawAxiosRequestConfig) {
        return PortfolioV2ApiFp(this.configuration).getAggregated(timeInterval, productCodes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} timeInterval 
     * @param {string} [productCodes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioV2Api
     */
    public getPortfolioAndHoldingsGainsLosses(timeInterval: number, productCodes?: string, options?: RawAxiosRequestConfig) {
        return PortfolioV2ApiFp(this.configuration).getPortfolioAndHoldingsGainsLosses(timeInterval, productCodes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [productCodes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioV2Api
     */
    public getProductsAndHoldings(productCodes?: string, options?: RawAxiosRequestConfig) {
        return PortfolioV2ApiFp(this.configuration).getProductsAndHoldings(productCodes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [productCodes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioV2Api
     */
    public getReservedAmounts(productCodes?: string, options?: RawAxiosRequestConfig) {
        return PortfolioV2ApiFp(this.configuration).getReservedAmounts(productCodes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioV2Api
     */
    public getRewardsDetails(options?: RawAxiosRequestConfig) {
        return PortfolioV2ApiFp(this.configuration).getRewardsDetails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [productCodes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioV2Api
     */
    public getVaultGains(productCodes?: string, options?: RawAxiosRequestConfig) {
        return PortfolioV2ApiFp(this.configuration).getVaultGains(productCodes, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [fromProductCode] 
         * @param {string} [toProductCode] 
         * @param {GetTypeEnum} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (fromProductCode?: string, toProductCode?: string, type?: GetTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products/transactable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fromProductCode !== undefined) {
                localVarQueryParameter['fromProductCode'] = fromProductCode;
            }

            if (toProductCode !== undefined) {
                localVarQueryParameter['toProductCode'] = toProductCode;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productDeFiVault: async (productCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productCode' is not null or undefined
            assertParamExists('productDeFiVault', 'productCode', productCode)
            const localVarPath = `/products/vault/{productCode}`
                .replace(`{${"productCode"}}`, encodeURIComponent(String(productCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products/suggestions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [interval] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradableProducts: async (interval?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products/tradable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [fromProductCode] 
         * @param {string} [toProductCode] 
         * @param {GetTypeEnum} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(fromProductCode?: string, toProductCode?: string, type?: GetTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductTransactablePairDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(fromProductCode, toProductCode, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.get']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} productCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productDeFiVault(productCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductVaultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productDeFiVault(productCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.productDeFiVault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async suggestions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PortfolioProductHoldingsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.suggestions(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.suggestions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [interval] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tradableProducts(interval?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PortfolioProductHoldingsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tradableProducts(interval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.tradableProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [fromProductCode] 
         * @param {string} [toProductCode] 
         * @param {GetTypeEnum} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(fromProductCode?: string, toProductCode?: string, type?: GetTypeEnum, options?: any): AxiosPromise<Array<ProductTransactablePairDto>> {
            return localVarFp.get(fromProductCode, toProductCode, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productDeFiVault(productCode: string, options?: any): AxiosPromise<ProductVaultDto> {
            return localVarFp.productDeFiVault(productCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestions(options?: any): AxiosPromise<Array<PortfolioProductHoldingsDto>> {
            return localVarFp.suggestions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [interval] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradableProducts(interval?: number, options?: any): AxiosPromise<Array<PortfolioProductHoldingsDto>> {
            return localVarFp.tradableProducts(interval, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * 
     * @param {string} [fromProductCode] 
     * @param {string} [toProductCode] 
     * @param {GetTypeEnum} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public get(fromProductCode?: string, toProductCode?: string, type?: GetTypeEnum, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).get(fromProductCode, toProductCode, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productDeFiVault(productCode: string, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productDeFiVault(productCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public suggestions(options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).suggestions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [interval] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public tradableProducts(interval?: number, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).tradableProducts(interval, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetTypeEnum = {
    SellsTo: 'SellsTo',
    Buys: 'Buys'
} as const;
export type GetTypeEnum = typeof GetTypeEnum[keyof typeof GetTypeEnum];


/**
 * ProfileApi - axios parameter creator
 * @export
 */
export const ProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {boolean} [revixRoundUp] 
         * @param {Array<string>} [acceptedTerms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate: async (revixRoundUp?: boolean, acceptedTerms?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/activate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (revixRoundUp !== undefined) {
                localVarQueryParameter['revixRoundUp'] = revixRoundUp;
            }

            if (acceptedTerms) {
                acceptedTerms.forEach((element) => {
                    localVarFormParams.append('acceptedTerms', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OtpActivateRequest} [otpActivateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateEmailOtp: async (otpActivateRequest?: OtpActivateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/activateEmailOtp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(otpActivateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OtpActivateRequest} [otpActivateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateSmsOtp: async (otpActivateRequest?: OtpActivateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/activateSmsOtp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(otpActivateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TotpActivateRequest} [totpActivateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateTotp: async (totpActivateRequest?: TotpActivateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/activateTotp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(totpActivateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChangeEmailRequest} [changeEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEmail: async (changeEmailRequest?: ChangeEmailRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/changeEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [totp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEmailVerify: async (token?: string, totp?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/changeEmail/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (totp !== undefined) {
                localVarQueryParameter['totp'] = totp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {ChangePasswordRequest} [changePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (_2fa?: string, otpFlowTypeCode?: string, changePasswordRequest?: ChangePasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/changePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (_2fa != null) {
                localVarHeaderParameter['2fa'] = String(_2fa);
            }

            if (otpFlowTypeCode != null) {
                localVarHeaderParameter['otpFlowTypeCode'] = String(otpFlowTypeCode);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OtpDeactivateRequest} [otpDeactivateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateEmailOtp: async (otpDeactivateRequest?: OtpDeactivateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/deactivateEmailOtp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(otpDeactivateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OtpDeactivateRequest} [otpDeactivateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateSmsOtp: async (otpDeactivateRequest?: OtpDeactivateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/deactivateSmsOtp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(otpDeactivateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableLoginNotification: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/loginNotification/disable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TotpDisableRequest} [totpDisableRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableTotp: async (totpDisableRequest?: TotpDisableRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/disableTotp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(totpDisableRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableLoginNotification: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/loginNotification/enable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ForgotPasswordRequest} [forgotPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: async (forgotPasswordRequest?: ForgotPasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/forgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OtpInitiateEmailRequest} [otpInitiateEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateEmailOtp: async (otpInitiateEmailRequest?: OtpInitiateEmailRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/initiateEmailOtp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(otpInitiateEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OtpInitiateRequest} [otpInitiateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateSmsOtp: async (otpInitiateRequest?: OtpInitiateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/initiateSmsOtp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(otpInitiateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LinkWithSsoRequestDto} [linkWithSsoRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkWithProvider: async (linkWithSsoRequestDto?: LinkWithSsoRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/linkWithProvider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(linkWithSsoRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginMethods: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/loginMethods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterRequestDto} [registerRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (registerRequestDto?: RegisterRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerTotp: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/registerTotp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterWithSsoRequestDto} [registerWithSsoRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerWithProvider: async (registerWithSsoRequestDto?: RegisterWithSsoRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/registerWithProvider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerWithSsoRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendRegistrationEmail: async (email?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/resendRegistrationEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetPasswordRequest} [resetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (resetPasswordRequest?: ResetPasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/resetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OtpSendEmailRequest} [otpSendEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailOtp: async (otpSendEmailRequest?: OtpSendEmailRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/sendEmailOtp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(otpSendEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OtpSendSmsRequest} [otpSendSmsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSmsOtp: async (otpSendSmsRequest?: OtpSendSmsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/sendSmsOtp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(otpSendSmsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        status: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {string} [dateOfBirth] 
         * @param {string} [phoneNumber] 
         * @param {string} [firstDepositDate] 
         * @param {number} [balance] 
         * @param {File} [idDocument] 
         * @param {File} [selfieDocument] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        twoFactorReset: async (firstName?: string, lastName?: string, email?: string, dateOfBirth?: string, phoneNumber?: string, firstDepositDate?: string, balance?: number, idDocument?: File, selfieDocument?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/twoFactorReset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (firstName !== undefined) { 
                localVarFormParams.append('FirstName', firstName as any);
            }
    
            if (lastName !== undefined) { 
                localVarFormParams.append('LastName', lastName as any);
            }
    
            if (email !== undefined) { 
                localVarFormParams.append('Email', email as any);
            }
    
            if (dateOfBirth !== undefined) { 
                localVarFormParams.append('DateOfBirth', dateOfBirth as any);
            }
    
            if (phoneNumber !== undefined) { 
                localVarFormParams.append('PhoneNumber', phoneNumber as any);
            }
    
            if (firstDepositDate !== undefined) { 
                localVarFormParams.append('FirstDepositDate', firstDepositDate as any);
            }
    
            if (balance !== undefined) { 
                localVarFormParams.append('Balance', balance as any);
            }
    
            if (idDocument !== undefined) { 
                localVarFormParams.append('IdDocument', idDocument as any);
            }
    
            if (selfieDocument !== undefined) { 
                localVarFormParams.append('SelfieDocument', selfieDocument as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProfileUpdateDto} [profileUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDetails: async (profileUpdateDto?: ProfileUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/updateDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProfileHearAboutDto} [profileHearAboutDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHearAbout: async (profileHearAboutDto?: ProfileHearAboutDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/updateHearAbout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileHearAboutDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CredentialDto} [credentialDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateEmailToken: async (credentialDto?: CredentialDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/validateEmailToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(credentialDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PromoCodeRequest} [promoCodeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePromoCode: async (promoCodeRequest?: PromoCodeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/validatePromoCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promoCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileApi - functional programming interface
 * @export
 */
export const ProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {boolean} [revixRoundUp] 
         * @param {Array<string>} [acceptedTerms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activate(revixRoundUp?: boolean, acceptedTerms?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activate(revixRoundUp, acceptedTerms, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.activate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {OtpActivateRequest} [otpActivateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateEmailOtp(otpActivateRequest?: OtpActivateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateEmailOtp(otpActivateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.activateEmailOtp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {OtpActivateRequest} [otpActivateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateSmsOtp(otpActivateRequest?: OtpActivateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateSmsOtp(otpActivateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.activateSmsOtp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {TotpActivateRequest} [totpActivateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateTotp(totpActivateRequest?: TotpActivateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateTotp(totpActivateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.activateTotp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ChangeEmailRequest} [changeEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeEmail(changeEmailRequest?: ChangeEmailRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeEmail(changeEmailRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.changeEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [totp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeEmailVerify(token?: string, totp?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeEmailVerify(token, totp, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.changeEmailVerify']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {ChangePasswordRequest} [changePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(_2fa?: string, otpFlowTypeCode?: string, changePasswordRequest?: ChangePasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(_2fa, otpFlowTypeCode, changePasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.changePassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {OtpDeactivateRequest} [otpDeactivateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateEmailOtp(otpDeactivateRequest?: OtpDeactivateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateEmailOtp(otpDeactivateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.deactivateEmailOtp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {OtpDeactivateRequest} [otpDeactivateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateSmsOtp(otpDeactivateRequest?: OtpDeactivateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateSmsOtp(otpDeactivateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.deactivateSmsOtp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disableLoginNotification(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disableLoginNotification(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.disableLoginNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {TotpDisableRequest} [totpDisableRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disableTotp(totpDisableRequest?: TotpDisableRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disableTotp(totpDisableRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.disableTotp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enableLoginNotification(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enableLoginNotification(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.enableLoginNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ForgotPasswordRequest} [forgotPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPassword(forgotPasswordRequest?: ForgotPasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgotPassword(forgotPasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.forgotPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.get']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {OtpInitiateEmailRequest} [otpInitiateEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiateEmailOtp(otpInitiateEmailRequest?: OtpInitiateEmailRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiateEmailOtp(otpInitiateEmailRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.initiateEmailOtp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {OtpInitiateRequest} [otpInitiateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiateSmsOtp(otpInitiateRequest?: OtpInitiateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiateSmsOtp(otpInitiateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.initiateSmsOtp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {LinkWithSsoRequestDto} [linkWithSsoRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linkWithProvider(linkWithSsoRequestDto?: LinkWithSsoRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkWithSsoResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linkWithProvider(linkWithSsoRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.linkWithProvider']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginMethods(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LoginMethodDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginMethods(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.loginMethods']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RegisterRequestDto} [registerRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(registerRequestDto?: RegisterRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisterResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(registerRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.register']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerTotp(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerTotp(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.registerTotp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RegisterWithSsoRequestDto} [registerWithSsoRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerWithProvider(registerWithSsoRequestDto?: RegisterWithSsoRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisterWithSsoResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerWithProvider(registerWithSsoRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.registerWithProvider']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendRegistrationEmail(email?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseUserResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendRegistrationEmail(email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.resendRegistrationEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ResetPasswordRequest} [resetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(resetPasswordRequest?: ResetPasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(resetPasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.resetPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {OtpSendEmailRequest} [otpSendEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmailOtp(otpSendEmailRequest?: OtpSendEmailRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendEmailOtp(otpSendEmailRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.sendEmailOtp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {OtpSendSmsRequest} [otpSendSmsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendSmsOtp(otpSendSmsRequest?: OtpSendSmsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendSmsOtp(otpSendSmsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.sendSmsOtp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async status(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.status(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.status']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {string} [dateOfBirth] 
         * @param {string} [phoneNumber] 
         * @param {string} [firstDepositDate] 
         * @param {number} [balance] 
         * @param {File} [idDocument] 
         * @param {File} [selfieDocument] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async twoFactorReset(firstName?: string, lastName?: string, email?: string, dateOfBirth?: string, phoneNumber?: string, firstDepositDate?: string, balance?: number, idDocument?: File, selfieDocument?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.twoFactorReset(firstName, lastName, email, dateOfBirth, phoneNumber, firstDepositDate, balance, idDocument, selfieDocument, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.twoFactorReset']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ProfileUpdateDto} [profileUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDetails(profileUpdateDto?: ProfileUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDetails(profileUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.updateDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ProfileHearAboutDto} [profileHearAboutDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateHearAbout(profileHearAboutDto?: ProfileHearAboutDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateHearAbout(profileHearAboutDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.updateHearAbout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CredentialDto} [credentialDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateEmailToken(credentialDto?: CredentialDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateEmailToken(credentialDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.validateEmailToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PromoCodeRequest} [promoCodeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validatePromoCode(promoCodeRequest?: PromoCodeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromoCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validatePromoCode(promoCodeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.validatePromoCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProfileApi - factory interface
 * @export
 */
export const ProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileApiFp(configuration)
    return {
        /**
         * 
         * @param {boolean} [revixRoundUp] 
         * @param {Array<string>} [acceptedTerms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate(revixRoundUp?: boolean, acceptedTerms?: Array<string>, options?: any): AxiosPromise<ProfileStatusDto> {
            return localVarFp.activate(revixRoundUp, acceptedTerms, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OtpActivateRequest} [otpActivateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateEmailOtp(otpActivateRequest?: OtpActivateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.activateEmailOtp(otpActivateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OtpActivateRequest} [otpActivateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateSmsOtp(otpActivateRequest?: OtpActivateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.activateSmsOtp(otpActivateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TotpActivateRequest} [totpActivateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateTotp(totpActivateRequest?: TotpActivateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.activateTotp(totpActivateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChangeEmailRequest} [changeEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEmail(changeEmailRequest?: ChangeEmailRequest, options?: any): AxiosPromise<ResultDto> {
            return localVarFp.changeEmail(changeEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [totp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEmailVerify(token?: string, totp?: string, options?: any): AxiosPromise<AccessTokenDto> {
            return localVarFp.changeEmailVerify(token, totp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
         * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
         * @param {ChangePasswordRequest} [changePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(_2fa?: string, otpFlowTypeCode?: string, changePasswordRequest?: ChangePasswordRequest, options?: any): AxiosPromise<ResultDto> {
            return localVarFp.changePassword(_2fa, otpFlowTypeCode, changePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OtpDeactivateRequest} [otpDeactivateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateEmailOtp(otpDeactivateRequest?: OtpDeactivateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deactivateEmailOtp(otpDeactivateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OtpDeactivateRequest} [otpDeactivateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateSmsOtp(otpDeactivateRequest?: OtpDeactivateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deactivateSmsOtp(otpDeactivateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableLoginNotification(options?: any): AxiosPromise<Profile> {
            return localVarFp.disableLoginNotification(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TotpDisableRequest} [totpDisableRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableTotp(totpDisableRequest?: TotpDisableRequest, options?: any): AxiosPromise<void> {
            return localVarFp.disableTotp(totpDisableRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableLoginNotification(options?: any): AxiosPromise<Profile> {
            return localVarFp.enableLoginNotification(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForgotPasswordRequest} [forgotPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(forgotPasswordRequest?: ForgotPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.forgotPassword(forgotPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(options?: any): AxiosPromise<Profile> {
            return localVarFp.get(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OtpInitiateEmailRequest} [otpInitiateEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateEmailOtp(otpInitiateEmailRequest?: OtpInitiateEmailRequest, options?: any): AxiosPromise<void> {
            return localVarFp.initiateEmailOtp(otpInitiateEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OtpInitiateRequest} [otpInitiateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateSmsOtp(otpInitiateRequest?: OtpInitiateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.initiateSmsOtp(otpInitiateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LinkWithSsoRequestDto} [linkWithSsoRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkWithProvider(linkWithSsoRequestDto?: LinkWithSsoRequestDto, options?: any): AxiosPromise<LinkWithSsoResponseDto> {
            return localVarFp.linkWithProvider(linkWithSsoRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginMethods(options?: any): AxiosPromise<Array<LoginMethodDto>> {
            return localVarFp.loginMethods(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterRequestDto} [registerRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(registerRequestDto?: RegisterRequestDto, options?: any): AxiosPromise<RegisterResponseDto> {
            return localVarFp.register(registerRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerTotp(options?: any): AxiosPromise<void> {
            return localVarFp.registerTotp(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterWithSsoRequestDto} [registerWithSsoRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerWithProvider(registerWithSsoRequestDto?: RegisterWithSsoRequestDto, options?: any): AxiosPromise<RegisterWithSsoResponseDto> {
            return localVarFp.registerWithProvider(registerWithSsoRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendRegistrationEmail(email?: string, options?: any): AxiosPromise<BaseUserResponseDto> {
            return localVarFp.resendRegistrationEmail(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResetPasswordRequest} [resetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(resetPasswordRequest?: ResetPasswordRequest, options?: any): AxiosPromise<AccessTokenDto> {
            return localVarFp.resetPassword(resetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OtpSendEmailRequest} [otpSendEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailOtp(otpSendEmailRequest?: OtpSendEmailRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendEmailOtp(otpSendEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OtpSendSmsRequest} [otpSendSmsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSmsOtp(otpSendSmsRequest?: OtpSendSmsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendSmsOtp(otpSendSmsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        status(options?: any): AxiosPromise<ProfileStatusDto> {
            return localVarFp.status(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {string} [dateOfBirth] 
         * @param {string} [phoneNumber] 
         * @param {string} [firstDepositDate] 
         * @param {number} [balance] 
         * @param {File} [idDocument] 
         * @param {File} [selfieDocument] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        twoFactorReset(firstName?: string, lastName?: string, email?: string, dateOfBirth?: string, phoneNumber?: string, firstDepositDate?: string, balance?: number, idDocument?: File, selfieDocument?: File, options?: any): AxiosPromise<void> {
            return localVarFp.twoFactorReset(firstName, lastName, email, dateOfBirth, phoneNumber, firstDepositDate, balance, idDocument, selfieDocument, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProfileUpdateDto} [profileUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDetails(profileUpdateDto?: ProfileUpdateDto, options?: any): AxiosPromise<Profile> {
            return localVarFp.updateDetails(profileUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProfileHearAboutDto} [profileHearAboutDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHearAbout(profileHearAboutDto?: ProfileHearAboutDto, options?: any): AxiosPromise<Profile> {
            return localVarFp.updateHearAbout(profileHearAboutDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CredentialDto} [credentialDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateEmailToken(credentialDto?: CredentialDto, options?: any): AxiosPromise<AccessTokenDto> {
            return localVarFp.validateEmailToken(credentialDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PromoCodeRequest} [promoCodeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePromoCode(promoCodeRequest?: PromoCodeRequest, options?: any): AxiosPromise<PromoCodeResponse> {
            return localVarFp.validatePromoCode(promoCodeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
export class ProfileApi extends BaseAPI {
    /**
     * 
     * @param {boolean} [revixRoundUp] 
     * @param {Array<string>} [acceptedTerms] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public activate(revixRoundUp?: boolean, acceptedTerms?: Array<string>, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).activate(revixRoundUp, acceptedTerms, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OtpActivateRequest} [otpActivateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public activateEmailOtp(otpActivateRequest?: OtpActivateRequest, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).activateEmailOtp(otpActivateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OtpActivateRequest} [otpActivateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public activateSmsOtp(otpActivateRequest?: OtpActivateRequest, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).activateSmsOtp(otpActivateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TotpActivateRequest} [totpActivateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public activateTotp(totpActivateRequest?: TotpActivateRequest, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).activateTotp(totpActivateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChangeEmailRequest} [changeEmailRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public changeEmail(changeEmailRequest?: ChangeEmailRequest, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).changeEmail(changeEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [token] 
     * @param {string} [totp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public changeEmailVerify(token?: string, totp?: string, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).changeEmailVerify(token, totp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [_2fa] 2FA PIN - required if 2FA enabled
     * @param {string} [otpFlowTypeCode] OTP Flow Type Code - required if SMS OTP enabled
     * @param {ChangePasswordRequest} [changePasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public changePassword(_2fa?: string, otpFlowTypeCode?: string, changePasswordRequest?: ChangePasswordRequest, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).changePassword(_2fa, otpFlowTypeCode, changePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OtpDeactivateRequest} [otpDeactivateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public deactivateEmailOtp(otpDeactivateRequest?: OtpDeactivateRequest, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).deactivateEmailOtp(otpDeactivateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OtpDeactivateRequest} [otpDeactivateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public deactivateSmsOtp(otpDeactivateRequest?: OtpDeactivateRequest, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).deactivateSmsOtp(otpDeactivateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public disableLoginNotification(options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).disableLoginNotification(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TotpDisableRequest} [totpDisableRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public disableTotp(totpDisableRequest?: TotpDisableRequest, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).disableTotp(totpDisableRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public enableLoginNotification(options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).enableLoginNotification(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForgotPasswordRequest} [forgotPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public forgotPassword(forgotPasswordRequest?: ForgotPasswordRequest, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).forgotPassword(forgotPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public get(options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).get(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OtpInitiateEmailRequest} [otpInitiateEmailRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public initiateEmailOtp(otpInitiateEmailRequest?: OtpInitiateEmailRequest, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).initiateEmailOtp(otpInitiateEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OtpInitiateRequest} [otpInitiateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public initiateSmsOtp(otpInitiateRequest?: OtpInitiateRequest, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).initiateSmsOtp(otpInitiateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LinkWithSsoRequestDto} [linkWithSsoRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public linkWithProvider(linkWithSsoRequestDto?: LinkWithSsoRequestDto, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).linkWithProvider(linkWithSsoRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public loginMethods(options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).loginMethods(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterRequestDto} [registerRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public register(registerRequestDto?: RegisterRequestDto, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).register(registerRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public registerTotp(options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).registerTotp(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterWithSsoRequestDto} [registerWithSsoRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public registerWithProvider(registerWithSsoRequestDto?: RegisterWithSsoRequestDto, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).registerWithProvider(registerWithSsoRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public resendRegistrationEmail(email?: string, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).resendRegistrationEmail(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResetPasswordRequest} [resetPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public resetPassword(resetPasswordRequest?: ResetPasswordRequest, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).resetPassword(resetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OtpSendEmailRequest} [otpSendEmailRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public sendEmailOtp(otpSendEmailRequest?: OtpSendEmailRequest, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).sendEmailOtp(otpSendEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OtpSendSmsRequest} [otpSendSmsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public sendSmsOtp(otpSendSmsRequest?: OtpSendSmsRequest, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).sendSmsOtp(otpSendSmsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public status(options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).status(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {string} [email] 
     * @param {string} [dateOfBirth] 
     * @param {string} [phoneNumber] 
     * @param {string} [firstDepositDate] 
     * @param {number} [balance] 
     * @param {File} [idDocument] 
     * @param {File} [selfieDocument] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public twoFactorReset(firstName?: string, lastName?: string, email?: string, dateOfBirth?: string, phoneNumber?: string, firstDepositDate?: string, balance?: number, idDocument?: File, selfieDocument?: File, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).twoFactorReset(firstName, lastName, email, dateOfBirth, phoneNumber, firstDepositDate, balance, idDocument, selfieDocument, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileUpdateDto} [profileUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public updateDetails(profileUpdateDto?: ProfileUpdateDto, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).updateDetails(profileUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileHearAboutDto} [profileHearAboutDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public updateHearAbout(profileHearAboutDto?: ProfileHearAboutDto, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).updateHearAbout(profileHearAboutDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CredentialDto} [credentialDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public validateEmailToken(credentialDto?: CredentialDto, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).validateEmailToken(credentialDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PromoCodeRequest} [promoCodeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public validatePromoCode(promoCodeRequest?: PromoCodeRequest, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).validatePromoCode(promoCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RatesApi - axios parameter creator
 * @export
 */
export const RatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [targetProductCode] 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDisplayRates: async (companyCode?: string, targetProductCode?: string, requestBody?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rates/displayable-rates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            if (targetProductCode !== undefined) {
                localVarQueryParameter['targetProductCode'] = targetProductCode;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RatesApi - functional programming interface
 * @export
 */
export const RatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [targetProductCode] 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDisplayRates(companyCode?: string, targetProductCode?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDisplayRates(companyCode, targetProductCode, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RatesApi.getDisplayRates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RatesApi - factory interface
 * @export
 */
export const RatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RatesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [targetProductCode] 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDisplayRates(companyCode?: string, targetProductCode?: string, requestBody?: Array<string>, options?: any): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.getDisplayRates(companyCode, targetProductCode, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RatesApi - object-oriented interface
 * @export
 * @class RatesApi
 * @extends {BaseAPI}
 */
export class RatesApi extends BaseAPI {
    /**
     * 
     * @param {string} [companyCode] 
     * @param {string} [targetProductCode] 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RatesApi
     */
    public getDisplayRates(companyCode?: string, targetProductCode?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig) {
        return RatesApiFp(this.configuration).getDisplayRates(companyCode, targetProductCode, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RatesV2Api - axios parameter creator
 * @export
 */
export const RatesV2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [targetProductCode] 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDisplayRates: async (companyCode?: string, targetProductCode?: string, requestBody?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rates/v2/displayable-rates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            if (targetProductCode !== undefined) {
                localVarQueryParameter['targetProductCode'] = targetProductCode;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RatesV2Api - functional programming interface
 * @export
 */
export const RatesV2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RatesV2ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [targetProductCode] 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDisplayRates(companyCode?: string, targetProductCode?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDisplayRates(companyCode, targetProductCode, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RatesV2Api.getDisplayRates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RatesV2Api - factory interface
 * @export
 */
export const RatesV2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RatesV2ApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [targetProductCode] 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDisplayRates(companyCode?: string, targetProductCode?: string, requestBody?: Array<string>, options?: any): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.getDisplayRates(companyCode, targetProductCode, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RatesV2Api - object-oriented interface
 * @export
 * @class RatesV2Api
 * @extends {BaseAPI}
 */
export class RatesV2Api extends BaseAPI {
    /**
     * 
     * @param {string} [companyCode] 
     * @param {string} [targetProductCode] 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RatesV2Api
     */
    public getDisplayRates(companyCode?: string, targetProductCode?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig) {
        return RatesV2ApiFp(this.configuration).getDisplayRates(companyCode, targetProductCode, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RecurringBuyApi - axios parameter creator
 * @export
 */
export const RecurringBuyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancels (deletes) a recurring buy. It cannot be reactivated
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelRecurringBuy: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelRecurringBuy', 'id', id)
            const localVarPath = `/recurring-buys/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new recurring buy for the user given the specified parameters
         * @param {CreateRecurringBuyRequest} [createRecurringBuyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecurringBuy: async (createRecurringBuyRequest?: CreateRecurringBuyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/recurring-buys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRecurringBuyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets details about a specified recurring buy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecurringBuyById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRecurringBuyById', 'id', id)
            const localVarPath = `/recurring-buys/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets details of all recurring buys of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecurringBuys: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/recurring-buys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pauses a specified recurring buy. It will not be executed until resumed
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pauseRecurringBuy: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('pauseRecurringBuy', 'id', id)
            const localVarPath = `/recurring-buys/{id}/pause`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resumes a specified recurring buy. It will be executed on the next scheduled date
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeRecurringBuy: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('resumeRecurringBuy', 'id', id)
            const localVarPath = `/recurring-buys/{id}/resume`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecurringBuyApi - functional programming interface
 * @export
 */
export const RecurringBuyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecurringBuyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancels (deletes) a recurring buy. It cannot be reactivated
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelRecurringBuy(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelRecurringBuy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RecurringBuyApi.cancelRecurringBuy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Creates a new recurring buy for the user given the specified parameters
         * @param {CreateRecurringBuyRequest} [createRecurringBuyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRecurringBuy(createRecurringBuyRequest?: CreateRecurringBuyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecurringBuyCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRecurringBuy(createRecurringBuyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RecurringBuyApi.createRecurringBuy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets details about a specified recurring buy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecurringBuyById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecurringBuyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecurringBuyById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RecurringBuyApi.getRecurringBuyById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets details of all recurring buys of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecurringBuys(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecurringBuyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecurringBuys(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RecurringBuyApi.getRecurringBuys']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Pauses a specified recurring buy. It will not be executed until resumed
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pauseRecurringBuy(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pauseRecurringBuy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RecurringBuyApi.pauseRecurringBuy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Resumes a specified recurring buy. It will be executed on the next scheduled date
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resumeRecurringBuy(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resumeRecurringBuy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RecurringBuyApi.resumeRecurringBuy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RecurringBuyApi - factory interface
 * @export
 */
export const RecurringBuyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecurringBuyApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancels (deletes) a recurring buy. It cannot be reactivated
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelRecurringBuy(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancelRecurringBuy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new recurring buy for the user given the specified parameters
         * @param {CreateRecurringBuyRequest} [createRecurringBuyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecurringBuy(createRecurringBuyRequest?: CreateRecurringBuyRequest, options?: any): AxiosPromise<RecurringBuyCreateResponse> {
            return localVarFp.createRecurringBuy(createRecurringBuyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets details about a specified recurring buy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecurringBuyById(id: string, options?: any): AxiosPromise<RecurringBuyResponse> {
            return localVarFp.getRecurringBuyById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets details of all recurring buys of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecurringBuys(options?: any): AxiosPromise<Array<RecurringBuyResponse>> {
            return localVarFp.getRecurringBuys(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pauses a specified recurring buy. It will not be executed until resumed
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pauseRecurringBuy(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.pauseRecurringBuy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resumes a specified recurring buy. It will be executed on the next scheduled date
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeRecurringBuy(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.resumeRecurringBuy(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecurringBuyApi - object-oriented interface
 * @export
 * @class RecurringBuyApi
 * @extends {BaseAPI}
 */
export class RecurringBuyApi extends BaseAPI {
    /**
     * 
     * @summary Cancels (deletes) a recurring buy. It cannot be reactivated
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringBuyApi
     */
    public cancelRecurringBuy(id: string, options?: RawAxiosRequestConfig) {
        return RecurringBuyApiFp(this.configuration).cancelRecurringBuy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new recurring buy for the user given the specified parameters
     * @param {CreateRecurringBuyRequest} [createRecurringBuyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringBuyApi
     */
    public createRecurringBuy(createRecurringBuyRequest?: CreateRecurringBuyRequest, options?: RawAxiosRequestConfig) {
        return RecurringBuyApiFp(this.configuration).createRecurringBuy(createRecurringBuyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets details about a specified recurring buy
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringBuyApi
     */
    public getRecurringBuyById(id: string, options?: RawAxiosRequestConfig) {
        return RecurringBuyApiFp(this.configuration).getRecurringBuyById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets details of all recurring buys of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringBuyApi
     */
    public getRecurringBuys(options?: RawAxiosRequestConfig) {
        return RecurringBuyApiFp(this.configuration).getRecurringBuys(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pauses a specified recurring buy. It will not be executed until resumed
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringBuyApi
     */
    public pauseRecurringBuy(id: string, options?: RawAxiosRequestConfig) {
        return RecurringBuyApiFp(this.configuration).pauseRecurringBuy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resumes a specified recurring buy. It will be executed on the next scheduled date
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringBuyApi
     */
    public resumeRecurringBuy(id: string, options?: RawAxiosRequestConfig) {
        return RecurringBuyApiFp(this.configuration).resumeRecurringBuy(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReferenceApi - axios parameter creator
 * @export
 */
export const ReferenceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsset: async (code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getAsset', 'code', code)
            const localVarPath = `/references/assets/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountry: async (code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getCountry', 'code', code)
            const localVarPath = `/references/countries/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrency: async (code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getCurrency', 'code', code)
            const localVarPath = `/references/currencies/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssets: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/references/assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClientApplicationSsos: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/references/clientApplicationSsos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCountries: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/references/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCurrencies: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/references/currencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVasps: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/references/vasps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferenceApi - functional programming interface
 * @export
 */
export const ReferenceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReferenceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAsset(code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetAggregateFullDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAsset(code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReferenceApi.getAsset']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountry(code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Country>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCountry(code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReferenceApi.getCountry']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrency(code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Currency>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrency(code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReferenceApi.getCurrency']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAssets(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssetAggregateDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAssets(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReferenceApi.listAssets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listClientApplicationSsos(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientApplicationSso>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listClientApplicationSsos(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReferenceApi.listClientApplicationSsos']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCountries(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Country>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCountries(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReferenceApi.listCountries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCurrencies(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Currency>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCurrencies(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReferenceApi.listCurrencies']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listVasps(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VaspDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listVasps(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReferenceApi.listVasps']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReferenceApi - factory interface
 * @export
 */
export const ReferenceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReferenceApiFp(configuration)
    return {
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsset(code: string, options?: any): AxiosPromise<AssetAggregateFullDto> {
            return localVarFp.getAsset(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountry(code: string, options?: any): AxiosPromise<Country> {
            return localVarFp.getCountry(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrency(code: string, options?: any): AxiosPromise<Currency> {
            return localVarFp.getCurrency(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssets(options?: any): AxiosPromise<Array<AssetAggregateDto>> {
            return localVarFp.listAssets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClientApplicationSsos(options?: any): AxiosPromise<Array<ClientApplicationSso>> {
            return localVarFp.listClientApplicationSsos(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCountries(options?: any): AxiosPromise<Array<Country>> {
            return localVarFp.listCountries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCurrencies(options?: any): AxiosPromise<Array<Currency>> {
            return localVarFp.listCurrencies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVasps(options?: any): AxiosPromise<Array<VaspDto>> {
            return localVarFp.listVasps(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReferenceApi - object-oriented interface
 * @export
 * @class ReferenceApi
 * @extends {BaseAPI}
 */
export class ReferenceApi extends BaseAPI {
    /**
     * 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceApi
     */
    public getAsset(code: string, options?: RawAxiosRequestConfig) {
        return ReferenceApiFp(this.configuration).getAsset(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceApi
     */
    public getCountry(code: string, options?: RawAxiosRequestConfig) {
        return ReferenceApiFp(this.configuration).getCountry(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceApi
     */
    public getCurrency(code: string, options?: RawAxiosRequestConfig) {
        return ReferenceApiFp(this.configuration).getCurrency(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceApi
     */
    public listAssets(options?: RawAxiosRequestConfig) {
        return ReferenceApiFp(this.configuration).listAssets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceApi
     */
    public listClientApplicationSsos(options?: RawAxiosRequestConfig) {
        return ReferenceApiFp(this.configuration).listClientApplicationSsos(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceApi
     */
    public listCountries(options?: RawAxiosRequestConfig) {
        return ReferenceApiFp(this.configuration).listCountries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceApi
     */
    public listCurrencies(options?: RawAxiosRequestConfig) {
        return ReferenceApiFp(this.configuration).listCurrencies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceApi
     */
    public listVasps(options?: RawAxiosRequestConfig) {
        return ReferenceApiFp(this.configuration).listVasps(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RewardsApi - axios parameter creator
 * @export
 */
export const RewardsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a referral history for a customer, ordering will default to property \"DateTime\" descending
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [orderByDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralHistory: async (skip?: number, take?: number, orderBy?: string, orderByDescending?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rewards/referrals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderByDescending !== undefined) {
                localVarQueryParameter['orderByDescending'] = orderByDescending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get detailed data about a customers current rewards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRewardDetails: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rewards/detailed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the different rewards tiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRewardTiers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rewards/tiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Redeem all of a users rewards for BTC
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeem: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rewards/redeem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RewardsApi - functional programming interface
 * @export
 */
export const RewardsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RewardsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a referral history for a customer, ordering will default to property \"DateTime\" descending
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [orderByDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralHistory(skip?: number, take?: number, orderBy?: string, orderByDescending?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralsHistoryDtoPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralHistory(skip, take, orderBy, orderByDescending, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RewardsApi.getReferralHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get detailed data about a customers current rewards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRewardDetails(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedRewardDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRewardDetails(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RewardsApi.getRewardDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the different rewards tiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRewardTiers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RewardTierDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRewardTiers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RewardsApi.getRewardTiers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Redeem all of a users rewards for BTC
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redeem(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RedeemRewardsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redeem(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RewardsApi.redeem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RewardsApi - factory interface
 * @export
 */
export const RewardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RewardsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a referral history for a customer, ordering will default to property \"DateTime\" descending
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [orderByDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralHistory(skip?: number, take?: number, orderBy?: string, orderByDescending?: string, options?: any): AxiosPromise<ReferralsHistoryDtoPagedList> {
            return localVarFp.getReferralHistory(skip, take, orderBy, orderByDescending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get detailed data about a customers current rewards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRewardDetails(options?: any): AxiosPromise<DetailedRewardDetailsDto> {
            return localVarFp.getRewardDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the different rewards tiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRewardTiers(options?: any): AxiosPromise<Array<RewardTierDto>> {
            return localVarFp.getRewardTiers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Redeem all of a users rewards for BTC
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeem(options?: any): AxiosPromise<RedeemRewardsResponseDto> {
            return localVarFp.redeem(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RewardsApi - object-oriented interface
 * @export
 * @class RewardsApi
 * @extends {BaseAPI}
 */
export class RewardsApi extends BaseAPI {
    /**
     * 
     * @summary Get a referral history for a customer, ordering will default to property \"DateTime\" descending
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {string} [orderByDescending] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RewardsApi
     */
    public getReferralHistory(skip?: number, take?: number, orderBy?: string, orderByDescending?: string, options?: RawAxiosRequestConfig) {
        return RewardsApiFp(this.configuration).getReferralHistory(skip, take, orderBy, orderByDescending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get detailed data about a customers current rewards
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RewardsApi
     */
    public getRewardDetails(options?: RawAxiosRequestConfig) {
        return RewardsApiFp(this.configuration).getRewardDetails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the different rewards tiers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RewardsApi
     */
    public getRewardTiers(options?: RawAxiosRequestConfig) {
        return RewardsApiFp(this.configuration).getRewardTiers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Redeem all of a users rewards for BTC
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RewardsApi
     */
    public redeem(options?: RawAxiosRequestConfig) {
        return RewardsApiFp(this.configuration).redeem(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SellApi - axios parameter creator
 * @export
 */
export const SellApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all products that the selected product can be sold to by customer
         * @param {string} sourceProductCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availableProducts: async (sourceProductCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceProductCode' is not null or undefined
            assertParamExists('availableProducts', 'sourceProductCode', sourceProductCode)
            const localVarPath = `/sell/{sourceProductCode}/products`
                .replace(`{${"sourceProductCode"}}`, encodeURIComponent(String(sourceProductCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel a pending sell trade order
         * @param {string} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancel: async (orderId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sell/quote/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirm and complete the sell using the order id
         * @param {string} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        complete: async (orderId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sell/quote/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get sell quote data
         * @param {string} [fromProductCode] 
         * @param {string} [toProductCode] 
         * @param {number} [amount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellQuote: async (fromProductCode?: string, toProductCode?: string, amount?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sell/quote/initiate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fromProductCode !== undefined) {
                localVarQueryParameter['fromProductCode'] = fromProductCode;
            }

            if (toProductCode !== undefined) {
                localVarQueryParameter['toProductCode'] = toProductCode;
            }

            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get sell quote selection data
         * @param {string} [fromProductCode] 
         * @param {string} [toProductCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellQuoteData: async (fromProductCode?: string, toProductCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sell/quote/selection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fromProductCode !== undefined) {
                localVarQueryParameter['fromProductCode'] = fromProductCode;
            }

            if (toProductCode !== undefined) {
                localVarQueryParameter['toProductCode'] = toProductCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh the sell quote using the order id
         * @param {string} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh: async (orderId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sell/quote/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SellApi - functional programming interface
 * @export
 */
export const SellApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SellApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all products that the selected product can be sold to by customer
         * @param {string} sourceProductCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async availableProducts(sourceProductCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TradeProductDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.availableProducts(sourceProductCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellApi.availableProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Cancel a pending sell trade order
         * @param {string} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancel(orderId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancel(orderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellApi.cancel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Confirm and complete the sell using the order id
         * @param {string} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async complete(orderId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RFQTradeResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.complete(orderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellApi.complete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get sell quote data
         * @param {string} [fromProductCode] 
         * @param {string} [toProductCode] 
         * @param {number} [amount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSellQuote(fromProductCode?: string, toProductCode?: string, amount?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RFQTradeQuoteDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSellQuote(fromProductCode, toProductCode, amount, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellApi.getSellQuote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get sell quote selection data
         * @param {string} [fromProductCode] 
         * @param {string} [toProductCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSellQuoteData(fromProductCode?: string, toProductCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RFQTradeQuoteSelectionDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSellQuoteData(fromProductCode, toProductCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellApi.getSellQuoteData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Refresh the sell quote using the order id
         * @param {string} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refresh(orderId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RFQTradeQuoteDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refresh(orderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellApi.refresh']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SellApi - factory interface
 * @export
 */
export const SellApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SellApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all products that the selected product can be sold to by customer
         * @param {string} sourceProductCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availableProducts(sourceProductCode: string, options?: any): AxiosPromise<Array<TradeProductDto>> {
            return localVarFp.availableProducts(sourceProductCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel a pending sell trade order
         * @param {string} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancel(orderId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.cancel(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirm and complete the sell using the order id
         * @param {string} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        complete(orderId?: string, options?: any): AxiosPromise<RFQTradeResultDto> {
            return localVarFp.complete(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get sell quote data
         * @param {string} [fromProductCode] 
         * @param {string} [toProductCode] 
         * @param {number} [amount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellQuote(fromProductCode?: string, toProductCode?: string, amount?: number, options?: any): AxiosPromise<RFQTradeQuoteDataDto> {
            return localVarFp.getSellQuote(fromProductCode, toProductCode, amount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get sell quote selection data
         * @param {string} [fromProductCode] 
         * @param {string} [toProductCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellQuoteData(fromProductCode?: string, toProductCode?: string, options?: any): AxiosPromise<RFQTradeQuoteSelectionDataDto> {
            return localVarFp.getSellQuoteData(fromProductCode, toProductCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh the sell quote using the order id
         * @param {string} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh(orderId?: string, options?: any): AxiosPromise<RFQTradeQuoteDataDto> {
            return localVarFp.refresh(orderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SellApi - object-oriented interface
 * @export
 * @class SellApi
 * @extends {BaseAPI}
 */
export class SellApi extends BaseAPI {
    /**
     * 
     * @summary Get all products that the selected product can be sold to by customer
     * @param {string} sourceProductCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellApi
     */
    public availableProducts(sourceProductCode: string, options?: RawAxiosRequestConfig) {
        return SellApiFp(this.configuration).availableProducts(sourceProductCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel a pending sell trade order
     * @param {string} [orderId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellApi
     */
    public cancel(orderId?: string, options?: RawAxiosRequestConfig) {
        return SellApiFp(this.configuration).cancel(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Confirm and complete the sell using the order id
     * @param {string} [orderId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellApi
     */
    public complete(orderId?: string, options?: RawAxiosRequestConfig) {
        return SellApiFp(this.configuration).complete(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get sell quote data
     * @param {string} [fromProductCode] 
     * @param {string} [toProductCode] 
     * @param {number} [amount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellApi
     */
    public getSellQuote(fromProductCode?: string, toProductCode?: string, amount?: number, options?: RawAxiosRequestConfig) {
        return SellApiFp(this.configuration).getSellQuote(fromProductCode, toProductCode, amount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get sell quote selection data
     * @param {string} [fromProductCode] 
     * @param {string} [toProductCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellApi
     */
    public getSellQuoteData(fromProductCode?: string, toProductCode?: string, options?: RawAxiosRequestConfig) {
        return SellApiFp(this.configuration).getSellQuoteData(fromProductCode, toProductCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh the sell quote using the order id
     * @param {string} [orderId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellApi
     */
    public refresh(orderId?: string, options?: RawAxiosRequestConfig) {
        return SellApiFp(this.configuration).refresh(orderId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SmsSignUpApi - axios parameter creator
 * @export
 */
export const SmsSignUpApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send an sms with a sign up link
         * @param {string} [mobileNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUpSms: async (mobileNumber?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/smsSignup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (mobileNumber !== undefined) {
                localVarQueryParameter['mobileNumber'] = mobileNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SmsSignUpApi - functional programming interface
 * @export
 */
export const SmsSignUpApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SmsSignUpApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send an sms with a sign up link
         * @param {string} [mobileNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signUpSms(mobileNumber?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signUpSms(mobileNumber, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SmsSignUpApi.signUpSms']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SmsSignUpApi - factory interface
 * @export
 */
export const SmsSignUpApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SmsSignUpApiFp(configuration)
    return {
        /**
         * 
         * @summary Send an sms with a sign up link
         * @param {string} [mobileNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUpSms(mobileNumber?: string, options?: any): AxiosPromise<void> {
            return localVarFp.signUpSms(mobileNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SmsSignUpApi - object-oriented interface
 * @export
 * @class SmsSignUpApi
 * @extends {BaseAPI}
 */
export class SmsSignUpApi extends BaseAPI {
    /**
     * 
     * @summary Send an sms with a sign up link
     * @param {string} [mobileNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SmsSignUpApi
     */
    public signUpSms(mobileNumber?: string, options?: RawAxiosRequestConfig) {
        return SmsSignUpApiFp(this.configuration).signUpSms(mobileNumber, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TermApi - axios parameter creator
 * @export
 */
export const TermApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} termCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptTerm: async (termCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'termCode' is not null or undefined
            assertParamExists('acceptTerm', 'termCode', termCode)
            const localVarPath = `/terms/{termCode}`
                .replace(`{${"termCode"}}`, encodeURIComponent(String(termCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTerms: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/terms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TermApi - functional programming interface
 * @export
 */
export const TermApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TermApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} termCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptTerm(termCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptTerm(termCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TermApi.acceptTerm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserTerms(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserTermDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserTerms(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TermApi.getUserTerms']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TermApi - factory interface
 * @export
 */
export const TermApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TermApiFp(configuration)
    return {
        /**
         * 
         * @param {string} termCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptTerm(termCode: string, options?: any): AxiosPromise<void> {
            return localVarFp.acceptTerm(termCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTerms(options?: any): AxiosPromise<Array<UserTermDto>> {
            return localVarFp.getUserTerms(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TermApi - object-oriented interface
 * @export
 * @class TermApi
 * @extends {BaseAPI}
 */
export class TermApi extends BaseAPI {
    /**
     * 
     * @param {string} termCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermApi
     */
    public acceptTerm(termCode: string, options?: RawAxiosRequestConfig) {
        return TermApiFp(this.configuration).acceptTerm(termCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermApi
     */
    public getUserTerms(options?: RawAxiosRequestConfig) {
        return TermApiFp(this.configuration).getUserTerms(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TransactionHistoryApi - axios parameter creator
 * @export
 */
export const TransactionHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a completed deposits and withdrawals for a customer, ordering will default to property \"DateTime\" descending
         * @param {GetCompletedDepositsWithdrawalsAsyncOrderTypeEnum} [orderType] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [orderByDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompletedDepositsWithdrawalsAsync: async (orderType?: GetCompletedDepositsWithdrawalsAsyncOrderTypeEnum, skip?: number, take?: number, orderBy?: string, orderByDescending?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/transactionHistory/depositWithdrawals/completed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderType !== undefined) {
                localVarQueryParameter['orderType'] = orderType;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderByDescending !== undefined) {
                localVarQueryParameter['orderByDescending'] = orderByDescending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pending deposits, withdrawals and vault orders for a customer, ordering will default to property \"DateTime\"  descending
         * @param {GetPendingOrdersOrderTypeEnum} [orderType] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [orderByDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingOrders: async (orderType?: GetPendingOrdersOrderTypeEnum, skip?: number, take?: number, orderBy?: string, orderByDescending?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/transactionHistory/orders/pending`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderType !== undefined) {
                localVarQueryParameter['orderType'] = orderType;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderByDescending !== undefined) {
                localVarQueryParameter['orderByDescending'] = orderByDescending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pending deposits, withdrawals and vault orders for a customer, ordering will default to property \"DateTime\"  descending
         * @param {GetPendingOrders0OrderTypeEnum} [orderType] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [orderByDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingOrders_1: async (orderType?: GetPendingOrders0OrderTypeEnum, skip?: number, take?: number, orderBy?: string, orderByDescending?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/transactionHistory/depositWithdrawals/pending`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderType !== undefined) {
                localVarQueryParameter['orderType'] = orderType;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderByDescending !== undefined) {
                localVarQueryParameter['orderByDescending'] = orderByDescending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRebalanceTransactionBreakdownAsync: async (orderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('getRebalanceTransactionBreakdownAsync', 'orderId', orderId)
            const localVarPath = `/transactionHistory/transaction/{orderId}/rebalanceBreakdown`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a rewards history for a customer, ordering will default to property \"DateTime\" descending
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [orderByDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRewardHistoryAsync: async (skip?: number, take?: number, orderBy?: string, orderByDescending?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/transactionHistory/rewards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderByDescending !== undefined) {
                localVarQueryParameter['orderByDescending'] = orderByDescending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get transaction history for a customer, ordering will default to property \"DateTime\" descending
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [orderByDescending] 
         * @param {GetTransactionHistoryAsyncOrderTypeEnum} [orderType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionHistoryAsync: async (skip?: number, take?: number, orderBy?: string, orderByDescending?: string, orderType?: GetTransactionHistoryAsyncOrderTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/transactionHistory/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderByDescending !== undefined) {
                localVarQueryParameter['orderByDescending'] = orderByDescending;
            }

            if (orderType !== undefined) {
                localVarQueryParameter['orderType'] = orderType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get vault transaction history for a customer, ordering will default to property \"DateTime\" descending
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [orderByDescending] 
         * @param {GetVaultTransactionHistoryAsyncOrderTypeEnum} [orderType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVaultTransactionHistoryAsync: async (skip?: number, take?: number, orderBy?: string, orderByDescending?: string, orderType?: GetVaultTransactionHistoryAsyncOrderTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/transactionHistory/vault`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderByDescending !== undefined) {
                localVarQueryParameter['orderByDescending'] = orderByDescending;
            }

            if (orderType !== undefined) {
                localVarQueryParameter['orderType'] = orderType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionHistoryApi - functional programming interface
 * @export
 */
export const TransactionHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a completed deposits and withdrawals for a customer, ordering will default to property \"DateTime\" descending
         * @param {GetCompletedDepositsWithdrawalsAsyncOrderTypeEnum} [orderType] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [orderByDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompletedDepositsWithdrawalsAsync(orderType?: GetCompletedDepositsWithdrawalsAsyncOrderTypeEnum, skip?: number, take?: number, orderBy?: string, orderByDescending?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompletedDepositsWithdrawalsDtoPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompletedDepositsWithdrawalsAsync(orderType, skip, take, orderBy, orderByDescending, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionHistoryApi.getCompletedDepositsWithdrawalsAsync']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get pending deposits, withdrawals and vault orders for a customer, ordering will default to property \"DateTime\"  descending
         * @param {GetPendingOrdersOrderTypeEnum} [orderType] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [orderByDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPendingOrders(orderType?: GetPendingOrdersOrderTypeEnum, skip?: number, take?: number, orderBy?: string, orderByDescending?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PendingOrdersDtoPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPendingOrders(orderType, skip, take, orderBy, orderByDescending, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionHistoryApi.getPendingOrders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get pending deposits, withdrawals and vault orders for a customer, ordering will default to property \"DateTime\"  descending
         * @param {GetPendingOrders0OrderTypeEnum} [orderType] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [orderByDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPendingOrders_1(orderType?: GetPendingOrders0OrderTypeEnum, skip?: number, take?: number, orderBy?: string, orderByDescending?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PendingOrdersDtoPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPendingOrders_1(orderType, skip, take, orderBy, orderByDescending, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionHistoryApi.getPendingOrders_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRebalanceTransactionBreakdownAsync(orderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RebalanceBreakdownDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRebalanceTransactionBreakdownAsync(orderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionHistoryApi.getRebalanceTransactionBreakdownAsync']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a rewards history for a customer, ordering will default to property \"DateTime\" descending
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [orderByDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRewardHistoryAsync(skip?: number, take?: number, orderBy?: string, orderByDescending?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedRewardsHistoryItemDtoPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRewardHistoryAsync(skip, take, orderBy, orderByDescending, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionHistoryApi.getRewardHistoryAsync']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get transaction history for a customer, ordering will default to property \"DateTime\" descending
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [orderByDescending] 
         * @param {GetTransactionHistoryAsyncOrderTypeEnum} [orderType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactionHistoryAsync(skip?: number, take?: number, orderBy?: string, orderByDescending?: string, orderType?: GetTransactionHistoryAsyncOrderTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompletedTransactionHistoryDtoPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactionHistoryAsync(skip, take, orderBy, orderByDescending, orderType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionHistoryApi.getTransactionHistoryAsync']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get vault transaction history for a customer, ordering will default to property \"DateTime\" descending
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [orderByDescending] 
         * @param {GetVaultTransactionHistoryAsyncOrderTypeEnum} [orderType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVaultTransactionHistoryAsync(skip?: number, take?: number, orderBy?: string, orderByDescending?: string, orderType?: GetVaultTransactionHistoryAsyncOrderTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompletedVaultTransactionDtoPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVaultTransactionHistoryAsync(skip, take, orderBy, orderByDescending, orderType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionHistoryApi.getVaultTransactionHistoryAsync']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TransactionHistoryApi - factory interface
 * @export
 */
export const TransactionHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionHistoryApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a completed deposits and withdrawals for a customer, ordering will default to property \"DateTime\" descending
         * @param {GetCompletedDepositsWithdrawalsAsyncOrderTypeEnum} [orderType] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [orderByDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompletedDepositsWithdrawalsAsync(orderType?: GetCompletedDepositsWithdrawalsAsyncOrderTypeEnum, skip?: number, take?: number, orderBy?: string, orderByDescending?: string, options?: any): AxiosPromise<CompletedDepositsWithdrawalsDtoPagedList> {
            return localVarFp.getCompletedDepositsWithdrawalsAsync(orderType, skip, take, orderBy, orderByDescending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pending deposits, withdrawals and vault orders for a customer, ordering will default to property \"DateTime\"  descending
         * @param {GetPendingOrdersOrderTypeEnum} [orderType] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [orderByDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingOrders(orderType?: GetPendingOrdersOrderTypeEnum, skip?: number, take?: number, orderBy?: string, orderByDescending?: string, options?: any): AxiosPromise<PendingOrdersDtoPagedList> {
            return localVarFp.getPendingOrders(orderType, skip, take, orderBy, orderByDescending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pending deposits, withdrawals and vault orders for a customer, ordering will default to property \"DateTime\"  descending
         * @param {GetPendingOrders0OrderTypeEnum} [orderType] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [orderByDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingOrders_1(orderType?: GetPendingOrders0OrderTypeEnum, skip?: number, take?: number, orderBy?: string, orderByDescending?: string, options?: any): AxiosPromise<PendingOrdersDtoPagedList> {
            return localVarFp.getPendingOrders_1(orderType, skip, take, orderBy, orderByDescending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRebalanceTransactionBreakdownAsync(orderId: string, options?: any): AxiosPromise<Array<RebalanceBreakdownDto>> {
            return localVarFp.getRebalanceTransactionBreakdownAsync(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a rewards history for a customer, ordering will default to property \"DateTime\" descending
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [orderByDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRewardHistoryAsync(skip?: number, take?: number, orderBy?: string, orderByDescending?: string, options?: any): AxiosPromise<DetailedRewardsHistoryItemDtoPagedList> {
            return localVarFp.getRewardHistoryAsync(skip, take, orderBy, orderByDescending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get transaction history for a customer, ordering will default to property \"DateTime\" descending
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [orderByDescending] 
         * @param {GetTransactionHistoryAsyncOrderTypeEnum} [orderType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionHistoryAsync(skip?: number, take?: number, orderBy?: string, orderByDescending?: string, orderType?: GetTransactionHistoryAsyncOrderTypeEnum, options?: any): AxiosPromise<CompletedTransactionHistoryDtoPagedList> {
            return localVarFp.getTransactionHistoryAsync(skip, take, orderBy, orderByDescending, orderType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get vault transaction history for a customer, ordering will default to property \"DateTime\" descending
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [orderByDescending] 
         * @param {GetVaultTransactionHistoryAsyncOrderTypeEnum} [orderType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVaultTransactionHistoryAsync(skip?: number, take?: number, orderBy?: string, orderByDescending?: string, orderType?: GetVaultTransactionHistoryAsyncOrderTypeEnum, options?: any): AxiosPromise<CompletedVaultTransactionDtoPagedList> {
            return localVarFp.getVaultTransactionHistoryAsync(skip, take, orderBy, orderByDescending, orderType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionHistoryApi - object-oriented interface
 * @export
 * @class TransactionHistoryApi
 * @extends {BaseAPI}
 */
export class TransactionHistoryApi extends BaseAPI {
    /**
     * 
     * @summary Get a completed deposits and withdrawals for a customer, ordering will default to property \"DateTime\" descending
     * @param {GetCompletedDepositsWithdrawalsAsyncOrderTypeEnum} [orderType] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {string} [orderByDescending] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionHistoryApi
     */
    public getCompletedDepositsWithdrawalsAsync(orderType?: GetCompletedDepositsWithdrawalsAsyncOrderTypeEnum, skip?: number, take?: number, orderBy?: string, orderByDescending?: string, options?: RawAxiosRequestConfig) {
        return TransactionHistoryApiFp(this.configuration).getCompletedDepositsWithdrawalsAsync(orderType, skip, take, orderBy, orderByDescending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get pending deposits, withdrawals and vault orders for a customer, ordering will default to property \"DateTime\"  descending
     * @param {GetPendingOrdersOrderTypeEnum} [orderType] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {string} [orderByDescending] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionHistoryApi
     */
    public getPendingOrders(orderType?: GetPendingOrdersOrderTypeEnum, skip?: number, take?: number, orderBy?: string, orderByDescending?: string, options?: RawAxiosRequestConfig) {
        return TransactionHistoryApiFp(this.configuration).getPendingOrders(orderType, skip, take, orderBy, orderByDescending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get pending deposits, withdrawals and vault orders for a customer, ordering will default to property \"DateTime\"  descending
     * @param {GetPendingOrders0OrderTypeEnum} [orderType] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {string} [orderByDescending] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionHistoryApi
     */
    public getPendingOrders_1(orderType?: GetPendingOrders0OrderTypeEnum, skip?: number, take?: number, orderBy?: string, orderByDescending?: string, options?: RawAxiosRequestConfig) {
        return TransactionHistoryApiFp(this.configuration).getPendingOrders_1(orderType, skip, take, orderBy, orderByDescending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionHistoryApi
     */
    public getRebalanceTransactionBreakdownAsync(orderId: string, options?: RawAxiosRequestConfig) {
        return TransactionHistoryApiFp(this.configuration).getRebalanceTransactionBreakdownAsync(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a rewards history for a customer, ordering will default to property \"DateTime\" descending
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {string} [orderByDescending] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionHistoryApi
     */
    public getRewardHistoryAsync(skip?: number, take?: number, orderBy?: string, orderByDescending?: string, options?: RawAxiosRequestConfig) {
        return TransactionHistoryApiFp(this.configuration).getRewardHistoryAsync(skip, take, orderBy, orderByDescending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get transaction history for a customer, ordering will default to property \"DateTime\" descending
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {string} [orderByDescending] 
     * @param {GetTransactionHistoryAsyncOrderTypeEnum} [orderType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionHistoryApi
     */
    public getTransactionHistoryAsync(skip?: number, take?: number, orderBy?: string, orderByDescending?: string, orderType?: GetTransactionHistoryAsyncOrderTypeEnum, options?: RawAxiosRequestConfig) {
        return TransactionHistoryApiFp(this.configuration).getTransactionHistoryAsync(skip, take, orderBy, orderByDescending, orderType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get vault transaction history for a customer, ordering will default to property \"DateTime\" descending
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {string} [orderByDescending] 
     * @param {GetVaultTransactionHistoryAsyncOrderTypeEnum} [orderType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionHistoryApi
     */
    public getVaultTransactionHistoryAsync(skip?: number, take?: number, orderBy?: string, orderByDescending?: string, orderType?: GetVaultTransactionHistoryAsyncOrderTypeEnum, options?: RawAxiosRequestConfig) {
        return TransactionHistoryApiFp(this.configuration).getVaultTransactionHistoryAsync(skip, take, orderBy, orderByDescending, orderType, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetCompletedDepositsWithdrawalsAsyncOrderTypeEnum = {
    All: 'All',
    BankDeposit: 'BankDeposit',
    CryptoDeposit: 'CryptoDeposit',
    BankWithdrawal: 'BankWithdrawal',
    CryptoWithdrawal: 'CryptoWithdrawal',
    AddCryptoFunds: 'AddCryptoFunds',
    AddFiatFunds: 'AddFiatFunds',
    RemoveCryptoFunds: 'RemoveCryptoFunds',
    RemoveFiatFunds: 'RemoveFiatFunds',
    ReferralDeposit: 'ReferralDeposit',
    StitchDeposit: 'StitchDeposit'
} as const;
export type GetCompletedDepositsWithdrawalsAsyncOrderTypeEnum = typeof GetCompletedDepositsWithdrawalsAsyncOrderTypeEnum[keyof typeof GetCompletedDepositsWithdrawalsAsyncOrderTypeEnum];
/**
 * @export
 */
export const GetPendingOrdersOrderTypeEnum = {
    All: 'All',
    BankDeposit: 'BankDeposit',
    CryptoDeposit: 'CryptoDeposit',
    BankWithdrawal: 'BankWithdrawal',
    CryptoWithdrawal: 'CryptoWithdrawal',
    DeFiVaultLock: 'DeFiVaultLock',
    DeFiVaultUnlock: 'DeFiVaultUnlock'
} as const;
export type GetPendingOrdersOrderTypeEnum = typeof GetPendingOrdersOrderTypeEnum[keyof typeof GetPendingOrdersOrderTypeEnum];
/**
 * @export
 */
export const GetPendingOrders0OrderTypeEnum = {
    All: 'All',
    BankDeposit: 'BankDeposit',
    CryptoDeposit: 'CryptoDeposit',
    BankWithdrawal: 'BankWithdrawal',
    CryptoWithdrawal: 'CryptoWithdrawal',
    DeFiVaultLock: 'DeFiVaultLock',
    DeFiVaultUnlock: 'DeFiVaultUnlock'
} as const;
export type GetPendingOrders0OrderTypeEnum = typeof GetPendingOrders0OrderTypeEnum[keyof typeof GetPendingOrders0OrderTypeEnum];
/**
 * @export
 */
export const GetTransactionHistoryAsyncOrderTypeEnum = {
    All: 'All',
    Buy: 'Buy',
    AutoBuy: 'AutoBuy',
    Sell: 'Sell',
    Rebalance: 'Rebalance',
    Redeem: 'Redeem',
    CreditAdjustment: 'CreditAdjustment',
    DebitAdjustment: 'DebitAdjustment'
} as const;
export type GetTransactionHistoryAsyncOrderTypeEnum = typeof GetTransactionHistoryAsyncOrderTypeEnum[keyof typeof GetTransactionHistoryAsyncOrderTypeEnum];
/**
 * @export
 */
export const GetVaultTransactionHistoryAsyncOrderTypeEnum = {
    All: 'All',
    Lock: 'Lock',
    Unlock: 'Unlock'
} as const;
export type GetVaultTransactionHistoryAsyncOrderTypeEnum = typeof GetVaultTransactionHistoryAsyncOrderTypeEnum[keyof typeof GetVaultTransactionHistoryAsyncOrderTypeEnum];


/**
 * TwoFactorApi - axios parameter creator
 * @export
 */
export const TwoFactorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {OtpSendElevatedRequest} [otpSendElevatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatedSend: async (otpSendElevatedRequest?: OtpSendElevatedRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/twoFactor/elevated/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(otpSendElevatedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActive: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/twoFactor/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listElevated: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/twoFactor/elevated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TwoFactorApi - functional programming interface
 * @export
 */
export const TwoFactorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TwoFactorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {OtpSendElevatedRequest} [otpSendElevatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elevatedSend(otpSendElevatedRequest?: OtpSendElevatedRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elevatedSend(otpSendElevatedRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TwoFactorApi.elevatedSend']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listActive(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listActive(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TwoFactorApi.listActive']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listElevated(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listElevated(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TwoFactorApi.listElevated']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TwoFactorApi - factory interface
 * @export
 */
export const TwoFactorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TwoFactorApiFp(configuration)
    return {
        /**
         * 
         * @param {OtpSendElevatedRequest} [otpSendElevatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatedSend(otpSendElevatedRequest?: OtpSendElevatedRequest, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.elevatedSend(otpSendElevatedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActive(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.listActive(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listElevated(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.listElevated(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TwoFactorApi - object-oriented interface
 * @export
 * @class TwoFactorApi
 * @extends {BaseAPI}
 */
export class TwoFactorApi extends BaseAPI {
    /**
     * 
     * @param {OtpSendElevatedRequest} [otpSendElevatedRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwoFactorApi
     */
    public elevatedSend(otpSendElevatedRequest?: OtpSendElevatedRequest, options?: RawAxiosRequestConfig) {
        return TwoFactorApiFp(this.configuration).elevatedSend(otpSendElevatedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwoFactorApi
     */
    public listActive(options?: RawAxiosRequestConfig) {
        return TwoFactorApiFp(this.configuration).listActive(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwoFactorApi
     */
    public listElevated(options?: RawAxiosRequestConfig) {
        return TwoFactorApiFp(this.configuration).listElevated(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VerificationApi - axios parameter creator
 * @export
 */
export const VerificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployment: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/verification/step/employment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestMonth: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/verification/step/investMonth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestYear: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/verification/step/investYear`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLevel: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/verification/step/level`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSource: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/verification/step/source`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUseStatus: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/verification/step/use`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initialiseUserForVerification: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('initialiseUserForVerification', 'userId', userId)
            const localVarPath = `/verification/{userId}/init`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InitializeKyxActionRequestDto} [initializeKyxActionRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initializeOrGetIdUserActionV2: async (initializeKyxActionRequestDto?: InitializeKyxActionRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/verification/initializeOrGetIdUserAction/v2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initializeKyxActionRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} [attachment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAttachmentSource: async (attachment?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/verification/step/source/attachment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (attachment !== undefined) { 
                localVarFormParams.append('Attachment', attachment as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifySubmissionDto} [verifySubmissionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEmployment: async (verifySubmissionDto?: VerifySubmissionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/verification/step/employment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifySubmissionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifySubmissionDto} [verifySubmissionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInvestMonth: async (verifySubmissionDto?: VerifySubmissionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/verification/step/investMonth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifySubmissionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifySubmissionDto} [verifySubmissionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInvestYear: async (verifySubmissionDto?: VerifySubmissionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/verification/step/investYear`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifySubmissionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifySubmissionDto} [verifySubmissionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLevel: async (verifySubmissionDto?: VerifySubmissionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/verification/step/level`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifySubmissionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifySubmissionDto} [verifySubmissionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSource: async (verifySubmissionDto?: VerifySubmissionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/verification/step/source`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifySubmissionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifySubmissionDto} [verifySubmissionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUse: async (verifySubmissionDto?: VerifySubmissionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/verification/step/use`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifySubmissionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tierStatus: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/verification/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateKyxActionRequestDto} [updateKyxActionRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdUserActionReference: async (updateKyxActionRequestDto?: UpdateKyxActionRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/verification/updateIdUserActionReference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateKyxActionRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VerificationApi - functional programming interface
 * @export
 */
export const VerificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VerificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmployment(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyActionStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployment(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerificationApi.getEmployment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvestMonth(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyActionStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvestMonth(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerificationApi.getInvestMonth']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvestYear(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyActionStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvestYear(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerificationApi.getInvestYear']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLevel(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyActionStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLevel(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerificationApi.getLevel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSource(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyActionStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSource(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerificationApi.getSource']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUseStatus(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyActionStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUseStatus(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerificationApi.getUseStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initialiseUserForVerification(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyActionStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initialiseUserForVerification(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerificationApi.initialiseUserForVerification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InitializeKyxActionRequestDto} [initializeKyxActionRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initializeOrGetIdUserActionV2(initializeKyxActionRequestDto?: InitializeKyxActionRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerificationInitializeOrGetIdUserActionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initializeOrGetIdUserActionV2(initializeKyxActionRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerificationApi.initializeOrGetIdUserActionV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {File} [attachment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAttachmentSource(attachment?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAttachmentSource(attachment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerificationApi.postAttachmentSource']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VerifySubmissionDto} [verifySubmissionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postEmployment(verifySubmissionDto?: VerifySubmissionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyActionStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postEmployment(verifySubmissionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerificationApi.postEmployment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VerifySubmissionDto} [verifySubmissionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postInvestMonth(verifySubmissionDto?: VerifySubmissionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyActionStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postInvestMonth(verifySubmissionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerificationApi.postInvestMonth']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VerifySubmissionDto} [verifySubmissionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postInvestYear(verifySubmissionDto?: VerifySubmissionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyActionStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postInvestYear(verifySubmissionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerificationApi.postInvestYear']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VerifySubmissionDto} [verifySubmissionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLevel(verifySubmissionDto?: VerifySubmissionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyActionStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLevel(verifySubmissionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerificationApi.postLevel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VerifySubmissionDto} [verifySubmissionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSource(verifySubmissionDto?: VerifySubmissionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyActionStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSource(verifySubmissionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerificationApi.postSource']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VerifySubmissionDto} [verifySubmissionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUse(verifySubmissionDto?: VerifySubmissionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyActionStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUse(verifySubmissionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerificationApi.postUse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tierStatus(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerificationTierStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tierStatus(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerificationApi.tierStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateKyxActionRequestDto} [updateKyxActionRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIdUserActionReference(updateKyxActionRequestDto?: UpdateKyxActionRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VerifyActionStatusDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIdUserActionReference(updateKyxActionRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerificationApi.updateIdUserActionReference']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VerificationApi - factory interface
 * @export
 */
export const VerificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VerificationApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployment(options?: any): AxiosPromise<VerifyActionStatusDto> {
            return localVarFp.getEmployment(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestMonth(options?: any): AxiosPromise<VerifyActionStatusDto> {
            return localVarFp.getInvestMonth(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestYear(options?: any): AxiosPromise<VerifyActionStatusDto> {
            return localVarFp.getInvestYear(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLevel(options?: any): AxiosPromise<VerifyActionStatusDto> {
            return localVarFp.getLevel(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSource(options?: any): AxiosPromise<VerifyActionStatusDto> {
            return localVarFp.getSource(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUseStatus(options?: any): AxiosPromise<VerifyActionStatusDto> {
            return localVarFp.getUseStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initialiseUserForVerification(userId: string, options?: any): AxiosPromise<VerifyActionStatusDto> {
            return localVarFp.initialiseUserForVerification(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InitializeKyxActionRequestDto} [initializeKyxActionRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initializeOrGetIdUserActionV2(initializeKyxActionRequestDto?: InitializeKyxActionRequestDto, options?: any): AxiosPromise<VerificationInitializeOrGetIdUserActionResponseDto> {
            return localVarFp.initializeOrGetIdUserActionV2(initializeKyxActionRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} [attachment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAttachmentSource(attachment?: File, options?: any): AxiosPromise<void> {
            return localVarFp.postAttachmentSource(attachment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifySubmissionDto} [verifySubmissionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEmployment(verifySubmissionDto?: VerifySubmissionDto, options?: any): AxiosPromise<VerifyActionStatusDto> {
            return localVarFp.postEmployment(verifySubmissionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifySubmissionDto} [verifySubmissionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInvestMonth(verifySubmissionDto?: VerifySubmissionDto, options?: any): AxiosPromise<VerifyActionStatusDto> {
            return localVarFp.postInvestMonth(verifySubmissionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifySubmissionDto} [verifySubmissionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInvestYear(verifySubmissionDto?: VerifySubmissionDto, options?: any): AxiosPromise<VerifyActionStatusDto> {
            return localVarFp.postInvestYear(verifySubmissionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifySubmissionDto} [verifySubmissionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLevel(verifySubmissionDto?: VerifySubmissionDto, options?: any): AxiosPromise<VerifyActionStatusDto> {
            return localVarFp.postLevel(verifySubmissionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifySubmissionDto} [verifySubmissionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSource(verifySubmissionDto?: VerifySubmissionDto, options?: any): AxiosPromise<VerifyActionStatusDto> {
            return localVarFp.postSource(verifySubmissionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifySubmissionDto} [verifySubmissionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUse(verifySubmissionDto?: VerifySubmissionDto, options?: any): AxiosPromise<VerifyActionStatusDto> {
            return localVarFp.postUse(verifySubmissionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tierStatus(options?: any): AxiosPromise<VerificationTierStatus> {
            return localVarFp.tierStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateKyxActionRequestDto} [updateKyxActionRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdUserActionReference(updateKyxActionRequestDto?: UpdateKyxActionRequestDto, options?: any): AxiosPromise<Array<VerifyActionStatusDto>> {
            return localVarFp.updateIdUserActionReference(updateKyxActionRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VerificationApi - object-oriented interface
 * @export
 * @class VerificationApi
 * @extends {BaseAPI}
 */
export class VerificationApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getEmployment(options?: RawAxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getEmployment(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getInvestMonth(options?: RawAxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getInvestMonth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getInvestYear(options?: RawAxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getInvestYear(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getLevel(options?: RawAxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getLevel(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getSource(options?: RawAxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getSource(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getUseStatus(options?: RawAxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getUseStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public initialiseUserForVerification(userId: string, options?: RawAxiosRequestConfig) {
        return VerificationApiFp(this.configuration).initialiseUserForVerification(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InitializeKyxActionRequestDto} [initializeKyxActionRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public initializeOrGetIdUserActionV2(initializeKyxActionRequestDto?: InitializeKyxActionRequestDto, options?: RawAxiosRequestConfig) {
        return VerificationApiFp(this.configuration).initializeOrGetIdUserActionV2(initializeKyxActionRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} [attachment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public postAttachmentSource(attachment?: File, options?: RawAxiosRequestConfig) {
        return VerificationApiFp(this.configuration).postAttachmentSource(attachment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifySubmissionDto} [verifySubmissionDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public postEmployment(verifySubmissionDto?: VerifySubmissionDto, options?: RawAxiosRequestConfig) {
        return VerificationApiFp(this.configuration).postEmployment(verifySubmissionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifySubmissionDto} [verifySubmissionDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public postInvestMonth(verifySubmissionDto?: VerifySubmissionDto, options?: RawAxiosRequestConfig) {
        return VerificationApiFp(this.configuration).postInvestMonth(verifySubmissionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifySubmissionDto} [verifySubmissionDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public postInvestYear(verifySubmissionDto?: VerifySubmissionDto, options?: RawAxiosRequestConfig) {
        return VerificationApiFp(this.configuration).postInvestYear(verifySubmissionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifySubmissionDto} [verifySubmissionDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public postLevel(verifySubmissionDto?: VerifySubmissionDto, options?: RawAxiosRequestConfig) {
        return VerificationApiFp(this.configuration).postLevel(verifySubmissionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifySubmissionDto} [verifySubmissionDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public postSource(verifySubmissionDto?: VerifySubmissionDto, options?: RawAxiosRequestConfig) {
        return VerificationApiFp(this.configuration).postSource(verifySubmissionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifySubmissionDto} [verifySubmissionDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public postUse(verifySubmissionDto?: VerifySubmissionDto, options?: RawAxiosRequestConfig) {
        return VerificationApiFp(this.configuration).postUse(verifySubmissionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public tierStatus(options?: RawAxiosRequestConfig) {
        return VerificationApiFp(this.configuration).tierStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateKyxActionRequestDto} [updateKyxActionRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public updateIdUserActionReference(updateKyxActionRequestDto?: UpdateKyxActionRequestDto, options?: RawAxiosRequestConfig) {
        return VerificationApiFp(this.configuration).updateIdUserActionReference(updateKyxActionRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WithdrawApi - axios parameter creator
 * @export
 */
export const WithdrawApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all available products that can a user can withdraw
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availableProducts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/withdraw/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WithdrawApi - functional programming interface
 * @export
 */
export const WithdrawApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WithdrawApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all available products that can a user can withdraw
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async availableProducts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WithdrawProductDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.availableProducts(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WithdrawApi.availableProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WithdrawApi - factory interface
 * @export
 */
export const WithdrawApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WithdrawApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all available products that can a user can withdraw
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availableProducts(options?: any): AxiosPromise<Array<WithdrawProductDto>> {
            return localVarFp.availableProducts(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WithdrawApi - object-oriented interface
 * @export
 * @class WithdrawApi
 * @extends {BaseAPI}
 */
export class WithdrawApi extends BaseAPI {
    /**
     * 
     * @summary Get all available products that can a user can withdraw
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApi
     */
    public availableProducts(options?: RawAxiosRequestConfig) {
        return WithdrawApiFp(this.configuration).availableProducts(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WithdrawCryptoApi - axios parameter creator
 * @export
 */
export const WithdrawCryptoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary User can cancel an order
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancel: async (orderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('cancel', 'orderId', orderId)
            const localVarPath = `/withdraw/crypto/{orderId}/cancel`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a crypto withdrawal.  If no ProductCode is passed in the object body, the value of ProductCode is defaulted to \"BTC\".
         * @param {CreateCryptoWithdrawDto} [createCryptoWithdrawDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithdraw: async (createCryptoWithdrawDto?: CreateCryptoWithdrawDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/withdraw/crypto`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCryptoWithdrawDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get data specific to the selected crypto product for the withdraw flow.  If no productCode is passed in the query string, the value of productCode is defaulted to \"BTC\".
         * @param {string} [productCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoWithdrawProductData: async (productCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/withdraw/crypto`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productCode !== undefined) {
                localVarQueryParameter['productCode'] = productCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pending crypto withrawal orders for the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingCryptoWithdrawOrderDetails: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/withdraw/crypto/pending`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WithdrawCryptoApi - functional programming interface
 * @export
 */
export const WithdrawCryptoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WithdrawCryptoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary User can cancel an order
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancel(orderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancel(orderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WithdrawCryptoApi.cancel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a crypto withdrawal.  If no ProductCode is passed in the object body, the value of ProductCode is defaulted to \"BTC\".
         * @param {CreateCryptoWithdrawDto} [createCryptoWithdrawDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithdraw(createCryptoWithdrawDto?: CreateCryptoWithdrawDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WithdrawDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithdraw(createCryptoWithdrawDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WithdrawCryptoApi.createWithdraw']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get data specific to the selected crypto product for the withdraw flow.  If no productCode is passed in the query string, the value of productCode is defaulted to \"BTC\".
         * @param {string} [productCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cryptoWithdrawProductData(productCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CryptoWithdrawProductDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cryptoWithdrawProductData(productCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WithdrawCryptoApi.cryptoWithdrawProductData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get pending crypto withrawal orders for the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPendingCryptoWithdrawOrderDetails(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PendingCryptoWithdrawOrderDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPendingCryptoWithdrawOrderDetails(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WithdrawCryptoApi.getPendingCryptoWithdrawOrderDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WithdrawCryptoApi - factory interface
 * @export
 */
export const WithdrawCryptoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WithdrawCryptoApiFp(configuration)
    return {
        /**
         * 
         * @summary User can cancel an order
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancel(orderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancel(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a crypto withdrawal.  If no ProductCode is passed in the object body, the value of ProductCode is defaulted to \"BTC\".
         * @param {CreateCryptoWithdrawDto} [createCryptoWithdrawDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithdraw(createCryptoWithdrawDto?: CreateCryptoWithdrawDto, options?: any): AxiosPromise<WithdrawDetailsDto> {
            return localVarFp.createWithdraw(createCryptoWithdrawDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get data specific to the selected crypto product for the withdraw flow.  If no productCode is passed in the query string, the value of productCode is defaulted to \"BTC\".
         * @param {string} [productCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoWithdrawProductData(productCode?: string, options?: any): AxiosPromise<CryptoWithdrawProductDataDto> {
            return localVarFp.cryptoWithdrawProductData(productCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pending crypto withrawal orders for the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingCryptoWithdrawOrderDetails(options?: any): AxiosPromise<Array<PendingCryptoWithdrawOrderDto>> {
            return localVarFp.getPendingCryptoWithdrawOrderDetails(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WithdrawCryptoApi - object-oriented interface
 * @export
 * @class WithdrawCryptoApi
 * @extends {BaseAPI}
 */
export class WithdrawCryptoApi extends BaseAPI {
    /**
     * 
     * @summary User can cancel an order
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawCryptoApi
     */
    public cancel(orderId: string, options?: RawAxiosRequestConfig) {
        return WithdrawCryptoApiFp(this.configuration).cancel(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a crypto withdrawal.  If no ProductCode is passed in the object body, the value of ProductCode is defaulted to \"BTC\".
     * @param {CreateCryptoWithdrawDto} [createCryptoWithdrawDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawCryptoApi
     */
    public createWithdraw(createCryptoWithdrawDto?: CreateCryptoWithdrawDto, options?: RawAxiosRequestConfig) {
        return WithdrawCryptoApiFp(this.configuration).createWithdraw(createCryptoWithdrawDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get data specific to the selected crypto product for the withdraw flow.  If no productCode is passed in the query string, the value of productCode is defaulted to \"BTC\".
     * @param {string} [productCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawCryptoApi
     */
    public cryptoWithdrawProductData(productCode?: string, options?: RawAxiosRequestConfig) {
        return WithdrawCryptoApiFp(this.configuration).cryptoWithdrawProductData(productCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get pending crypto withrawal orders for the customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawCryptoApi
     */
    public getPendingCryptoWithdrawOrderDetails(options?: RawAxiosRequestConfig) {
        return WithdrawCryptoApiFp(this.configuration).getPendingCryptoWithdrawOrderDetails(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WithdrawFiatApi - axios parameter creator
 * @export
 */
export const WithdrawFiatApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary User can cancel an order
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancel: async (orderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('cancel', 'orderId', orderId)
            const localVarPath = `/withdraw/fiat/{orderId}/cancel`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a fiat withdrawal
         * @param {CreateFiatWithdrawDto} [createFiatWithdrawDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithdrawal: async (createFiatWithdrawDto?: CreateFiatWithdrawDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/withdraw/fiat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFiatWithdrawDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get data specific to EUR for the withdraw flow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        euroFiatWithdrawProductData: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/withdraw/fiat/eur`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get data specific to GBP for the withdraw flow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gbpFiatWithdrawProductData: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/withdraw/fiat/gbp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pending fiat withdrawal orders for the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingFiatWithdrawOrderDetails: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/withdraw/fiat/pending`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get data specific to USD for the withdraw flow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usdFiatWithdrawProductData: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/withdraw/fiat/usd`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get data specific to ZAR for the withdraw flow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zarFiatWithdrawProductData: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/withdraw/fiat/zar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WithdrawFiatApi - functional programming interface
 * @export
 */
export const WithdrawFiatApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WithdrawFiatApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary User can cancel an order
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancel(orderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancel(orderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WithdrawFiatApi.cancel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a fiat withdrawal
         * @param {CreateFiatWithdrawDto} [createFiatWithdrawDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithdrawal(createFiatWithdrawDto?: CreateFiatWithdrawDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WithdrawDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithdrawal(createFiatWithdrawDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WithdrawFiatApi.createWithdrawal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get data specific to EUR for the withdraw flow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async euroFiatWithdrawProductData(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EuroFiatWithdrawProductDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.euroFiatWithdrawProductData(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WithdrawFiatApi.euroFiatWithdrawProductData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get data specific to GBP for the withdraw flow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gbpFiatWithdrawProductData(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GbpFiatWithdrawProductDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gbpFiatWithdrawProductData(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WithdrawFiatApi.gbpFiatWithdrawProductData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get pending fiat withdrawal orders for the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPendingFiatWithdrawOrderDetails(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PendingFiatWithdrawOrderDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPendingFiatWithdrawOrderDetails(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WithdrawFiatApi.getPendingFiatWithdrawOrderDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get data specific to USD for the withdraw flow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usdFiatWithdrawProductData(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsdFiatWithdrawProductDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usdFiatWithdrawProductData(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WithdrawFiatApi.usdFiatWithdrawProductData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get data specific to ZAR for the withdraw flow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zarFiatWithdrawProductData(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZarFiatWithdrawProductDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zarFiatWithdrawProductData(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WithdrawFiatApi.zarFiatWithdrawProductData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WithdrawFiatApi - factory interface
 * @export
 */
export const WithdrawFiatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WithdrawFiatApiFp(configuration)
    return {
        /**
         * 
         * @summary User can cancel an order
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancel(orderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancel(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a fiat withdrawal
         * @param {CreateFiatWithdrawDto} [createFiatWithdrawDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithdrawal(createFiatWithdrawDto?: CreateFiatWithdrawDto, options?: any): AxiosPromise<WithdrawDetailsDto> {
            return localVarFp.createWithdrawal(createFiatWithdrawDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get data specific to EUR for the withdraw flow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        euroFiatWithdrawProductData(options?: any): AxiosPromise<EuroFiatWithdrawProductDataDto> {
            return localVarFp.euroFiatWithdrawProductData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get data specific to GBP for the withdraw flow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gbpFiatWithdrawProductData(options?: any): AxiosPromise<GbpFiatWithdrawProductDataDto> {
            return localVarFp.gbpFiatWithdrawProductData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pending fiat withdrawal orders for the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingFiatWithdrawOrderDetails(options?: any): AxiosPromise<Array<PendingFiatWithdrawOrderDto>> {
            return localVarFp.getPendingFiatWithdrawOrderDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get data specific to USD for the withdraw flow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usdFiatWithdrawProductData(options?: any): AxiosPromise<UsdFiatWithdrawProductDataDto> {
            return localVarFp.usdFiatWithdrawProductData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get data specific to ZAR for the withdraw flow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zarFiatWithdrawProductData(options?: any): AxiosPromise<ZarFiatWithdrawProductDataDto> {
            return localVarFp.zarFiatWithdrawProductData(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WithdrawFiatApi - object-oriented interface
 * @export
 * @class WithdrawFiatApi
 * @extends {BaseAPI}
 */
export class WithdrawFiatApi extends BaseAPI {
    /**
     * 
     * @summary User can cancel an order
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawFiatApi
     */
    public cancel(orderId: string, options?: RawAxiosRequestConfig) {
        return WithdrawFiatApiFp(this.configuration).cancel(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a fiat withdrawal
     * @param {CreateFiatWithdrawDto} [createFiatWithdrawDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawFiatApi
     */
    public createWithdrawal(createFiatWithdrawDto?: CreateFiatWithdrawDto, options?: RawAxiosRequestConfig) {
        return WithdrawFiatApiFp(this.configuration).createWithdrawal(createFiatWithdrawDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get data specific to EUR for the withdraw flow
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawFiatApi
     */
    public euroFiatWithdrawProductData(options?: RawAxiosRequestConfig) {
        return WithdrawFiatApiFp(this.configuration).euroFiatWithdrawProductData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get data specific to GBP for the withdraw flow
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawFiatApi
     */
    public gbpFiatWithdrawProductData(options?: RawAxiosRequestConfig) {
        return WithdrawFiatApiFp(this.configuration).gbpFiatWithdrawProductData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get pending fiat withdrawal orders for the customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawFiatApi
     */
    public getPendingFiatWithdrawOrderDetails(options?: RawAxiosRequestConfig) {
        return WithdrawFiatApiFp(this.configuration).getPendingFiatWithdrawOrderDetails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get data specific to USD for the withdraw flow
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawFiatApi
     */
    public usdFiatWithdrawProductData(options?: RawAxiosRequestConfig) {
        return WithdrawFiatApiFp(this.configuration).usdFiatWithdrawProductData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get data specific to ZAR for the withdraw flow
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawFiatApi
     */
    public zarFiatWithdrawProductData(options?: RawAxiosRequestConfig) {
        return WithdrawFiatApiFp(this.configuration).zarFiatWithdrawProductData(options).then((request) => request(this.axios, this.basePath));
    }
}



