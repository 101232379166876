/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { getWithExpiry, setWithExpiry } from 'utils/with-expiry';
import semver from 'utils/semver';
import appUrls from '../../helpers/app-urls';
import pkg from '../../../package.json';

function AppVersion({ isVisible }: { isVisible?: boolean }) {
  const history = useHistory();
  const [serverVersion, setServerVersion] = useState<string>('');

  // #region FUNCTIONS
  const forceReload = () => {
    // NOTE: This is deprecated and should be replace asap.
    // https://github.com/Microsoft/TypeScript/issues/28898
    const appRefreshedKey = 'app_refreshed';
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(appRefreshedKey) || 'false',
    );

    if (!hasRefreshed) {
      localStorage.removeItem('revix/store');
      window.sessionStorage.setItem(appRefreshedKey, 'true');
      // @ts-ignore
      window.location.reload(true);
    }
  };

  const checkVersionAndUpdate = () => {
    const cdnURL = appUrls.getCDNUrl();
    axios({
      url: `${cdnURL}data/version.json?v=` + pkg.version,
      method: 'GET',
    }).then(response => {
      const appUpdateKey = 'app_force_update';
      const current = pkg.version;
      const latest = response.data?.version;
      setServerVersion(latest);

      const shouldRefresh = semver.compare(current, latest) === -1;

      if (shouldRefresh) {
        if (!getWithExpiry(appUpdateKey)) {
          setWithExpiry(appUpdateKey, 'true', 10_000);
          forceReload();
        }
      }
    });
  };
  // #endregion

  // #region LIFECYCLE
  useEffect(() => {
    checkVersionAndUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    history.listen(() => {
      checkVersionAndUpdate();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, history.location.pathname]);
  // #endregion

  return isVisible ? (
    <div style={{ borderLeft: '1px solid #fff' }}>
      <div className="p-4">
        <strong>Version</strong>
        <div>- current: {pkg.version}</div>
        <div>- latest: {serverVersion}</div>
      </div>
      <hr />
    </div>
  ) : null;
}

export default AppVersion;
