// The default behaviour of RTK query is having a baseQuery which controls how requests are sent (fetch, axios,...)
// And then a query for each endpoint to specify which path to call with which method and data
// The problem with this is that you loose type information from the generated openapi client
// As a workaround, we can use queryFn which totally bypassed the baseQuery.

import Bugsnag from '@bugsnag/js';
import { ApiError } from '@services/ApiError';
import { AxiosError, AxiosResponse } from 'axios';

// Since we still have to specify a baseQuery (even if its never used), we use this one
export const dummyBaseQuery: any = async () => {
  throw 'Use queryFn instead';
};

// From @reduxjs/toolkit/dist/query/baseQueryTypes
type QueryReturnValue<T = unknown, E = unknown, M = unknown> =
  | {
      error: E;
      data?: undefined;
      meta?: M;
    }
  | {
      error?: undefined;
      data: T;
      meta?: M;
    };

export async function handleErrors<T>(
  func: () => Promise<AxiosResponse<T>>,
): Promise<QueryReturnValue<T, ApiError>> {
  try {
    var res = await func();
    return { data: res.data };
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    Bugsnag.notify(err);
    return {
      error: err.response?.data as ApiError,
    };
  }
}
