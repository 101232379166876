import { otpProcesses } from 'constants/constants';
import {
  UpdateEmailRequest,
  UpdateEmailResponse,
  UpdateEmailVerificationRequest,
  UpdateEmailVerificationResponse,
  UpdatePasswordRequest,
  UpdatePasswordResponse,
  UserProfileResponse,
  CustomerTokenValidate,
  UserProfileStatus,
} from '../entities/profile';
import api from './api';

export const resendRegistrationEmail = async (
  email: string,
): Promise<string> => {
  try {
    const { data } = await api.post(
      `profile/resendRegistrationEmail?email=${encodeURIComponent(email)}`,
    );
    return data?.userId;
  } catch (Exception: any) {
    return Exception.response.data;
  }
};

export const getUserProfile = async (): Promise<UserProfileResponse | null> => {
  try {
    const result = await api.get(`profile`);

    return { statusCode: 200, ...result.data };
  } catch (Exception: any) {
    console.error(Exception);
    return Exception.response.data;
  }
};

export const getUserProfileStatus =
  async (): Promise<UserProfileStatus | null> => {
    try {
      const result = await api.get(`profile/status`);

      return result?.data;
    } catch (Exception: any) {
      console.error(Exception);
      return Exception.response.data;
    }
  };

export const enableEmailNotification =
  async (): Promise<UserProfileResponse | null> => {
    try {
      const result = await api.post(`profile/loginNotification/enable`);

      return { statusCode: 200, ...result.data };
    } catch (Exception: any) {
      console.error(Exception);
      return Exception.response.data;
    }
  };

export const disableEmailNotification =
  async (): Promise<UserProfileResponse | null> => {
    try {
      const result = await api.post(`profile/loginNotification/disable`);

      return { statusCode: 200, ...result.data };
    } catch (Exception: any) {
      console.error(Exception);
      return Exception.response.data;
    }
  };

export const updateEmail = async (
  request: UpdateEmailRequest,
): Promise<UpdateEmailResponse> => {
  try {
    const updateEmailRequest = {
      newEmail: request.newEmail,
      existingPassword: request.existingPassword,
    };

    const result = await api.post(`profile/changeEmail`, updateEmailRequest);

    return { statusCode: 200, ...result.data };
  } catch (Exception: any) {
    console.error(Exception);
    return {
      statusCode: Exception.response.status,
      ...Exception.response.data,
    };
  }
};

export const updatePassword = async (
  request: UpdatePasswordRequest,
): Promise<UpdatePasswordResponse> => {
  try {
    const updatePasswordRequest = {
      existingPassword: request.existingPassword,
      newPassword: request.newPassword,
    };

    const result = await api.post(
      `profile/changePassword`,
      updatePasswordRequest,
      {
        headers: {
          'content-type': 'application/json',
          otpFlowTypeCode: otpProcesses.PROFILE_CHANGE_PASSWORD,
          '2fa': request.totp,
        },
      },
    );

    return { statusCode: result?.status, ...result.data };
  } catch (Exception: any) {
    console.error(Exception);
    return {
      success: false,
      message: Exception.response.data as string,
    };
  }
};

export const updateEmailVerification = async (
  request: UpdateEmailVerificationRequest,
): Promise<UpdateEmailVerificationResponse> => {
  try {
    const { token, totp } = request;
    const result = await api.post(
      totp.length > 0
        ? `/profile/changeEmail/verify?token=${encodeURIComponent(token)}&totp=${totp}`
        : `/profile/changeEmail/verify?token=${encodeURIComponent(token)}`,
      null,
      {
        headers: {
          'content-type': 'application/json',
        },
      },
    );

    return { statusCode: 200, ...result.data };
  } catch (Exception: any) {
    console.error(Exception);
    return {
      statusCode: Exception.response.status,
      ...Exception.response.data,
    };
  }
};

export const validateToken = async (
  token: string,
): Promise<CustomerTokenValidate | any> => {
  try {
    const result = await api.post(`/customer/vt/${token}`, {});
    return result.data;
  } catch (Exception: any) {
    return Exception.response.data;
  }
};

export const linkWithProvider = async (token: string): Promise<any> => {
  try {
    const result = await api.post(`/profile/linkWithProvider`, {
      clientApplicationSsoCode: 'DASHBOARD_GOOGLE',
      ssoJwt: token,
    });

    return {
      statusCode: result.status,
      ...result.data,
    };
  } catch (Exception: any) {
    console.error(Exception);
    return {
      statusCode: Exception.response.status,
      ...Exception.response.data,
    };
  }
};

export const getLoginMethods = async (): Promise<any> => {
  try {
    const result = await api.get('/profile/loginMethods');

    return {
      statusCode: result.status,
      methods: result.data,
    };
  } catch (Exception: any) {
    console.error(Exception);
    return {
      statusCode: Exception.response.status,
      ...Exception.response.data,
    };
  }
};
