/* eslint-disable react/button-has-type */
import ArrowButton from '../ArrowButton';
import InfoButton from '../InfoButton';

const SourceOfFundsButton = ({
  onClickPressed,
  value,
  name,
  tooltipMessage,
}: {
  onClickPressed: (value: string) => void;
  value: string;
  name: string;
  tooltipMessage: string;
}) => {
  const toolTip = (
    <InfoButton darkIcon id={name} mt-1 ml-2>
      <span
        className="font-light"
        dangerouslySetInnerHTML={{ __html: tooltipMessage }}
      />
    </InfoButton>
  );

  return (
    <button
      className="outline-none w-full bg-background rounded-lg duration-200 font-semibold focus:outline-none inline-flex items-center
             secondary-button-text justify-start py-2 my-1 text-lg
             flex-1 sm:px-0 sm:pr-0 px-2 pr-5"
      onClick={() => onClickPressed(value)}
    >
      <div className="outline-none flex flex-row w-full focus:outline-none rounded-card pr-2 sm:px-4 py-1 bg-background duration-1000 m-auto">
        <div className="px-3 w-full text-left justify-start items-center pt-2 sm:text-base text-xs text-white font-semibold flex flex-row -mt-2">
          <span
            className="text-left"
          >
            {name}
          </span>

          {toolTip}
        </div>

        <div className="h-16 lg:h-12 flex items-center justify-center">
          <ArrowButton
            className="w-11 flex flex-col items-center"
            //onClick={() => onClickPressed(value)}
          />
        </div>
      </div>
    </button>
  );
};

export default SourceOfFundsButton;
