/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  VerificationProofOfResidence,
  VerificationStatus,
  VerificationStatusResponse,
  VerificationStep,
} from '../../entities/verification';

export interface VerificationState {
  sumSub: SumSubState;
  loading: boolean;
  status: VerificationStatus;
  verificationState?: string;
  verificationStepFunds: VerificationStep;
  verificationStepInvestShort: VerificationStep;
  verificationStepInvestLong: VerificationStep;
  verificationStepEmployment: VerificationStep;
  verificationStepInvestorLevel: VerificationStep;
  verificationStepSource: VerificationStep;
  verificationStepPOR: VerificationStep;
  verificationStepID: VerificationStep;
  verificationResponse: VerificationStatusResponse;
  verificationProofOfResidence: VerificationProofOfResidence;
  verificationError: any;
}

export interface SumSubState {
  sdkToken: string;
  timestamp: string;
  idUserIdAction: string;
}
function returnNewVerification() {
  return {
    actionCode: '',
    name: '',
    userMessage: '',
    userOptions: [],
    points: 0,
    providerActionCode: '',
    requiredSubmissionFields: [],
    completedSubmissionFields: {
      additionalProp1: '',
      additionalProp2: '',
      additionalProp3: '',
    },
    requiredVerificationFields: [],
    completedValidationFields: {
      additionalProp1: '',
      additionalProp2: '',
      additionalProp3: '',
    },
    manualVerification: false,
    attachmentRequired: false,
    attachmentName: '',
    statusCode: '',
    message: '',
    completed: false,
    completedAt: '',
    approved: false,
    approvedBy: '',
    rejected: false,
    rejectedReason: '',
    rejectedBy: '',
    startedAt: '',
  };
}

const initialState: VerificationState = {
  sumSub: {
    sdkToken: '',
    timestamp: '',
    idUserIdAction: '',
  },
  loading: false,
  status: {
    userId: '',
    countryCode: '',
    tier: {
      code: '',
      verificationLevelCode: '',
      countryCode: '',
      active: false,
    },
    requiredLevel: {
      code: '',
      extendsLevelCode: '',
      name: '',
      active: false,
      createdAt: '',
    },
    dependentLevels: [
      {
        code: '',
        extendsLevelCode: '',
        name: '',
        active: false,
        createdAt: '',
      },
    ],
    actions: [],
    started: false,
    completed: false,
    active: false,
  },
  verificationStepFunds: returnNewVerification(),
  verificationStepInvestShort: returnNewVerification(),
  verificationStepInvestLong: returnNewVerification(),
  verificationStepEmployment: returnNewVerification(),
  verificationStepInvestorLevel: returnNewVerification(),
  verificationStepSource: returnNewVerification(),
  verificationStepPOR: returnNewVerification(),
  verificationStepID: returnNewVerification(),
  verificationResponse: returnNewVerification(),
  verificationProofOfResidence: {
    // addressNo: '',
    // addressUnit: '',
    // addressRoad: '',
    // addressBuilding: '',
    // addressSuburb: '',
    // addressPostalCode: '',
    // addressProvince: '',
    // addressCountry: '',
    addressProof: undefined,
  },
  verificationError: '',
};

const verification = createSlice({
  name: 'verification',
  initialState,
  reducers: {
    submitVerificationFieldsStart(state) {
      state.loading = true;
      state.verificationError = {};
    },
    submitVerificationFieldsEnd(state) {
      state.loading = false;
    },
    submitVerificationFieldsError(
      state,
      action: PayloadAction<VerificationStatusResponse>,
    ) {
      state.loading = false;
      state.verificationResponse = action.payload;
    },
    submitVerificationFieldsResponse(
      state,
      action: PayloadAction<VerificationStatusResponse>,
    ) {
      state.loading = false;
      state.verificationResponse = action.payload;
      state.verificationError = action.payload;
    },
    getVerificationStatusStart(state) {
      state.loading = true;
    },
    getVerificationStatusEnd(state) {
      state.loading = false;
    },
    getVerificationStatusError(
      state,
      action: PayloadAction<VerificationStatus>,
    ) {
      state.loading = false;
      state.status = action.payload;
    },
    getVerificationStatusData(
      state,
      action: PayloadAction<VerificationStatus>,
    ) {
      state.loading = false;
      state.status = action.payload;
    },
    getVerificationStepFundsStart(state) {
      state.loading = true;
    },
    getVerificationStepFundsEnd(state) {
      state.loading = false;
    },
    getVerificationStepFundsError(
      state,
      action: PayloadAction<VerificationStep>,
    ) {
      state.loading = false;
      state.verificationStepFunds = action.payload;
    },
    getVerificationStepFundsData(
      state,
      action: PayloadAction<VerificationStep>,
    ) {
      state.loading = false;
      state.verificationStepFunds = action.payload;
    },
    getVerificationStepInvestShortStart(state) {
      state.loading = true;
    },
    getVerificationStepInvestShortEnd(state) {
      state.loading = false;
    },
    getVerificationStepInvestShortError(
      state,
      action: PayloadAction<VerificationStep>,
    ) {
      state.loading = false;
      state.verificationStepInvestShort = action.payload;
    },
    getVerificationStepInvestShortData(
      state,
      action: PayloadAction<VerificationStep>,
    ) {
      state.loading = false;
      state.verificationStepInvestShort = action.payload;
    },
    getVerificationStepInvestLongStart(state) {
      state.loading = true;
    },
    getVerificationStepInvestLongEnd(state) {
      state.loading = false;
    },
    getVerificationStepInvestLongError(
      state,
      action: PayloadAction<VerificationStep>,
    ) {
      state.loading = false;
      state.verificationStepInvestLong = action.payload;
    },
    getVerificationStepInvestLongData(
      state,
      action: PayloadAction<VerificationStep>,
    ) {
      state.loading = false;
      state.verificationStepInvestLong = action.payload;
    },
    getVerificationStepEmployStart(state) {
      state.loading = true;
    },
    getVerificationStepEmployEnd(state) {
      state.loading = false;
    },
    getVerificationStepEmployError(
      state,
      action: PayloadAction<VerificationStep>,
    ) {
      state.loading = false;
      state.verificationStepEmployment = action.payload;
    },
    getVerificationStepEmployData(
      state,
      action: PayloadAction<VerificationStep>,
    ) {
      state.loading = false;
      state.verificationStepEmployment = action.payload;
    },
    getVerificationStepInvestorStart(state) {
      state.loading = true;
    },
    getVerificationStepInvestorEnd(state) {
      state.loading = false;
    },
    getVerificationStepInvestorError(
      state,
      action: PayloadAction<VerificationStep>,
    ) {
      state.loading = false;
      state.verificationStepInvestorLevel = action.payload;
    },
    getVerificationStepInvestorData(
      state,
      action: PayloadAction<VerificationStep>,
    ) {
      state.loading = false;
      state.verificationStepInvestorLevel = action.payload;
    },
    getVerificationStepSourceStart(state) {
      state.loading = true;
    },
    getVerificationStepSourceEnd(state) {
      state.loading = false;
    },
    getVerificationStepSourceError(
      state,
      action: PayloadAction<VerificationStep>,
    ) {
      state.loading = false;
      state.verificationStepSource = action.payload;
    },
    getVerificationStepSourceData(
      state,
      action: PayloadAction<VerificationStep>,
    ) {
      state.loading = false;
      state.verificationStepSource = action.payload;
    },
    getVerificationStepPorStart(state) {
      state.loading = true;
    },
    getVerificationStepPorEnd(state) {
      state.loading = false;
    },
    getVerificationStepPorError(
      state,
      action: PayloadAction<VerificationStep>,
    ) {
      state.loading = false;
      state.verificationStepPOR = action.payload;
    },
    getVerificationStepPorData(state, action: PayloadAction<VerificationStep>) {
      state.loading = false;
      state.verificationStepPOR = action.payload;
    },
    getVerificationStepIdStart(state) {
      state.loading = true;
    },
    getVerificationStepIdEnd(state) {
      state.loading = false;
    },
    getVerificationStepIdError(state, action: PayloadAction<VerificationStep>) {
      state.loading = false;
      state.verificationStepID = action.payload;
    },
    getVerificationStepIdData(state, action: PayloadAction<VerificationStep>) {
      state.loading = false;
      state.verificationStepID = action.payload;
    },
    setVerificationProofOfResidence(
      state,
      action: PayloadAction<VerificationProofOfResidence>,
    ) {
      state = {
        ...state,
        verificationProofOfResidence: {
          ...state.verificationProofOfResidence,
          ...action.payload,
        },
      };
    },
    setVerificationPOAFile(state, action: PayloadAction<File>) {
      state = {
        ...state,
        verificationProofOfResidence: {
          ...state.verificationProofOfResidence,
          addressProof: action.payload,
        },
      };
    },
    setVerificationSumSubData(state, action: PayloadAction<SumSubState>) {
      state.loading = false;
      state.sumSub = action.payload;
    },
  },
});

export const {
  submitVerificationFieldsStart,
  submitVerificationFieldsEnd,
  submitVerificationFieldsResponse,
  submitVerificationFieldsError,
  getVerificationStatusStart,
  getVerificationStatusEnd,
  getVerificationStatusData,
  getVerificationStatusError,
  getVerificationStepFundsStart,
  getVerificationStepFundsEnd,
  getVerificationStepFundsData,
  getVerificationStepFundsError,
  getVerificationStepInvestShortStart,
  getVerificationStepInvestShortEnd,
  getVerificationStepInvestShortData,
  getVerificationStepInvestShortError,
  getVerificationStepInvestLongStart,
  getVerificationStepInvestLongEnd,
  getVerificationStepInvestLongData,
  getVerificationStepInvestLongError,
  getVerificationStepEmployStart,
  getVerificationStepEmployEnd,
  getVerificationStepEmployError,
  getVerificationStepEmployData,
  getVerificationStepInvestorStart,
  getVerificationStepInvestorEnd,
  getVerificationStepInvestorError,
  getVerificationStepInvestorData,
  getVerificationStepSourceStart,
  getVerificationStepSourceEnd,
  getVerificationStepSourceError,
  getVerificationStepSourceData,
  getVerificationStepPorStart,
  getVerificationStepPorEnd,
  getVerificationStepPorError,
  getVerificationStepPorData,
  getVerificationStepIdStart,
  getVerificationStepIdEnd,
  getVerificationStepIdError,
  getVerificationStepIdData,
  setVerificationProofOfResidence,
  setVerificationPOAFile,
  setVerificationSumSubData,
} = verification.actions;

export default verification.reducer;
