import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducer';
import ModalView from './ModalView';

interface ModalProps {
  content: JSX.Element;
  hideModal: Function | undefined;
  show: boolean;
  hasBlurredBackground?: boolean;
  hasTitle?: boolean;
  title?: JSX.Element;
  titleClassName?: string;
  hasFooter?: boolean;
  footer?: JSX.Element;
  closeModal?: Function;
  width?: string;
  height?: string;
  maxWidth?: boolean;
  largeContent?: boolean;
  noneScrollableContent?: boolean;
  hideBgItems?: boolean;
  preventOverflow?: boolean | undefined;
  background?: string;
  isFullWidth?: boolean;
  innerBackground?: string;
}

const Modal = (props: ModalProps) => {
  const { theme } = useSelector((state: RootState) => state.theme);

  return (
    <ModalView
      show={props.show}
      content={props.content}
      hasTitle={props.hasTitle}
      title={props.title}
      titleClassName={props.titleClassName}
      hasFooter={props.hasFooter}
      footer={props.footer}
      closeModal={props.closeModal}
      hideModal={props.hideModal}
      width={props.width}
      noneScrollableContent={props.noneScrollableContent}
      height={props.height}
      theme={theme}
      maxWidth={props.maxWidth}
      largeContent={props.largeContent}
      preventOverflow={props.preventOverflow}
      hideBgItems
      background={props.background}
      hasBlurredBackground={props.hasBlurredBackground}
      isFullWidth={props.isFullWidth}
      innerBackground={props.innerBackground}
    />
  );
};

export default Modal;
