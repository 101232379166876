const verificationStatus = {
  Verified: 'VERIFIED',
  PendingAttachment: 'PENDING_ATTACHMENT',
  PendingProvider: 'PENDING_PROVIDER',
  Started: 'STARTED',
  NotStarted: 'NOT_STARTED',
  Failed: 'FAILED',
  PendingAdmin: 'PENDING_ADMIN',
  InProgress: 'IN_PROGRESS',
  PendingUser: 'PENDING_USER',
  Pending: 'PENDING',
};

export default verificationStatus;
