/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import clsx from 'clsx';
import { isSafari } from 'react-device-detect';
import colors from 'styles/colors';
import appHelpers from '../../helpers/app-urls';
import device from '../../helpers/device';
import Transition from '../../hooks/use-is-initial-render';

interface ModalViewProps {
  show: boolean;
  content: JSX.Element;
  hasBlurredBackground?: boolean;
  hasTitle?: boolean;
  title?: JSX.Element;
  titleClassName?: string;
  hasFooter?: boolean;
  noneScrollableContent?: boolean;
  footer?: JSX.Element;
  closeModal?: Function;
  hideModal?: Function | undefined;
  width?: string;
  height?: string;
  theme: string;
  maxWidth?: boolean;
  largeContent?: boolean;
  hideBgItems?: boolean;
  preventOverflow?: boolean | undefined;
  background?: string;
  isFullWidth?: boolean;
  innerBackground?: string;
}

const ModalView = (props: ModalViewProps) => {
  const cdnURL = appHelpers.getCDNUrl();
  const { isDeviceMobile } = device();

  const closeImg = `${cdnURL}assets/navigation/close.svg`;
  const closeDarkImg = `${cdnURL}assets/navigation/close-dark.svg`;

  return (
    <div
      className={clsx(
        'fixed h-screen overflow-y-auto inset-x-0 bottom-0 flex items-start px-4 pb-6 justify-start md:h-auto md:inset-0 md:p-0 md:items-center md:flex md:justify-center lg:h-auto lg:inset-0 lg:p-0 lg:flex lg:items-center lg:justify-center xl:h-auto xl:inset-0 xl:p-0 xl:flex xl:items-center xl:justify-center z-50',
        {
          '-mb-20': isDeviceMobile && isSafari,
          'hidden pointer-events-none': !props.show,
        },
      )}>
      {/* Background overlay */}
      <Transition
        show={props.show}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        <div
          onClick={() => props.hideModal && props.hideModal()}
          className="fixed inset-0 transition-opacity h-full overflow-hidden">
          <div
            className={clsx('absolute inset-0 z-50', {
              'opacity-100': props.hideBgItems,
              'opacity-75': !props.hideBgItems,
              'bg-main-background': !props.hasBlurredBackground,
            })}
            style={{
              backdropFilter: props.hasBlurredBackground ? 'blur(2px)' : 'none',
              backgroundColor: props.hasBlurredBackground
                ? '#00000044'
                : colors.offBlack,
            }}
          />
        </div>
      </Transition>

      {/* Modal panel */}
      <Transition
        show={props.show}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
        <div
          style={{
            width: props.width,
            height: props.height,
            background: props.innerBackground,
          }}
          className={clsx(
            `${
              !props.innerBackground
                ? (props.background ?? 'bg-background')
                : ''
            } max-h-10/12 rounded-lg px-4 pt-5 pb-4 transform transition-all relative flex flex-col top-20 lg:top-0 overflow-auto`,
            {
              'h-full': props?.largeContent,
              'w-560': !props.isFullWidth,
            },
          )}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline">
          {/* Close Button */}
          <button
            style={
              // eslint-disable-next-line no-nested-ternary
              !isDeviceMobile
                ? { top: '8px', right: '8px' }
                : { top: 4, right: 4 }
            }
            className={`${
              props.show && props.closeModal ? 'z-20' : 'hidden'
            } absolute flex items-center text-white justify-center w-8 h-8 bg-background rounded-full text-center focus:outline-none`}
            onClick={() => props.closeModal && props.closeModal()}
            type="button">
            <img
              src={props.theme === 'dark' ? closeImg : closeDarkImg}
              alt="close"
            />
          </button>

          <div
            className={`${
              props.maxWidth ? 'flex flex-col w-full' : 'flex flex-col'
            } h-full`}>
            <div
              className={`${
                props.largeContent ? `mt-3` : ` mt-3 sm:mt-5`
              } text-center ${
                props.noneScrollableContent
                  ? `h-auto`
                  : `max-h-12/12  overflow-y-auto`
              } ${props.preventOverflow ? 'max-h-10/12' : ''}`}>
              {props.hasTitle ? (
                <h4
                  className={clsx(
                    `leading-6 font-normal text-header-text`,
                    props.titleClassName,
                  )}
                  id="modal-headline">
                  {props.title}
                </h4>
              ) : null}

              <div className="mt-2">
                <div className="text-base px-2 leading-5 text-body-text pt-2">
                  {props.content}
                </div>
              </div>
            </div>
            {props.largeContent && !props.hasFooter ? (
              ''
            ) : (
              <div className="flex mt-5 sm:mt-6">
                {props.hasFooter && (
                  <span className="flex w-full rounded-md">{props.footer}</span>
                )}
              </div>
            )}
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default ModalView;
