import React from 'react';
import isSafariOnIos from 'utils/user-agent';

const UserAgentIncludes = ({ text }: { text: string }) => {
  const isInUAString = window?.navigator?.userAgent?.includes(text);
  return (
    <div
      className={isInUAString ? 'text-success' : 'text-error'}
    >{`- ${text}`}</div>
  );
};

function UserAgent({
  isVisible = false,
  showUserAgentDEBUG,
}: {
  isVisible?: boolean;
  showUserAgentDEBUG?: boolean;
}) {
  const { userAgent } = window.navigator;

  return isVisible ? (
    <div>
      <div className="p-4" style={{ borderLeft: '1px solid #fff' }}>
        <strong>User Agent:</strong>
        <div>{userAgent}</div>
        <br />
        <div>
          <div>
            <strong>Utils</strong>
          </div>
          <div className={`${isSafariOnIos() ? 'text-success' : 'text-error'}`}>
            - utils/isSafariOnIos(): {isSafariOnIos() ? 'YES' : 'NO'}
          </div>
        </div>
      </div>
      <hr />
      {showUserAgentDEBUG && (
        <>
          <div className="md:flex md:flex-row">
            <div className="p-4" style={{ borderLeft: '1px solid #fff' }}>
              <strong>Operating System</strong>
              <br />
              <UserAgentIncludes text="Mobile" />

              <br />
              <div>Windows</div>
              {['Windows', 'Windows Phone'].map(platform => (
                <UserAgentIncludes text={platform} />
              ))}
              <div>iOS / Mac</div>
              {['Mac OS', 'Macintosh', 'iPhone', 'iPad', 'iPod touch'].map(
                platform => (
                  <UserAgentIncludes text={platform} />
                ),
              )}
              <div>Android / Linux</div>
              {['Android', 'Linux', 'Debian'].map(platform => (
                <UserAgentIncludes text={platform} />
              ))}
              <br />
            </div>
            <div
              className="md:ml-4 p-4"
              style={{ borderLeft: '1px solid #fff' }}
            >
              Browsers
              {[
                'Chrome',
                'Safari',
                'Mobile Safari',
                'Mozilla',
                'Edg',
                'EdgiOS',
                'EdgA',
                'IE',
                'IEMobile',
                'Opera',
                'OPR',
                'AppleWebKit',
              ].map(browser => (
                <UserAgentIncludes text={browser} />
              ))}
              <br />
            </div>
          </div>
        </>
      )}
    </div>
  ) : null;
}

export default UserAgent;
