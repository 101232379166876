import {
  DialogBackdrop,
  DialogPanel,
  Dialog as HeadlessuiDialog,
} from '@headlessui/react';
import appHelpers from 'helpers/app-urls';

import clsx from 'clsx';
import { ReactNode } from 'react';

const cdnURL = appHelpers.getCDNUrl();
const closeImg = `${cdnURL}assets/navigation/close.svg`;

export function Dialog({
  children,
  onClose,
  isOpen = true,
  backgroundColor = '#0C1C2C',
  dismissable = true,
  blurEnabled = false,
}: {
  children: ReactNode;
  onClose?: () => void;
  isOpen?: boolean;
  backgroundColor?: string;
  dismissable?: boolean;
  blurEnabled?: boolean;
}) {
  return (
    <HeadlessuiDialog
      open={isOpen}
      className="relative z-50"
      onClose={() => onClose?.()}>
      <DialogBackdrop
        className={clsx(
          'fixed inset-0 bg-[#303243]',
          blurEnabled ? 'opacity-80' : 'opacity-100',
        )}
      />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel
          style={{ backgroundColor: backgroundColor }}
          className="w-full max-w-[600px] xl:ml-64 flex flex-col max-h-full space-y-4 rounded-2xl px-6 pb-10 pt-4">
          {dismissable && (
            <div className="-mr-4">
              <img
                onClick={() => onClose?.()}
                src={closeImg}
                alt="close"
                className="ml-auto cursor-pointer"
              />
            </div>
          )}
          {children}
        </DialogPanel>
      </div>
    </HeadlessuiDialog>
  );
}
