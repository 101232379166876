/* eslint-disable @typescript-eslint/ban-ts-ignore */
import { AppThunk } from '..';
import {
  RedeemRewardsDetails,
  RewardsDetails,
  RewardsDetailsResponse,
  Tiers,
} from '../../entities/rewardsDetails';
import * as rewardsApi from '../../services/api-rewards-actions';
import {
  redeemRewardsStart,
  redeemRewardsEnd,
  addRewardsDetailsError,
  setAddRewardsDetailsResponse,
  getRewardsDetailsStart,
  getRewardsDetailsEnd,
  getRewardsDetailsError,
  getRewardsDetailsData,
  getTiersDetailsStart,
  getTiersDetailsEnd,
  getTiersDetailsError,
  getTiersDetailsData,
  enterPromoDetailsStart,
  enterPromoDetailsEnd,
  enterPromoDetailsError,
  setEnterPromoDetailsResponse,
  setRewardReferralsLoading,
  setRewardReferralsData,
  setRewardReferralsError,
} from './rewards-details-slice';

const initialReferralState = {
  items: [],
};

/**
 * addBankDetailsAction(request: BankDetails)
 *
 * Add new Bank Details
 *
 * @returns void
 */
export const redeemRewardsAction =
  (request: RedeemRewardsDetails): AppThunk =>
  async dispatch => {
    try {
      dispatch(redeemRewardsStart());

      const data: RewardsDetailsResponse | null =
        await rewardsApi.redeemRewards();

      if (!data) {
        dispatch(redeemRewardsEnd());
        return;
      }

      dispatch(setAddRewardsDetailsResponse(data));
      dispatch(redeemRewardsEnd());

      if (request.callback) request.callback(data);
    } catch (Exception: any) {
      console.error(Exception);
      dispatch(addRewardsDetailsError(Exception));
    }
  };

export const enterPromoAction =
  (request: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(enterPromoDetailsStart());

      const data: RewardsDetailsResponse | null =
        await rewardsApi.enterPromo(request);

      if (!data) {
        dispatch(enterPromoDetailsEnd());
        return;
      }

      dispatch(enterPromoDetailsError(data));
    } catch (Exception: any) {
      console.error(Exception);
      dispatch(setEnterPromoDetailsResponse(Exception));
    }
  };

export const getRewardsDetailsAction = (): AppThunk => async dispatch => {
  try {
    dispatch(getRewardsDetailsStart());

    const data: RewardsDetails | null = await rewardsApi.getRewardsDetails();

    if (!data) {
      dispatch(getRewardsDetailsEnd());
      return;
    }

    dispatch(getRewardsDetailsData(data));
    dispatch(getRewardsDetailsEnd());
  } catch (Exception: any) {
    console.error(Exception);
    dispatch(getRewardsDetailsError(Exception));
  }
};

export const getTiersAction = (): AppThunk => async dispatch => {
  try {
    dispatch(getTiersDetailsStart());

    const data: Tiers[] | null = await rewardsApi.getTiersDetails();

    if (!data) {
      dispatch(getTiersDetailsEnd());
      return;
    }

    dispatch(getTiersDetailsData(data));
  } catch (Exception: any) {
    console.error(Exception);
    dispatch(getTiersDetailsError(Exception));
  }
};

export const getRewardReferrals =
  (queryParams: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(setRewardReferralsLoading(true));
      dispatch(setRewardReferralsData(initialReferralState));

      const result = await rewardsApi.getRewardReferrals(queryParams);
      dispatch(setRewardReferralsLoading(false));
      // @ts-ignore
      dispatch(setRewardReferralsData(result));
    } catch (Exception: any) {
      dispatch(setRewardReferralsLoading(false));
      dispatch(setRewardReferralsError(Exception));
    }
  };
