import React from 'react';
import verificationStatus from '../../data/verificationStatus';
import {
  SubmitVerificationInfo,
  VerificationStatusResponse,
} from '../../entities/verification';
import {
  useGetVerificationStatusSource,
  useSubmitVerificationSource,
} from '../../hooks/use-get-verification-status';
import { SourceOfFundsState } from './models/SourceOfFundsState';
import { SourceOfFundsTypes } from './models/SourceOfFundsTypes';
import SourceOfFundsView from './SourceOfFundsView';

const SourceOfFunds = ({
  onComplete,
}: {
  onComplete: (sourceOfFundsTypes: SourceOfFundsTypes) => void;
}) => {
  const responses: VerificationStatusResponse[] = [];
  const [submitVerificationSource] = useSubmitVerificationSource();
  const sourceInfo = useGetVerificationStatusSource();

  function addCallback(response: VerificationStatusResponse) {
    responses.push(response);
  }

  async function submitForm(viewState: SourceOfFundsState) {
    const request: SubmitVerificationInfo = {
      fields: [],
      callback: addCallback,
    };

    // eslint-disable-next-line no-unused-expressions
    sourceInfo?.requiredSubmissionFields?.forEach(field => {
      request.fields.push({
        key: field,
        value: viewState.source,
      });
    });

    await submitVerificationSource(request);
    const temp = viewState.source as SourceOfFundsTypes;

    if (
      responses.filter(
        x =>
          x.statusCode !== verificationStatus.PendingAttachment &&
          x.statusCode !== verificationStatus.Verified,
      ).length === 0
    )
      onComplete(temp);
  }

  return <SourceOfFundsView sourceInfo={sourceInfo} onComplete={submitForm} />;
};

export default SourceOfFunds;
