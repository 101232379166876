import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { removeOtpTimestamps } from 'utils/otp-timestamps';
import cookie from 'helpers/cookie';
import { logout } from 'store/auth/auth-actions';
import { logoutZendesk } from 'utils/zendesk';

const useLogout = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  return () => {
    history.replace('/', {});
    cookie.removeAll();
    dispatch(logout());
    removeOtpTimestamps();
    logoutZendesk();
  };
};

export default useLogout;
