/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Country } from '../../entities/country';

export interface CountryState {
  loading: boolean;
  activeCountry: Country;
  data: Country[];
}

const newCountry: Country = {
  code: 'ZA',
  name: 'South Africa',
  symbol: 'R',
  alpha3Code: 'ZAF',
};

const initialCountryState: CountryState = {
  loading: false,
  activeCountry: newCountry,
  data: [],
};

const country = createSlice({
  name: 'country',
  initialState: initialCountryState,
  reducers: {
    getCountriesStart(state) {
      state.loading = true;
    },
    getCountriesEnd(state) {
      state.loading = false;
    },
    setData(state, action: PayloadAction<Country[]>) {
      state.data = action.payload;
    },
  },
});

export const { getCountriesStart, getCountriesEnd, setData } = country.actions;

export default country.reducer;
