import { Language } from '../../store/slices/language-slice';
import { InactivityTimerMessages } from './InactivityTimerView';

type Message = {
  [key in Language]: InactivityTimerMessages;
};

const messages: Message = {
  eng: {
    title: 'You will be logged out in',
    message:
      'We automatically log you out of your account after being inactive for {timeout}min for your security.',
  },
};

export default messages;
