import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_THEME, ThemeName } from '../../styles/themes';
import { AppThunk } from '..';
import { applyTheme } from '../../styles/themes/utils';

const initialState: {
  theme: ThemeName;
} = {
  theme: DEFAULT_THEME,
};

const theme = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setThemeAction(state, action: PayloadAction<ThemeName>) {
      // eslint-disable-next-line no-param-reassign
      state.theme = action.payload;
      return state;
    },
  },
});

export const { setThemeAction } = theme.actions;

/**
 * Use middlewareif you want async or side effects.
 */
export const setTheme =
  (themeName: ThemeName): AppThunk =>
  dispatch => {
    try {
      dispatch(setThemeAction(themeName));
      applyTheme(themeName);
    } catch (err) {
      //
    }
  };

export default theme.reducer;
