/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RedeemRewardsDetails } from '../entities/rewardsDetails';
import {
  enterPromoAction,
  getRewardsDetailsAction,
  getTiersAction,
  redeemRewardsAction,
} from '../store/rewardsDetails/rewards-details-actions';
import { RootState } from '../store/reducer';

export const useRedeemRewards = () => {
  const dispatch = useDispatch();

  const redeemRewards = async (request: RedeemRewardsDetails) => {
    await dispatch(redeemRewardsAction(request));
  };

  return [redeemRewards];
};

export const useEnterPromoCode = () => {
  const dispatch = useDispatch();

  const enterPromo = async (promo: string) => {
    await dispatch(enterPromoAction(promo));
  };

  return [enterPromo];
};
export const useGetRewardDetails = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getRewardsDetailsAction());
  }, [dispatch]);
  return useSelector((state: RootState) => state.rewards.rewardsDetails);
};

export const useGetTierDetails = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getTiersAction());
  }, [dispatch]);
  return useSelector((state: RootState) => state.rewards.tiers);
};
