/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import fp from 'lodash/fp';

import {
  PortfolioSummary,
  PortfolioHoldings,
  PortfolioBalance,
  PortfolioPerformance,
  PortfolioTimespan,
} from 'entities/portfolio';
import { TimeFrameKey } from 'entities/time-frame';

type TimeFrameContainer<T> = {
  [key in TimeFrameKey]?: {
    data?: T;
    error?: string;
    loading?: boolean;
  };
};

export type Portfolio = {
  summary?: TimeFrameContainer<PortfolioSummary>;
  holdings?: TimeFrameContainer<PortfolioHoldings>;
  balances?: {
    data?: PortfolioBalance[];
    error?: string;
    loading?: boolean;
  };
  performance?: TimeFrameContainer<PortfolioPerformance>;
  timespan?: {
    data?: PortfolioTimespan[];
    error?: string;
    loading?: boolean;
  };
  loading?: boolean;
};

export const defaultPortfolioState: Portfolio = {};

const portfolio = createSlice({
  name: 'portfolio',
  initialState: defaultPortfolioState,
  reducers: {
    setSummaryLoading(state, action: PayloadAction<TimeFrameKey>) {
      state.summary = fp.merge(
        { [action.payload]: { loading: true } },
        state.summary,
      );
      state.loading = true;
    },
    setSummaryError(
      state,
      action: PayloadAction<{ timeFrame: TimeFrameKey; error: string }>,
    ) {
      state.summary = fp.merge(
        { [action.payload.timeFrame]: { error: action.payload.error } },
        state.summary,
      );
      state.loading = false;
    },
    setSummaryData(
      state,
      action: PayloadAction<{
        timeFrame: TimeFrameKey;
        data: PortfolioSummary;
      }>,
    ) {
      // TODO: add ability to use the Logical OR assignment ||= : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Logical_OR_assignment
      if (!state.summary) {
        state.summary = {
          [action.payload.timeFrame]: {},
        };
      }
      state.summary[action.payload.timeFrame] = { data: action.payload.data };
      state.loading = false;
    },
    clearHoldingsData(state) {
      state.holdings = {};
    },
    setHoldingsLoading(state, action: PayloadAction<TimeFrameKey>) {
      state.holdings = fp.merge(
        { [action.payload]: { loading: true, portfolioProductHoldings: [] } },
        state.holdings,
      );
      state.loading = true;
    },
    setHoldingsError(
      state,
      action: PayloadAction<{ timeFrame: TimeFrameKey; error: string }>,
    ) {
      state.holdings = fp.merge(
        { [action.payload.timeFrame]: { error: action.payload.error } },
        state.holdings,
      );
      state.loading = false;
    },
    setHoldingsData(
      state,
      action: PayloadAction<{
        timeFrame: TimeFrameKey;
        data: PortfolioHoldings;
      }>,
    ) {
      // TODO: add ability to use the Logical OR assignment ||= : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Logical_OR_assignment
      if (!state.holdings) {
        state.holdings = {
          [action.payload.timeFrame]: {},
        };
      }
      state.holdings[action.payload.timeFrame] = { data: action.payload.data };
      state.loading = false;
    },
    setBalancesLoading(state) {
      state.balances = fp.merge({ loading: true }, state.balances);
      state.loading = true;
    },
    setBalancesError(state, action: PayloadAction<string>) {
      state.balances = fp.merge({ error: action.payload }, state.balances);
      state.loading = false;
    },
    setBalancesData(state, action: PayloadAction<PortfolioBalance[]>) {
      state.balances = { data: action.payload };
      state.loading = false;
    },
    setPerformanceLoading(state, action: PayloadAction<TimeFrameKey>) {
      state.performance = fp.merge(
        { [action.payload]: { loading: true } },
        state.performance,
      );
      state.loading = true;
    },
    setPerformanceError(
      state,
      action: PayloadAction<{ timeFrame: TimeFrameKey; error: string }>,
    ) {
      state.performance = fp.merge(
        { [action.payload.timeFrame]: { error: action.payload.error } },
        state.performance,
      );
      state.loading = false;
    },
    setPerformanceData(
      state,
      action: PayloadAction<{
        timeFrame: TimeFrameKey;
        data: PortfolioPerformance;
      }>,
    ) {
      // TODO: add ability to use the Logical OR assignment ||= : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Logical_OR_assignment
      if (!state.performance) {
        state.performance = {
          [action.payload.timeFrame]: {},
        };
      }
      state.performance[action.payload.timeFrame] = {
        data: action.payload.data,
      };
      state.loading = false;
    },
    setTimespanLoading(state) {
      state.timespan = fp.merge({ loading: true }, state.timespan);
      state.loading = true;
    },
    setTimespanError(state, action: PayloadAction<string>) {
      state.timespan = fp.merge({ error: action.payload }, state.timespan);
      state.loading = false;
    },
    setTimespanData(state, action: PayloadAction<PortfolioTimespan[]>) {
      state.timespan = { data: action.payload };
      state.loading = false;
    },
  },
});

export const {
  setSummaryLoading,
  setSummaryError,
  setSummaryData,
  setHoldingsLoading,
  setHoldingsError,
  setHoldingsData,
  setBalancesLoading,
  setBalancesError,
  setBalancesData,
  setPerformanceLoading,
  setPerformanceError,
  setPerformanceData,
  setTimespanLoading,
  setTimespanError,
  setTimespanData,
  clearHoldingsData,
} = portfolio.actions;

export default portfolio.reducer;
