import axios from 'axios';
import appHelpers from '../helpers/app-urls';
import Bugsnag from '@bugsnag/js';

const cdnURL = appHelpers.getCDNUrl();

// TODO: once https://revix-dev-cdn-public.azureedge.net has the graphs, remove hard coding.

const cdnApi = axios.create({
  // baseURL: 'https://revix-prod-cdn-public.azureedge.net/', // cdnURL,
  baseURL: cdnURL,
});

export const fetchRewardTierImage = async (rewardTierCode: string) => {
  if (!rewardTierCode) return null;
  const url = `${cdnURL}assets/rewards/badges/${rewardTierCode.toLocaleLowerCase()}.svg`;

  try {
    const { data, status } = await axios.get(url);

    if (status === 200) {
      return data;
    } else {
      return null;
    }
  } catch (error: any) {
    Bugsnag.notify(error);
    return null;
  }
};

export default cdnApi;
