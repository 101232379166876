/* eslint-disable no-nested-ternary,@typescript-eslint/ban-ts-ignore */
import clsx from 'clsx';
import React from 'react';
import Transition from '../../../hooks/use-is-initial-render';

export type DropdownMessages = {
  autocompleteEmpty: React.ReactNode;
};

export default function Dropdown({
  options,
  value,
  autocomplete,
  onChange,
  name,
  placeholder,
  label,
  preventNumbers,
  ...props
}: {
  options: { value: string; name: string }[];
  value: string | undefined;
  autocomplete?: boolean;
  name?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  label?: string | any;
  preventNumbers?: boolean;
  [props: string]: unknown;
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  /**
   * search is only used in autocomplete
   */
  const [search, setSearch] = React.useState('');
  const [keyboardSelection, setKeyboardSelection] = React.useState<number>(-1);
  const activeName = options?.find(option => option.value === value)?.name;

  const filteredOptions = (() => {
    if (!search) return options;
    if (search === activeName) return options;
    return options?.filter(o =>
      o.name.toLowerCase().includes(search.toLowerCase()),
    );
  })();

  React.useEffect(() => {
    setKeyboardSelection(-1);
  }, [isOpen, value, search]);

  React.useEffect(() => {
    if (autocomplete && !isOpen) {
      /**
       * if only one value is filtered, auto select it
       */
      if (
        filteredOptions?.length === 1 &&
        value !== filteredOptions[0]?.value
      ) {
        onChange(filteredOptions[0].value);
        setSearch(filteredOptions[0].name);
      } else {
        /**
         * if closing, either clear search or match it to current value
         */
        const selectedName = options?.find(
          option => option.value === value,
        )?.name;
        setSearch(selectedName || '');
      }
    }
  }, [autocomplete, filteredOptions, isOpen, onChange, options, value]);

  const floatingLabel = (
    <label
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
      role="button"
      tabIndex={0}
      onClick={() => setIsOpen(!isOpen)}
      onKeyDown={() => setIsOpen(!isOpen)}
      className={`font-normal leading-5 text-gray-900 absolute transition-all ${
        (!value || value?.length === 0) && !isOpen
          ? autocomplete
            ? '-mt-4'
            : 'mt-1'
          : autocomplete
            ? '-mt-14'
            : '-mt-5'
      }`}>
      {label}
    </label>
  );

  const component = (() => {
    if (!autocomplete) {
      return (
        <>
          {floatingLabel}
          <button
            onClick={() => setIsOpen(!isOpen)}
            onBlur={() => {
              /**
               * Blur fires before the dropdown's button onClick, so that action never happens.
               * This allows the click to first propogate before onBlur is actioned.
               */
              setTimeout(() => {
                setIsOpen(false);
              }, 100);
            }}
            type="button"
            className="
                            inline-flex justify-start w-full rounded-full items-center
                            py-2 text-md leading-5 text-input-text
                            focus:outline-none transition ease-in-out duration-150 border-0"
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true">
            <div
              className={clsx(
                'h-5 md:mr-4 mr-2 truncate whitespace-nowrap px-0',
                activeName ? 'text-header-text' : '',
              )}>
              {activeName || placeholder}
            </div>
            <svg
              className="right-2 lg:right-1 absolute"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3.56699 6.8501C3.75944 7.18343 4.24056 7.18343 4.43301 6.8501L7.89711 0.850098C8.08957 0.516765 7.849 0.100098 7.4641 0.100098H0.535898C0.150998 0.100098 -0.0895646 0.516764 0.102885 0.850098L3.56699 6.8501Z"
                fill="#A4A7C1"
              />
            </svg>
          </button>
        </>
      );
    }
    return (
      <div
        className="sm:py-0 py-0.5
                    relative inline-flex justify-start w-full rounded-full items-center
                    text-md leading-5
                    transition ease-in-out duration-150 cursor-pointer focus:outline-one">
        {floatingLabel}
        <input
          type="text"
          className="leading-5 w-full bg-transparent focus:outline-none py-2 px-0 text-header-text border-0"
          value={search}
          placeholder={placeholder}
          name={name}
          autoComplete={name}
          onChange={e => {
            const inputValue = e.target.value;
            // @ts-ignore
            // eslint-disable-next-line no-restricted-globals
            if (preventNumbers && !isNaN(inputValue) && inputValue.length > 0) {
              e.preventDefault();
            } else {
              setSearch(inputValue);
            }
          }}
          onFocus={() => setIsOpen(true)}
          onKeyUp={e => {
            if (e.key === 'ArrowDown') {
              if (keyboardSelection >= filteredOptions?.length - 1) {
                setKeyboardSelection(0);
              } else {
                setKeyboardSelection(keyboardSelection + 1);
              }
            } else if (e.key === 'ArrowUp') {
              if (keyboardSelection < 0) {
                setKeyboardSelection(filteredOptions?.length - 1);
              } else {
                setKeyboardSelection(keyboardSelection - 1);
              }
            } else if (e.key === 'Enter') {
              const option = filteredOptions[keyboardSelection];
              if (option) {
                setSearch(option.name);
                setIsOpen(false);
              }
              (document?.activeElement as HTMLElement).blur();
            }
          }}
          onBlur={() => {
            /**
             * Blur fires before the dropdown's button onClick, so that action never happens.
             * This allows the click to first propogate before onBlur is actioned.
             */
            setTimeout(() => {
              setIsOpen(false);
            }, 200);
          }}
        />
        <span
          className="relative focus:outline-one active:outline-none -mt-2"
          role="button"
          tabIndex={-1}
          onKeyDown={() => setIsOpen(!isOpen)}
          onClick={() => setIsOpen(!isOpen)}>
          <svg
            className="right-2 lg:right-1 absolute"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3.56699 6.8501C3.75944 7.18343 4.24056 7.18343 4.43301 6.8501L7.89711 0.850098C8.08957 0.516765 7.849 0.100098 7.4641 0.100098H0.535898C0.150998 0.100098 -0.0895646 0.516764 0.102885 0.850098L3.56699 6.8501Z"
              fill="#A4A7C1"
            />
          </svg>
        </span>
      </div>
    );
  })();

  return (
    <div className={clsx('relative max-w-full', props)} data-browsing-ignore>
      <div>
        <span className="w-full">{component}</span>
      </div>
      <div className="block border-0 border-b-2 form-input p-0 rounded-b-none sm:leading-5 sm:text-sm w-full border-register-border" />

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95">
        <div className="origin-top-right absolute z-10 mt-2 w-full shadow-input-background bg-input-background">
          <div className=" ring-1 ring-black ring-opacity-5 text-center rounded-lg">
            <div
              className="max-h-96 overflow-auto p-2 text-center"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu">
              {(autocomplete ? filteredOptions : options)?.map((option, oi) => (
                <button
                  key={`dropdown-option-${oi}-${option.value}`}
                  type="button"
                  onClick={() => {
                    setSearch(option.name);
                    onChange(option.value);
                    setIsOpen(false);
                  }}
                  className={clsx(
                    'block w-full text-left px-3 py-3 text-sm leading-5 text-body-text focus:outline-none hover:text-header-text -mt-2 mb-2 -ml-2',
                    { 'bg-gray-300': oi === keyboardSelection },
                  )}
                  role="menuitem">
                  {option.name}
                </button>
              ))}
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
}
