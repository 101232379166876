import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducer';
import messages from './messages';
import CompleteModal from './CompleteModal';

const CompleteView = ({ onComplete }: { onComplete: () => void }) => {
  const { language } = useSelector((state: RootState) => state.language);

  const viewMessages = messages[language].complete;

  return (
    <div>
      <CompleteModal
        heading={viewMessages.heading}
        subheading={viewMessages.subheading}
        continueButton={viewMessages.continueButton}
        onComplete={onComplete}
      />
    </div>
  );
};

export default CompleteView;
