/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import fp from 'lodash/fp';
import { SSOAuthProviderKey } from 'entities/sso';
import { Client } from 'entities/auth';

export type JWT_DECODED = {
  id: string;
  status: 'PENDING' | 'ACTIVE';
  service: string;
  email: string;
  scopes: string;
  nbf: number;
  exp: number;
  iat: number;
  iss: string;
  aud: string;
};

export interface FullClient extends Client {
  jwtDecoded?: JWT_DECODED | undefined;
  zendeskJwt?: string | undefined;
}

export type Auth = {
  ssoLoading?: SSOAuthProviderKey | null;
  client?: {
    data?: FullClient;
    error?: string;
    loading?: boolean;
  };
  ssoUserDetails?: {
    firstName?: string;
    lastName?: string;
    email?: string;
  };
  twoFactorEmail?: string;
  twoFactorClient?: {
    data?: Client;
    error?: string;
    loading?: boolean;
  };
};

const defaultAuthState: Auth = {};

const auth = createSlice({
  name: 'auth',
  initialState: defaultAuthState,
  reducers: {
    setSSOLoading(state, action: PayloadAction<SSOAuthProviderKey | null>) {
      state.ssoLoading = action.payload;
    },
    setClientLoading(state, action: PayloadAction<boolean>) {
      state.client = fp.merge({ loading: action.payload }, state.client);
    },
    setClientError(state, action: PayloadAction<string>) {
      state.client = fp.merge(
        { error: action.payload, loading: false },
        state.client,
      );
    },
    setSsoUserFirstName(state, action: PayloadAction<string>) {
      state.ssoUserDetails = {
        ...state.ssoUserDetails,
        firstName: action.payload,
      };
    },
    setSsoUserLastName(state, action: PayloadAction<string>) {
      state.ssoUserDetails = {
        ...state.ssoUserDetails,
        lastName: action.payload,
      };
    },
    setSsoUserEmail(state, action: PayloadAction<string>) {
      state.ssoUserDetails = {
        ...state.ssoUserDetails,
        email: action.payload,
      };
    },
    setClientData(state, action: PayloadAction<Client | undefined>) {
      const data = action.payload as FullClient;
      data.jwtDecoded =
        (action.payload?.token &&
          jwtDecode<JWT_DECODED>(action.payload.token)) ||
        undefined;
      data.zendeskJwt = action.payload?.zendeskToken || undefined;
      state.client = {
        data,
      };

      state.twoFactorClient = {};
    },
    setTwoFactorClientData(state, action: PayloadAction<Client | undefined>) {
      state.client = {};
      state.twoFactorClient = { data: action.payload };
    },
    setTwoFactorLoginLoading(state) {
      state.client = fp.merge({ loading: true }, state.client);
    },
    setTwoFactorLoginError(state, action: PayloadAction<string>) {
      state.client = fp.merge({ error: action.payload }, state.client);
      state.twoFactorClient = {};
    },
    setClientDataFromTwoFactorClientData(state, action: PayloadAction<string>) {
      const clientData = fp.merge(state.twoFactorClient?.data, {
        token: action.payload,
      });

      state.client = { data: clientData };
      state.twoFactorClient = {};
    },
    setTwoFactorEmail(state, action: PayloadAction<string>) {
      state.twoFactorEmail = action.payload;
    },
  },
});

export const {
  setSSOLoading,
  setClientLoading,
  setClientError,
  setClientData,
  setTwoFactorLoginError,
  setTwoFactorLoginLoading,
  setTwoFactorEmail,
  setTwoFactorClientData,
  setClientDataFromTwoFactorClientData,
  setSsoUserFirstName,
  setSsoUserLastName,
  setSsoUserEmail,
} = auth.actions;

export default auth.reducer;
