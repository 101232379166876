interface SourceOfFundsDocumentsCommon {
  heading: string;
  acceptedList: string;
}

export interface ProfileVerificationMessages {
  warningMessages: {
    fileNameTooLong: string;
    fileSizeTooLarge: Function;
    networkError: string;
  };
  profileVerification: {
    back: string;
    battlingWithVerification: string;
    contactUs: string;
  };
  whatYouWillNeed: {
    heading: string;
    subheading: string;
    continueButton: string;
    requirement1: Function;
    requirement2: string;
    whyDoINeed: string;
    reason1: string;
    reason2: string;
    ifSaveAndExit: string;
  };
  intent: {
    heading: string;
    toProtectYou: string;
    subheading: string;
    reason1: string;
    reason2: string;
    reason3: string;
    reason4: string;
    reason3Tooltip: string;
    reason4Tooltip: string;
    howMuchDoYouIntend: string;
    howMuchInvest: string;
    continueButton: string;
    accountVerification: string;
    contactUs: string;
    dropdownPlaceholder: string;
  };
  employment: {
    heading: string;
    continueButton: string;
    toProtectYou: string;
    employment: string;
    investorLevel: string;
    dropdownPlaceholder: string;
  };
  sourceOfFunds: {
    heading: string;
    headingTooltip: string;
    subheading: string;
    pleaseNote: string;
    earningsTooltip: string;
    businessTooltip: string;
    investmentsTooltip: string;
    inheritanceTooltip: string;
    pensionTooltip: string;
    cryptoTooltip: string;
    otherTooltip: string;
  };
  sourceOfFundsDocuments: {
    subHeading: string;
    bodyHeading: string;
    uploadButton: string;
    alternativeProcessMessage: (supportEmail: string | undefined) => string;
    sendLaterButton: string;
    continueButton: string;
    employment: SourceOfFundsDocumentsCommon;
    pension: SourceOfFundsDocumentsCommon;
    inheritance: SourceOfFundsDocumentsCommon;
    business: SourceOfFundsDocumentsCommon;
    investments: SourceOfFundsDocumentsCommon;
    crypto: SourceOfFundsDocumentsCommon;
    other: SourceOfFundsDocumentsCommon;
  };
  proofOfResidence: {
    heading: string;
    continueButton: string;
    streetNumber: string;
    unitNumber: string;
    roadName: string;
    buildingName: string;
    suburb: string;
    postalCode: string;
    province: string;
    country: string;
    acceptedDocs: string;
    utilityBill: string;
    bankStatement: string;
    taxCertificate: string;
    upload: string;
    uploadSuccessful: string;
    subheading: string;
  };
  phoneNumber: {
    heading: string;
    continueButton: string;
    subheading: string;
    subheading2: string;
    didntReceive: string;
    resend: string;
    country: string;
    phone: string;
  };
  linkSentToMobile: {
    heading: string;
    continueButton: string;
    completeModalImageAltText: string;
    subheading: string;
  };
  verifyingAccount: {
    heading: string;
    continueButton: string;
    completeModalImageAltText: string;
    subheading: string;
    contactSupport: string;
  };
  verificationError: {
    heading: string;
    continueButton: string;
    errorModalImageAltText: string;
    subheading: string;
    errorMessageIos: string;
    errorMessageGeneric: string;
    supportMessagePreLink: string;
    supportMessagePostLink: string;
  };
  documentsUploaded: {
    heading: string;
    heading2: string;
    continueButton: string;
    completeModalImageAltText: string;
    subheading: string;
  };
  complete: {
    heading: string;
    continueButton: string;
    completeModalImageAltText: string;
    subheading: string;
  };
}

type Messages = {
  [key: string]: ProfileVerificationMessages;
};

const messages: Messages = {
  eng: {
    warningMessages: {
      fileSizeTooLarge: (sizeInMB = 10) =>
        `The attached file is larger than ${sizeInMB}mb. Please upload a smaller file and try again.`,
      fileNameTooLong:
        'Your file name is too long, please rename the file to no more than 50 characters and try again.',
      networkError:
        'It looks like there was a network problem. Please refresh the page and try again.',
    },
    profileVerification: {
      back: 'Back',
      battlingWithVerification: 'Battling with your account verification?',
      contactUs: 'Contact us at ',
    },
    whatYouWillNeed: {
      heading: 'This usually takes 4 minutes',
      subheading: 'What you will need',
      continueButton: 'Continue →',

      requirement1: (countryCode = 'ZA') => `${countryCode == 'ZA' ? 'Your physical passport or ID' : 'Your physical passport, ID or drivers license'}`,
      requirement2:
        'Be in a well lit room to take a selfie so that we can match you to your ID photo',
      whyDoINeed: 'Why do I need to verify my identity?',
      reason1: 'We are obliged to comply with the law.',
      reason2:
        'So that you, and only you, can access your funds on our system.',
      ifSaveAndExit:
        'If you need to save and exit your application at any time you will be able to log in again and continue from where you left off.',
    },
    intent: {
      heading: 'Getting to know you',
      continueButton: 'Continue →',
      toProtectYou:
        'To protect you we operate in accordance with all laws and regulations. This means that we require some personal information.',
      subheading: 'What do you want to use Altify for?',
      reason1: 'Short-term trading',
      reason2: 'Long-term investing',
      reason3: 'use_arbitrage',
      reason4: 'use_remittance',
      reason3Tooltip: 'Taking advantage of price differences',
      reason4Tooltip: 'Sending funds offshore',
      howMuchDoYouIntend:
        'How much do you intend on investing over the next 30-days?',
      howMuchInvest:
        'How much do you forecast investing over the next year with us?',
      accountVerification: 'Battling with account verification?',
      contactUs: 'Contact us at',
      dropdownPlaceholder: 'Please select',
    },
    employment: {
      heading: 'Getting to know you',
      continueButton: 'Continue →',
      toProtectYou:
        'To protect you we operate under strict financial protocols. This means that we require some personal information.',
      employment: 'Employment status',
      dropdownPlaceholder: 'Please select',
      investorLevel: 'What investor level would you classify yourself as?',
    },
    sourceOfFunds: {
      heading: 'Source of funds',
      headingTooltip:
        'A source of funds document, or collection of documents, shows the origin of the funds that you will you use for deposits with us. Any documents provided should clearly show your name and surname, be in English and not be older than six months. Please note that a source of funds document does not primarily refer to the location of the funds (e.g. a bank account) as such, but instead to the reasons why you have access to the money (e.g. “I have earned this money through job X and saved it up over time frame Y. Furthermore, you will be able to find proof of its origin here.”).',
      subheading: 'Please select your main source of funds',
      pleaseNote:
        'Please note that you and only you can fund your Altify account. Deposits from 3rd parties cannot be processed.',
      earningsTooltip: `<span class="font-semibold">Earnings, wages or contractual services income</span>.
                <br/>
                <br/>
                We may require a payslip, paystub or services contract together with an up-to-date tax return.`,
      businessTooltip: `<span class="font-semibold">Proceeds from the sale of a business or dividends received from a business</span>.
                <br/>
                <br/>
                We may require proof of income from the business (e.g. a bank statement, distribution agreements, dividend resolutions, current account balance, or payment receipts.)`,
      investmentsTooltip: `<span class="font-semibold">Proceeds from the sale of investments or dividends or interest received.</span>
                <br/>
                <br/>
                We may require proof of income from investments or savings (e.g. bank statement, distribution agreements, dividend resolutions and current account balance), such as statements, contracts and payment receipts.`,
      inheritanceTooltip: `<span class="font-semibold">A windfall is a large financial gain—often the result of an inheritance, lawsuit settlement, property sale, salary bonus, or even a winning lottery ticket</span>.
                <br/>
                <br/>
                We may require a notarial proof, which has been verified by a certified notary, of an inheritance or gift together with a bank statement showing the amount received.`,
      pensionTooltip: `<span class="font-semibold">Proceeds received from a pension scheme</span>.
                <br/>
                <br/>
                We may require a pension statement and a bank statement showing that the payment was received. `,
      cryptoTooltip: `<span class="font-semibold">Crypto gains from sales, airdrops, staked rewards, mining rewards and other crypto-related income</span>
                <br/>
                <br/>
                We may require additional information on the source of your cryptocurrency funds.`,
      otherTooltip: `<span class="font-semibold">Any other form of income not detailed in the above options</span>.
                <br/>
                <br/>
                These include loans, income from rentals and leases.
                <ul class="pl-3">
                <li>Loan contracts in combination with bank statements showing the transaction.</li>
                <li>Proof of income from renting and leasing (e.g. rental agreement at the time of the agreement being signed and proof of the last rental payment)</li>
                </ul>`,
    },
    sourceOfFundsDocuments: {
      subHeading: 'Please upload your proof',
      bodyHeading: 'Accepted documents',
      uploadButton: 'Upload proof',
      alternativeProcessMessage: (supportEmail: string | undefined) => `
                <p class="my-5 text-center bg-horizontal-line"><span class="bg-background px-3 text-sm">Or</span></p>
                <p class="md:text-center">Manually send proof to <a class="text-secondary underline" href="mailto:${supportEmail}">${supportEmail}</a>. This will take up to 24 hours longer to be processed.</p>
                `,
      sendLaterButton: "I'll send these through later",
      continueButton: 'Continue',
      employment: {
        heading: 'Earnings in employment',
        acceptedList:
          '<ul class="list-none list-items-secondary text-tertiary">' +
          '<li>Payslip (not older than 3 months)</li>' +
          '<li>Bank statements showing incoming funds (not older than 3 months)</li><li>Tax returns</li>' +
          '</ul>',
      },
      pension: {
        heading: 'Pension',
        acceptedList:
          '<ul class="list-none list-items-secondary text-tertiary">' +
          '<li>Pension statement</li>' +
          '<li>Past 3 months bank statements showing incoming funds</li>' +
          '</ul>',
      },
      inheritance: {
        heading: 'Inheritance and/or windfall',
        acceptedList:
          '<ul class="list-none list-items-secondary text-tertiary">' +
          '<li>Bank statements showing incoming funds</li>' +
          '<li>Sale completion statement if income is from a sale of property</li>' +
          '<li>Payout statement from gambling or lottery winnings</li>' +
          '<li>Will or testament for inheritance</li>' +
          '<li>Grant of probate for inheritance</li>' +
          '<li>Letter from executed or solicitor for inheritance</li>' +
          '</ul>',
      },
      business: {
        heading: 'Business ownership',
        acceptedList:
          '<ul class="list-none list-items-secondary text-tertiary">' +
          '<li>Company documentation</li>' +
          '<li>Audited or unaudited financial statements</li>' +
          '<li>Business bank statement</li>' +
          '</ul>',
      },
      investments: {
        heading: 'Investments and/or savings',
        acceptedList:
          '<ul class="list-none list-items-secondary text-tertiary">' +
          '<li>Savings account statement</li>' +
          '<li>Investment account statement</li>' +
          '</ul>',
      },
      crypto: {
        heading: 'Cryptocurrency activities',
        acceptedList:
          '<ul class="list-none list-items-secondary text-tertiary">' +
          '<li>Bank statement showing payout related to purchase and trade</li>' +
          '<li>Statements, screenshots or email confirmations showing evidence of relevant trades</li>' +
          '</ul>' +
          '<sub>We unfortunately cannot accept crypto deposits from mining related activities</sub>',
      },
      other: {
        heading: 'Other',
        acceptedList:
          '<ul class="list-none list-items-secondary text-tertiary">' +
          '<li>Please look at other sources of income to determine appropriate proof</li>' +
          '<li>The more clear proof provided the better</li>' +
          '</ul>' +
          '<sub>We unfortunately cannot accept crypto deposits from mining related activities</sub>',
      },
    },
    proofOfResidence: {
      heading: 'Proof of residence',
      continueButton: 'Continue',
      streetNumber: '* Street number',
      unitNumber: 'Unit number (optional)',
      roadName: '* Road name',
      buildingName: 'Building name (optional)',
      suburb: '* Suburb/Borough',
      postalCode: '* Postal code',
      province: '* Province/State',
      country: '* Country',
      acceptedDocs: 'Accepted documents:',
      utilityBill: 'Utility bill (not older than 3-months)',
      bankStatement: 'Bank statement (not older than 3-months)',
      taxCertificate: 'Tax certificates',
      upload: 'Upload proof',
      uploadSuccessful: 'Upload successful',
      subheading:
        'Please enter your residential address and provide us with proof that you reside at this address.',
    },
    phoneNumber: {
      heading: 'Grab your passport or ID card',
      continueButton: 'Send text message',
      subheading:
        'You’ll be required to take a photo of your physical passport or ID card in this next step.',
      subheading2:
        'We’ll send you a text message with a link to finish verifying your account.',
      didntReceive: '',
      resend: '',
      country: 'Country',
      phone: 'Phone number',
    },
    linkSentToMobile: {
      heading: "We've sent a link to your phone",
      continueButton: 'Explore products',
      completeModalImageAltText: 'Success',
      subheading: `Get your passport, ID card or drivers license ready and make sure you’re in a well-lit room for a selfie. <br/><br/> We’ve sent you a link to verify your identity with our partner Netverify. <br/><br/> You’ll be notified by email once your verification is successful.`,
    },
    verifyingAccount: {
      heading: 'Verification in progress',
      continueButton: 'Explore products',
      completeModalImageAltText: 'Success',
      subheading:
        'This is usually a few hours but it can can take up to 2 days. <br /><br />Once verified, you’ll be able to make bank and crypto deposits.',
      contactSupport: 'Contact support',
    },
    verificationError: {
      heading: 'An error occured',
      continueButton: 'Portfolio',
      errorModalImageAltText: 'Error',
      subheading: 'Verification Error.',
      errorMessageIos:
        'If you are using a browser on your Apple device that isn’t Safari, please copy the verification link and paste it in Safari as Apple devices restrict access with other browsers.',
      errorMessageGeneric:
        'We have been notified of this error. Please navigate back to your portfolio and try again.',
      supportMessagePreLink: 'If the problem persists please reach out to',
      supportMessagePostLink: 'for further assistance.',
    },
    documentsUploaded: {
      heading: 'Success!',
      heading2: "We've received everything that we need",
      continueButton: 'Login →',
      completeModalImageAltText: 'Success',
      subheading: `We're busy with your account verification. This usually takes 24 to 48 hours. Once completed we'll send you an email letting you know that you're verified. <br/><br/> You can then login to your account to make a deposit.`,
    },
    complete: {
      heading: 'Congratulations!',
      continueButton: 'Explore products',
      completeModalImageAltText: 'Success',
      subheading: 'Your account has been successfully verified.',
    },
  },
};

export default messages;
