import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducer';
import messages from './messages';
// import { SourceOfFundsTypes } from './models/SourceOfFundsTypes';
// import sourceOfFunds from '../../data/sourceOfFunds';
import SourceOfFundsButton from './SourceOfFundsButton';
import { SourceOfFundsState } from './models/SourceOfFundsState';
import { VerificationStep } from '../../entities/verification';
import CustomLoader from '../CustomLoader';
import InfoButton from '../InfoButton';

const SourceOfFundsView = ({
  onComplete,
  sourceInfo,
}: {
  sourceInfo: VerificationStep;
  onComplete: (state: SourceOfFundsState) => void;
}) => {
  const { language } = useSelector((state: RootState) => state.language);

  const { loading } = useSelector((state: RootState) => state.verification);

  const [state] = React.useState<SourceOfFundsState>({
    source: '',
  });

  const viewMessages = messages[language].sourceOfFunds;

  function onClick(value: string) {
    state.source = value;
    onComplete(state);
  }

  const SOFTooltipMessages = [
    viewMessages.earningsTooltip,
    viewMessages.businessTooltip,
    viewMessages.investmentsTooltip,
    viewMessages.inheritanceTooltip,
    viewMessages.pensionTooltip,
    viewMessages.cryptoTooltip,
    viewMessages.otherTooltip,
  ];

  return (
    <div className="sm:max-w-1xl sm:mx-auto px-4 sm:px-6 lg:px-8">
      <div className=" sm:w-full mb-2">
        <h3 className="sm:text-center">
          {sourceInfo.userMessage}{' '}
          <InfoButton darkIcon id="heading" className="-mb-1">
            {viewMessages.headingTooltip}
          </InfoButton>
        </h3>
      </div>
      <div className=" sm:w-full  sm:mb-7 sm:mb-2 mb-3">
        <p className="sm:text-base text-xs sm:text-center">
          {viewMessages.subheading}
        </p>
      </div>
      <div className="pb-4 pt-5 rounded-lg transition-all">
        {loading ? (
          <CustomLoader />
        ) : (
          sourceInfo?.userOptions?.map((source, index) => {
            return (
              <SourceOfFundsButton
                onClickPressed={value => {
                  onClick(value);
                }}
                key={source.value}
                value={source.value}
                name={source.display}
                tooltipMessage={SOFTooltipMessages[index]}
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default SourceOfFundsView;
