import React from 'react';
import messages from 'pages/Error/messages';
import appHelpers from 'helpers/app-urls';
import Button from 'components/Button';
import usePageConfig from 'hooks/use-page-config';

const cdnURL = appHelpers.getCDNUrl();

const NotFoundView = () => {
  // #region HOOKS
  const pageConfig = usePageConfig();
  // #endregion

  const errorImg = `${cdnURL}assets/navigation/error-frown.svg`;
  const errorMessages = messages.eng.error;
  const goToPortfolio = () => window.location.replace('/');

  return (
    <div className="relative w-full mt-8">
      <div className="w-full flex items-center justify-center">
        <div className="flex flex-col items-center justify-center h-screen">
          <img className="w-full" src={errorImg} alt="404" />
          <div className="font-semibold text-header-text text-3xl md:text-4xl leading-10 pt-8">
            {errorMessages.errorHeading}
          </div>

          <div className="pt-5 text-header-text text-center">
            <div>{errorMessages.errorMessage}</div>
            <div>
              {errorMessages.supportMessagePreLink}{' '}
              <span className="font-semibold">
                <a href={`mailto:${pageConfig?.App?.SupportEmail}`}>
                  {pageConfig?.App?.SupportEmail}
                </a>
              </span>{' '}
              {errorMessages.supportMessagePostLink}
            </div>
          </div>

          <Button
            primary
            main
            onClick={() => goToPortfolio()}
            className="mt-8 continue"
          >
            {errorMessages.buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotFoundView;
