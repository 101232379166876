import { Checkbox } from '@altify/components';
import InfoButton from '../InfoButton';

const IntentCheckbox = ({
  checkboxName,
  checkboxValue,
  tooltip,
  tooltipText,
  onChange,
  checked,
}: {
  checkboxName: string;
  checkboxValue: string;
  tooltip?: boolean;
  tooltipText?: string;
  checked?: boolean;
  onChange: (checked: boolean, value: string) => void;
}) => {
  return (
    <div className="sm:px-3 py-2 flex flex-row">
      <Checkbox
        value={checked}
        onChange={checked => onChange(checked, checkboxValue)}
        label={checkboxName}
      />
      {tooltip && (
        <InfoButton
          darkIcon
          name={`intentTooltip-${checkboxValue}`}
          className="ml-2"
          place="bottom">
          {tooltipText}
        </InfoButton>
      )}
    </div>
  );
};

export default IntentCheckbox;
