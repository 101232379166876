import React from 'react';
import verificationStatus from '../../data/verificationStatus';
import {
  SubmitVerificationInfo,
  VerificationStatusResponse,
} from '../../entities/verification';
import {
  useGetVerificationStatusEmployment,
  useGetVerificationStatusInvestorLevel,
  useSubmitVerificationEmployment,
  useSubmitVerificationLevel,
} from '../../hooks/use-get-verification-status';
import EmploymentView from './EmploymentView';
import { EmploymentState } from './models/EmploymentState';
import { useAppDispatch } from 'hooks/use-app-dispatch';
import {
  getVerificationStatusEmploymentAction,
  getVerificationStatusInvestorLevelAction,
} from 'store/verification/verification-actions';

const Employment = ({ onComplete }: { onComplete: () => void }) => {
  const dispatch = useAppDispatch();
  const employmentInfo = useGetVerificationStatusEmployment();
  const investorLevelInfo = useGetVerificationStatusInvestorLevel();
  const [submitVerificationEmployment] = useSubmitVerificationEmployment();
  const [submitVerificationLevel] = useSubmitVerificationLevel();
  const responses: VerificationStatusResponse[] = [];

  function addCallback(response: VerificationStatusResponse) {
    responses.push(response);
  }

  async function submitForm(viewState: EmploymentState) {
    const requestEmp: SubmitVerificationInfo = {
      fields: [],
      callback: addCallback,
    };
    const requestLevel: SubmitVerificationInfo = {
      fields: [],
      callback: addCallback,
    };

    employmentInfo.requiredSubmissionFields.forEach(field => {
      requestEmp.fields.push({
        key: field,
        value: viewState.employment,
      });
    });

    investorLevelInfo.requiredSubmissionFields.forEach(field => {
      requestLevel.fields.push({
        key: field,
        value: viewState.investorLevel,
      });
    });

    await Promise.all([
      submitVerificationEmployment(requestEmp),
      submitVerificationLevel(requestLevel),
    ]);

    if (
      responses.filter(x => x.statusCode !== verificationStatus.Verified)
        .length === 0
    ) {
      dispatch(getVerificationStatusEmploymentAction());
      dispatch(getVerificationStatusInvestorLevelAction());
      onComplete();
    }
  }

  return (
    <EmploymentView
      employmentInfo={employmentInfo}
      investorLevelInfo={investorLevelInfo}
      onComplete={submitForm}
    />
  );
};

export default Employment;
