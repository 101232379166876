/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Currency } from '../../entities/currency';

export interface CurrencyState {
  loading: boolean;
  currencyCode: string;
  currencyData: Currency[];
}

const initialCurrencyState: CurrencyState = {
  loading: false,
  currencyCode: 'ZAR',
  currencyData: [],
};

const currency = createSlice({
  name: 'currency',
  initialState: initialCurrencyState,
  reducers: {
    getCurrenciesStart(state) {
      state.loading = true;
    },
    getCurrenciesEnd(state) {
      state.loading = false;
    },
    setCurrencyCodeStart(state) {
      state.loading = true;
    },
    setCurrencyCodeEnd(state) {
      state.loading = false;
    },
    setActiveCurrency(state, action: PayloadAction<string>) {
      state.currencyCode = action.payload;
    },
    setData(state, action: PayloadAction<Currency[]>) {
      state.currencyData = action.payload;
      state.loading = false;
    },
  },
});

export const {
  getCurrenciesStart,
  getCurrenciesEnd,
  setCurrencyCodeStart,
  setCurrencyCodeEnd,
  setActiveCurrency,
  setData,
} = currency.actions;

export default currency.reducer;
