import { useSelector } from 'react-redux';
import { RootState } from 'store/reducer';

const useAppLanguage = () => {
  const { language } = useSelector(
    (rootState: RootState) => rootState.language,
  );
  return language;
};

export default useAppLanguage;
