import {
  InitializeKyxActionRequestDto,
  UpdateKyxActionRequestDto,
} from 'services/generated';
import { AppThunk } from '..';
import {
  SubmitVerificationInfo,
  VerificationProofOfResidence,
  VerificationStatus,
  VerificationStatusResponse,
  VerificationStep,
} from '../../entities/verification';
import * as apiBankDetailsActions from '../../services/api-verification-actions';
import {
  getVerificationStatusData,
  getVerificationStatusEnd,
  getVerificationStatusError,
  getVerificationStatusStart,
  getVerificationStepEmployData,
  getVerificationStepEmployEnd,
  getVerificationStepEmployStart,
  getVerificationStepFundsData,
  getVerificationStepFundsEnd,
  getVerificationStepFundsStart,
  getVerificationStepIdData,
  getVerificationStepIdEnd,
  getVerificationStepIdStart,
  getVerificationStepInvestLongData,
  getVerificationStepInvestLongEnd,
  getVerificationStepInvestLongStart,
  getVerificationStepInvestorData,
  getVerificationStepInvestorEnd,
  getVerificationStepInvestorStart,
  getVerificationStepInvestShortData,
  getVerificationStepInvestShortEnd,
  getVerificationStepInvestShortStart,
  getVerificationStepPorData,
  getVerificationStepPorEnd,
  getVerificationStepPorStart,
  getVerificationStepSourceData,
  getVerificationStepSourceEnd,
  getVerificationStepSourceStart,
  setVerificationProofOfResidence,
  setVerificationSumSubData,
  submitVerificationFieldsEnd,
  submitVerificationFieldsResponse,
  submitVerificationFieldsStart,
  SumSubState,
} from './verification-slice';

export const submitVerificationFieldsFunds =
  (request: SubmitVerificationInfo): AppThunk =>
  async dispatch => {
    try {
      dispatch(submitVerificationFieldsStart());

      const data: VerificationStatusResponse | null =
        await apiBankDetailsActions.submitVerificationFunds(request);

      if (!data) {
        dispatch(submitVerificationFieldsEnd());
        return;
      }
      dispatch(submitVerificationFieldsResponse(data));

      if (request.callback) request.callback(data);
    } catch (Exception: any) {
      console.error(Exception);
      dispatch(getVerificationStatusError(Exception));
    }
  };

export const submitVerificationFieldsShortInvest =
  (request: SubmitVerificationInfo): AppThunk =>
  async dispatch => {
    try {
      dispatch(submitVerificationFieldsStart());

      const data: VerificationStatusResponse | null =
        await apiBankDetailsActions.submitVerificationShortInvest(request);

      if (!data) {
        dispatch(submitVerificationFieldsEnd());
        return;
      }

      dispatch(submitVerificationFieldsResponse(data));

      if (request.callback) request.callback(data);
    } catch (Exception: any) {
      console.error(Exception);
      dispatch(getVerificationStatusError(Exception));
    }
  };

export const submitVerificationFieldsLongInvest =
  (request: SubmitVerificationInfo): AppThunk =>
  async dispatch => {
    try {
      dispatch(submitVerificationFieldsStart());

      const data: VerificationStatusResponse | null =
        await apiBankDetailsActions.submitVerificationLongInvest(request);

      if (!data) {
        dispatch(submitVerificationFieldsEnd());
        return;
      }

      dispatch(submitVerificationFieldsResponse(data));

      if (request.callback) request.callback(data);
    } catch (Exception: any) {
      console.error(Exception);
      dispatch(getVerificationStatusError(Exception));
    }
  };

export const submitVerificationFieldsEmployment =
  (request: SubmitVerificationInfo): AppThunk =>
  async dispatch => {
    try {
      dispatch(submitVerificationFieldsStart());

      const data: VerificationStatusResponse | null =
        await apiBankDetailsActions.submitVerificationEmployment(request);

      if (!data) {
        dispatch(submitVerificationFieldsEnd());
        return;
      }
      dispatch(submitVerificationFieldsResponse(data));

      if (request.callback) request.callback(data);
    } catch (Exception: any) {
      console.error(Exception);
      dispatch(getVerificationStatusError(Exception));
    }
  };

export const submitVerificationFieldsLevel =
  (request: SubmitVerificationInfo): AppThunk =>
  async dispatch => {
    try {
      dispatch(submitVerificationFieldsStart());

      const data: VerificationStatusResponse | null =
        await apiBankDetailsActions.submitVerificationLevel(request);

      if (!data) {
        dispatch(submitVerificationFieldsEnd());
        return;
      }
      dispatch(submitVerificationFieldsResponse(data));

      if (request.callback) request.callback(data);
    } catch (Exception: any) {
      console.error(Exception);
      dispatch(getVerificationStatusError(Exception));
    }
  };

export const submitVerificationFieldsSource =
  (request: SubmitVerificationInfo): AppThunk =>
  async dispatch => {
    try {
      dispatch(submitVerificationFieldsStart());

      const data: VerificationStatusResponse | null =
        await apiBankDetailsActions.submitVerificationSource(request);

      if (!data) {
        dispatch(submitVerificationFieldsEnd());
        return;
      }
      dispatch(submitVerificationFieldsResponse(data));

      if (request.callback) request.callback(data);
    } catch (Exception: any) {
      console.error(Exception);
      dispatch(getVerificationStatusError(Exception));
    }
  };

export const submitVerificationFieldsAttachment =
  (request: File): AppThunk =>
  async dispatch => {
    try {
      dispatch(submitVerificationFieldsStart());

      const data: VerificationStatusResponse | null =
        await apiBankDetailsActions.submitVerificationSourceAttachment(request);

      if (!data) {
        dispatch(submitVerificationFieldsEnd());
        return;
      }
      dispatch(submitVerificationFieldsResponse(data));
    } catch (Exception: any) {
      console.error(Exception);
      dispatch(getVerificationStatusError(Exception));
    }
  };

export const submitVerificationFieldsPor =
  (request: SubmitVerificationInfo): AppThunk =>
  async dispatch => {
    try {
      dispatch(submitVerificationFieldsStart());

      const data: VerificationStatusResponse | null =
        await apiBankDetailsActions.submitVerificationPor(request);

      if (!data) {
        dispatch(submitVerificationFieldsEnd());
        return;
      }
      dispatch(submitVerificationFieldsResponse(data));

      if (request.callback) request.callback(data);
    } catch (Exception: any) {
      console.error(Exception);
      dispatch(getVerificationStatusError(Exception));
    }
  };

export const submitVerificationFieldsPorAttachment =
  (
    request: File,
    callback?: (response: VerificationStatusResponse) => void,
  ): AppThunk =>
  async dispatch => {
    try {
      dispatch(submitVerificationFieldsStart());

      const data: VerificationStatusResponse | null =
        await apiBankDetailsActions.submitVerificationPorAttachment(request);

      if (!data) {
        dispatch(submitVerificationFieldsEnd());
        return;
      }
      dispatch(submitVerificationFieldsResponse(data));

      if (callback) callback(data);
    } catch (Exception: any) {
      console.error(Exception);
      dispatch(getVerificationStatusError(Exception));
    }
  };

export const submitVerificationFieldsId =
  (request: SubmitVerificationInfo): AppThunk =>
  async dispatch => {
    try {
      dispatch(submitVerificationFieldsStart());

      const data: VerificationStatusResponse | null =
        await apiBankDetailsActions.submitVerificationId(request);

      if (!data) {
        dispatch(submitVerificationFieldsEnd());
        return;
      }
      dispatch(submitVerificationFieldsResponse(data));

      if (request.callback) request.callback(data);
    } catch (Exception: any) {
      console.error(Exception);
      dispatch(getVerificationStatusError(Exception));
    }
  };

export const getVerificationStatusUseOfFundsAction =
  (): AppThunk => async dispatch => {
    try {
      dispatch(getVerificationStepFundsStart());

      const data: VerificationStep | null =
        await apiBankDetailsActions.getVerificationStatusUseOfFunds();
      if (!data) {
        dispatch(getVerificationStepFundsEnd());
        return;
      }

      dispatch(getVerificationStepFundsData(data));
    } catch (Exception: any) {
      console.error(Exception);
      dispatch(getVerificationStatusError(Exception));
    }
  };

export const getVerificationStatusInvestShortAction =
  (): AppThunk => async dispatch => {
    try {
      dispatch(getVerificationStepInvestShortStart());

      const data: VerificationStep | null =
        await apiBankDetailsActions.getVerificationStatusInvestShort();
      if (!data) {
        dispatch(getVerificationStepInvestShortEnd());
        return;
      }

      dispatch(getVerificationStepInvestShortData(data));
    } catch (Exception: any) {
      console.error(Exception);
      dispatch(getVerificationStatusError(Exception));
    }
  };

export const getVerificationStatusInvestLongAction =
  (): AppThunk => async dispatch => {
    try {
      dispatch(getVerificationStepInvestLongStart());

      const data: VerificationStep | null =
        await apiBankDetailsActions.getVerificationStatusInvestLong();
      if (!data) {
        dispatch(getVerificationStepInvestLongEnd());
        return;
      }

      dispatch(getVerificationStepInvestLongData(data));
    } catch (Exception: any) {
      console.error(Exception);
      dispatch(getVerificationStatusError(Exception));
    }
  };

export const getVerificationStatusEmploymentAction =
  (): AppThunk => async dispatch => {
    try {
      dispatch(getVerificationStepEmployStart());

      const data: VerificationStep | null =
        await apiBankDetailsActions.getVerificationStatusEmployment();
      if (!data) {
        dispatch(getVerificationStepEmployEnd());
        return;
      }

      dispatch(getVerificationStepEmployData(data));
    } catch (Exception: any) {
      console.error(Exception);
      dispatch(getVerificationStatusError(Exception));
    }
  };

export const getVerificationStatusInvestorLevelAction =
  (): AppThunk => async dispatch => {
    try {
      dispatch(getVerificationStepInvestorStart());

      const data: VerificationStep | null =
        await apiBankDetailsActions.getVerificationStatusInvestorLevel();
      if (!data) {
        dispatch(getVerificationStepInvestorEnd());
        return;
      }

      dispatch(getVerificationStepInvestorData(data));
    } catch (Exception: any) {
      console.error(Exception);
      dispatch(getVerificationStatusError(Exception));
    }
  };

export const getVerificationStatusSourceAction =
  (): AppThunk => async dispatch => {
    try {
      dispatch(getVerificationStepSourceStart());

      const data: VerificationStep | null =
        await apiBankDetailsActions.getVerificationStatusSource();
      if (!data) {
        dispatch(getVerificationStepSourceEnd());
        return;
      }

      dispatch(getVerificationStepSourceData(data));
    } catch (Exception: any) {
      console.error(Exception);
      dispatch(getVerificationStatusError(Exception));
    }
  };

export const getVerificationStatusPorAction =
  (): AppThunk => async dispatch => {
    try {
      dispatch(getVerificationStepPorStart());

      const data: VerificationStep | null =
        await apiBankDetailsActions.getVerificationStatusProofOfResidence();
      if (!data) {
        dispatch(getVerificationStepPorEnd());
        return;
      }

      dispatch(getVerificationStepPorData(data));
    } catch (Exception: any) {
      console.error(Exception);
      dispatch(getVerificationStatusError(Exception));
    }
  };

export const getVerificationStatusIdAction = (): AppThunk => async dispatch => {
  try {
    dispatch(getVerificationStepIdStart());

    const data: VerificationStep | null =
      await apiBankDetailsActions.getVerificationStatusId();
    if (!data) {
      dispatch(getVerificationStepIdEnd());
      return;
    }

    dispatch(getVerificationStepIdData(data));
  } catch (Exception: any) {
    console.error(Exception);
    dispatch(getVerificationStatusError(Exception));
  }
};

export const getVerificationStatusAction = (): AppThunk => async dispatch => {
  try {
    dispatch(getVerificationStatusStart());

    const data: VerificationStatus | null =
      await apiBankDetailsActions.getVerificationStatus();
    if (!data) {
      dispatch(getVerificationStatusEnd());
      return;
    }

    dispatch(getVerificationStatusData(data));
  } catch (Exception: any) {
    console.error(Exception);
    dispatch(getVerificationStatusError(Exception));
  }
};

export const setVerificationProofOfResidenceData =
  (data: VerificationProofOfResidence): AppThunk =>
  async dispatch => {
    try {
      dispatch(setVerificationProofOfResidence(data));
    } catch (Exception: any) {
      console.error(Exception);
      dispatch(getVerificationStatusError(Exception));
    }
  };

export const initializeUserActionServiceAction =
  (payload: InitializeKyxActionRequestDto): AppThunk =>
  async dispatch => {
    try {
      const data =
        await apiBankDetailsActions.initializeUserActionService(payload);
      const sumSubState = {
        sdkToken: data.fields.sdkToken,
        timestamp: data.fields.timestamp,
        idUserIdAction: data.idUserActionId,
      } as SumSubState;
      dispatch(setVerificationSumSubData(sumSubState));
    } catch (Exception: any) {
      console.error(Exception);
      dispatch(getVerificationStatusError(Exception));
    }
  };

export const updateUserActionServiceAction =
  (payload: UpdateKyxActionRequestDto): AppThunk =>
  async dispatch => {
    try {
      await apiBankDetailsActions.updateUserActionService(payload);
    } catch (Exception: any) {
      console.error(Exception);
      dispatch(getVerificationStatusError(Exception));
    }
  };
