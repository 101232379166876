import React from 'react';
import useAppLanguage from 'hooks/use-app-language';
import appHelpers from '../../helpers/app-urls';
import messages from '../../pages/404/messages';

const cdnURL = appHelpers.getCDNUrl();

const NotFoundView = () => {
  const language = useAppLanguage();

  const notFoundImg = `${cdnURL}assets/navigation/404.svg`;

  const notFoundMessages = messages[language].notFound;

  return (
    <div className="relative w-full">
      <div className="absolute -mt-20 w-full flex items-center justify-center">
        <div className="flex flex-col items-center justify-center h-screen">
          <img className="w-full" src={notFoundImg} alt="404" />
          <div className="font-semibold text-header-text text-3xl md:text-4xl leading-10 pt-8">
            {notFoundMessages.pageNotFound}
          </div>
          <div className="pt-5 text-header-text">
            {notFoundMessages.navToPortfolio}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundView;
