import * as React from 'react';
import Modal from '../Modal';
import Button from '../Button';

export type InactivityTimerMessages = {
  title: React.ReactNode;
  message: string;
};

interface InactivityTimerViewProps {
  show: boolean;
  timeout: number;
  cancellationCountdown: number;
  onCancel: Function;
  messages: InactivityTimerMessages;
}

const InactivityTimerView = ({
  timeout,
  cancellationCountdown,
  show,
  onCancel,
  messages,
}: InactivityTimerViewProps) => {
  return (
    <Modal
      hideModal={undefined}
      show={show}
      content={
        <div className="-mt-4 w-full flex flex-col justify-center">
          <h4 className="mt-4">{messages.title}</h4>
          <div
            className="leading-none my-4 font-semibold text-header-text"
            style={{ fontSize: '100px' }}
          >
            {cancellationCountdown}
          </div>
          <div className="mb-5">
            {messages.message.replace('{timeout}', (timeout / 60).toFixed(0))}
          </div>
          <div className="w-full flex justify-center items-center">
            <div className="w-4/5 flex justify-center">
              <Button main full onClick={() => onCancel()}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default InactivityTimerView;
