import React, { useEffect } from 'react';
import ErrorComponent from '../../components/Error';
import { getWithExpiry, setWithExpiry } from '../../utils/with-expiry';

const Error = ({ error }: { error?: any | null }) => {
  useEffect(() => {
    const chunkFailedKey = 'chunk_failed';
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (error?.message && chunkFailedMessage.test(error.message)) {
      if (!getWithExpiry(chunkFailedKey)) {
        setWithExpiry(chunkFailedKey, 'true', 10_000);
        window.location.reload();
      }
    }
  }, [error]);

  return (
    <>
      <div className="px-6 py-4">
        <ErrorComponent />
      </div>
    </>
  );
};

export default Error;
