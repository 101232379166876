/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import clsx from 'clsx';
import ButtonSpinner from 'components/ButtonSpinner';
import appHelpers from 'helpers/app-urls';
import * as React from 'react';

const Button = ({
  id = 'button-id',
  isRoundedFull = true,
  children,
  onClick,
  disabled,
  xsmall,
  small,
  error,
  secondary,
  clickBlocked,
  outlined,
  outlinedHeaderText,
  text,
  underline,
  active,
  fluid,
  main,
  fluidWidth,
  fluidWidthSlim,
  normalText,
  primaryColour,
  secondaryColour,
  btcButton,
  full,
  fileUpload,
  fileUploadHoverIgnore,
  edit,
  loading,
  className,
  style,
  isVerify,
  isVerificationPending,
  isDepositPending,
  isDisabledOpaque,
  isStakable,
  ...props
}: {
  id?: string | undefined;
  type?: 'button' | 'submit' | 'reset';
  isRoundedFull?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  xsmall?: boolean;
  small?: boolean;
  error?: boolean;
  secondary?: boolean;
  clickBlocked?: boolean;
  outlined?: boolean;
  normalText?: boolean;
  outlinedHeaderText?: boolean;
  text?: boolean;
  underline?: boolean;
  active?: boolean;
  btcButton?: boolean;
  fluid?: boolean;
  main?: boolean;
  fluidWidth?: boolean;
  fluidWidthSlim?: boolean;
  primaryColour?: boolean;
  secondaryColour?: boolean;
  full?: boolean;
  loading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  fileUpload?: boolean;
  fileUploadHoverIgnore?: boolean;
  edit?: boolean;
  className?: string;
  style?: any;
  isVerify?: boolean;
  isVerificationPending?: boolean;
  isDepositPending?: boolean;
  [props: string]: unknown;
  isDisabledOpaque?: boolean;
  isStakable?: boolean;
}) => {
  const type = props.type || 'button';

  const cdnURL = appHelpers.getCDNUrl();

  const uploadImgDark = `${cdnURL}assets/components/button/file-upload-dark.svg`;
  const editBankIconDark = `${cdnURL}assets/funds/withdraw/fiat/edit-dark.svg`;
  const buttonSpinner = <ButtonSpinner />;

  const sizeProps = (() => {
    if (fluid) {
      if (small) {
        return ['h-10 text-base px-3'];
      }
      return ['h-12 text-lg px-3'];
    }
    if (xsmall) {
      return ['w-12 h-10 text-base'];
    }
    if (small) {
      return ['w-24 h-9 text-base'];
    }
    if (fluidWidth) {
      return ['h-12 text-lg px-3 lg:text-lg 2xl:text-2xl'];
    }
    if (fluidWidthSlim) {
      return ['h-12 text-lg lg:text-lg 2xl:text-2xl'];
    }
    if (main) {
      return [`${full ? 'w-full' : 'w-64'} h-12 text-base`];
    }
    return ['w-36 h-10 text-base'];
  })();

  const colorProps = (() => {
    if (outlined) {
      if (outlinedHeaderText) {
        let borderColour: { [key: string]: boolean } = {
          'hover:border-tertiary hover:text-tertiary': !disabled,
        };

        if (primaryColour)
          borderColour = {
            'hover:border-primary hover:text-primary': !disabled,
          };

        return [`border-2 border-header-text text-header-text`, borderColour];
      }

      let borderColour: { [key: string]: boolean } = {
        'hover:border-tertiary hover:text-tertiary': !disabled,
      };

      if (primaryColour === true)
        borderColour = { 'hover:border-primary hover:text-primary': !disabled };

      if (error) {
        borderColour = {
          'border-red-500 text-error hover:border-red-500 hover:text-error':
            error,
        };
      }

      return [
        `border-2 outlined-button-text ${
          primaryColour ? 'primary-button-text' : 'secondary-button-text'
        }`,
        borderColour,
      ];
    }

    if (secondary) {
      return [
        'bg-secondary-button button-text text-secondary border border-secondary',
        { 'hover:bg-neutral-600': !disabled },
      ];
    }

    if (clickBlocked) {
      return ['bg-text-grey button-text hover:bg-text-grey-hover'];
    }

    if (text) {
      return [
        `${active ? 'text-header-text' : 'text-light-slate-gray'} border-none text-2xl`,
        { 'hover:text-header-text': !disabled },
      ];
    }

    if (underline) {
      return [
        `text-header-text underline`,
        { 'hover:border-primary hover:text-primary': !disabled },
      ];
    }

    if (btcButton) {
      return ['bg-btc button-text', { 'hover:bg-primary-hover': !disabled }];
    }

    if (isVerify) {
      return ['bg-violet button-text hover:bg-violet-hover'];
    }

    if (isStakable)
      return ['bg-lock-and-earn hover:bg-ock-and-earn-hover button-text'];

    if (isDisabledOpaque) {
      return ['bg-disabled-button-background text-disabled-button-text'];
    }

    if (isVerificationPending || isDepositPending) {
      return ['bg-text-grey button-text hover:bg-text-grey-hover'];
    }
    return [
      'bg-primary-button button-text',
      { 'hover:bg-primary-hover': !disabled },
    ];
  })();

  const tempClassName = clsx(
    `${
      isRoundedFull ? 'rounded-r-full rounded-l-full' : 'rounded-none'
    } transition duration-200 ${
      normalText ? `font-normal` : `font-semibold`
    } focus:outline-none inline-flex items-center justify-center ${
      primaryColour ? 'primary-button-text' : 'secondary-button-text'
    } ${fileUpload ? 'file-upload' : ''}`,
    className,
    ...sizeProps,
    ...colorProps,
    { 'opacity-50 cursor-default': disabled },
    primaryColour && 'border-primary border-primary-hover border-transparent',
    // secondaryColour && 'border-secondary border-secondary-hover border-transparent',
    !primaryColour &&
      !secondary &&
      'border-tertiary border-tertiary-hover border-transparent',
    props,
  );

  const iconImage: string = edit ? editBankIconDark : uploadImgDark;

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      // #@ts-ignore
      id={id}
      type={type}
      onClick={onClick}
      disabled={disabled || loading}
      className={tempClassName}
      style={{ opacity: isDisabledOpaque ? '1' : '', ...style }}>
      {!fileUpload && !edit ? (
        !loading ? (
          children
        ) : null
      ) : (
        <div className="flex flex-row justify-around items-center truncate text-xs sm:text-base">
          {!loading ? (
            <img className="pr-1 ml-0 sm:ml-0 sm:pr-4" src={iconImage} alt="" />
          ) : null}{' '}
          {!loading ? children : null}
        </div>
      )}
      {loading && <> {buttonSpinner}</>}
    </button>
  );
};

export default Button;
