import React from 'react';

const WhatYouWillNeedCard = ({
  itemNumber,
  text,
}: {
  itemNumber: number;
  text: string;
}) => {
  return (
    <div className="sm:mx-1 ml-0 sm:py-3 py-2 sm:px-2 flex flex-row">
      <div className="w-8 h-8 mr-6 mb-2 flex shrink-0">
        <div
          className="flex items-center rounded-lg mt-1 sm:px-3 px-2 py-1 text-center bg-blue-grey
                text-white sm:text-base">
          {itemNumber}
        </div>
      </div>
      <div className="w-full mb-2 sm:text-1.06x1 text-md">{text}</div>
    </div>
  );
};

export default WhatYouWillNeedCard;
