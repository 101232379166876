import { BottomSheet } from '@altify/components';
import { Dialog } from 'components/Dialog/Dialog';
import useBreakpoint from 'hooks/use-breakpoint';
import { ReactNode } from 'react';

export function DialogOrBottomSheet({
  children,
  onClose,
  snapPoints,
  isOpen = true,
  backgroundColor = '#0C1C2C',
  dismissable = true,
  initialSnapPosition = 0,
  footerContent,
  blurEnabled = false,
}: {
  children: ReactNode;
  onClose?: () => void;
  snapPoints?: number[];
  isOpen?: boolean;
  backgroundColor?: string;
  dismissable?: boolean;
  initialSnapPosition?: number;
  footerContent?: ReactNode;
  blurEnabled?: boolean;
}) {
  const { isSmaller } = useBreakpoint();
  const isDeviceMobile = isSmaller('sm');

  return isDeviceMobile ? (
    <BottomSheet
      isOpen={isOpen}
      onClose={() => onClose?.()}
      snapPoints={snapPoints}
      backgroundColor={backgroundColor}
      blurEnabled={blurEnabled}
      dismissable={dismissable}
      footerContent={footerContent}
      initialSnapPosition={initialSnapPosition}
    >
      <div className="max-h-screen flex flex-col z-50">{children}</div>
    </BottomSheet>
  ) : (
    <Dialog
      backgroundColor={backgroundColor}
      blurEnabled={blurEnabled}
      dismissable={blurEnabled}
      isOpen={isOpen}
      onClose={onClose}>
      {children}
      {footerContent}
    </Dialog>
  );
}
