type OnboardingModalTranslations = {
  [language: string]: {
    [key: string]: string;
  };
};

const messages: OnboardingModalTranslations = {
  eng: {
    getVerified: 'Get verified',
    send: 'Send ➔',
    downLoadHeader: 'Need a download link sent to your phone?',
    step1text: 'Download the Altify app',
    step2text: 'Sign in to your account and verify your identity',
    step3text: 'Make a bank or crypto deposit to start investing',
    getApp: 'Get App',
    warningTop: 'Beware of any other apps using our name and/or logo.',
    warningBottom:
      'Please ensure that you only use the official app from the Google Play and Apple App stores published by Altify UK Ltd.',
    smsSuccess: 'Download link sent to',
    requestError: 'Something went wrong while trying to send your link.',
  },
};

export default messages;
