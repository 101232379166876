/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import fp from 'lodash/fp';
import { TimeFrameKey } from '../../entities/time-frame';
import { Graph } from '../../entities/graphs';

export type GraphsContainer = {
  timeFrame: TimeFrameKey;
  currency: string;
  code: string;
  data?: Graph;
  error?: string;
  loading?: boolean;
};

export const defaultGraphsState: GraphsContainer[] = [];

const graphs = createSlice({
  name: 'graphs',
  initialState: defaultGraphsState,
  reducers: {
    setGraphsData(
      state,
      action: PayloadAction<{
        timeFrame: TimeFrameKey;
        currency: string;
        code: string;
        data?: Graph;
      }>,
    ) {
      const { timeFrame, currency, code } = action.payload;
      const graphIndex = state.findIndex(
        s =>
          s.timeFrame === timeFrame &&
          s.code === code &&
          s.currency === currency,
      );
      if (graphIndex < 0) {
        state.push(action.payload);
      } else {
        state = fp.set(`[${graphIndex}]`, action.payload, state);
      }
      return state;
    },
    setGraphsError(
      state,
      action: PayloadAction<{
        timeFrame: TimeFrameKey;
        currency: string;
        code: string;
        error: string;
      }>,
    ) {
      const { timeFrame, currency, code } = action.payload;
      const graphIndex = state.findIndex(
        s =>
          s.timeFrame === timeFrame &&
          s.code === code &&
          s.currency === currency,
      );

      if (graphIndex < 0) {
        state.push(action.payload);
      } else {
        state = fp.set(`[${graphIndex}]`, action.payload, state);
      }
      return state;
    },
    setGraphsLoading(
      state,
      action: PayloadAction<{
        timeFrame: TimeFrameKey;
        currency: string;
        code: string;
      }>,
    ) {
      const { timeFrame, currency, code } = action.payload;
      const graphIndex = state.findIndex(
        s =>
          s.timeFrame === timeFrame &&
          s.code === code &&
          s.currency === currency,
      );

      if (graphIndex < 0) {
        state.push({ ...action.payload, loading: true });
      } else {
        state = fp.set(
          `[${graphIndex}]`,
          { ...action.payload, loading: true },
          state,
        );
      }
      return state;
    },
  },
});

export const { setGraphsData, setGraphsError, setGraphsLoading } =
  graphs.actions;

export default graphs.reducer;
