import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PortfolioVault, ProductVault } from 'entities/products';

/* eslint-disable no-param-reassign */

export interface VaultState {
  refreshInterval: number;
  portfolio: {
    data: PortfolioVault[];
    loading: boolean;
    error: any;
  };
  product: {
    data: ProductVault;
    loading: boolean;
    error: any;
  };
  products: {
    data: any[];
    loading: boolean;
    error: any;
  };
  lock: {
    amount: {
      data: any;
      loading: boolean;
      error: any;
      value: number | null | undefined;
    };
    confirm: {
      data: any;
      loading: boolean;
      error: any;
    };
  };
  unlock: {
    amount: {
      data:
        | {
            amountBlocks: { isPercentage: boolean; value: number }[];
            availableForFeeFree: number;
            balanceInFromCurrency: number;
            balanceInVaultCurrency: number;
            feePercentageBreakdown: {
              [key in string]: number;
            };
            fromProductCode: string;
            fromProductName: string;
            fromProductToFunctionalProductDisplayRate: number;
            minimumAmount: number;
            rate: number;
            rewardTierCode: string;
            rewardTierName: string;
            rewardTierPercentOffFees: number;
            targetExecution: string;
            vaultProductCode: string;
          }
        | any;
      loading: boolean;
      error: any;
      value: number | null | undefined;
    };
    confirm: {
      data: any;
      loading: boolean;
      error: any;
    };
  };
}

const initialState: VaultState = {
  refreshInterval: 30_000,
  portfolio: {
    loading: false,
    error: null,
    data: [],
  },
  product: {
    loading: false,
    error: null,
    // @ts-ignore
    data: {},
  },
  products: {
    loading: false,
    error: null,
    data: [],
  },
  lock: {
    amount: {
      loading: true,
      error: null,
      data: {},
      value: null,
    },
    confirm: {
      loading: true,
      error: null,
      data: {},
    },
  },
  unlock: {
    amount: {
      loading: true,
      error: null,
      data: {},
      value: null,
    },
    confirm: {
      loading: true,
      error: null,
      data: {},
    },
  },
};

const vault = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    // LOCK
    setLockAmountValue(
      state,
      action: PayloadAction<number | null | undefined>,
    ) {
      state.lock.amount.value = action.payload;
    },
    setLockAmountLoading(state, action: PayloadAction<boolean>) {
      state.lock.amount.loading = action.payload;
      state.lock.amount.error = null;
    },
    setLockAmountData(state, action: PayloadAction<any>) {
      state.lock.amount.data = action.payload;
      state.lock.amount.loading = false;
      state.lock.amount.error = null;
    },
    setLockAmountError(state, action: PayloadAction<any>) {
      state.lock.amount.error = action.payload;
      state.lock.amount.loading = false;
    },
    setLockConfirmLoading(state, action: PayloadAction<boolean>) {
      state.lock.confirm.loading = action.payload;
      state.lock.confirm.error = null;
    },
    setLockConfirmData(state, action: PayloadAction<any>) {
      state.lock.confirm.data = action.payload;
      state.lock.confirm.loading = false;
      state.lock.confirm.error = null;
    },
    setLockConfirmError(state, action: PayloadAction<any>) {
      state.lock.confirm.error = action.payload;
      state.lock.confirm.loading = false;
    },
    // UNLOCK
    setUnlockAmountValue(
      state,
      action: PayloadAction<number | null | undefined>,
    ) {
      state.unlock.amount.value = action.payload;
    },
    setUnlockAmountLoading(state, action: PayloadAction<boolean>) {
      state.unlock.amount.loading = action.payload;
      state.unlock.amount.error = null;
    },
    setUnlockAmountData(state, action: PayloadAction<any>) {
      state.unlock.amount.data = action.payload;
      state.unlock.amount.loading = false;
      state.unlock.amount.error = null;
    },
    setUnlockAmountError(state, action: PayloadAction<any>) {
      state.unlock.amount.error = action.payload;
      state.unlock.amount.loading = false;
    },
    setUnlockConfirmLoading(state, action: PayloadAction<boolean>) {
      state.unlock.confirm.loading = action.payload;
      state.unlock.confirm.error = null;
    },
    setUnlockConfirmData(state, action: PayloadAction<any>) {
      state.unlock.confirm.data = action.payload;
      state.unlock.confirm.loading = false;
      state.unlock.confirm.error = null;
    },
    setUnlockConfirmError(state, action: PayloadAction<any>) {
      state.unlock.confirm.error = action.payload;
      state.unlock.confirm.loading = false;
    },
    // PRODUCTS
    setVaultProductLoading(state, action: PayloadAction<any>) {
      state.product.loading = action.payload;
      state.product.error = null;
    },
    setVaultProductData(state, action: PayloadAction<any>) {
      state.product.data = action.payload;
      state.product.loading = false;
      state.product.error = null;
    },
    setVaultProductError(state, action: PayloadAction<any>) {
      state.product.error = action.payload;
      state.product.loading = false;
    },
    // PORTFOLIO
    setVaultPortfolioLoading(state, action: PayloadAction<any>) {
      state.portfolio.loading = action.payload;
      state.portfolio.error = null;
    },
    setVaultPortfolioData(state, action: PayloadAction<any>) {
      state.portfolio.data = action.payload;
      state.portfolio.loading = false;
      state.portfolio.error = null;
    },
    setVaultPortfolioError(state, action: PayloadAction<any>) {
      state.portfolio.error = action.payload;
      state.portfolio.loading = false;
    },
  },
});

export const {
  setLockAmountValue,
  setLockAmountLoading,
  setLockAmountData,
  setLockAmountError,
  setLockConfirmLoading,
  setLockConfirmData,
  setLockConfirmError,
  setUnlockAmountValue,
  setUnlockAmountLoading,
  setUnlockAmountData,
  setUnlockAmountError,
  setUnlockConfirmLoading,
  setUnlockConfirmData,
  setUnlockConfirmError,
  setVaultProductLoading,
  setVaultProductData,
  setVaultProductError,
  setVaultPortfolioLoading,
  setVaultPortfolioData,
  setVaultPortfolioError,
} = vault.actions;

export default vault.reducer;
