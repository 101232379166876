/* eslint-disable import/prefer-default-export */

/**
 * Joins strings into a relative url:  a, b, c => /a/b/c
 * @param urls
 */
export function joinUrl(...urls: string[]): string {
  /**
   * Remove all slashes from the beginning or ends of urls.
   * @param url
   */
  function parseUrl(url: string): string {
    if (url.startsWith('/')) {
      return parseUrl(url.slice(1));
    }
    if (url.endsWith('/')) {
      return parseUrl(url.slice(0, -1));
    }
    return url;
  }

  const parsedUrl = urls.map(url => parseUrl(url)).join('/');

  // Changes a/b/c to /a/b/c
  return parsedUrl.startsWith('/') ? parsedUrl : `/${parsedUrl}`;
}

export function replaceTextInObject<T>(
  content: T | undefined | null,
  searchValue: string | RegExp,
  replaceValue: string,
): T | undefined {
  if (!content) {
    return undefined;
  }
  return Object.entries(content as any).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      acc[key] = value.map(item => item.replace(searchValue, replaceValue));
    } else if (typeof value === 'string') {
      acc[key] = value.replace(searchValue, replaceValue);
    }
    // For other field types (undefined, etc.), just copy them over
    else {
      acc[key] = value;
    }
    return acc;
  }, {} as any);
}
