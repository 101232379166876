/* eslint-disable @typescript-eslint/ban-ts-ignore */
import clsx from 'clsx';
import { uniqueId } from 'lodash/fp';
import React from 'react';
import Tooltip from '../Tooltip';
import appHelpers from '../../helpers/app-urls';

const cdnURL = appHelpers.getCDNUrl();

export default function InfoButton({
  children,
  darkIcon,
  darkBg,
  className,
  clickable,
  delayHide,
  tooltipClassName,
  fixTooltip,
  ...props
}: {
  name?: string;
  darkIcon?: boolean;
  darkBg?: boolean;
  className?: string;
  clickable?: boolean;
  delayHide?: number;
  children: React.ReactNode;
  tooltipClassName?: string;
  fixTooltip?: boolean;
  [props: string]: unknown;
}) {
  const iconDark = `${cdnURL}assets/register/icon-dark.svg`;
  const icon = `${cdnURL}assets/register/icon-light.svg`;

  const id = `info-${uniqueId('')}`;

  return (
    <div
      className={`${
        fixTooltip ? 'w-screen flex' : 'inline-block'
      } pointer-events-auto whitespace-normal`}
    >
      <Tooltip
        id={id}
        content={children}
        interactive={clickable}
        delay={[null, !delayHide ? null : delayHide]}
        className={tooltipClassName}
        darkBg={darkBg}
        darkIcon={darkIcon}
      >
        <img
          src={darkIcon ? iconDark : icon}
          className={clsx('p-1', className, props)}
          alt="info"
        />
      </Tooltip>
    </div>
  );
}
