import GetAppButton from 'components/GetAppButton';
import OnboardingModal from 'components/OnboardingModal';
import usePageConfig from 'hooks/use-page-config';
import * as React from 'react';
import mainLogoWhite from '../../assets/images/logo-white.svg';
import mainLogo from '../../assets/images/logo.svg';
import { NavItem } from './TopNav';

interface TopNavViewProps {
  children?: React.ReactNode;
  onLogin: Function;
  register: Function;
  menuOpen: boolean;
  navItems: NavItem[];
  setMenuOpen: Function;
  loggedIn?: boolean;
  theme: string;
  setCurrentThemeFunc?: Function;
}

export default function TopNavView(props: TopNavViewProps) {
  const pageConfig = usePageConfig();
  const [isOnBoardingModalOpen, setIsOnboardingModalOpen] =
    React.useState<boolean>(false);
  return (
    <>
      <div className="bg-background h-screen transition duration-500 ease-in-out transition-colors lg:bg-body-background">
        <nav className="flex items-center justify-between flex-wrap white p-6 bg-body-background transition duration-500 ease-in-out transition-colors">
          <div className="flex items-center flex-shrink-0 mr-6">
            <a href="https://altify.app">
              <img
                src={props.theme === 'light' ? mainLogo : mainLogoWhite}
                alt="appLogo"
                className="h-6"
              />
            </a>
          </div>

          <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto hidden sm:hidden md:hidden">
            <div className="text-sm lg:flex-grow" />

            <div className="flex justify-between">
              {props.navItems.map(item => (
                <a
                  key={item.name}
                  href={item.route}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="flex mt-12 pt-2 h-10 w-fit mr-8 font-semibold text-header-text lg:inline-block lg:mt-0 hover:text-vivid-blue"
                >
                  {item.name}
                </a>
              ))}
            </div>

            <button
              onClick={() => props.onLogin()}
              type="button"
              className="mr-10 font-semibold text-header-text focus:outline-none hover:text-vivid-blue"
            >
              Login
            </button>

            <GetAppButton
              hasLightBorder
              hasDarkContent={props.theme === 'light'}
              hasNoText
              onClick={() => setIsOnboardingModalOpen(true)}
            />
          </div>

          <div className="flex items-center lg:hidden absolute right-4 z-20">
            {/* Mobile menu button */}
            <button
              onClick={() => props.setMenuOpen(!props.menuOpen)}
              type="button"
              className="inline-flex items-center justify-center p-2 text-vivid-blue focus:outline-none"
            >
              {/* Menu open: "hidden", Menu closed: "block" */}
              <svg
                className={props.menuOpen ? 'hidden' : 'block h-8 w-8'}
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              {/* Menu open: "block", Menu closed: "hidden" */}
              <svg
                className={props.menuOpen ? 'block h-8 w-8' : 'hidden'}
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div
            className={
              props.menuOpen
                ? 'flex lg:hidden fixed top-0 left-0 right-0 bottom-0 z-10 bg-white'
                : 'hidden lg:hidden'
            }
          >
            <div className="w-full">
              <div className="flex items-center flex-shrink-0 text-white pt-6 pl-6">
                <img
                  src={props.theme === 'light' ? mainLogo : mainLogoWhite}
                  alt="appLogo"
                  className="h-6"
                />
              </div>

              <div className="pt-2 w-full">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${pageConfig?.App?.SiteBaseUrl}/exploreinvestments`}
                  className="flex mt-8 flex items-center h-10 w-full font-semibold lg:inline-block hover:text-vivid-blue px-10"
                >
                  Explore Investments
                </a>

                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${pageConfig?.App?.SiteBaseUrl}/about/`}
                  className="flex mt-8 flex items-center h-10 w-full font-semibold lg:inline-block hover:text-vivid-blue px-10"
                >
                  Company
                </a>

                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${pageConfig?.App?.SiteBaseUrl}/help-centre`}
                  className="flex mt-8 flex items-center h-10 w-full font-semibold lg:inline-block hover:text-vivid-blue px-10"
                >
                  Support
                </a>
                <button
                  onClick={() => props.onLogin}
                  type="button"
                  className="flex mt-8 items-center mb-10 h-10 w-full font-semibold focus:outline-none lg:inline-block hover:text-vivid-blue px-10"
                >
                  Login
                </button>
                <div className="flex justify-center">
                  <GetAppButton hasDarkContent hasLightBorder />
                </div>
              </div>
            </div>
          </div>
        </nav>
        {props.children}
      </div>
      <OnboardingModal
        isOpen={isOnBoardingModalOpen}
        onClose={() => setIsOnboardingModalOpen(false)}
      />
    </>
  );
}
