import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import appHelpers from '../../helpers/app-urls';
import ButtonSpinner from '../ButtonSpinner';

const cdnURL = appHelpers.getCDNUrl();

export default function ArrowButton({
  onClick,
  link,
  backgroundColor,
  className,
  pending,
  loading,
  ...props
}: {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  link?: string;
  backgroundColor?: string;
  className?: string;
  pending?: boolean;
  loading?: boolean;
  [props: string]: unknown;
}) {
  const classNames = clsx('rounded-lg focus:outline-none p-1', className);
  const arrowIcon = `${cdnURL}assets/components/arrow-button/arrow.svg`;
  const pendingIcon = `${cdnURL}assets/components/arrow-button/pending.svg`;

  const content = (
    <div className="inline-flex h-8 w-8 items-center justify-center">
      {loading ? (
        <ButtonSpinner />
      ) : (
        <img
          style={pending ? { paddingTop: '10px' } : {}}
          src={pending ? pendingIcon : arrowIcon}
          className={clsx('p-1', props)}
          alt="arrow"
        />
      )}
    </div>
  );

  if (link) {
    return (
      <Link
        className={classNames}
        style={{
          backgroundColor: backgroundColor || 'var(--input-background)',
        }}
        to={link}
      >
        {content}
      </Link>
    );
  }

  return (
    <button type="button" className={classNames} onClick={onClick}>
      {content}
    </button>
  );
}
