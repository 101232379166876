/* eslint-disable no-plusplus */
import useAppLanguage from 'hooks/use-app-language';
import usePageConfig from 'hooks/use-page-config';
import Modal from '../Modal';
import Complete from './Complete';
import Employment from './Employment';
import Intent from './Intent';
import messages from './messages';
import { SourceOfFundsTypes } from './models/SourceOfFundsTypes';
import SourceOfFunds from './SourceOfFunds';
import SumSubWeb from './SumSubWeb';
import VerifyingAccount from './VerifyingAccount';
import WhatYouWillNeed from './WhatYouWillNeed';

const ProfileVerificationView = ({
  onChangePage,
  onComplete,
  showModal,
  showError,
  errorMessage,
  // overrideSecondTimeLoad,
  stepNumber,
  // sourceOfFundsType,
  closeModal,
  setSourceOfFundsType,
}: {
  onChangePage?: (path: string, secondTimeLoad: boolean) => void;
  onComplete?: (products: boolean) => void;
  showModal?: boolean;
  showError?: boolean;
  // overrideSecondTimeLoad: boolean;
  errorMessage?: string;
  stepNumber: number;
  // sourceOfFundsType?: SourceOfFundsTypes;
  closeModal?: () => void;
  setSourceOfFundsType?: (sourceOfFundsType: SourceOfFundsTypes) => void;
}) => {
  // #region HOOKS
  const language = useAppLanguage();
  const pageConfig = usePageConfig();
  // #endregion

  // #region TRANSLATIONS
  const viewMessages = messages[language].profileVerification;
  // #endregion

  const buildProgressBar = () => {
    const results = [];

    for (let i = 0; i < 8; i++)
      if (i < stepNumber)
        results.push(
          <div
            key={`step-${i}`}
            className="border-b-4 border-secondary rounded-b-none flex-1"
          >
            &nbsp;
          </div>,
        );
      else
        results.push(
          <div
            key={`step-${i}`}
            className="border-b-4 border-transparent border-dark-blue rounded-b-none flex-1"
          >
            &nbsp;
          </div>,
        );

    return results;
  };

  const changePage = (path: string, secondTimeLoad = true) => {
    if (onChangePage) onChangePage(path, secondTimeLoad);
  };

  const renderBody = () => {
    switch (stepNumber) {
      case 1:
        return <WhatYouWillNeed onComplete={() => changePage(`intent`)} />;
      case 2:
        return <Intent onComplete={() => changePage(`employment`)} />;
      case 3:
        return <Employment onComplete={() => changePage(`source-of-funds`)} />;
      case 4:
        return (
          <SourceOfFunds
            onComplete={(value: SourceOfFundsTypes) => {
              if (setSourceOfFundsType) setSourceOfFundsType(value);
              changePage(`id-verification`);
            }}
          />
        );
      case 5:
        return <SumSubWeb onComplete={() => changePage('verifying-account')} />;
      default:
        return <></>;
    }
  };

  const renderModalBody = () => {
    switch (stepNumber) {
      case 6:
        return (
          <VerifyingAccount
            onComplete={() => {
              if (onComplete) onComplete(true);
            }}
          />
        );
      case 7:
        return (
          <Complete
            onComplete={() => {
              if (onComplete) onComplete(true);
            }}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className="flex flex-col justify-center lg:px-8 sm:px-6 py-6 lg:py-12">
          <div className="lg:px-8 max-w-2xl mb-7 px-4 sm:px-6">
            <nav className="flex">{buildProgressBar()}</nav>
          </div>
          {renderBody()}
          {showError !== true ? null : (
            <div className="mt-6">
              <span className="block text-center text-red-700">
                {errorMessage}
              </span>
            </div>
          )}
          <Modal
            // largeContent={stepNumber === 8}
            hideModal={closeModal}
            content={renderModalBody()}
            closeModal={closeModal}
            show={showModal || false}
          />
        </div>
      </div>
      {stepNumber > 1 && (
        <div className="text-center">
          <p className="text-white">{viewMessages.battlingWithVerification}</p>
          <p className="font-extralight">
            {viewMessages.contactUs}

            <a
              href={`mailto:${pageConfig?.App?.SupportEmail}`}
              className="text-secondary underline"
            >
              {pageConfig?.App?.SupportEmail}
            </a>
          </p>
        </div>
      )}
    </div>
  );
};

export default ProfileVerificationView;
