import React from 'react';
import appUrls from 'helpers/app-urls';
import clsx from 'clsx';
import messages from './messages';

const cdnUrl = appUrls.getCDNUrl();

const GetAppButton = ({
  hasDarkContent,
  hasNoText,
  onClick,
  isFullWidth,
  hasLightBorder,
}: {
  hasDarkContent?: boolean;
  hasNoText?: boolean;
  onClick?: () => void;
  isFullWidth?: boolean;
  hasLightBorder?: boolean;
}) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    } else {
      window.open('https://revix.onelink.me/LjV8/bicplp15');
    }
  };
  return (
    <button
      type="button"
      onClick={handleOnClick}
      data-cy="button_login_get-app"
      className={clsx(
        'focus:outline-none border h-14 rounded-l-full rounded-r-full',
        {
          'w-full': isFullWidth,
          'border-grey-white': hasLightBorder,
          'border-text-grey': !hasLightBorder,
        },
      )}
    >
      <div className="flex items-center py-2 px-6 justify-center">
        <img
          src={`${cdnUrl}assets/logos/android-playstore.svg`}
          alt="android"
        />
        <img
          src={`${cdnUrl}assets/logos/${
            hasDarkContent
              ? 'apple-appstore-black.svg'
              : 'apple-appstore-white.svg'
          }`}
          alt="apple"
          className={clsx('ml-3', {
            'mr-6': !hasNoText,
          })}
        />
        {!hasNoText && (
          <div
            className={clsx('text-2xl whitespace-nowrap', {
              'text-gray-900': hasDarkContent,
              'text-white': !hasDarkContent,
            })}
          >
            {messages.getApp}
          </div>
        )}
      </div>
    </button>
  );
};

export default GetAppButton;
