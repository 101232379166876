import React, { useEffect } from 'react';
// import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom';
import { EVENTS, track, trackPageView } from 'utils/analytics';
import { LocationTypes } from 'entities/external';

/**
 * Wrapper for analytics.  (Make sure sits beneath react-router's Provider)
 */

export default function AnalyticsProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const history = useHistory();

  useEffect(() => {
    /**
     * Setup.
     */
    // ReactGA.initialize(import.meta.env.VITE_GA_TRACKING_PROPERTY_ID as string);

    /**
     * Initial page load.
     */
    // ReactGA.pageview(history.location.pathname);

    trackPageView(history.location.pathname);
    /**
     * Logs pageview for each route change.
     */
    history.listen(location => {
      // ReactGA.set({ page: location.pathname });
      // ReactGA.pageview(location.pathname);

      trackPageView(location.pathname);
    });
  }, [history]);

  return <>{children}</>;
}
