import React from 'react';
import './custom-loader.css';

export default function CustomLoader({ page }: { page?: boolean }) {
  const loader = (
    <div className="custom-loader h-4 w-4 relative">
      <div className="custom-loader--ball rounded-full absolute h-full w-full bg-custom-loader" />
      <div className="custom-loader--ball rounded-full absolute h-full w-full bg-custom-loader" />
      <div className="custom-loader--ball rounded-full absolute h-full w-full bg-custom-loader" />
    </div>
  );

  const fullLoader = (
    <div className="z-50 top-1/2 left-1/2">
      <div className="custom-loader h-4 w-4">
        <div className="custom-loader--ball rounded-full absolute h-full w-full bg-custom-loader" />
        <div className="custom-loader--ball rounded-full absolute h-full w-full bg-custom-loader" />
        <div className="custom-loader--ball rounded-full absolute h-full w-full bg-custom-loader" />
      </div>
    </div>
  );

  if (page) {
    return (
      <div className="relative">
        <div className="w-full h-full lg:h-screen lg:w-screen flex items-center justify-center fixed top-0 left-0 bg-background50 z-40">
          {fullLoader}
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-full flex items-center justify-center p-6 overflow-hidden">
      {loader}
    </div>
  );
}
