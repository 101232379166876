const getEndpointUrl = () => {
  return import.meta.env.VITE_API_URL !== undefined
    ? import.meta.env.VITE_API_URL
    : 'https://dashboard.revix.com/';
};

const getCDNUrl = () => {
  return import.meta.env.VITE_CDN_URL !== undefined
    ? import.meta.env.VITE_CDN_URL
    : 'https://revix-prod-cdn-public.azureedge.net/';
};

const getStagingCDNUrl = () => {
  return import.meta.env.VITE_CDN_URL !== undefined
    ? import.meta.env.VITE_CDN_URL
    : 'https://revix-prod-cdn-public.azureedge.net/';
};

const getSiteUrl = () => {
  return import.meta.env.VITE_BASE_URL !== undefined
    ? import.meta.env.VITE_BASE_URL
    : 'https://app.edge.altify.com/';
};

const getMarketingUrl = () => {
  return import.meta.env.VITE_MARKETING !== undefined
    ? import.meta.env.VITE_MARKETING
    : 'https://altify.com/';
};

const crispUrl = () => {
  return 'https://go.crisp.chat/chat/embed/?website_id=b4abd7ee-3daf-4cc5-8036-2189f605b0ab';
};

export default {
  getEndpointUrl,
  getCDNUrl,
  getStagingCDNUrl,
  getSiteUrl,
  getMarketingUrl,
  crispUrl,
};
