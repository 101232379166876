import mixpanel from 'mixpanel-browser';
import { cioIdentify, cioTrackPageView } from './customerio';

export const track = (eventName: string, eventProps?: {}) => {
  mixpanel.track(eventName, eventProps);
};

export function trackPageView(pathName: string) {
  track(EVENTS.PAGE_VIEW, {
    page: pathName,
  });

  cioTrackPageView(pathName);
}

export const identify = (id: string) => {
  cioIdentify({ id });
  mixpanel.identify(id);
};

export const setPerson = (personDetails: any) => {
  cioIdentify({
    id: personDetails?.id,
    email: personDetails?.$email,
    first_name: personDetails?.$first_name,
    last_name: personDetails?.$last_name,
  });

  mixpanel.people.set(personDetails);
};

export const tradeEventFields = {
  amountFrom: 'Amount From',
  fromCurrencyCode: 'From currency',
  toAmountCrypto: 'Amount to',
  toCurrencyCode: 'To currency',
  tradeFees: 'Trade fees',
  feeCurrency: 'Fee currency',
  tradeType: 'Trade type',
  firstTrade: 'First trade',
};

export enum EVENTS {
  TRADE_COMPLETE = 'trade_complete',
  LOGIN = 'login',
  PAGE_VIEW = 'page_view',
  REGISTRATION_COMPLETE = 'registration_complete',
}

export enum DATALAYER_EVENTS {
  REGISTER = 'register',
}

export enum DATALAYER_STEPS {
  SIGN_UP = 'sign up',
  DETAILS = 'details',
  HEAR_ABOUT = 'hear about us',
  OTP = 'OTP',
  TERMS = 'terms & conditions',
  DASHBOARD = 'dashboard',
}

export enum TRADE_TYPE {
  INVEST = 'Invest',
  SELL = 'Sell',
  LOCK = 'Lock',
  UNLOCK = 'Lock',
}
