declare global {
  interface Window {
    zE: any;
  }
}

export const logoutZendesk = () => {
  window.zE?.('messenger', 'logoutUser');
};

export const loginZendesk = (token: string) => {
  window.zE?.('messenger', 'loginUser', (callback: (jwt: string) => void) => {
    callback(token);
  });
};
