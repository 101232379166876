import moment from 'moment';

const cookie = {
  set: (key: string, value: string, date: Date | null = null) => {
    const host = window.location.hostname;
    const setDomainName = false; // host.indexOf('localhost') < 0 && host.indexOf('azurewebsites') < 0;

    const convertedDate = date
      ? moment(date).toDate()
      : moment().add(1, 'years').toDate();
    var expires = `; expires=${convertedDate}`;

    if (setDomainName) {
      document.cookie = `${key}=${value || ''}${expires}; path=/; domain=auto`;
    } else {
      document.cookie = `${key}=${value || ''}${expires}; path=/`;
    }

    /* REMOVING DOMAIN SPECIFIC COOKIES TO ATTEMPT TO FIX REFERRER ISSUE
        if (setDomainName) {
            
            cookieObj = `${key}=${
                value
                // expiration value set here
                } ;expires=${convertedDate} ;path=/ ;domain=.${host.split('.').reverse()[1]}.${
                    host.split('.').reverse()[0]
                }`;
        }
        */
  },

  get: (key: string) => {
    const name = `${key}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  },

  remove: (key: string) => {
    const host = window.location.hostname;
    const setDomainName = false; // host.indexOf('localhost') < 0 && host.indexOf('azurewebsites') < 0;

    if (setDomainName) {
      document.cookie = `${encodeURIComponent(
        key,
      )}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=auto; path=/`;
    } else {
      document.cookie = `${encodeURIComponent(
        key,
      )}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    }

    // let cookieObj = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/ ;domain='auto'`;
    /* REMOVING DOMAIN SPECIFIC COOKIES TO ATTEMPT TO FIX REFERRER ISSUE
        if (setDomainName) {
            
            cookieObj = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/ ;domain=.${host.split('.').reverse()[1]}.${
                host.split('.').reverse()[0]
            }`;
        }
        */
  },

  removeAll: () => {
    const cookies = document.cookie.split(';');

    const host = window.location.hostname;
    const setDomainName = false; // host.indexOf('localhost') < 0 && host.indexOf('azurewebsites') < 0;

    for (let i = 0; i < cookies.length; i += 1) {
      const cookieObj = cookies[i];
      const eqPos = cookieObj.indexOf('=');
      const name = eqPos > -1 ? cookieObj.substr(0, eqPos) : cookie;

      //let cookieData = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=auto`;
      if (setDomainName) {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=auto; path=/`;
      } else {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
      }
      /* REMOVING DOMAIN SPECIFIC COOKIES TO ATTEMPT TO FIX REFERRER ISSUE
            if (setDomainName) {
                cookieData = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.${host.split('.').reverse()[1]}.${
                    host.split('.').reverse()[0]
                }`;
            }
            */

      //document.cookie = cookieData;
    }
  },
};

export default cookie;
