/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TimeFrameKey } from '../../entities/time-frame';

export interface AppState {
  loading: boolean;
  hasGlobalTimeFrame: boolean;
  globalTimeFrame: TimeFrameKey;
  isTourOpen: boolean;
  tourSlide: number;
  pageConfig: any;
  url: {
    previousPath: string;
    currentPath: string;
  };
}

const initialAppState: AppState = {
  loading: true,
  hasGlobalTimeFrame: false,
  globalTimeFrame: '',
  isTourOpen: false,
  tourSlide: 0,
  pageConfig: {},
  url: {
    previousPath: '',
    currentPath: '',
  },
};

const app = createSlice({
  name: 'app',
  initialState: initialAppState,
  reducers: {
    setAppLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setAppHasGlobalTimeFrame(state, action: PayloadAction<boolean>) {
      state.hasGlobalTimeFrame = action.payload;
    },
    setAppGlobalTimeFrame(state, action: PayloadAction<TimeFrameKey>) {
      state.globalTimeFrame = action.payload;
    },
    setIsTourOpen(state, action: PayloadAction<boolean>) {
      state.isTourOpen = action.payload;
    },
    setTourSlide(state, action: PayloadAction<number>) {
      state.tourSlide = action.payload;
    },
    setUrl(state, action: PayloadAction<string>) {
      if (!state.url) return;
      if (state.url.currentPath === action.payload) return;
      state.url.previousPath = state.url.currentPath;
      state.url.currentPath = action.payload;
    },
    setPageConfig(state, action: PayloadAction<any>) {
      state.pageConfig = action.payload;
    },
  },
});

export const {
  setAppLoading,
  setAppHasGlobalTimeFrame,
  setAppGlobalTimeFrame,
  setIsTourOpen,
  setTourSlide,
  setPageConfig,
  setUrl,
} = app.actions;

export default app.reducer;
