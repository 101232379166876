import { createContent } from '@altify/utils';

export const content = createContent({
  en: {
    selectOrderType: 'Select Your Order Type',
    buyNowSubtitle: 'Instant buy at the best available price',
    sellNowSubtitle: 'Instant sell at the best available price',
    customBuyOrderSubtitle: 'Buy when a target price is reached',
    customSellOrderSubtitle: 'Sell when a target price is reached',
    recurringBuySubtitle: 'Buy at regular intervals',
  },
  de: {
    selectOrderType: 'Wähle deinen Order Typ',
    buyNowSubtitle: 'Sofortiger Kauf zum besten verfügbaren Preis',
    sellNowSubtitle: 'Sofortiger Verkauf zum besten verfügbaren Preis',
    customBuyOrderSubtitle:
      'Kaufe, sobald ein festgelegter Preis erreicht wird',
    customSellOrderSubtitle:
      'Verkaufe, sobald ein festgelegter Preis erreicht wird',
    recurringBuySubtitle: 'Kaufe in regelmäßigen Abständen',
  },
});
