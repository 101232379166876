import SumsubWebSdk from '@sumsub/websdk-react';
import CustomLoader from 'components/CustomLoader';
import useCountry from 'hooks/use-country';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducer';
import { useGetSumsubTokenQuery, useUpdateUserActionMutation } from 'store/slices/verification-api';
import {
  getVerificationStatusAction
} from 'store/verification/verification-actions';

const SumSubWeb = ({ onComplete }: { onComplete: () => void }) => {
  const dispatch = useDispatch();
  const consentObtainedAt = useMemo(() => new Date().toISOString(), []);
  const countries = useCountry();
  const { userProfileResponse } = useSelector(
    (state: RootState) => state.profile,
  );
  const alpha3Country = countries?.find(
    (country: any) => country.code === userProfileResponse?.countryCode,
  );

  const sumsubPayload = {
    userIp: '192.0.0.1',
    userCountry: alpha3Country?.alpha3Code ?? 'ZAF',
    consentObtained: 'yes',
    consentObtainedAt: consentObtainedAt, // Jumio requires date string in this format
  };

  const {isLoading, data: sumsubData, refetch} = useGetSumsubTokenQuery(sumsubPayload);
  const [updateUserAction, updateUserActionResult] = useUpdateUserActionMutation();

  const handleMessage = async (event: any, payload: any) => {
    if (event === 'idCheck.onApplicantStatusChanged') {
      onComplete();

      await updateUserAction({
        userActionId: sumsubData?.idUserIdAction!,
        providerReference: userProfileResponse?.id!,
      });

      dispatch(getVerificationStatusAction());
    }
  };

  return !isLoading ? (
    <SumsubWebSdk
      accessToken={sumsubData?.sdkToken!}
      expirationHandler={async () => {
        const res = await refetch();
        return res.data?.sdkToken!;
      }}
      config={{
        lang: 'en',
        email: userProfileResponse?.email,
        phone: userProfileResponse?.msisdn,
      }}
      options={{ addViewportTag: false, adaptIframeHeight: true }}
      onMessage={handleMessage}
      onError={e => console.error('onError', e)}
    />
  ) : (
    <CustomLoader page={true} />
  );
};

export default SumSubWeb;
