import React from 'react';
import { useSelector } from 'react-redux';
import openCrisp from 'helpers/crisp';
import { RootState } from '../../store/reducer';
import messages from './messages';
import appHelpers from '../../helpers/app-urls';
import Button from '../Button';

const CompleteModal = ({
  heading,
  subheading,
  continueButton,
  onComplete,
  isVerificationPending,
}: {
  heading: string;
  subheading: string;
  continueButton: string;
  onComplete?: () => void;
  isVerificationPending?: boolean;
}) => {
  const { language } = useSelector((state: RootState) => state.language);

  const viewMessages = messages[language].linkSentToMobile;
  const verificationMessages = messages[language].verifyingAccount;
  const cdnURL = appHelpers.getCDNUrl();
  const marketingUrl = appHelpers.getMarketingUrl();

  const handleContactSupport = () => {
    openCrisp();
  };

  return (
    <div>
      <div className="mb-8">
        <img
          src={`${cdnURL}assets/profile/verification/${
            isVerificationPending ? 'pending' : 'success'
          }.svg`}
          alt={viewMessages.completeModalImageAltText}
          className="center"
        />
      </div>
      <div className="mx-5 md:mx-20">
        <h3
          className="mb-7 font-semibold text-2xl tracking-wide leading-8"
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <h5
          className="mb-7 text-gray-900 font-normal text-lg"
          dangerouslySetInnerHTML={{ __html: subheading }}
        />
        <Button className="w-full mb-6" m-2 fluid onClick={onComplete} continue>
          <span className="text text-sm sm:text-base">{continueButton}</span>
        </Button>
        {isVerificationPending && (
          <a
            className="text-white font-semibold focus:outline-none"
            href="https://www.chatbase.co/chatbot-iframe/gPYNlNcz-RWulEQXb7NAx"
            target="_blank"
            rel="noopener noreferrer"
          >
            {verificationMessages.contactSupport}
          </a>
        )}
      </div>
    </div>
  );
};

export default CompleteModal;
