/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Process } from 'entities/otp';

interface OtpTimestamps {
  WITHDRAW_FIAT: Date | null;
  WITHDRAW_CRYPTO_BTC: Date | null;
  WITHDRAW_CRYPTO_USDT: Date | null;
  WITHDRAW_CRYPTO_USDC: Date | null;
  PROFILE_BANK_DETAILS: Date | null;
  PROFILE_BTC_WALLET: Date | null;
  PROFILE_USDT_WALLET: Date | null;
  PROFILE_USDC_WALLET: Date | null;
  PROFILE_CHANGE_PASSWORD: Date | null;
  SIGNUP_ENABLE_SMS_OTP: Date | null;
  PROFILE_ENABLE_SMS_OTP: Date | null;
}
interface StateType {
  smsOtpActivationSuccess: boolean;
  smsOtpTimestamps: OtpTimestamps;
  emailOtpTimestamps: OtpTimestamps;
  sendSmsOtpError: boolean;
  initiateSmsOtpError: boolean;
  activateSmsOtpError: boolean;
  sendEmailOtpError: boolean;
  initiateEmailOtpError: boolean;
  initiateSmsOtpResponse: {
    success: boolean;
    message: string | null;
  };
  loading: boolean;
}

const initialState: StateType = {
  smsOtpActivationSuccess: false,
  smsOtpTimestamps: {
    WITHDRAW_FIAT: null,
    WITHDRAW_CRYPTO_BTC: null,
    WITHDRAW_CRYPTO_USDT: null,
    WITHDRAW_CRYPTO_USDC: null,
    PROFILE_BANK_DETAILS: null,
    PROFILE_BTC_WALLET: null,
    PROFILE_USDT_WALLET: null,
    PROFILE_USDC_WALLET: null,
    PROFILE_CHANGE_PASSWORD: null,
    SIGNUP_ENABLE_SMS_OTP: null,
    PROFILE_ENABLE_SMS_OTP: null,
  },
  emailOtpTimestamps: {
    WITHDRAW_FIAT: null,
    WITHDRAW_CRYPTO_BTC: null,
    WITHDRAW_CRYPTO_USDT: null,
    WITHDRAW_CRYPTO_USDC: null,
    PROFILE_BANK_DETAILS: null,
    PROFILE_BTC_WALLET: null,
    PROFILE_USDT_WALLET: null,
    PROFILE_USDC_WALLET: null,
    PROFILE_CHANGE_PASSWORD: null,
    SIGNUP_ENABLE_SMS_OTP: null,
    PROFILE_ENABLE_SMS_OTP: null,
  },
  sendSmsOtpError: false,
  initiateSmsOtpError: false,
  activateSmsOtpError: false,
  loading: false,
  sendEmailOtpError: false,
  initiateEmailOtpError: false,
  initiateSmsOtpResponse: {
    success: true,
    message: null,
  },
};

const otpSlice = createSlice({
  name: 'otp',
  initialState,
  reducers: {
    setSendSmsOtpError(state, action) {
      state.sendSmsOtpError = action.payload;
    },
    setSendEmailOtpError(state, action) {
      state.sendEmailOtpError = action.payload;
    },
    setInitiateSmsOtpError(state, action) {
      state.sendSmsOtpError = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setActivateSmsOtpError(state, action) {
      state.activateSmsOtpError = action.payload;
    },
    setInitiateEmailOtpError(state, action) {
      state.sendEmailOtpError = action.payload;
    },
    setSmsOtpActivated(state) {
      state.smsOtpActivationSuccess = true;
    },
    setInitiateSmsOtpResponse(state, action) {
      state.initiateSmsOtpResponse = action.payload;
    },
    setSmsOtpTimestampRedux(
      state,
      action: PayloadAction<{ process: Process; timestamp: Date | null }>,
    ) {
      const { process, timestamp } = action.payload;
      state.smsOtpTimestamps = {
        ...state.smsOtpTimestamps,
        [process]: timestamp,
      };
    },
    setEmailOtpTimestampRedux(
      state,
      action: PayloadAction<{ process: Process; timestamp: Date | null }>,
    ) {
      const { process, timestamp } = action.payload;
      state.emailOtpTimestamps = {
        ...state.emailOtpTimestamps,
        [process]: timestamp,
      };
    },
  },
});

export const {
  setSendSmsOtpError,
  setSendEmailOtpError,
  setInitiateSmsOtpError,
  setInitiateEmailOtpError,
  setLoading,
  setActivateSmsOtpError,
  setSmsOtpActivated,
  setSmsOtpTimestampRedux,
  setEmailOtpTimestampRedux,
  setInitiateSmsOtpResponse,
} = otpSlice.actions;

export default otpSlice.reducer;
