import {
  SubmitVerificationInfo,
  VerificationStatus,
  VerificationStatusResponse,
  VerificationStep,
} from '../entities/verification';
import api, { convertToFormData } from './api';
import {
  InitializeKyxActionRequestDto,
  UpdateKyxActionRequestDto,
} from './generated';

const parseException = (Exception: any) => {
  console.error(Exception);
  // Exception.toJSON() is for axios-wrapped Network erros (e.g. CORS)
  return Exception?.response?.data || Exception?.toJSON?.();
};

export const getVerificationStatus =
  async (): Promise<VerificationStatus | null> => {
    try {
      const pathToUse = `/verification/status`;
      const result = await api.get(pathToUse);

      return result.data;
    } catch (Exception: any) {
      console.error(Exception);
      return Exception.response.data;
    }
  };

export const initializeUserActionService = async (
  payload: InitializeKyxActionRequestDto,
) => {
  try {
    const result = await api.post(
      '/verification/initializeOrGetIdUserAction/v2',
      payload,
    );
    return result.data;
  } catch (Exception: any) {
    console.error(Exception);
    return Exception.response.data;
  }
};

export const updateUserActionService = async (
  payload: UpdateKyxActionRequestDto,
) => {
  try {
    const result = await api.post(
      '/verification/updateIdUserActionReference',
      payload,
    );
    return result.data;
  } catch (Exception: any) {
    console.error(Exception);
    return Exception.response.data;
  }
};

export const postVerificationUserInit = async (
  userId: string,
): Promise<any | null> => {
  try {
    const result = await api.post(`/verification/${userId}/init`);
    return { statusCode: result.status, ...result.data };
  } catch (Exception: any) {
    console.error(Exception);
    return Exception.response.data;
  }
};

export const getVerificationStatusUseOfFunds =
  async (): Promise<VerificationStep | null> => {
    try {
      const pathToUse = `/verification/step/use`;
      const result = await api.get(pathToUse);

      return result.data;
    } catch (Exception: any) {
      console.error(Exception);
      return Exception.response.data;
    }
  };

export const getVerificationStatusInvestShort =
  async (): Promise<VerificationStep | null> => {
    try {
      const pathToUse = `/verification/step/investMonth`;
      const result = await api.get(pathToUse);

      return result.data;
    } catch (Exception: any) {
      console.error(Exception);
      return Exception.response.data;
    }
  };

export const getVerificationStatusInvestLong =
  async (): Promise<VerificationStep | null> => {
    try {
      const pathToUse = `/verification/step/investYear`;
      const result = await api.get(pathToUse);

      return result.data;
    } catch (Exception: any) {
      console.error(Exception);
      return Exception.response.data;
    }
  };

export const getVerificationStatusEmployment =
  async (): Promise<VerificationStep | null> => {
    try {
      const pathToUse = `/verification/step/employment`;
      const result = await api.get(pathToUse);

      return result.data;
    } catch (Exception: any) {
      console.error(Exception);
      return Exception.response.data;
    }
  };

export const getVerificationStatusSource =
  async (): Promise<VerificationStep | null> => {
    try {
      const pathToUse = `/verification/step/source`;
      const result = await api.get(pathToUse);

      return result.data;
    } catch (Exception: any) {
      console.error(Exception);
      return Exception.response.data;
    }
  };

export const getVerificationStatusInvestorLevel =
  async (): Promise<VerificationStep | null> => {
    try {
      const pathToUse = `/verification/step/level`;
      const result = await api.get(pathToUse);

      return result.data;
    } catch (Exception: any) {
      console.error(Exception);
      return Exception.response.data;
    }
  };

export const getVerificationStatusProofOfResidence =
  async (): Promise<VerificationStep | null> => {
    try {
      const pathToUse = `/verification/step/por`;
      const result = await api.get(pathToUse);

      return result.data;
    } catch (Exception: any) {
      console.error(Exception);
      return Exception.response.data;
    }
  };

export const getVerificationStatusId =
  async (): Promise<VerificationStep | null> => {
    try {
      const pathToUse = `/verification/step/id`;
      const result = await api.get(pathToUse);

      return result.data;
    } catch (Exception: any) {
      console.error(Exception);
      return Exception.response.data;
    }
  };

export const submitVerificationFunds = async (
  request: SubmitVerificationInfo,
): Promise<VerificationStatusResponse | null> => {
  try {
    const result = await api.post(`/verification/step/use`, request);

    return { statusCode: result.status, ...result.data };
  } catch (Exception: any) {
    console.error(Exception);
    return Exception.response.data;
  }
};

export const submitVerificationShortInvest = async (
  request: SubmitVerificationInfo,
): Promise<VerificationStatusResponse | null> => {
  try {
    const result = await api.post(`/verification/step/investMonth`, request);

    return { statusCode: result.status, ...result.data };
  } catch (Exception: any) {
    console.error(Exception);
    return Exception.response.data;
  }
};

export const submitVerificationLongInvest = async (
  request: SubmitVerificationInfo,
): Promise<VerificationStatusResponse | null> => {
  try {
    const result = await api.post(`/verification/step/investYear`, request);

    return { statusCode: result.status, ...result.data };
  } catch (Exception: any) {
    console.error(Exception);
    return Exception.response.data;
  }
};

export const submitVerificationEmployment = async (
  request: SubmitVerificationInfo,
): Promise<VerificationStatusResponse | null> => {
  try {
    const result = await api.post(`/verification/step/employment`, request);

    return { statusCode: result.status, ...result.data };
  } catch (Exception: any) {
    console.error(Exception);
    return Exception.response.data;
  }
};

export const submitVerificationLevel = async (
  request: SubmitVerificationInfo,
): Promise<VerificationStatusResponse | null> => {
  try {
    const result = await api.post(`/verification/step/level`, request);

    return { statusCode: result.status, ...result.data };
  } catch (Exception: any) {
    console.error(Exception);
    return Exception.response.data;
  }
};

export const submitVerificationSource = async (
  request: SubmitVerificationInfo,
): Promise<VerificationStatusResponse | null> => {
  try {
    const result = await api.post(`/verification/step/source`, request);

    return { statusCode: result.status, ...result.data };
  } catch (Exception: any) {
    console.error(Exception);
    return Exception.response.data;
  }
};

export const submitVerificationSourceAttachment = async (
  request: File,
): Promise<VerificationStatusResponse | null> => {
  try {
    const formData = convertToFormData({ attachment: request });

    const result = await api.post(
      `/verification/step/source/attachment`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return { statusCode: result.status, ...result.data };
  } catch (Exception: any) {
    console.error(Exception);
    return Exception.response.data;
  }
};

export const submitVerificationPor = async (
  request: SubmitVerificationInfo,
): Promise<VerificationStatusResponse | null> => {
  try {
    const result = await api.post(`/verification/step/por`, request);

    return { statusCode: result.status, ...result.data };
  } catch (Exception: any) {
    console.error(Exception);
    return Exception.response.data;
  }
};

export const submitVerificationPorAttachment = async (
  request: File,
): Promise<VerificationStatusResponse | null> => {
  try {
    const formData = convertToFormData({ attachment: request });

    const result = await api.post(
      `/verification/step/por/attachment`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return { statusCode: result.status, ...result.data };
  } catch (Exception: any) {
    throw parseException(Exception);
  }
};

export const submitVerificationId = async (
  request: SubmitVerificationInfo,
): Promise<VerificationStatusResponse | null> => {
  try {
    const result = await api.post(`/verification/step/id`, request);

    return { statusCode: result.status, ...result.data };
  } catch (Exception: any) {
    console.error(Exception);
    return Exception.response.data;
  }
};
