import React from 'react';
import { useSelector } from 'react-redux';
import NotFoundComponent from '../../components/NotFound';
import SEO from '../../components/Seo';
import messages from './messages';
import { RootState } from '../../store/reducer';

const NotFound = () => {
  const { language } = useSelector(
    (rootState: RootState) => rootState.language,
  );

  const notFoundMessages = messages[language].seo;

  return (
    <>
      <SEO
        title={notFoundMessages.title}
        description={notFoundMessages.description}
        keywords={notFoundMessages.keywords}
        page="/404"
      />
      <div className="px-6 py-4">
        <NotFoundComponent />
      </div>
    </>
  );
};

export default NotFound;
