export default function determineTimeRemaining(
  timeStamp: string,
  delayMs: number,
) {
  const rateLimitTimestampDate = new Date(timeStamp);
  const timeNow = new Date();
  const timeRemaining =
    // @ts-ignore
    Math.max(delayMs - (timeNow - rateLimitTimestampDate), 0);
  return timeRemaining;
}
