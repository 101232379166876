import { AppThunk } from '..';
import { Country } from '../../entities/country';
import apiCountryActions from '../../services/api-country-actions';
import { getCountriesEnd, getCountriesStart, setData } from './countries-slice';

/**
 * getCountries()
 *
 * Gets all of the active countries
 *
 * @returns void
 */
// eslint-disable-next-line import/prefer-default-export
export const getCountries = (): AppThunk => async dispatch => {
  try {
    dispatch(getCountriesStart());

    const data: Country[] | null = await apiCountryActions.getCountries();

    if (!data) {
      dispatch(getCountriesEnd());
      return;
    }

    const countries = data.map(country => country);

    dispatch(setData(countries));
  } catch (Ex) {
    console.error(Ex);
    dispatch(getCountriesEnd());
  }
};
