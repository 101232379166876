/* eslint-disable no-console */
import { SSOAuthProviderKey } from 'entities/sso';
import {
  ActivateProfileResponse,
  RegisterUserRequest,
  RegisterUserResponse,
  UpdateDetailsRequest,
  UpdateDetailsResponse,
  ValidateEmailTokenRequest,
  ValidateEmailTokenResponse,
  ValidatePromoCodeRequest,
  ValidatePromoCodeResponse,
} from '../entities/register';
import api from './api';

export const registerUser = async (
  request: RegisterUserRequest,
): Promise<RegisterUserResponse | null> => {
  try {
    const result = await api.post(`profile/register`, request);

    return { statusCode: 200, ...result.data };
  } catch (Exception: any) {
    console.error(Exception);
    return Exception.response.data;
  }
};

export const registerWithProvider = async (payload: {
  provider: SSOAuthProviderKey;
  ssoJwt: string;
  clientApplicationSsoCode: string;
}) => {
  const url = `profile/registerWithProvider`;
  try {
    const { data, status } = await api.post(url, {
      ssoJwt: payload.ssoJwt,
      clientApplicationSsoCode: payload?.clientApplicationSsoCode,
    });
    if (data) {
      return {
        data,
        status,
      };
    }
    return null;
  } catch (Exception: any) {
    console.error(Exception);
    return Exception.response.data;
  }
};

export const validateEmailToken = async (
  request: ValidateEmailTokenRequest,
): Promise<ValidateEmailTokenResponse | any> => {
  try {
    const result = await api.post(`profile/validateEmailToken`, request);
    return { statusCode: 200, ...result.data };
  } catch (Exception: any) {
    return Exception.response.data;
  }
};

export const validatePromoCode = async (
  request: ValidatePromoCodeRequest,
): Promise<ValidatePromoCodeResponse | null> => {
  try {
    const result = await api.post(`profile/validatePromoCode`, request);

    return { statusCode: 200, ...result.data };
  } catch (Exception: any) {
    console.error(Exception);
    return Exception.response.data;
  }
};

export const updateDetails = async (
  request: UpdateDetailsRequest,
): Promise<UpdateDetailsResponse | null> => {
  try {
    const result = await api.post(`profile/updateDetails`, request);

    return result.data;
  } catch (Exception: any) {
    console.error(Exception);
    return Exception.response.data;
  }
};

export const activateProfile = async (
  revixRoundUp: boolean | undefined,
  termsCodes: string[],
): Promise<ActivateProfileResponse> => {
  try {
    const result = await api.post(
      `profile/activate?revixRoundup=${revixRoundUp}`,
      termsCodes,
    );

    return result.data;
  } catch (Exception: any) {
    console.error(Exception);
    return Exception.response.data;
  }
};

export const registerWithGoogleService = async (payload: {
  ssoJwt: string;
}) => {
  const url = `signup/registerWithGoogle`;
  try {
    const { status, data } = await api.post(url, payload);
    console.log('signup/registerWithGoogle:', {
      status,
      data,
    });
    if (status === 200) {
      return data;
    }
    return data?.message;
  } catch (Exception: any) {
    console.log(
      'signup/registerWithGoogle:',
      JSON.stringify(
        {
          Exception,
        },
        null,
        2,
      ),
    );

    return Exception.response?.message;
  }
};
