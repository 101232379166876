export const RecurringBuyIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={46}
    height={46}
    fill="none"
    {...props}
  >
    <circle cx={23} cy={23} r={22.5} />
    <path
      fill="#626AFF"
      fillRule="evenodd"
      d="m16.414 20.683.017-.048a7.035 7.035 0 0 1 1.645-2.648 6.887 6.887 0 0 1 9.858 0l1.212 1.24h-2.142c-.567 0-1.034.467-1.034 1.057 0 .59.467 1.057 1.034 1.057h4.663c.567 0 1.034-.467 1.034-1.057v-4.75c0-.59-.467-1.058-1.034-1.058s-1.033.468-1.033 1.058v2.229L29.39 16.49a8.92 8.92 0 0 0-12.774 0 9.171 9.171 0 0 0-2.131 3.442 1.063 1.063 0 0 0 .63 1.35 1.028 1.028 0 0 0 1.3-.6Zm-2.367 4.015c-.164.05-.318.139-.442.268l-.003.003a1.025 1.025 0 0 0-.288.59v.006a.964.964 0 0 0-.013.165v4.733c0 .59.466 1.057 1.033 1.057s1.034-.467 1.034-1.057v-2.219l1.247 1.262a8.923 8.923 0 0 0 12.771 0 9.2 9.2 0 0 0 2.135-3.438 1.063 1.063 0 0 0-.631-1.35 1.029 1.029 0 0 0-1.315.644 7.033 7.033 0 0 1-1.645 2.648 6.886 6.886 0 0 1-9.859 0l-.004-.004-1.214-1.236h2.149c.567 0 1.034-.467 1.034-1.057 0-.59-.467-1.057-1.034-1.057h-4.65c-.055 0-.109.003-.163.01a.676.676 0 0 0-.133.029l-.01.003Z"
      clipRule="evenodd"
    />
  </svg>
);
