import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserTermDto } from '@services/generated';
import { PURGE } from 'redux-persist';

export type TermsAndConditions = {
  list: UserTermDto[];
  loading: boolean;
  error: any;
  success: boolean;
};

const defaultState: TermsAndConditions = {
  list: [],
  loading: false,
  error: null,
  success: false,
};

const termAndConditions = createSlice({
  name: 'termAndConditions',
  initialState: defaultState,
  extraReducers: builder => {
    builder.addCase(PURGE, state => {
      // customEntityAdapter.removeAll(state);
      state = defaultState;
    });
  },
  reducers: {
    setTermsList(
      state: TermsAndConditions,
      action: PayloadAction<UserTermDto[]>,
    ) {
      state.list = action.payload;
    },
    setTermsAndConditionsLoading(
      state: TermsAndConditions,
      action: PayloadAction<boolean>,
    ) {
      state.loading = action.payload;
    },
    setTermsAndConditionsError(
      state: TermsAndConditions,
      action: PayloadAction<any>,
    ) {
      state.error = action.payload;
    },
    setTermsAndConditionsSuccess(
      state: TermsAndConditions,
      action: PayloadAction<any>,
    ) {
      state.success = action.payload;
    },
    setTermsAndConditionsDefaults(state: TermsAndConditions) {
      state = defaultState;
    },
  },
});

export const {
  setTermsList,
  setTermsAndConditionsLoading,
  setTermsAndConditionsError,
  setTermsAndConditionsSuccess,
  setTermsAndConditionsDefaults,
} = termAndConditions.actions;
export default termAndConditions.reducer;
