const tradeStatus = {
  CryptoSourceProductType: 'Cryptocurrency',
  CryptoBundleType: 'Crypto Bundle',
  Locked: 'Locked',
  BundleType: 'bundle',
  CryptoType: 'crypto',
  FiatSourceProductType: 'Fiat',
  RewardsSourceProductType: 'Reward',
  PAXGSourceProducts: 'PAXG',
  PoundProduct: 'Pounds',
  RandsProduct: 'Rands',
  ZARProduct: 'ZAR',
  GBPProduct: 'GBP',
  CryptoDecimals: 8,
  BundleDecimals: 5,
  FiatDecimals: 2,
};

export default tradeStatus;
