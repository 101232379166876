import React from 'react';
import ReactDOM from 'react-dom/client';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import mixpanel from 'mixpanel-browser';
import './styles';
import App from './App';
import * as config from '../package.json';
import Error from './pages/Error';

Bugsnag.start({
  apiKey: import.meta.env.VITE_BUG_SNAG_API_KEY as string,
  plugins: [new BugsnagPluginReact()],
  releaseStage: import.meta.env.NODE_ENV || 'development',
  appVersion: config.version,
});

mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN as string, {
  debug: import.meta.env.VITE_ENV === 'development',
  ignore_dnt: true,
});

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    {typeof ErrorBoundary !== 'undefined' ? (
      <ErrorBoundary FallbackComponent={Error}>
        <App />
      </ErrorBoundary>
    ) : (
      <App />
    )}
  </React.StrictMode>,
);
