import axios from 'axios';

const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

export function setToken(token: string) {
  api.defaults.headers.Authorization = `bearer ${token}`;
}

/**
 * If the token has not expired, refreshToken is undefined and will not be executed.
 * Otherwise, refreshToken will execute, while the request waits for it to finish.
 */
export function addRefreshTokenInterceptor(
  refreshToken: () => Promise<unknown> | undefined,
) {
  const id = api.interceptors.request.use(async response => {
    if (refreshToken) {
      await refreshToken();
    }
    return response;
  });
  return () => api.interceptors.request.eject(id);
}

export function addUnauthorizedInterceptor(callback: () => void) {
  api.interceptors.response.use(
    response => response,
    error => {
      if (error?.response?.status === 401) {
        callback();
      }
      return Promise.reject(error);
    },
  );
}

export function convertToFormData(data: { [property: string]: any }): FormData {
  const formData = new FormData();
  const keys = Object.keys(data);

  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const value = data[key];

    // eslint-disable-next-line no-continue
    if (typeof value === 'function') continue;

    if (value.name) formData.append(key, data[key], value.name);
    else formData.append(key, data[key]);
  }

  return formData;
}

export default api;
