import { RootState } from '../store/reducer';

const STORE_KEY = 'revix/store';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setCachedStore(store: any) {
  localStorage.setItem(STORE_KEY, JSON.stringify(store));
}

export function getCachedStore(): RootState {
  const storeString = localStorage.getItem(STORE_KEY);
  return storeString ? JSON.parse(storeString) : undefined;
}

export function removeCachedStore(): void {
  localStorage.removeItem(STORE_KEY);
}
