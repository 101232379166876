import cdnApi from 'services/cdn-api';
import store, { AppThunk } from '..';
import {
  setAppLoading,
  setAppHasGlobalTimeFrame,
  setAppGlobalTimeFrame,
  setIsTourOpen,
  setTourSlide,
  setPageConfig,
} from './app-slice';
import { TimeFrameKey } from '../../entities/time-frame';
import * as config from './../../../package.json';

export const setHasGlobalTimeFrame =
  (value: boolean): AppThunk =>
  async dispatch => {
    try {
      dispatch(setAppHasGlobalTimeFrame(value));
    } catch (Exception: any) {
      console.error(Exception?.message);
    }
  };

export const setGlobalTimeFrame =
  (value: TimeFrameKey | string): AppThunk =>
  async dispatch => {
    try {
      dispatch(setAppGlobalTimeFrame(value));
    } catch (Exception: any) {
      console.error(Exception?.message);
    }
  };

export const setTourOpen =
  (value: boolean): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsTourOpen(value));
    } catch (Exception: any) {
      console.error(Exception?.message);
    }
  };

export const setSlide =
  (slide: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(setTourSlide(slide));
    } catch (Exception: any) {
      console.error(Exception?.message);
    }
  };

export const isAppDoneLoading = (): AppThunk => async dispatch => {
  try {
    const storeState = store.getState();

    if (
      storeState?.portfolio?.holdings?.[1]?.loading ||
      storeState?.portfolio?.holdings?.[7]?.loading ||
      storeState?.portfolio?.holdings?.[30]?.loading ||
      storeState?.portfolio?.holdings?.[365]?.loading ||
      storeState?.portfolio?.holdings?.all?.loading ||
      storeState?.rewards?.loading
    ) {
      dispatch(setAppLoading(true));
    } else if (storeState.app.loading === true) {
      dispatch(setAppLoading(false));
    }
  } catch (Exception: any) {
    console.error(Exception?.message);
  }
};

export const fetchPageConfigAction = (): AppThunk => async dispatch => {
  try {
    const response = await cdnApi.get(
      `/data/page_config.json?version=${config.version}`,
    );

    dispatch(setPageConfig(response.data));
  } catch (error) {
    // TODO: error handling
  }
};
