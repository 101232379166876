import Markdown from 'markdown-to-jsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { VerificationStep } from '../../entities/verification';
import { RootState } from '../../store/reducer';
import Button from '../Button';
import Dropdown from '../Forms/Dropdown';
import IntentCheckbox from './IntentCheckbox';
import messages from './messages';
import { IntentState } from './models/IntentState';

const IntentView = ({
  stepFundsInfo,
  stepInvestShortInfo,
  stepInvestLongInfo,
  onComplete,
}: {
  stepFundsInfo: VerificationStep;
  stepInvestShortInfo: VerificationStep;
  stepInvestLongInfo: VerificationStep;
  onComplete: (state: IntentState) => void;
}) => {
  const { language } = useSelector((state: RootState) => state.language);
  const [state, setState] = React.useState<IntentState>({
    investmentReasons: stepFundsInfo?.userOptions
      .filter(
        option =>
          option.display &&
          stepFundsInfo?.completedSubmissionFields?.[option.field] === 'yes',
      )
      .map(option => ({ name: option.field, checked: true })),
    ShortInvestmentAmount:
      stepInvestShortInfo?.completedSubmissionFields?.['invest_30'],
    LongTermInvestmentAMount:
      stepInvestLongInfo?.completedSubmissionFields?.['invest_365'],
  });
  const viewMessages = messages[language].intent;

  const { loading } = useSelector(
    (rootState: RootState) => rootState.verification,
  );

  function isStateValid(value: IntentState): boolean {
    if (value.investmentReasons.length === 0) return false;
    if (!value.LongTermInvestmentAMount) return false;
    if (!value.ShortInvestmentAmount) return false;
    return true;
  }
  function onChange(checked: boolean, value: string) {
    const { investmentReasons } = state;
    const index = investmentReasons.findIndex(x => x.name === value);
    if (checked && index === -1) {
      investmentReasons.push({ name: value, checked: true });
    } else if (index !== -1 && !checked) {
      investmentReasons.splice(index, 1);
    }
    setState({
      ...state,
      investmentReasons,
    });
  }

  return (
    <div className="sm:max-w-1xl sm:mx-auto px-4 sm:px-6 lg:px-8">
      <div className=" sm:w-full  mb-2">
        <h3 className="sm:text-center">{viewMessages.heading}</h3>
      </div>
      <div className=" sm:w-full  sm:mb-7 sm:mb-2 mb-3">
        <p className="sm:text-base text-xs sm:text-center">
          {viewMessages.toProtectYou}
        </p>
      </div>
      <div className="bg-background pb-4 pt-5 px-4 rounded-lg shadow-xl transition-all">
        <div className="mx-1 sm:px-3">
          <h5 className="sm:text-base text-xs">{stepFundsInfo.userMessage}</h5>
        </div>
        <div className="sm:mx-1 mx-0 mb-8 px-0 mt-4">
          {!stepFundsInfo.userOptions
            ? null
            : stepFundsInfo.userOptions
                .filter(x => x.display)
                .map(option => {
                  if (option.field === viewMessages.reason3) {
                    return (
                      <IntentCheckbox
                        checked={
                          state.investmentReasons.find(
                            o => o.name === option.field,
                          )?.checked ?? false
                        }
                        checkboxName={option.display}
                        checkboxValue={option.field}
                        onChange={onChange}
                        tooltip
                        tooltipText={viewMessages.reason3Tooltip}
                      />
                    );
                  }
                  if (option.field === viewMessages.reason4) {
                    return (
                      <IntentCheckbox
                        checked={
                          state.investmentReasons.find(
                            o => o.name === option.field,
                          )?.checked ?? false
                        }
                        checkboxName={option.display}
                        checkboxValue={option.field}
                        onChange={onChange}
                        tooltip
                        tooltipText={viewMessages.reason4Tooltip}
                      />
                    );
                  }

                  return (
                    <IntentCheckbox
                      checked={
                        state.investmentReasons.find(
                          o => o.name === option.field,
                        )?.checked ?? false
                      }
                      checkboxName={option.display}
                      checkboxValue={option.field}
                      onChange={onChange}
                    />
                  );
                })}
        </div>
        <div className="mx-1 sm:px-3">
          <h5 className="sm:text-base text-xs">
            <Markdown>{stepInvestShortInfo.userMessage}</Markdown>
          </h5>
        </div>
        <div className="sm:mx-1 mb-8 mx-0 px-3">
          <Dropdown
            className="bg-transparent border-0 border-b-2 form-input rounded-none sm:leading-5 sm:text-sm w-full py-4"
            placeholder={viewMessages.dropdownPlaceholder}
            options={
              stepInvestShortInfo.userOptions?.map(t => ({
                id: t.value,
                value: t.value,
                name: t.display,
              })) ?? []
            }
            darkTheme
            value={state.ShortInvestmentAmount}
            onChange={value => {
              setState({
                ...state,
                ShortInvestmentAmount: value as string,
              });
            }}
          />
        </div>
        <div className="mx-1 sm:px-3">
          <h5 className="sm:text-base text-sm">
            <Markdown>{stepInvestLongInfo.userMessage}</Markdown>
          </h5>
        </div>
        <div className="sm:mx-1 mx-0 px-3">
          <Dropdown
            className="bg-transparent border-0 border-b-2 form-input rounded-none sm:leading-5 sm:text-sm w-full py-4"
            placeholder={viewMessages.dropdownPlaceholder}
            options={
              stepInvestLongInfo.userOptions?.map(t => ({
                id: t.value,
                value: t.value,
                name: t.display,
              })) ?? []
            }
            darkTheme
            value={state.LongTermInvestmentAMount}
            onChange={value => {
              setState({
                ...state,
                LongTermInvestmentAMount: value as string,
              });
            }}
          />
        </div>
      </div>
      <div className="sm:py-5 py-4 flex flex-col">
        <Button
          className={`w-full rounded-lg duration-200 font-semibold focus:outline-none
                    inline-flex items-center text-white secondary-button-text h-12 justify-center py-3
                     text-lg bg-primary hover:bg-primary-hover flex-1`}
          loading={loading}
          onClick={() => {
            onComplete(state);
          }}
          disabled={!isStateValid(state)}>
          {viewMessages.continueButton}
        </Button>
      </div>
    </div>
  );
};

export default IntentView;
