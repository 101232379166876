/* eslint-disable import/prefer-default-export */
import { AppThunk } from '..';
import {
  UpdateEmailRequest,
  UpdateEmailResponse,
  UpdateEmailVerificationRequest,
  UpdateEmailVerificationResponse,
  UpdatePasswordRequest,
  UpdatePasswordResponse,
  UserProfileResponse,
} from '../../entities/profile';
import * as apiProfileActions from '../../services/api-profile-actions';
import {
  disableEmailNotificationEnd,
  disableEmailNotificationError,
  disableEmailNotificationStart,
  enableEmailNotificationEnd,
  enableEmailNotificationError,
  enableEmailNotificationStart,
  getUserProfileEnd,
  getUserProfileError,
  getUserProfileStart,
  setDisableEmailNotificationResponse,
  setEnableEmailNotificationResponse,
  setUpdateEmailResponse,
  setUpdateEmailVerificationResponse,
  setUpdatePasswordResponse,
  setUserProfileResponse,
  updateEmailEnd,
  updateEmailError,
  updateEmailStart,
  updateEmailVerificationEnd,
  updateEmailVerificationError,
  updateEmailVerificationStart,
  updatePasswordEnd,
  updatePasswordError,
  updatePasswordStart,
  setPendingLoading,
  setPendingError,
  setPendingErrorMessage,
  setCustomerValidToken,
  setSsoLinkingLoading,
  setSsoLinkingData,
  setSsoLinkingError,
  setLoginMethodsLoading,
  setLoginMethodsData,
  setLoginMethodsError,
} from './profile-slice';

export const getUserProfileAction = (): AppThunk => async dispatch => {
  try {
    dispatch(getUserProfileStart());

    const data: UserProfileResponse | null =
      await apiProfileActions.getUserProfile();

    if (!data) {
      dispatch(getUserProfileEnd());
      return;
    }

    dispatch(setUserProfileResponse(data));
  } catch (Exception: any) {
    console.error(Exception);
    dispatch(getUserProfileError(Exception?.message));
  }
};

export const enableEmailNotificationAction = (): AppThunk => async dispatch => {
  try {
    dispatch(enableEmailNotificationStart());

    const data: UserProfileResponse | null =
      await apiProfileActions.enableEmailNotification();

    if (!data) {
      dispatch(enableEmailNotificationEnd());
      return;
    }

    dispatch(setEnableEmailNotificationResponse(data));
  } catch (Exception: any) {
    console.error(Exception);
    dispatch(enableEmailNotificationError(Exception));
  }
};

export const disableEmailNotificationAction =
  (): AppThunk => async dispatch => {
    try {
      dispatch(disableEmailNotificationStart());

      const data: UserProfileResponse | null =
        await apiProfileActions.disableEmailNotification();

      if (!data) {
        dispatch(disableEmailNotificationEnd());
        return;
      }

      dispatch(setDisableEmailNotificationResponse(data));
    } catch (Exception: any) {
      console.error(Exception);
      dispatch(disableEmailNotificationError(Exception));
    }
  };

export const updateEmailAction =
  (request: UpdateEmailRequest): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateEmailStart());

      const data: UpdateEmailResponse | null | any =
        await apiProfileActions.updateEmail(request);

      if (!data) {
        dispatch(updateEmailEnd());
        return;
      }

      if (data?.statusCode >= 500 || data?.StatusCode >= 500) {
        dispatch(updateEmailError(data?.Message));
      } else {
        dispatch(setUpdateEmailResponse(data));
      }

      if (request.callback) request.callback(data);
    } catch (Exception: any) {
      console.error(Exception);
      dispatch(updateEmailError(Exception));
    }
  };

export const updatePasswordAction =
  (request: UpdatePasswordRequest): AppThunk =>
  async dispatch => {
    try {
      dispatch(updatePasswordStart());

      const data: UpdatePasswordResponse | null =
        await apiProfileActions.updatePassword(request);

      if (!data) {
        dispatch(updatePasswordEnd());
        return;
      }

      dispatch(setUpdatePasswordResponse(data));

      if (request.callback) request.callback(data);
    } catch (Exception: any) {
      console.error(Exception);
      dispatch(updatePasswordError(Exception));
    }
  };

export const updateEmailVerificationAction =
  (request: UpdateEmailVerificationRequest): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateEmailVerificationStart());

      const data: UpdateEmailVerificationResponse | null =
        await apiProfileActions.updateEmailVerification(request);

      if (!data) {
        dispatch(updateEmailVerificationEnd());
        return;
      }

      dispatch(setUpdateEmailVerificationResponse(data));

      if (request.callback) request.callback(data);
    } catch (Exception: any) {
      console.error(Exception);
      dispatch(updateEmailVerificationError(Exception));
    }
  };

export const checkCustomerToken =
  (token: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(setPendingLoading(true));

      const data = await apiProfileActions.validateToken(token);

      if (data.statusCode || data.StatusCode) {
        dispatch(setPendingLoading(true));
        dispatch(setPendingError(true));
        dispatch(setPendingErrorMessage(data.message));
        dispatch(setCustomerValidToken(false));
        return;
      }

      dispatch(setCustomerValidToken(true));
      dispatch(setPendingLoading(false));
    } catch (Exception: any) {
      dispatch(setPendingError(true));
      dispatch(setPendingErrorMessage(Exception?.message));
    }
  };

export const linkWithProviderAction =
  (token: string): AppThunk =>
  async dispatch => {
    dispatch(setSsoLinkingLoading(true));
    try {
      const data = await apiProfileActions.linkWithProvider(token);

      dispatch(setSsoLinkingData(data));
    } catch (Exception: any) {
      dispatch(setSsoLinkingError(Exception));
    } finally {
      dispatch(setSsoLinkingLoading(false));
    }
  };

export const getLoginMethodsAction = (): AppThunk => async dispatch => {
  dispatch(setLoginMethodsLoading(true));

  try {
    const data = await apiProfileActions.getLoginMethods();

    dispatch(setLoginMethodsData(data?.methods));
  } catch (Exception: any) {
    dispatch(setLoginMethodsError(Exception));
  } finally {
    dispatch(setLoginMethodsLoading(false));
  }
};
