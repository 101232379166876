import {
  ListContainer,
  OrderTypeListItem,
  Typography,
} from '@altify/components';
import { useContent } from '@altify/utils';

import { DialogOrBottomSheet } from 'components/DialogOrBottomSheet/DialogOrBottomSheet';
import { createCallable } from 'react-call';
import { useHistory } from 'react-router-dom';
import { tradeSharedContent } from '../tradeShared.content';
import { BuyNowIcon } from './Icons/BuyNowIcon';
import { RecurringBuyIcon } from './Icons/RecurringBuyIcon';
import { SellNowIcon } from './Icons/SellNowIcon';
import { content } from './OrderTypeModal.content';

export const OrderTypeModal = ({
  isOpen,
  productCode,
  onClose,
  filter,
}: {
  productCode: string;
  isOpen: boolean;
  onClose: () => void;
  filter?: 'buy' | 'sell';
}) => {
  const history = useHistory();
  const localizedContent = useContent(content, tradeSharedContent);
  const listItemColor = 'bg-[#1E2741]';

  const orderTypes = [
    {
      id: 1,
      className: listItemColor,
      iconComponent: BuyNowIcon,
      title: localizedContent.buyNow,
      subtitle: localizedContent.buyNowSubtitle,
      side: 'buy',
      onPress: () => {
        onClose?.();
        history.replace(`/trade/buy-now/${productCode ?? ''}`);
      },
    },
    {
      id: 2,
      className: listItemColor,
      iconComponent: RecurringBuyIcon,
      title: localizedContent.recurringBuy,
      subtitle: localizedContent.recurringBuySubtitle,
      side: 'buy',
      onPress: () => {
        onClose?.();
        history.replace(`/trade/recurring-buy/${productCode ?? ''}`);
      },
    },
    {
      id: 3,
      className: listItemColor,
      iconComponent: SellNowIcon,
      title: localizedContent.sellNow,
      subtitle: localizedContent.sellNowSubtitle,
      side: 'sell',
      onPress: () => {
        onClose?.();

        history.replace(`/trade/sell-now/${productCode ?? ''}`);
      },
    },
  ];

  const listItems =
    filter == null ? orderTypes : orderTypes.filter(o => o.side == filter);

  return (
    <DialogOrBottomSheet
      isOpen={isOpen}
      blurEnabled={true}
      onClose={onClose}
      backgroundColor="#0C1C2C"
    >
      <div className="my-4 w-full flex flex-col items-center px-4">
        <Typography variant="headline" size="h4" className="mb-6 text-white">
          {localizedContent.selectOrderType}
        </Typography>
        <ListContainer>
          {listItems.map(i => (
            <OrderTypeListItem {...i} key={i.id} />
          ))}
        </ListContainer>
      </div>
    </DialogOrBottomSheet>
  );
};

interface CallableProps {
  productCode: string;
  filter?: 'buy' | 'sell';
}

export const OrderTypeModalCallable = createCallable<CallableProps, boolean>(
  ({ call, productCode, filter }) => (
    <OrderTypeModal
      isOpen
      productCode={productCode}
      filter={filter}
      onClose={() => call.end(true)}
    />
  ),
);
