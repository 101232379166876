import React from 'react';
import verificationStatus from '../../data/verificationStatus';
import {
  SubmitVerificationInfo,
  VerificationStatusResponse,
} from '../../entities/verification';
import {
  useGetVerificationStatusInvestLong,
  useGetVerificationStatusInvestShort,
  useGetVerificationStatusUseOfFunds,
  useSubmitVerificationFunds,
  useSubmitVerificationLongInvest,
  useSubmitVerificationShortInvest,
} from '../../hooks/use-get-verification-status';
import IntentView from './IntentView';
import { IntentState } from './models/IntentState';
import { useAppDispatch } from 'hooks/use-app-dispatch';
import {
  getVerificationStatusInvestLongAction,
  getVerificationStatusInvestShortAction,
  getVerificationStatusUseOfFundsAction,
} from 'store/verification/verification-actions';

const Intent = ({ onComplete }: { onComplete: () => void }) => {
  const dispatch = useAppDispatch();
  const stepFundsInfo = useGetVerificationStatusUseOfFunds();
  const stepInvestShortInfo = useGetVerificationStatusInvestShort();
  const stepInvestLongInfo = useGetVerificationStatusInvestLong();
  const [submitVerificationFunds] = useSubmitVerificationFunds();
  const [submitVerificationShort] = useSubmitVerificationShortInvest();
  const [submitVerificationLong] = useSubmitVerificationLongInvest();
  const responses: VerificationStatusResponse[] = [];

  function addCallback(response: VerificationStatusResponse) {
    responses.push(response);
  }

  async function submitForm(viewState: IntentState) {
    const request: SubmitVerificationInfo = {
      fields: [],
      callback: addCallback,
    };
    const requestShort: SubmitVerificationInfo = {
      fields: [],
      callback: addCallback,
    };
    const requestLong: SubmitVerificationInfo = {
      fields: [],
      callback: addCallback,
    };

    viewState.investmentReasons.forEach(reason => {
      if (reason.checked) {
        request.fields.push({ key: reason.name, value: 'yes' });
      } else {
        request.fields.push({ key: reason.name, value: 'no' });
      }
    });

    stepFundsInfo.requiredSubmissionFields.forEach(field => {
      if (!request.fields.find(x => x.key === field))
        request.fields.push({ key: field, value: 'no' });
    });

    stepInvestShortInfo.requiredSubmissionFields.forEach(field => {
      requestShort.fields.push({
        key: field,
        value: viewState.ShortInvestmentAmount,
      });
    });

    stepInvestLongInfo.requiredSubmissionFields.forEach(field => {
      requestLong.fields.push({
        key: field,
        value: viewState.LongTermInvestmentAMount,
      });
    });

    await submitVerificationFunds(request);
    await submitVerificationShort(requestShort);
    await submitVerificationLong(requestLong);

    if (
      responses.filter(x => x.statusCode !== verificationStatus.Verified)
        .length === 0
    ) {
      dispatch(getVerificationStatusUseOfFundsAction());
      dispatch(getVerificationStatusInvestShortAction());
      dispatch(getVerificationStatusInvestLongAction());
      onComplete();
    }
  }

  return (
    <IntentView
      stepFundsInfo={stepFundsInfo}
      stepInvestShortInfo={stepInvestShortInfo}
      stepInvestLongInfo={stepInvestLongInfo}
      onComplete={submitForm}
    />
  );
};

export default Intent;
