interface Colors {
  [key: string]: string;
}
const colors: Colors = {
  'bg-dark-50': '#0C1C2CB3',
  'bg-light-50': '#F6F5FFB3',
  'blue-bayoux': '#525D7C',
  'blue-grey': '#2F3A5C',
  'cornflower-blue': '#4EA6F8',
  'cyan-light': '#53f5f5',
  'dark-blue': '#1E2741',
  'card-background': '#192037',
  'ghost-white': '#f6f5ff',
  'light-gray': '#F3F3F3',
  'lock-and-earn': '#43C4A6',
  'off-black': '#0C1C2C',
  'off-white': '#F9F9F9',
  'orange-red': '#FF430E',
  'register-border': '#e5e7eb',
  secondary: '#FFFFFF',
  'secondary-button': '#FFFFF00',
  'secondary-blue-hover': '#8A91FF',
  'secondary-blue': '#626BFF',
  'text-grey': '#A4A7C1',
  'vivid-blue-50': '#9096f6',
  'vivid-blue-hover': '#5157C9',
  'vivid-blue': '#626AFF',
  'primary-blue': '#626AFF',
  'primary-blue-gradient':
    'linear-gradient(100.55deg, #626AFF 0%, #626AFF 73.44%)',
  'price-green': '#23DDB2',
  'price-red': '#FF6D69',
  black: '#000000',
  cornflowerBlueAlpha: '#4EA6F819',
  darkGrey: '#6d728b',
  darkOrangeAlpha: '#F7931A19',
  green: '#1be56d',
  lightSteelBlue: '#A8AFCC',
  red: '#ff430e',
  sandybrownAlpha: '#FFC54E19',
  spearmint: '#1BE56D',
  tierBlueGradient: 'linear-gradient(0deg, #626AFF 0%, #626BFF 100%)',
  tierGoldGradient: 'linear-gradient(0deg,#FFC425 0%,#FFDF8A 100%)',
  tierPlatinumGradient: 'linear-gradient(0deg,#959595 0%,#F8F8F8  100%)',
  tierSilverGradient: 'linear-gradient(0deg,#484848 0%,#ACACAC 100%)',
  tierVIPGradient: 'linear-gradient(0deg, #5725ED 0%,#C521EE 100%)',
  violet: '#9421EE',
  alternative: '#9421EE',
  rvxVault: '#43C3A6',
  blueSteel: '#626AFF',
  rvx: '#626AFF',
  rvxGradient: 'linear-gradient(to right, #626AFF 0%, #626AFF 100%)',
  violetAlpha: '#9421EE19',
  vividBlueAlpha: '#626AFF19',
  white: '#FFFFFF',
  whiteAlpha: '#FFFFFF19',
  navy: '#101239',
  revix: '#626AFF',
  sectors: '#7125D4',
  lightBlue: '#626AFF',
};

export default colors;
