import * as React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store/reducer';
import { useSelector } from 'react-redux';
import usePageConfig from 'hooks/use-page-config';
import TopNavView from './TopNavView';

interface TopNavProps {
  children: React.ReactNode;
}

export type NavItem = {
  name: string;
  route: string;
};

const TopNav = (props: TopNavProps) => {
  // #region HOOKS
  const history = useHistory();
  const pageConfig = usePageConfig();
  // const { theme } = useAppTheme();
  const { theme } = useSelector((state: RootState) => state.theme);
  // #endregion

  // #region STATE
  const [menuOpen, setMenuOpen] = useState(false);
  const navItems = [
    {
      name: 'Explore Investments',
      route: `${pageConfig?.App?.SiteBaseUrl}/exploreinvestments`,
    },
    {
      name: 'Company',
      route: `${pageConfig?.App?.SiteBaseUrl}/about`,
    },
    {
      name: 'Support',
      route: `${pageConfig?.App?.SiteBaseUrl}/help-centre`,
    },
  ];
  // #endregion

  // #region HANDLERS
  const onLogin = () => {
    history.push('/login');
  };

  const register = () => {
    history.push('/register');
  };
  // #endregion

  return (
    <TopNavView
      onLogin={onLogin}
      register={register}
      menuOpen={menuOpen}
      navItems={navItems}
      setMenuOpen={setMenuOpen}
      theme={theme}
    >
      {props.children}
    </TopNavView>
  );
};

export default TopNav;
