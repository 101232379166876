/* eslint-disable @typescript-eslint/ban-ts-ignore */
// eslint:disable:no-var-requires
import ProfileVerification from 'components/ProfileVerification';
import { Route } from '.';
import NotFound from '../pages/404';
import asyncComponent from '../utils/async-component';

// PORTFOLIO
// const Portfolio = asyncComponent(() => import('../pages/Portfolio'));
const PortfolioV3 = asyncComponent(() => import('../pages/Portfolio/v3'));
// PRODUCTS
const ProductExplore = asyncComponent(
  () => import('pages/Products/ProductExplore'),
);
const ProductDetails = asyncComponent(
  () => import('pages/Products/v2/ProductDetails'),
);
// AUTH
const LoginView = asyncComponent(() => import('../pages/Login'));
const RegisterView = asyncComponent(() => import('../pages/Register'));
const ResetPasswordView = asyncComponent(
  () => import('../pages/ResetPassword'),
);
const CustomerPending = asyncComponent(
  () => import('../pages/Profile/Pending'),
);
const DocumentUploaded = asyncComponent(
  () => import('../pages/DocumentUploaded/DocumentUploaded'),
);
const VerificationError = asyncComponent(
  () => import('../pages/VerificationError/VerificationError'),
);
// PROFILE
const Profile = asyncComponent(() => import('../pages/Profile'));
const ProfileLoginMethod = asyncComponent(
  () => import('../pages/Profile/ProfileLoginMethod'),
);
const BankDetailsView = asyncComponent(() => import('../pages/BankDetails'));
const ResetTwoFactor = asyncComponent(() => import('../pages/ResetTwoFactor'));
const Funds = asyncComponent(() => import('../pages/Funds'));
const Rewards = asyncComponent(() => import('../pages/Rewards'));
const DepositFundsSelect = asyncComponent(
  () => import('../pages/Funds/DepositFundsSelect'),
);
const BankDeposit = asyncComponent(() => import('../pages/Funds/BankDeposit'));
const BankDepositPOP = asyncComponent(
  () => import('../pages/Funds/BankDepositPOP'),
);
const CryptoDepositV2 = asyncComponent(
  () => import('../pages/Funds/CryptoDeposit'),
);
const FiatDeposit = asyncComponent(() => import('../pages/Funds/FiatDeposit'));

const TwoFactor = asyncComponent(() => import('../pages/TwoFactor'));
const EnableOtp = asyncComponent(() => import('../pages/EnableOtp'));
const BTCView = asyncComponent(() => import('../pages/BtcDetails'));
const CryptoWalletView = asyncComponent(
  () => import('../pages/CryptoWalletDetails'),
);
const UpdateEmail = asyncComponent(() => import('../pages/UpdateEmail'));
const UpdateEmailVerification = asyncComponent(
  () => import('../pages/UpdateEmailVerification'),
);
const UpdatePassword = asyncComponent(() => import('../pages/UpdatePassword'));
// DEPOSIT
const CryptoDepositCodeV2 = asyncComponent(
  () => import('../pages/Funds/CryptoDepositCode'),
);
const CryptoDepositDisclaimerV2 = asyncComponent(
  () => import('../pages/Funds/CryptoDepositDisclaimer'),
);
const CryptoDepositComplete = asyncComponent(
  () => import('../pages/Funds/CryptoDepositComplete'),
);
// WITHDRAW
const WithdrawFundsSelect = asyncComponent(
  () => import('../pages/Funds/WithdrawFundsSelect'),
);
const BankWithdraw = asyncComponent(
  () => import('../pages/Funds/BankWithdraw'),
);
const BankWithdrawDetails = asyncComponent(
  () => import('../pages/Funds/BankWithdrawDetails'),
);
const BankWithdrawDetailsEdit = asyncComponent(
  () => import('../pages/Funds/BankWithdrawDetailsEdit'),
);
const BankWithdraw2FA = asyncComponent(
  () => import('../pages/Funds/BankWithdraw2FA'),
);
const BankWithdrawOtp = asyncComponent(
  () => import('../pages/Funds/BankWithdrawOtp'),
);
const BankWithdrawComplete = asyncComponent(
  () => import('../pages/Funds/BankWithdrawComplete'),
);
const CryptoWithdrawDetails = asyncComponent(
  () => import('../pages/Funds/CryptoWithdrawDetails'),
);
const CryptoWithdraw = asyncComponent(
  () => import('../pages/Funds/CryptoWithdraw'),
);
const CryptoWithdraw2FA = asyncComponent(
  () => import('../pages/Funds/CryptoWithdraw2FA'),
);
const CryptoWithdrawOtp = asyncComponent(
  () => import('../pages/Funds/CryptoWithdrawOtp'),
);
const CryptoWithdrawComplete = asyncComponent(
  () => import('../pages/Funds/CryptoWithdrawComplete'),
);
const WithdrawPending = asyncComponent(() => import('../pages/Funds/Pending'));

const TravelRule = asyncComponent(
  () => import('../pages/CryptoWithdrawal/TravelRule/TravelRule'),
);

const WithdrawCryptoWallet = asyncComponent(
  () =>
    import(
      '../pages/CryptoWithdrawal/WithdrawCryptoWallet/WithdrawCryptoWallet'
    ),
);

const WithdrawCryptoDisclaimer = asyncComponent(
  () =>
    import(
      '../pages/CryptoWithdrawal/WithdrawCryptoDisclaimer/WithdrawCryptoDisclaimer'
    ),
);

const WithdrawCryptoAccept = asyncComponent(
  () =>
    import(
      '../pages/CryptoWithdrawal/WithdrawCryptoAccept/WithdrawCryptoAccept'
    ),
);

const WithdrawCryptoConfirm = asyncComponent(
  () =>
    import(
      '../pages/CryptoWithdrawal/WithdrawCryptoConfirm/WithdrawCryptoConfirm'
    ),
);

const WithdrawCryptoAccountDetails = asyncComponent(
  () =>
    import(
      '../pages/CryptoWithdrawal/WithdrawCryptoAccountDetails/WithdrawCryptoAccountDetails'
    ),
);
// TRANSACTIONS
const PendingTransactions = asyncComponent(
  () => import('../pages/Transactions/PendingTransactions'),
);
const CompletedTransactions = asyncComponent(
  () => import('../pages/Transactions/CompletedTransactions'),
);
const AllTransactions = asyncComponent(
  () => import('../pages/Transactions/AllTransactions'),
);
const VaultTransactions = asyncComponent(
  () => import('../pages/Transactions/VaultTransactions'),
);
const RewardsTransactions = asyncComponent(
  () => import('../pages/Transactions/RewardsTransactions'),
);

// TRADE
const BuyNowScreenPage = asyncComponent(
  () => import('pages/Trade/TradeScreen/BuyNowScreen'),
);
const SellNowScreenPage = asyncComponent(
  () => import('pages/Trade/TradeScreen/SellNowScreen'),
);
const RecurringBuycreenPage = asyncComponent(
  () => import('pages/Trade/RecurringBuys/RecurringBuyScreen'),
);

const RecurringBuysOverviewScreenPage = asyncComponent(
  () => import('pages/RecurringBuy/screens/RecurringBuys/RecurringBuys'),
);
/**
 * If a route is a sub-route of another, the parent must pass down the child route via its
 * children property.
 */
const routes: Route[] = [
  {
    path: '/404',
    Component: NotFound,
  },
  {
    path: '/dashboard',
    Component: PortfolioV3,
  },
  {
    path: '/login',
    Component: LoginView,
    public: true,
  },
  {
    path: '/register',
    Component: RegisterView,
    public: true,
  },
  {
    path: '/reset-password',
    Component: ResetPasswordView,
    public: true,
  },
  {
    path: '/profile/bank-details',
    Component: BankDetailsView,
    public: false,
  },
  {
    path: '/reset-two-factor',
    Component: ResetTwoFactor,
    public: true,
  },
  {
    path: '/products/explore/:type?',
    Component: ProductExplore,
    public: false,
  },
  {
    path: '/products/:productCode',
    Component: ProductDetails,
    public: false,
  },
  {
    path: '/funds/add/select/eft/:currency/pop',
    Component: BankDepositPOP,
    public: false,
  },
  {
    path: '/funds/add/select/fiat/eft/:currency',
    Component: BankDeposit,
    public: false,
  },
  {
    path: '/funds/add/select/crypto/:currency/complete',
    Component: CryptoDepositComplete,
    public: false,
  },
  {
    path: '/funds/add/select/crypto/code/:currency',
    Component: CryptoDepositCodeV2,
    public: false,
  },
  {
    path: '/funds/add/select/crypto/disclaimer/:currency',
    Component: CryptoDepositDisclaimerV2,
    public: false,
  },
  {
    path: '/funds/add/select/crypto',
    Component: CryptoDepositV2,
    public: false,
  },
  {
    path: '/funds/add/select/fiat',
    Component: FiatDeposit,
    public: false,
  },
  {
    path: '/funds/add/select',
    Component: DepositFundsSelect,
    public: false,
  },
  {
    path: '/funds/withdraw/:currencyType/:currency/pending',
    Component: WithdrawPending,
    public: false,
  },
  {
    path: '/funds/withdraw/fiat/2FA',
    Component: BankWithdraw2FA,
    public: false,
  },
  {
    path: '/funds/withdraw/fiat/OTP',
    Component: BankWithdrawOtp,
    public: false,
  },
  {
    path: '/funds/withdraw/fiat/:currency/bank-details-review',
    Component: BankWithdrawDetails,
    public: false,
  },
  {
    path: '/funds/withdraw/fiat/bank-details',
    Component: BankWithdrawDetailsEdit,
    public: false,
  },
  {
    path: '/funds/withdraw/fiat/:currency/complete',
    Component: BankWithdrawComplete,
    public: false,
  },
  {
    path: '/funds/withdraw/fiat/:currency',
    Component: BankWithdraw,
    public: false,
  },
  {
    path: '/funds/withdraw/crypto/:productCode/disclaimer',
    Component: WithdrawCryptoDisclaimer,
    public: false,
  },
  {
    path: '/funds/withdraw/crypto/:productCode/accept',
    Component: WithdrawCryptoAccept,
    public: false,
  },
  {
    path: '/funds/withdraw/crypto/:productCode/wallet',
    Component: WithdrawCryptoWallet,
    public: false,
  },
  {
    path: '/funds/withdraw/crypto/:productCode/travel-rule',
    Component: TravelRule,
    public: false,
  },
  {
    path: '/funds/withdraw/crypto/:productCode/account-details',
    Component: WithdrawCryptoAccountDetails,
    public: false,
  },
  {
    path: '/funds/withdraw/crypto/:currency/complete',
    Component: CryptoWithdrawComplete,
    public: false,
  },
  {
    path: '/funds/withdraw/crypto/:productCode/confirm',
    Component: WithdrawCryptoConfirm,
    public: false,
  },
  {
    path: '/funds/withdraw/crypto/2FA',
    Component: CryptoWithdraw2FA,
    public: false,
  },
  {
    path: '/funds/withdraw/crypto/OTP',
    Component: CryptoWithdrawOtp,
    public: false,
  },
  {
    path: '/funds/withdraw/crypto/:currency/details-edit',
    Component: CryptoWalletView,
    public: false,
  },
  {
    path: '/funds/withdraw/crypto/:currency/details',
    Component: CryptoWithdrawDetails,
    public: false,
  },
  {
    path: '/funds/withdraw/crypto/:currency',
    Component: CryptoWithdraw,
    public: false,
  },
  {
    path: '/funds/withdraw',
    Component: WithdrawFundsSelect,
    public: false,
  },
  {
    path: '/trade/buy-now/:productCode?',
    Component: BuyNowScreenPage,
    public: false,
  },
  {
    path: '/trade/sell-now/:productCode?',
    Component: SellNowScreenPage,
    public: false,
  },
  {
    path: '/trade/recurring-buy/:productCode?',
    Component: RecurringBuycreenPage,
    public: false,
  },
  {
    path: '/funds',
    Component: Funds,
    public: false,
  },
  {
    path: '/profile/bitcoin-address',
    Component: BTCView,
    public: false,
  },
  {
    path: '/profile/wallet/:productCode',
    Component: CryptoWalletView,
    public: false,
  },
  {
    path: '/profile/otp',
    Component: EnableOtp,
    public: false,
  },
  {
    path: '/profile/two-factor',
    Component: TwoFactor,
    public: false,
  },
  {
    path: '/profile/update-email/verify',
    Component: UpdateEmailVerification,
    public: false,
  },
  {
    path: '/profile/update-email',
    Component: UpdateEmail,
  },
  {
    path: '/profile/update-password',
    Component: UpdatePassword,
    public: false,
  },
  {
    path: '/profile/login-method',
    Component: ProfileLoginMethod,
  },
  {
    path: '/profile/verification',
    Component: ProfileVerification,
    public: false,
  },
  {
    path: '/profile',
    Component: Profile,
    public: false,
  },
  {
    path: '/customer/:verificationType/pending',
    Component: CustomerPending,
    public: false,
  },
  {
    path: '/rewards',
    Component: Rewards,
    public: false,
  },
  {
    path: '/document-uploaded',
    Component: DocumentUploaded,
    public: true,
  },
  {
    path: '/verification-error',
    Component: VerificationError,
    public: true,
  },
  {
    path: '/transactions/pending',
    Component: PendingTransactions,
    public: false,
  },
  {
    path: '/transactions/rewards',
    Component: RewardsTransactions,
    public: false,
  },
  {
    path: '/transactions/completed',
    Component: CompletedTransactions,
    public: false,
  },
  {
    path: '/transactions/all',
    Component: AllTransactions,
    public: false,
  },
  {
    path: '/transactions/vault',
    Component: VaultTransactions,
    public: false,
  },
  {
    path: '/recurring-buys',
    Component: RecurringBuysOverviewScreenPage,
    public: false,
  },
];

export default routes;
