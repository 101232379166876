/* eslint-disable @typescript-eslint/ban-ts-ignore */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import store from '../store';
import { getCountries } from '../store/countries/countries-actions';
import { RootState } from '../store/reducer';

const useCountry = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    // @ts-ignore
    const { countries } = store.getState();

    if (countries.data && countries.data.length > 0) return;

    dispatch(getCountries());
  }, [dispatch]);

  // @ts-ignore
  const data = useSelector((state: RootState) => state.countries.data);

  // @ts-ignore
  const countries = Array.from(data).sort((a, b) => (a.name > b.name ? 1 : -1));

  return countries;
};

export default useCountry;
