import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MetricState {
  metrics: any;
}

const initialMetricState: MetricState = {
  metrics: {},
};

const metric = createSlice({
  name: 'metric',
  initialState: initialMetricState,
  reducers: {
    setMetrics(state, action: PayloadAction<any>) {
      // eslint-disable-next-line no-param-reassign
      state.metrics = action.payload;
    },
  },
});

export const { setMetrics } = metric.actions;

export default metric.reducer;
