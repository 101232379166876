import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducer';
import Button from '../Button';
import messages from './messages';
import WhatYouWillNeedCard from './WhatYouWillNeedCard';

const WhatYouWillNeedView = ({ onComplete }: { onComplete: () => void }) => {
  const { language } = useSelector((state: RootState) => state.language);
  const profile = useSelector((state: RootState) => state.profile?.userProfileResponse);

  const viewMessages = messages[language].whatYouWillNeed;

  return (
    <div className="sm:max-w-1xl sm:mx-auto px-4 sm:px-6 lg:px-8">
      <div className=" sm:w-full  sm:mb-7 mb-2">
        <h3 className="text-center">{viewMessages.heading}</h3>
      </div>
      <div className="bg-background overflow-hidden pb-4 pt-5 px-4 rounded-lg shadow-xl transition-all">
        <div className="mx-1 sm:px-3">
          <h5 className="sm:text-base text-md">{viewMessages.subheading}</h5>
        </div>
        <WhatYouWillNeedCard itemNumber={1} text={viewMessages.requirement1(profile?.countryCode)} />
        <WhatYouWillNeedCard itemNumber={2} text={viewMessages.requirement2} />
        <div className="sm:mx-1 sm:px-3 px-1 mb-1 mt-1">
          <h5 className="sm:text-base text-sm">{viewMessages.whyDoINeed}</h5>
        </div>
        <div className="sm:mx-5 mx-0 px-3">
          <ul className="why-verify-list sm:text-base text-xs">
            <li>{viewMessages.reason1}</li>
            <li>{viewMessages.reason2}</li>
          </ul>
        </div>
      </div>
      <div className="sm:py-5 py-4 flex flex-col">
        <Button
          className="w-full rounded-lg duration-200 font-semibold focus:outline-none
                    inline-flex items-center text-white secondary-button-text h-12 justify-center py-3
                     text-lg bg-primary hover:bg-primary-hover flex-1"
          onClick={onComplete}
        >
          {viewMessages.continueButton}
        </Button>
      </div>
      <div className="sm:py-5 flex flex-col">
        <div className="sm:text-1.06x1 text-center">
          {viewMessages.ifSaveAndExit}
        </div>
      </div>
    </div>
  );
};

export default WhatYouWillNeedView;
