/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FundFiatDepositCreate } from '../../entities/fund-fiat-deposit-create';
import { FundFiatPending } from '../../entities/fund-fiat-pending';
import { FundFiatWithdrawalCreate } from '../../entities/fund-fiat-withdrawal-create';
import { FundProduct } from '../../entities/fund-product';
import { FundProductDeposit } from '../../entities/fund-product-deposit';
import { FundProductWithdraw } from '../../entities/fund-product-withdraw';
import { FundWithdrawItem } from '../../entities/fund-withdraw-item';

export interface FundsState {
  loading: boolean;
  fundsAction: string;
  depositInit: boolean;
  pendingDepositProducts: FundFiatPending[];
  pendingDepositProductsLoading: boolean;
  pendingDepositProductsError: any;
  depositProducts: FundProduct[];
  depositType: string;
  depositCurrency: string;
  depositProduct: FundProductDeposit;
  depositAmount: number | string;
  depositProductPending: FundFiatDepositCreate;
  depositSuccess: boolean;
  pendingWithdrawFiatProducts: any[];
  pendingWithdrawCryptoProducts: any[];
  pendingWithdrawFiatProductsLoading: boolean;
  pendingWithdrawCryptoProductsLoading: boolean;
  withdrawInit: boolean;
  withdrawProducts: FundWithdrawItem[];
  withdrawProductsLoading: boolean;
  withdrawProduct: FundProductWithdraw;
  withdrawProductData: FundWithdrawItem;
  withdrawProductDataLoading: boolean;
  withdrawAmount: number | string;
  withdrawFee: number;
  withdrawAmountCrypto: number | string;
  withdrawCurrency: string;
  withdrawPendingData: FundFiatWithdrawalCreate;
  withdrawSuccess: boolean;
  withdrawValidToken: boolean;
  error: boolean;
  errorMessage: string;
}

const initialFundsState: FundsState = {
  loading: true,
  fundsAction: '',
  // Deposit
  depositInit: false,
  pendingDepositProducts: [],
  pendingDepositProductsLoading: true,
  pendingDepositProductsError: {},
  depositProducts: [],
  depositType: '',
  depositCurrency: '',
  depositProduct: {},
  depositAmount: 0,
  depositProductPending: {},
  depositSuccess: false,
  // Withdraw
  withdrawInit: false,
  pendingWithdrawFiatProducts: [],
  pendingWithdrawCryptoProducts: [],
  pendingWithdrawFiatProductsLoading: true,
  pendingWithdrawCryptoProductsLoading: true,
  withdrawProducts: [],
  withdrawProductsLoading: true,
  withdrawProduct: {},
  withdrawProductData: {},
  withdrawProductDataLoading: true,
  withdrawAmount: 0,
  withdrawFee: 0,
  withdrawAmountCrypto: 0,
  withdrawCurrency: '',
  withdrawPendingData: {},
  withdrawSuccess: false,
  withdrawValidToken: false,
  error: false,
  errorMessage: '',
};

const fund = createSlice({
  name: 'fund',
  initialState: initialFundsState,
  reducers: {
    setFundsLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setFundsAction(state, action: PayloadAction<string>) {
      state.fundsAction = action.payload;
    },
    setDepositInit(state, action: PayloadAction<boolean>) {
      state.depositInit = action.payload;
    },
    setPendingDepositProducts(state, action: PayloadAction<FundFiatPending[]>) {
      state.pendingDepositProducts = action.payload;
    },
    setPendingDepositProductsLoading(state, action: PayloadAction<boolean>) {
      state.pendingDepositProductsLoading = action.payload;
    },
    setPendingDepositProductsError(state, action: PayloadAction<any>) {
      state.pendingDepositProductsError = action.payload;
    },
    setDepositProducts(state, action: PayloadAction<FundProduct[]>) {
      state.depositProducts = action.payload;
    },
    setDepositType(state, action: PayloadAction<string>) {
      state.depositType = action.payload;
    },
    setDepositCurrency(state, action: PayloadAction<string>) {
      state.depositCurrency = action.payload;
    },
    setDepositData(state, action: PayloadAction<FundProductDeposit>) {
      state.depositProduct = action.payload;
    },
    setDepositAmount(state, action: PayloadAction<number | string>) {
      state.depositAmount = action.payload;
    },
    setDepositPendingData(state, action: PayloadAction<FundFiatDepositCreate>) {
      state.depositProductPending = action.payload;
    },
    setDepositSuccess(state, action: PayloadAction<boolean>) {
      state.depositSuccess = action.payload;
    },
    setPendingWithdrawFiatProducts(state, action: PayloadAction<any[]>) {
      state.pendingWithdrawFiatProducts = action.payload;
    },
    setPendingWithdrawCryptoProducts(state, action: PayloadAction<any[]>) {
      state.pendingWithdrawCryptoProducts = action.payload;
    },
    setPendingWithdrawFiatProductsLoading(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.pendingWithdrawFiatProductsLoading = action.payload;
    },
    setPendingWithdrawCryptoProductsLoading(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.pendingWithdrawCryptoProductsLoading = action.payload;
    },
    setWithdrawInit(state, action: PayloadAction<boolean>) {
      state.withdrawInit = action.payload;
    },
    setWithdrawProducts(state, action: PayloadAction<FundWithdrawItem[]>) {
      state.withdrawProducts = action.payload;
    },
    setWithdrawProductsLoading(state, action: PayloadAction<boolean>) {
      state.withdrawProductsLoading = action.payload;
    },
    setWithdrawData(state, action: PayloadAction<FundProductWithdraw>) {
      state.withdrawProduct = action.payload;
    },
    setWithdrawProductData(state, action: PayloadAction<FundWithdrawItem>) {
      state.withdrawProductData = action.payload;
    },
    setWithdrawProductDataLoading(state, action: PayloadAction<boolean>) {
      state.withdrawProductDataLoading = action.payload;
    },
    setWithdrawAmount(state, action: PayloadAction<number | string>) {
      state.withdrawAmount = action.payload;
    },
    setWithdrawFee(state, action: PayloadAction<number>) {
      state.withdrawFee = action.payload;
    },
    setWithdrawAmountCrypto(state, action: PayloadAction<number | string>) {
      state.withdrawAmountCrypto = action.payload;
    },
    setWithdrawCurrency(state, action: PayloadAction<string>) {
      state.withdrawCurrency = action.payload;
    },
    setWithdrawPendingData(
      state,
      action: PayloadAction<FundFiatWithdrawalCreate>,
    ) {
      state.withdrawPendingData = action.payload;
    },
    setWithdrawSuccess(state, action: PayloadAction<boolean>) {
      state.withdrawSuccess = action.payload;
    },
    setError(state, action: PayloadAction<boolean>) {
      state.error = action.payload;
    },
    setErrorMessage(state, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    setWithdrawValidToken(state, action: PayloadAction<boolean>) {
      state.withdrawValidToken = action.payload;
    },
    resetError(state) {
      state.errorMessage = '';
      state.error = false;
    },
  },
});

export const {
  setFundsLoading,
  setFundsAction,
  setDepositInit,
  setPendingDepositProducts,
  setPendingDepositProductsLoading,
  setPendingDepositProductsError,
  setDepositProducts,
  setDepositType,
  setDepositCurrency,
  setDepositData,
  setDepositAmount,
  setDepositPendingData,
  setDepositSuccess,
  setWithdrawInit,
  setPendingWithdrawFiatProducts,
  setPendingWithdrawCryptoProducts,
  setPendingWithdrawFiatProductsLoading,
  setPendingWithdrawCryptoProductsLoading,
  setWithdrawProducts,
  setWithdrawProductsLoading,
  setWithdrawData,
  setWithdrawProductData,
  setWithdrawProductDataLoading,
  setWithdrawAmount,
  setWithdrawFee,
  setWithdrawAmountCrypto,
  setWithdrawCurrency,
  setWithdrawPendingData,
  setWithdrawSuccess,
  setError,
  setErrorMessage,
  setWithdrawValidToken,
  resetError,
} = fund.actions;

export default fund.reducer;
