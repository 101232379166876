import { createApi } from '@reduxjs/toolkit/query/react';
import { dummyBaseQuery } from '../services/helpers';

export const rootApi = createApi({
  reducerPath: 'api',
  baseQuery: dummyBaseQuery,
  tagTypes: [
    'UserTerms',
    'RecurringBuys',
    'CustomerAccount',
    'CustomerPersonalData',
  ],
  endpoints: builder => ({}),
});
