export type OnboardingModalItem = {
  image: string;
  text: string;
};

const onboardingModalItems: OnboardingModalItem[] = [
  {
    image: 'numberOneIcon.svg',
    text: 'step1text',
  },
  {
    image: 'numberTwoIcon.svg',
    text: 'step2text',
  },
  {
    image: 'numberThreeIcon.svg',
    text: 'step3text',
  },
];

export default onboardingModalItems;
