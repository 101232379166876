/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ProductWalletDetails,
  ProductWalletDetailsResponse,
} from '../../entities/productWalletDetails';

export interface ProductWalletDetailsState {
  loading: boolean;
  error?: boolean;
  errorMessage?: any;
  productWalletDetails: { [key in string]: ProductWalletDetails };
  productWalletDetailsResponse: ProductWalletDetailsResponse;
}

const initialState: ProductWalletDetailsState = {
  loading: false,
  error: false,
  errorMessage: '',
  productWalletDetails: {
    BTC: {
      address: '',
      name: '',
      accountId: '',
      twofaPin: '',
      productCode: '',
    },
    USDT: {
      address: '',
      name: '',
      accountId: '',
      twofaPin: '',
      productCode: '',
    },
  },
  productWalletDetailsResponse: {
    message: '',
    statusCode: 0,
    userId: '',
  },
};

const productWalletDetails = createSlice({
  name: 'productWalletDetails',
  initialState,
  reducers: {
    addProductWalletDetailsStart(state) {
      state.loading = true;
      state.error = false;
      state.errorMessage = '';
    },
    addProductWalletDetailsEnd(state) {
      state.loading = false;
    },
    addProductWalletDetailsError(
      state,
      action: PayloadAction<ProductWalletDetailsResponse | any>,
    ) {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload;
      state.productWalletDetailsResponse = action.payload;
    },
    setAddProductWalletDetailsResponse(
      state,
      action: PayloadAction<ProductWalletDetailsResponse>,
    ) {
      state.productWalletDetailsResponse = action.payload;
    },
    getProductWalletDetailsStart(state) {
      state.loading = true;
    },
    getProductWalletDetailsEnd(state) {
      state.loading = false;
    },
    getProductWalletDetailsError(
      state,
      action: PayloadAction<ProductWalletDetails>,
    ) {
      const { productCode } = action.payload;
      state.loading = false;
      state.productWalletDetails[productCode as string] = action.payload;
    },
    getAddProductWalletDetailsData(
      state,
      action: PayloadAction<ProductWalletDetails>,
    ) {
      const { productCode } = action.payload;
      state.loading = false;
      state.productWalletDetails[productCode as string] = action.payload;
    },
    resetProductWalletDetailsError(state) {
      state.error = false;
      state.errorMessage = '';
    },
  },
});

export const {
  addProductWalletDetailsStart,
  addProductWalletDetailsEnd,
  setAddProductWalletDetailsResponse,
  addProductWalletDetailsError,
  getAddProductWalletDetailsData,
  getProductWalletDetailsEnd,
  getProductWalletDetailsError,
  getProductWalletDetailsStart,
  resetProductWalletDetailsError,
} = productWalletDetails.actions;

export default productWalletDetails.reducer;
