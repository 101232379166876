import { FundProduct } from 'entities/fund-product';
import { FundProductDeposit } from 'entities/fund-product-deposit';
import { FundFiatDepositCreate } from 'entities/fund-fiat-deposit-create';
import { FundWithdrawItem } from 'entities/fund-withdraw-item';
import { FundProductWithdraw } from 'entities/fund-product-withdraw';
import { FundFiatPending } from 'entities/fund-fiat-pending';
import { FundTokenValidate } from 'entities/fund-token-validate';
import { PaymentMadeRequest, PaymentMadeResponse } from 'entities/payment-made';
import api, { convertToFormData } from './api';

export const getDepositCryptoProducts = async (): Promise<
  FundProduct[] | null
> => {
  try {
    const result = await api.get(`deposit/crypto/products`, {});
    return result.data;
  } catch (Exception: any) {
    // eslint-disable-next-line no-console
    console.error(Exception);
    return null;
  }
};

export const getDepositFiatProductData = async (): Promise<
  FundProductDeposit | any
> => {
  try {
    const result = await api.get(`deposit/fiat`, {});
    return result.data;
  } catch (Exception: any) {
    return Exception.response.data;
  }
};

export const setDepositCryptoProductData = async (
  productCode: string,
): Promise<FundProductDeposit | any> => {
  try {
    const result = await api.post(`deposit/crypto/${productCode}`, {});
    return result.data;
  } catch (Exception: any) {
    // eslint-disable-next-line no-console
    console.log(Exception);
    return Exception.response.data;
  }
};

export const getDepositCryptoProductData = async (
  productCode: string,
): Promise<FundProductDeposit | any> => {
  try {
    const result = await api.get(`deposit/crypto/${productCode}`, {});
    return result.data;
  } catch (Exception: any) {
    // eslint-disable-next-line no-console
    console.log(Exception);
    return Exception.response.data;
  }
};

export const postHideInstructions = async (): Promise<any | null> => {
  try {
    return await api.post(`deposit/fiat/hideInstructions`, {});
  } catch (Exception: any) {
    // eslint-disable-next-line no-console
    console.error(Exception);
    return null;
  }
};

export const postHideStablecoinNotice = async (
  productCode: string,
): Promise<any | null> => {
  try {
    return await api.post(`deposit/crypto/hideStablecoinNotice/${productCode}`);
  } catch (Exception: any) {
    console.error(Exception);
    return Exception;
  }
};

export const createFiatDeposit = async (
  amount: number,
  fee?: number,
): Promise<FundFiatDepositCreate | any> => {
  try {
    const result = await api.post(`deposit/fiat`, {
      amount,
      quotedFee: fee,
    });
    return result.data;
  } catch (Exception: any) {
    return Exception.response.data;
  }
};

export const depositPaymentMade = async (
  request: PaymentMadeRequest,
): Promise<PaymentMadeResponse> => {
  try {
    const formData = convertToFormData(request);

    const result = await api.post(`/deposit/fiat/paymentMade`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return { statusCode: 200, ...result.data };
  } catch (Exception: any) {
    // eslint-disable-next-line no-console
    console.error(Exception);
    return Exception?.response?.data || Exception;
  }
};

export const cancelFiatDeposit = async (
  orderId: string,
): Promise<any | null> => {
  try {
    const response = await api.delete(`/deposit/fiat/${orderId}/cancel`, {});
    return response;
  } catch (Exception: any) {
    // eslint-disable-next-line no-console
    console.error(Exception);
    return Exception?.response?.data;
  }
};

export const cancelCryptoDeposit = async (
  orderId: string,
): Promise<any | null> => {
  try {
    return await api.delete(`/deposit/crypto/${orderId}/cancel`, {});
  } catch (Exception: any) {
    // eslint-disable-next-line no-console
    console.error(Exception);
    return null;
  }
};

export const cancelWithdraw = async (
  orderId: string,
  currencyType: string,
): Promise<any | null> => {
  try {
    return await api.delete(`/withdraw/${currencyType}/${orderId}/cancel`, {});
  } catch (Exception: any) {
    // eslint-disable-next-line no-console
    console.error(Exception);
    return null;
  }
};

export const depositPaymentMadeCrypto = async (
  orderId: string,
): Promise<any | null> => {
  try {
    return await api.post(`/deposit/crypto/${orderId}/depositMade`, {});
  } catch (Exception: any) {
    // eslint-disable-next-line no-console
    console.error(Exception);
    return null;
  }
};

export const getFiatWithdrawProducts = async (): Promise<
  FundWithdrawItem[] | null
> => {
  try {
    const result = await api.get(`/withdraw/products`, {});
    return result.data;
  } catch (Exception: any) {
    // eslint-disable-next-line no-console
    console.error(Exception);
    return null;
  }
};

export const getWithdrawFiatProductData = async (
  productCode: string,
): Promise<FundProductWithdraw | null> => {
  try {
    const result = await api.get(`/withdraw/fiat/${productCode}`, {});
    return result.data;
  } catch (Exception: any) {
    // eslint-disable-next-line no-console
    console.error(Exception);
    return null;
  }
};

export const getWithdrawCryptoProductData = async (
  productCode: string,
): Promise<FundProductWithdraw | null> => {
  try {
    const result = await api.get(
      `/withdraw/crypto?productCode=${productCode}`,
      {},
    );
    return result.data;
  } catch (Exception: any) {
    // eslint-disable-next-line no-console
    console.error(Exception);
    return null;
  }
};

export const getPendingDepositFiatData = async (): Promise<
  FundFiatPending[] | null
> => {
  try {
    const result = await api.get('/deposit/fiat/pending', {});
    return result.data;
  } catch (Exception: any) {
    // eslint-disable-next-line no-console
    console.error(Exception);
    return null;
  }
};

export const getWithdrawCryptoProducts = async (): Promise<
  FundProduct[] | null
> => {
  try {
    const result = await api.get(`withdraw/crypto/products`, {});
    return result.data;
  } catch (Exception: any) {
    // eslint-disable-next-line no-console
    console.error(Exception);
    return null;
  }
};

export const getPendingWithdrawFiatData = async (): Promise<
  FundFiatPending[] | any
> => {
  try {
    const result = await api.get('/withdraw/fiat/pending', {});
    return result.data;
  } catch (Exception: any) {
    return Exception.response.data;
  }
};

export const getPendingWithdrawCryptoData = async (): Promise<
  FundFiatPending[] | any
> => {
  try {
    const result = await api.get('/withdraw/crypto/pending', {});
    return result.data;
  } catch (Exception: any) {
    return Exception.response.data;
  }
};

export const validateToken = async (
  token: string,
): Promise<FundTokenValidate | any> => {
  try {
    const result = await api.post(`/customer/vt/${token}`, {});
    return result.data;
  } catch (Exception: any) {
    return Exception.response.data;
  }
};

export const createWithdrawal = async (data: {
  withdrawalType: string;
  amount: number;
  quotedFeeAmount: number;
  accountId: string;
  productCode?: string;
  emailOtp: string;
  otpType?: string;
  deviceOtp?: string;
  otpFlowTypeCode: string;
  acceptAbsolution?: boolean;
}) => {
  try {
    const response = await api.post(`/withdraw/${data?.withdrawalType}`, {
      amount: data.amount,
      quotedFeeAmount: data.quotedFeeAmount,
      accountId: data.accountId,
      ...(data.productCode && { productCode: data.productCode }),
      twoFactorPins: {
        ...(data.otpType && { [data.otpType]: data.deviceOtp }),
        EMAIL: data.emailOtp,
      },
      productCode: data.productCode,
      otpFlowTypeCode: data.otpFlowTypeCode,
      acceptAbsolution: data?.acceptAbsolution || false,
    });
    return response;
  } catch (Exception: any) {
    return Exception?.response?.data;
  }
};
