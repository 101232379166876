import { createContent } from '@altify/utils';

export const tradeSharedContent = createContent({
  en: {
    cancel: 'Cancel',
    yes: 'Yes',
    no: 'No',
    buy: 'Buy',
    buyNow: 'Buy Now',
    sellNow: 'Sell Now',
    sell: 'Sell',
    for: 'For',
    rewardLevel: 'Reward Level',
    bought: "You've Bought",
    genericTradeError: 'Your trade could not execute',
    genericActionError: 'Your action could not execute, please try again',
    reviewSell: 'Review Sell',
    reviewBuy: 'Review Buy',
    using: 'Using',
    recurringBuy: 'Recurring Buy',
    available: 'available',
    exceedsBalanceOf: (amount: string) => `Exceeds balance of ${amount}`,
    enterAMinimumOf: (amount: string) => `Enter a minimum of ${amount}`,
    lastPrice: 'Last Price',
    select: 'Select',
    tradeError:
      'Your trade cannot be completed.\nPlease try again in a few minutes.',
    dontShowAgain: `Don't show me this again`,
    iUnderstand: 'I understand',
    and: 'and',
  },
  de: {
    cancel: 'Abbrechen',
    yes: 'Ja',
    no: 'Nein',
    from: 'Von',
    to: 'Zu',
    buy: 'Kaufe',
    buyNow: 'Sofortkauf',
    sellNow: 'Sofortverkauf',
    sell: 'Verkaufe',
    recurringBuy: 'Wiederkeherender Kauf',
    reviewSell: 'Verkauf überprüfen',
    for: 'Für',
    rewardLevel: 'Reward Level',
    bought: 'Du hast gekauft',
    genericTradeError:
      'Dein Trade ist fehlgeschlagen, bitte versuche es erneut',
    genericActionError:
      'Dein Aktion ist fehlgeschlagen, bitte versuche es erneut',
    reviewBuy: 'Kauf überprüfen',
    using: 'Mit',
    available: 'verfügbar',
    exceedsBalanceOf: (amount: string) =>
      `Überschreitet Guthaben von ${amount}`,
    enterAMinimumOf: (amount: string) => `Gib ein Minimum von ${amount} ein`,
    lastPrice: 'Letzter Preis',
    select: 'Auswählen',
    tradeError: 'Dein Trade kann zurzeit nicht ausgeführt werden',
    dontShowAgain: 'Nicht mehr anzeigen',
    iUnderstand: 'Ich verstehe',
    and: 'und',
  },
});
