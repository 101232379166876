/* eslint-disable import/prefer-default-export */
/* eslint-disable no-plusplus */
import {
  RewardsDetails,
  RewardsDetailsResponse,
  Tiers,
  Referral,
} from '../entities/rewardsDetails';
import api from './api';

export const redeemRewards =
  async (): Promise<RewardsDetailsResponse | null> => {
    try {
      const result = await api.post(`/rewards/redeem`, {});

      return { statusCode: result.status, ...result.data };
    } catch (Exception: any) {
      console.error(Exception);
      return Exception.response.data;
    }
  };

export const enterPromo = async (
  request: string,
): Promise<RewardsDetailsResponse | null> => {
  try {
    const result = await api.post(`rewards/enterpromo`, {
      code: request,
    });

    return { statusCode: result.status, ...result.data };
  } catch (Exception: any) {
    console.error(Exception);
    return Exception.response.data;
  }
};

export const getRewardsDetails = async (): Promise<RewardsDetails | null> => {
  try {
    const result = await api.get(`/rewards/detailed`);

    return result.data;
  } catch (Exception: any) {
    console.error(Exception);
    return Exception.response.data;
  }
};

export const getTiersDetails = async (): Promise<Tiers[] | null> => {
  try {
    const result = await api.get(`/rewards/tiers`);

    return result.data;
  } catch (Exception: any) {
    console.error(Exception);
    return Exception.response.data;
  }
};

export const getRewardReferrals = async (
  queryParams: string,
): Promise<Referral[] | null> => {
  try {
    const result = await api.get(`/rewards/referrals?${queryParams}`, {});

    return result.data;
  } catch (Exception: any) {
    console.error(Exception);
    return Exception;
  }
};
