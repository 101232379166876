import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SubmitVerificationInfo,
  VerificationStatusResponse,
} from '../entities/verification';
import {
  getVerificationStatusAction,
  getVerificationStatusUseOfFundsAction,
  getVerificationStatusInvestLongAction,
  getVerificationStatusInvestShortAction,
  getVerificationStatusEmploymentAction,
  getVerificationStatusInvestorLevelAction,
  getVerificationStatusSourceAction,
  getVerificationStatusPorAction,
  getVerificationStatusIdAction,
  submitVerificationFieldsFunds,
  submitVerificationFieldsLongInvest,
  submitVerificationFieldsShortInvest,
  submitVerificationFieldsEmployment,
  submitVerificationFieldsLevel,
  submitVerificationFieldsSource,
  submitVerificationFieldsAttachment,
  submitVerificationFieldsPor,
  submitVerificationFieldsPorAttachment,
  submitVerificationFieldsId,
} from '../store/verification/verification-actions';
import { RootState } from '../store/reducer';

const useGetVerificationStatus = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getVerificationStatusAction());
  }, [dispatch]);

  const data = useSelector((state: RootState) => state.verification.status);

  return data;
};

const useGetVerificationStatusUseOfFunds = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getVerificationStatusUseOfFundsAction());
  }, [dispatch]);

  const data = useSelector(
    (state: RootState) => state.verification.verificationStepFunds,
  );

  return data;
};

const useGetVerificationStatusInvestShort = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getVerificationStatusInvestShortAction());
  }, [dispatch]);

  const data = useSelector(
    (state: RootState) => state.verification.verificationStepInvestShort,
  );

  return data;
};

const useGetVerificationStatusInvestLong = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getVerificationStatusInvestLongAction());
  }, [dispatch]);

  const data = useSelector(
    (state: RootState) => state.verification.verificationStepInvestLong,
  );

  return data;
};

const useGetVerificationStatusEmployment = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getVerificationStatusEmploymentAction());
  }, [dispatch]);

  const data = useSelector(
    (state: RootState) => state.verification.verificationStepEmployment,
  );

  return data;
};

const useGetVerificationStatusInvestorLevel = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getVerificationStatusInvestorLevelAction());
  }, [dispatch]);

  const data = useSelector(
    (state: RootState) => state.verification.verificationStepInvestorLevel,
  );

  return data;
};

const useGetVerificationStatusSource = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getVerificationStatusSourceAction());
  }, [dispatch]);

  const data = useSelector(
    (state: RootState) => state.verification.verificationStepSource,
  );

  return data;
};

const useGetVerificationStatusPor = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getVerificationStatusPorAction());
  }, [dispatch]);

  const data = useSelector(
    (state: RootState) => state.verification.verificationStepPOR,
  );

  return data;
};

const useGetVerificationStatusId = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getVerificationStatusIdAction());
  }, [dispatch]);

  const data = useSelector(
    (state: RootState) => state.verification.verificationStepID,
  );

  return data;
};

const useSubmitVerificationFunds = () => {
  const dispatch = useDispatch();

  const submitVerificationFunds = async (request: SubmitVerificationInfo) => {
    await dispatch(submitVerificationFieldsFunds(request));
  };

  return [submitVerificationFunds];
};

const useSubmitVerificationShortInvest = () => {
  const dispatch = useDispatch();

  const submitVerificationShortInvest = async (
    request: SubmitVerificationInfo,
  ) => {
    await dispatch(submitVerificationFieldsShortInvest(request));
  };

  return [submitVerificationShortInvest];
};

const useSubmitVerificationLongInvest = () => {
  const dispatch = useDispatch();

  const submitVerificationLongInvest = async (
    request: SubmitVerificationInfo,
  ) => {
    await dispatch(submitVerificationFieldsLongInvest(request));
  };

  return [submitVerificationLongInvest];
};

const useSubmitVerificationEmployment = () => {
  const dispatch = useDispatch();

  const submitVerificationEmployment = async (
    request: SubmitVerificationInfo,
  ) => {
    await dispatch(submitVerificationFieldsEmployment(request));
  };

  return [submitVerificationEmployment];
};

const useSubmitVerificationLevel = () => {
  const dispatch = useDispatch();

  const submitVerificationLevel = async (request: SubmitVerificationInfo) => {
    await dispatch(submitVerificationFieldsLevel(request));
  };

  return [submitVerificationLevel];
};

const useSubmitVerificationSource = () => {
  const dispatch = useDispatch();

  const submitVerificationSource = async (request: SubmitVerificationInfo) => {
    await dispatch(submitVerificationFieldsSource(request));
  };

  return [submitVerificationSource];
};

const useSubmitVerificationAttachment = () => {
  const dispatch = useDispatch();

  const submitVerificationAttachment = async (request: File) => {
    await dispatch(submitVerificationFieldsAttachment(request));
  };

  return [submitVerificationAttachment];
};

const useSubmitVerificationPor = () => {
  const dispatch = useDispatch();

  const submitVerificationPor = async (request: SubmitVerificationInfo) => {
    await dispatch(submitVerificationFieldsPor(request));
  };

  return [submitVerificationPor];
};

const useSubmitVerificationPorAttachment = () => {
  const dispatch = useDispatch();

  const submitVerificationPorAttachment = async (
    request: File,
    callback?: (response: VerificationStatusResponse) => void,
  ) => {
    await dispatch(submitVerificationFieldsPorAttachment(request, callback));
  };

  return [submitVerificationPorAttachment];
};

const useSubmitVerificationId = () => {
  const dispatch = useDispatch();

  const submitVerificationid = async (request: SubmitVerificationInfo) => {
    await dispatch(submitVerificationFieldsId(request));
  };

  return [submitVerificationid];
};

export {
  useGetVerificationStatus,
  useGetVerificationStatusUseOfFunds,
  useGetVerificationStatusInvestShort,
  useGetVerificationStatusInvestLong,
  useGetVerificationStatusEmployment,
  useGetVerificationStatusInvestorLevel,
  useGetVerificationStatusSource,
  useSubmitVerificationId,
  useSubmitVerificationFunds,
  useSubmitVerificationShortInvest,
  useSubmitVerificationLongInvest,
  useSubmitVerificationEmployment,
  useSubmitVerificationLevel,
  useSubmitVerificationSource,
  useSubmitVerificationAttachment,
  useGetVerificationStatusPor,
  useGetVerificationStatusId,
  useSubmitVerificationPor,
  useSubmitVerificationPorAttachment,
};
