import React from 'react';
import { useSelector } from 'react-redux';
import { VerificationStep } from '../../entities/verification';
import { RootState } from '../../store/reducer';
import Button from '../Button';
import Dropdown from '../Forms/Dropdown';
import messages from './messages';
import { EmploymentState } from './models/EmploymentState';
// import Modal from '../Modal';
// import CustomLoader from '../CustomLoader';

const EmploymentView = ({
  employmentInfo,
  investorLevelInfo,
  onComplete,
}: {
  employmentInfo: VerificationStep;
  investorLevelInfo: VerificationStep;
  onComplete: (state: EmploymentState) => void;
}) => {
  const { language } = useSelector((state: RootState) => state.language);

  const [state, setState] = React.useState<EmploymentState>({
    employment: employmentInfo?.completedSubmissionFields?.['employmentStatus'],
    investorLevel: investorLevelInfo?.completedSubmissionFields?.['level'],
  });

  const viewMessages = messages[language].employment;

  const { loading } = useSelector(
    (rootState: RootState) => rootState.verification,
  );

  function isStateValid(value: EmploymentState): boolean {
    if (!value.employment) return false;
    if (!value.investorLevel) return false;
    return true;
  }

  return (
    <div className="sm:max-w-1xl sm:mx-auto px-4 sm:px-6 lg:px-8">
      <div className=" sm:w-full  mb-2">
        <h3 className="sm:text-center">{viewMessages.heading}</h3>
      </div>
      <div className=" sm:w-full  sm:mb-7 sm:mb-2 mb-3">
        <p className="sm:text-base text-xs sm:text-center">
          {viewMessages.toProtectYou}
        </p>
      </div>
      <div className="bg-background pb-4 pt-5 px-4 rounded-lg shadow-xl transition-all">
        <div className="mx-1 sm:px-3">
          <h5 className="sm:text-base text-xs">{employmentInfo.userMessage}</h5>
        </div>
        <div className="sm:mx-1 mb-8 mx-0 px-3">
          <Dropdown
            className="bg-transparent border-0 border-b-2 form-input rounded-none sm:leading-5 sm:text-sm w-full py-4"
            placeholder={viewMessages.dropdownPlaceholder}
            options={employmentInfo?.userOptions?.map(t => ({
              id: t.value,
              value: t.value,
              name: t.display,
            }))}
            value={state.employment}
            onChange={value => {
              setState({
                ...state,
                employment: value as string,
              });
            }}
          />
        </div>
        <div className="mx-1 sm:px-3">
          <h5 className="sm:text-base text-sm">
            {investorLevelInfo.userMessage}
          </h5>
        </div>
        <div className="sm:mx-1 mx-0 px-3">
          <Dropdown
            className="bg-transparent border-0 border-b-2 form-input rounded-none sm:leading-5 sm:text-sm w-full py-4"
            placeholder={viewMessages.dropdownPlaceholder}
            options={investorLevelInfo?.userOptions?.map(t => ({
              id: t.value,
              value: t.value,
              name: t.display,
            }))}
            value={state.investorLevel}
            onChange={value => {
              setState({
                ...state,
                investorLevel: value as string,
              });
            }}
          />
        </div>
      </div>
      <div className="sm:py-5 py-4 flex flex-col">
        <Button
          className={`w-full rounded-lg duration-200 font-semibold focus:outline-none
                    inline-flex items-center text-white secondary-button-text h-12 justify-center py-3
                     text-lg bg-primary hover:bg-primary-hover flex-1`}
          loading={loading}
          onClick={() => {
            onComplete(state);
          }}
          disabled={!isStateValid(state)}>
          {viewMessages.continueButton}
        </Button>
      </div>
    </div>
  );
};

export default EmploymentView;
