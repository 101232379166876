/* eslint-disable @typescript-eslint/ban-ts-ignore */
import { AppThunk } from '..';
import tradeStatus from '../../data/trade';
import { FundWithdrawItem } from '../../entities/fund-withdraw-item';
import { PaymentMadeRequest } from '../../entities/payment-made';
import * as apiFunds from '../../services/api-funds';
import {
  setDepositAmount,
  setDepositCurrency,
  setDepositData,
  setDepositInit,
  setDepositPendingData,
  setDepositProducts,
  setDepositType,
  setError,
  setErrorMessage,
  setFundsAction,
  setFundsLoading,
  setPendingDepositProducts,
  setPendingDepositProductsError,
  setPendingDepositProductsLoading,
  setPendingWithdrawCryptoProducts,
  setPendingWithdrawCryptoProductsLoading,
  setPendingWithdrawFiatProducts,
  setPendingWithdrawFiatProductsLoading,
  setWithdrawAmount,
  setWithdrawAmountCrypto,
  setWithdrawCurrency,
  setWithdrawData,
  setWithdrawFee,
  setWithdrawInit,
  setWithdrawPendingData,
  setWithdrawProductData,
  setWithdrawProductDataLoading,
  setWithdrawProducts,
  setWithdrawProductsLoading,
  setWithdrawValidToken,
} from './funds-slice';

export const setFundAction =
  (action: string): AppThunk =>
  dispatch => {
    try {
      // dispatch(setFundsLoading(true));
      dispatch(setFundsAction(action));
      // dispatch(setFundsLoading(false));
    } catch (Exception: any) {
      dispatch(setError(true));
      dispatch(setErrorMessage(Exception?.message));
    }
  };

export const setDepositTypeAction =
  (type: string): AppThunk =>
  dispatch => {
    try {
      // dispatch(setFundsLoading(true));
      dispatch(setDepositType(type));
      // dispatch(setFundsLoading(false));
    } catch (Exception: any) {
      dispatch(setError(true));
      dispatch(setErrorMessage(Exception?.message));
    }
  };

export const setDepositInitialize = (): AppThunk => dispatch => {
  try {
    // dispatch(setFundsLoading(true));
    dispatch(setDepositInit(true));
    dispatch(setWithdrawInit(false));
    // dispatch(setFundsLoading(false));
  } catch (Exception: any) {
    dispatch(setError(true));
    dispatch(setErrorMessage(Exception?.message));
  }
};

export const setPendingDepositProductsData = (): AppThunk => async dispatch => {
  try {
    dispatch(setPendingDepositProductsLoading(true));
    const data = await apiFunds.getPendingDepositFiatData();

    if (!data) {
      dispatch(setPendingDepositProductsLoading(false));
      return;
    }

    dispatch(setPendingDepositProducts(data));
    dispatch(setPendingDepositProductsLoading(false));
  } catch (Exception: any) {
    dispatch(setError(true));
    dispatch(setErrorMessage(Exception?.message));
  }
};

export const setCryptoDepositProductsAction =
  (): AppThunk => async dispatch => {
    try {
      dispatch(setFundsLoading(true));
      const data = await apiFunds.getDepositCryptoProducts();

      if (!data) {
        dispatch(setFundsLoading(false));
        return;
      }

      dispatch(setDepositProducts(data));
      dispatch(setFundsLoading(false));
    } catch (Exception: any) {
      dispatch(setError(true));
      dispatch(setErrorMessage(Exception?.message));
    }
  };

export const setDepositCurrencyData =
  (currency: string): AppThunk =>
  dispatch => {
    try {
      dispatch(setFundsLoading(true));
      dispatch(setDepositCurrency(currency));
      dispatch(setFundsLoading(false));
    } catch (Exception: any) {
      dispatch(setError(true));
      dispatch(setErrorMessage(Exception?.message));
    }
  };

export const setDepositCurrencyAction =
  (
    currency: string,
    isCrypto = false,
    isPendingCryptoOrder = false,
  ): AppThunk =>
  dispatch => {
    try {
      dispatch(setFundsLoading(true));
      dispatch(setDepositCurrency(currency));
      if (!isCrypto) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        dispatch(setDepositDataAction());
      }
      if (isCrypto) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        if (isPendingCryptoOrder) {
          dispatch(getCryptoDepositDataAction(currency));
        } else {
          dispatch(setCryptoDepositDataAction(currency));
        }
      }
    } catch (Exception: any) {
      dispatch(setError(true));
      dispatch(setErrorMessage(Exception?.message));
    }
  };

export const setDepositDataAction = (): AppThunk => async dispatch => {
  try {
    dispatch(setFundsLoading(true));
    const data = await apiFunds.getDepositFiatProductData();

    if (data.statusCode || data.StatusCode) {
      dispatch(setFundsLoading(true));
      dispatch(setError(true));
      dispatch(setErrorMessage(data.message));
      return;
    }

    dispatch(setDepositData(data));
    dispatch(setFundsLoading(false));
  } catch (Exception: any) {
    dispatch(setError(true));
    dispatch(setErrorMessage(Exception?.message));
  }
};

export const setDepositAmountAction =
  (amount: number | string): AppThunk =>
  async dispatch => {
    try {
      // dispatch(setFundsLoading(true));
      dispatch(setDepositAmount(amount));
      // dispatch(setFundsLoading(false));
    } catch (Exception: any) {
      dispatch(setError(true));
      dispatch(setErrorMessage(Exception?.message));
    }
  };

export const setCryptoDepositDataAction =
  (productCode: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(setFundsLoading(true));
      //we do this to clean up from previous deposit data
      dispatch(setDepositData({}));
      const data = await apiFunds.setDepositCryptoProductData(productCode);

      if (data.statusCode || data.StatusCode) {
        dispatch(setError(true));
        dispatch(setErrorMessage(data.message));
        dispatch(setFundsLoading(false));
        return;
      }

      dispatch(setDepositData(data));
      dispatch(setFundsLoading(false));
    } catch (Exception: any) {
      dispatch(setError(true));
      dispatch(setErrorMessage(Exception?.message));
      dispatch(setFundsLoading(false));
    }
  };

export const getCryptoDepositDataAction =
  (productCode: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(setFundsLoading(true));
      //we do this to clean up from previous deposit data
      dispatch(setDepositData({}));
      const data = await apiFunds.getDepositCryptoProductData(productCode);

      if (data.statusCode || data.StatusCode) {
        dispatch(setError(true));
        dispatch(setErrorMessage(data.message));
        dispatch(setFundsLoading(false));
        return;
      }

      dispatch(setDepositData(data));
      dispatch(setFundsLoading(false));
    } catch (Exception: any) {
      dispatch(setError(true));
      dispatch(setErrorMessage(Exception?.message));
      dispatch(setFundsLoading(false));
    }
  };

export const hideInstructionsAction = (): AppThunk => async dispatch => {
  try {
    dispatch(setFundsLoading(true));
    await apiFunds.postHideInstructions();
    dispatch(setFundsLoading(false));
  } catch (Exception: any) {
    dispatch(setError(true));
    dispatch(setErrorMessage(Exception?.message));
  }
};

export const setDepositPendingDataAction =
  (currency: string, amount: number, fee?: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(setFundsLoading(true));
      const data = await apiFunds.createFiatDeposit(amount, fee);

      if (data.statusCode || data.StatusCode) {
        dispatch(setFundsLoading(true));
        dispatch(setError(true));
        dispatch(setErrorMessage(data.message));
        return;
      }

      dispatch(
        setDepositPendingData({
          ...data,
          quotedFee: fee,
        }),
      );
      dispatch(setFundsLoading(false));
    } catch (Exception: any) {
      dispatch(setError(true));
      dispatch(setErrorMessage(Exception?.message));
    }
  };

export const clearDepositPendingDataAction = (): AppThunk => async dispatch => {
  try {
    dispatch(setFundsLoading(true));
    dispatch(setDepositPendingData({}));
    dispatch(setFundsLoading(false));
  } catch (Exception: any) {
    dispatch(setError(true));
    dispatch(setErrorMessage(Exception?.message));
  }
};

export const setPaymentMadeAction =
  (request: PaymentMadeRequest): AppThunk =>
  async dispatch => {
    try {
      dispatch(setFundsLoading(true));

      const data = await apiFunds.depositPaymentMade(request);

      if (!data || data.statusCode !== 200) {
        // eslint-disable-next-line no-unused-expressions
        request.errorCallback?.(data);
        if (data && data.statusCode === 401) dispatch(setError(true));
        return;
      }

      if (request.callback) request.callback(data);

      dispatch(setFundsLoading(false));
    } catch (Exception: any) {
      request.errorCallback?.(Exception);
      dispatch(setError(true));
      dispatch(setErrorMessage(Exception?.message));
    }
  };

export const setPaymentMadeCryptoAction =
  (orderId: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(setFundsLoading(true));
      await apiFunds.depositPaymentMadeCrypto(orderId);
      dispatch(setFundsLoading(false));
    } catch (Exception: any) {
      dispatch(setError(true));
      dispatch(setErrorMessage(Exception?.message));
    }
  };

export const setPaymentCancelAction =
  (orderId: string, callback?: Function): AppThunk =>
  async dispatch => {
    try {
      dispatch(setFundsLoading(true));
      const data = await apiFunds.cancelFiatDeposit(orderId);
      dispatch(setPendingDepositProductsData());
      if (data.statusCode >= 400) {
        dispatch(setPendingDepositProductsError(data));
      }
      dispatch(setFundsLoading(false));
      if (callback) callback();
    } catch (Exception: any) {
      dispatch(setError(true));
      dispatch(setErrorMessage(Exception?.message));
    }
  };

export const setPaymentCryptoCancelAction =
  (orderId: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(setFundsLoading(true));
      await apiFunds.cancelCryptoDeposit(orderId);
      dispatch(setFundsLoading(false));
    } catch (Exception: any) {
      dispatch(setError(true));
      dispatch(setErrorMessage(Exception?.message));
    }
  };

export const setWithdrawInitialize = (): AppThunk => dispatch => {
  try {
    // dispatch(setFundsLoading(true));
    dispatch(setDepositInit(false));
    dispatch(setWithdrawInit(true));
    // dispatch(setFundsLoading(false));
  } catch (Exception: any) {
    dispatch(setError(true));
    dispatch(setErrorMessage(Exception?.message));
  }
};

export const setPendingWithdrawFiatProductsData =
  (callback?: Function): AppThunk =>
  async dispatch => {
    try {
      dispatch(setPendingWithdrawFiatProductsLoading(true));
      const data = await apiFunds.getPendingWithdrawFiatData();

      if (data.statusCode || data.StatusCode) {
        dispatch(setPendingWithdrawFiatProductsLoading(true));
        dispatch(setError(true));
        dispatch(setErrorMessage(data.message));
        return;
      }

      dispatch(setPendingWithdrawFiatProducts(data));
      dispatch(setPendingWithdrawFiatProductsLoading(false));

      if (callback) {
        callback(data);
      }
    } catch (Exception: any) {
      dispatch(setError(true));
      dispatch(setErrorMessage(Exception?.message));
    }
  };

export const setPendingWithdrawCryptoProductsData =
  (): AppThunk => async dispatch => {
    try {
      dispatch(setPendingWithdrawCryptoProductsLoading(true));
      const data = await apiFunds.getPendingWithdrawCryptoData();

      if (data.statusCode || data.StatusCode) {
        dispatch(setPendingWithdrawCryptoProductsLoading(true));
        dispatch(setError(true));
        dispatch(setErrorMessage(data.message));
        return;
      }

      dispatch(setPendingWithdrawCryptoProducts(data));
      dispatch(setPendingWithdrawCryptoProductsLoading(false));
    } catch (Exception: any) {
      dispatch(setError(true));
      dispatch(setErrorMessage(Exception?.message));
    }
  };

export const setCancelWithdrawAction =
  (orderId: string, currencyType: string, callback?: Function): AppThunk =>
  async dispatch => {
    try {
      dispatch(setFundsLoading(true));
      await apiFunds.cancelWithdraw(orderId, currencyType);
      dispatch(setFundsLoading(false));
      dispatch(setPendingWithdrawFiatProductsData());
      dispatch(setPendingWithdrawCryptoProductsData());
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      dispatch(setFiatWithdrawProductsAction());
      if (callback) callback();
    } catch (Exception: any) {
      dispatch(setError(true));
      dispatch(setFundsLoading(false));
      dispatch(setErrorMessage(Exception?.message));
      if (callback) callback();
    }
  };

export const setFiatWithdrawProductsAction = (): AppThunk => async dispatch => {
  try {
    dispatch(setWithdrawProductsLoading(true));
    const data = await apiFunds.getFiatWithdrawProducts();

    if (!data) {
      dispatch(setWithdrawProductsLoading(false));
      return;
    }
    dispatch(setWithdrawProducts(data));
    dispatch(setWithdrawProductsLoading(false));
  } catch (Exception: any) {
    dispatch(setError(true));
    dispatch(setWithdrawProductsLoading(false));
    dispatch(setErrorMessage(Exception?.message));
  }
};

export const setWithdrawDataAction =
  (currency: string, type: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(setFundsLoading(true));
      let data;

      if (type === tradeStatus.FiatSourceProductType) {
        data = await apiFunds.getWithdrawFiatProductData(currency);
      }

      if (type === tradeStatus.CryptoSourceProductType) {
        data = await apiFunds.getWithdrawCryptoProductData(currency);
      }

      if (!data) {
        dispatch(setFundsLoading(false));
        return;
      }

      dispatch(setWithdrawData(data));
      dispatch(setFundsLoading(false));
    } catch (Exception: any) {
      dispatch(setError(true));
      dispatch(setFundsLoading(false));
      dispatch(setErrorMessage(Exception?.message));
    }
  };

export const setWithdrawProduct =
  (product: FundWithdrawItem): AppThunk =>
  async dispatch => {
    try {
      dispatch(setWithdrawProductDataLoading(true));
      dispatch(setWithdrawProductData(product));
      dispatch(setWithdrawProductDataLoading(false));
    } catch (Exception: any) {
      dispatch(setError(true));
      dispatch(setWithdrawProductDataLoading(false));
      dispatch(setErrorMessage(Exception?.message));
    }
  };

export const setWithdrawAmountAction =
  (amount: number | string): AppThunk =>
  async dispatch => {
    try {
      // dispatch(setFundsLoading(true));
      dispatch(setWithdrawAmount(amount));
      // dispatch(setFundsLoading(false));
    } catch (Exception: any) {
      dispatch(setError(true));
      dispatch(setErrorMessage(Exception?.message));
    }
  };

export const setWithdrawFeeAction =
  (amount: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(setFundsLoading(true));
      dispatch(setWithdrawFee(amount));
      dispatch(setFundsLoading(false));
    } catch (Exception: any) {
      dispatch(setError(true));
      dispatch(setFundsLoading(false));
      dispatch(setErrorMessage(Exception?.message));
    }
  };

export const setWithdrawCryptoAmountAction =
  (amount: number | string): AppThunk =>
  async dispatch => {
    try {
      // dispatch(setFundsLoading(true));
      dispatch(setWithdrawAmountCrypto(amount));
      // dispatch(setFundsLoading(false));
    } catch (Exception: any) {
      dispatch(setError(true));
      dispatch(setErrorMessage(Exception?.message));
    }
  };

export const setWithdrawCurrencyAction =
  (currency: string): AppThunk =>
  dispatch => {
    try {
      // dispatch(setFundsLoading(true));
      dispatch(setWithdrawCurrency(currency));
      // dispatch(setFundsLoading(false));
    } catch (Exception: any) {
      dispatch(setError(true));
      dispatch(setErrorMessage(Exception?.message));
    }
  };

export const clearWithdrawPendingDataAction =
  (): AppThunk => async dispatch => {
    dispatch(setWithdrawPendingData({}));
  };

export const withdrawAction =
  (data: {
    withdrawalType: string;
    amount: number;
    accountId: string;
    quotedFeeAmount: number;
    deviceOtp?: string;
    emailOtp: string;
    otpType?: string;
    productCode?: string;
    otpFlowTypeCode: string;
    acceptAbsolution?: boolean;
  }): AppThunk =>
  async dispatch => {
    try {
      dispatch(setFundsLoading(true));
      dispatch(setError(false));

      const response = await apiFunds.createWithdrawal({
        withdrawalType: data?.withdrawalType,
        amount: data?.amount,
        quotedFeeAmount: data?.quotedFeeAmount,
        accountId: data?.accountId,
        productCode: data?.productCode,
        emailOtp: data?.emailOtp,
        otpType: data?.otpType,
        deviceOtp: data?.deviceOtp,
        otpFlowTypeCode: data?.otpFlowTypeCode,
        acceptAbsolution: data?.acceptAbsolution || false,
      });

      if (response.status !== 200) {
        dispatch(setFundsLoading(false));
        dispatch(setError(true));
        dispatch(setErrorMessage(response.message));
        return;
      }

      dispatch(setWithdrawPendingData(response.data));
      dispatch(setFundsLoading(false));
    } catch (Exception: any) {
      dispatch(setError(true));
      dispatch(setFundsLoading(false));
      dispatch(setErrorMessage(Exception?.message));
    }
  };

export const checkWithdrawToken =
  (token: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(setFundsLoading(true));

      const data = await apiFunds.validateToken(token);

      if (data.statusCode || data.StatusCode) {
        dispatch(setFundsLoading(true));
        dispatch(setError(true));
        dispatch(setErrorMessage(data.message));
        dispatch(setWithdrawValidToken(false));
        return;
      }

      dispatch(setWithdrawValidToken(true));
      dispatch(setFundsLoading(false));
    } catch (Exception: any) {
      dispatch(setError(true));
      dispatch(setFundsLoading(false));
      dispatch(setErrorMessage(Exception?.message));
    }
  };
