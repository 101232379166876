import useAppLanguage from 'hooks/use-app-language';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import verificationStatus from '../../data/verificationStatus';
import { useGetVerificationStatus } from '../../hooks/use-get-verification-status';
import CustomLoader from '../CustomLoader';
import Modal from '../Modal';
import messages from './messages';
import { ProfileVerificationState } from './models/ProfileVerificationStateState';
import { SourceOfFundsTypes } from './models/SourceOfFundsTypes';
import ProfileVerificationView from './ProfileVerificationView';
const routingPrefix = '/profile/verification/';

const steps = [
  { link: 'what-you-will-need', stepNumber: 1 },
  { link: 'intent', stepNumber: 2 },
  { link: 'employment', stepNumber: 3 },
  { link: 'source-of-funds', stepNumber: 4 },
  { link: 'id-verification', stepNumber: 5 },
  { link: 'verifying-account', stepNumber: 6 },
  { link: 'complete', stepNumber: 7 },
];

const ProfileVerification = () => {
  // #region HOOKS
  const language = useAppLanguage();
  const history = useHistory();
  const status = useGetVerificationStatus();
  // #endregion

  const profileVerificationMessage = messages[language].profileVerification;

  let step: { link: string; stepNumber: number } | undefined = steps[0];

  const [state, setState] = useState<ProfileVerificationState>({
    stepNumber: determineCurrentStepNumber(),
    showModal: determineCurrentStepNumber() >= 6,
  });

  const loading: boolean = state.loading ?? false;

  const previousStepNumber = determinePreviousStepNumber(state.stepNumber);

  if (state.stepNumber !== previousStepNumber && !state.secondTimeLoad)
    redirectBasedOnStepNumber(previousStepNumber);

  function determineCurrentStepNumber() {
    const currentPath = window.location.pathname;
    step = steps.find(x => new RegExp(x.link, 'i').test(currentPath));
    return step?.stepNumber ?? 0;
  }

  function determinePreviousStepNumber(stepNumber: number) {
    const { started } = status;
    const { completed } = status;
    const { actions } = status;

    if (!actions) return 1;

    const finished = !(
      actions.filter(x => x.statusCode !== verificationStatus.Verified).length >
      0
    );

    let returnValue = 1;

    if (started) returnValue = 2;
    if (
      actions.find(
        x =>
          x.actionCode === 'USE' &&
          x.completed &&
          x.statusCode === verificationStatus.Verified,
      )
    )
      returnValue = 2;
    if (
      actions.find(
        x =>
          x.actionCode === 'ZAR_INV_30' &&
          x.completed &&
          x.statusCode === verificationStatus.Verified,
      )
    )
      returnValue = 2;
    if (
      actions.find(
        x =>
          x.actionCode === 'ZAR_INV_365' &&
          x.completed &&
          x.statusCode === verificationStatus.Verified,
      )
    )
      returnValue = 3;
    if (
      actions.find(
        x =>
          x.actionCode === 'EMPLOYMENT' &&
          x.completed &&
          x.statusCode === verificationStatus.Verified,
      )
    )
      returnValue = 4;
    if (
      actions.find(
        x =>
          x.actionCode === 'LEVEL' &&
          x.completed &&
          x.statusCode === verificationStatus.Verified,
      )
    )
      returnValue = 4;

    if (
      actions.find(
        x =>
          (x.actionCode === 'SOURCE' &&
            x.statusCode === verificationStatus.PendingAttachment &&
            stepNumber === 6) ||
          (x.actionCode === 'SOURCE' &&
            x.completed &&
            x.statusCode === verificationStatus.Verified),
      )
    ) {
      returnValue = 4;
    }
    if (
      actions.find(
        x =>
          x.actionCode === 'ID' && x.statusCode === verificationStatus.Started,
      )
    )
      returnValue = 5;
    if (
      actions.find(
        x =>
          x.actionCode === 'ID' &&
          [
            verificationStatus.Verified,
            verificationStatus.PendingAdmin,
            verificationStatus.PendingProvider,
          ].includes(x.statusCode),
      )
    ) {
      returnValue = 6;
    }

    if (finished && completed) returnValue = 7;

    return returnValue;
  }

  function redirectBasedOnStepNumber(stepNumber: number) {
    const redirectStep = steps.find(x => x.stepNumber === stepNumber);
    if (stepNumber === 1)
      history.replace(`/profile/verification/${redirectStep?.link}`);
    else history.push(`/profile/verification/${redirectStep?.link}`);
    setState({ ...state, loading: false });
  }

  const onChangePage = (path: string, secondTimeLoad = true) => {
    history.push(`${routingPrefix}${path}`);
    // console.log(state.stepNumber + 1);
    setState({
      ...state,
      stepNumber: state.stepNumber + 1,
      showModal: state.stepNumber + 1 >= 6,
      secondTimeLoad,
      loading: false,
    });
  };

  const onChangePageBack = () => {
    let stepNumber = state.stepNumber - 1;
    if (stepNumber === 5) stepNumber -= 1;

    if (stepNumber < 1) {
      history.push(`/profile`);
    } else {
      const redirectStep = steps.find(x => x.stepNumber === stepNumber);
      history.push(`/profile/verification/${redirectStep?.link}`);
      setState({
        ...state,
        stepNumber,
        secondTimeLoad: true,
        loading: false,
        showModal: stepNumber >= 6,
      });
    }
  };

  const onComplete = (products: boolean) => {
    if (products) {
      history.push(`/products/explore`);
    } else {
      history.push(`/profile`);
    }
  };

  return (
    <div className="static lg:relative w-full">
      <button
        type="button"
        onClick={() => onChangePageBack()}
        className="absolute lg:fixed mt-2 ml-6 text-2xl lg:mt-10 lg:ml-6 lg:text-base left-0 top-0 lg:top-auto lg:left-auto z-40 text-header-text focus:outline-none font-semibold"
      >
        <span className="block lg:hidden">←</span>
        <span className="hidden lg:block">
          ← {profileVerificationMessage.back}
        </span>
      </button>
      <ProfileVerificationView
        onChangePage={onChangePage}
        onComplete={onComplete}
        showModal={state.showModal}
        showError={state.showError}
        errorMessage={state.errorMessage}
        stepNumber={state.stepNumber}
        closeModal={() => onComplete(false)}
        setSourceOfFundsType={(sourceOfFundsType: SourceOfFundsTypes) =>
          setState({ ...state, sourceOfFundsType })
        }
      />
      <Modal hideModal={undefined} show={loading} content={<CustomLoader />} />
    </div>
  );
};

export default ProfileVerification;
