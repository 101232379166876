import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import WhatYouWillNeedView from './WhatYouWillNeedView';

import { postVerificationUserInit } from 'services/api-verification-actions';
import { RootState } from 'store/reducer';

const WhatYouWillNeed = ({ onComplete }: { onComplete: () => void }) => {
  const { profile } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (profile.userProfileResponse) {
      postVerificationUserInit(profile?.userProfileResponse?.id);
    }
  }, []);

  return <WhatYouWillNeedView onComplete={onComplete} />;
};

export default WhatYouWillNeed;
