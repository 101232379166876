import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducer';
import CompleteModal from './CompleteModal';
import messages from './messages';

const VerifyingAccountView = ({ onComplete }: { onComplete: () => void }) => {
  const { language } = useSelector((state: RootState) => state.language);
  const { status } = useSelector((state: RootState) => state.verification);

  const viewMessages = messages[language].verifyingAccount;

  return (
    <div>
      <CompleteModal
        heading={viewMessages.heading}
        subheading={viewMessages.subheading}
        continueButton={viewMessages.continueButton}
        onComplete={onComplete}
      />
    </div>
  );
};

export default VerifyingAccountView;
