/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ActivateProfileResponse,
  RegisterUserResponse,
  UpdateDetailsResponse,
  ValidateEmailTokenResponse,
  ValidatePromoCodeResponse,
} from '../../entities/register';
import { UserTermDto } from 'services/api-terms-and-conditions';

export interface RegisterState {
  loading: boolean;
  checkingPromoCode: boolean;
  registerWithSsoSuccess: boolean;
  registerUserResponse: RegisterUserResponse;
  validateEmailTokenResponse: ValidateEmailTokenResponse;
  validatePromoCodeResponse: ValidatePromoCodeResponse;
  updateDetailsResponse: UpdateDetailsResponse;
  activateProfileResponse: ActivateProfileResponse;
  acceptedTermsList: UserTermDto[];
}

const defaultValidateEmailTokenResponse = {
  token: '',
  tokenExpiry: '',
  statusCode: 0,
  message: '',
};

const initialState: RegisterState = {
  loading: false,
  checkingPromoCode: false,
  registerWithSsoSuccess: false,
  registerUserResponse: {
    message: '',
    statusCode: 0,
    userId: '',
    promoCode: '',
  },
  validateEmailTokenResponse: defaultValidateEmailTokenResponse,
  validatePromoCodeResponse: {
    valid: false,
  },
  updateDetailsResponse: {
    active: false,
    countryCode: '',
    createdAt: '',
    dateOfBirth: '',
    domain: '',
    email: '',
    emailConfirmed: false,
    firstName: '',
    id: '',
    lastName: '',
    msisdnConfirmed: false,
    statusCode: '',
    title: '',
  },
  activateProfileResponse: {
    detailsCompleted: false,
    emailVerified: false,
    registered: false,
    termsAccepted: false,
    accessToken: {
      token: '',
      tokenExpiry: '',
    },
  },
  acceptedTermsList: [],
};

const register = createSlice({
  name: 'register',
  initialState,
  reducers: {
    registerUserStart(state) {
      state.loading = true;
    },
    registerUserEnd(state) {
      state.loading = false;
    },
    registerUserError(state, action: PayloadAction<RegisterUserResponse>) {
      state.loading = false;
      state.registerUserResponse = action.payload;
    },
    setRegisterUserResponse(
      state,
      action: PayloadAction<RegisterUserResponse>,
    ) {
      state.loading = false;
      state.registerUserResponse = action.payload;
    },
    setRegisterWithSsoSuccess(state, action: PayloadAction<boolean>) {
      state.registerWithSsoSuccess = action.payload;
    },
    validateEmailTokenStart(state) {
      state.loading = true;
    },
    validateEmailTokenEnd(state) {
      state.loading = false;
    },
    validateEmailTokenError(
      state,
      action: PayloadAction<ValidateEmailTokenResponse>,
    ) {
      state.loading = false;
      state.validateEmailTokenResponse = action.payload;
    },
    setValidateEmailTokenResponse(
      state,
      action: PayloadAction<ValidateEmailTokenResponse>,
    ) {
      state.loading = false;
      state.validateEmailTokenResponse = action.payload;
    },
    resetValidateEmail(state) {
      state.validateEmailTokenResponse = defaultValidateEmailTokenResponse;
    },
    validatePromoCodeStart(state) {
      state.checkingPromoCode = true;
    },
    validatePromoCodeEnd(state) {
      state.checkingPromoCode = true;
    },
    validatePromoCodeError(
      state,
      action: PayloadAction<ValidatePromoCodeResponse>,
    ) {
      state.checkingPromoCode = false;
      state.validatePromoCodeResponse = action.payload;
    },
    setValidatePromoCodeResponse(
      state,
      action: PayloadAction<ValidatePromoCodeResponse>,
    ) {
      state.checkingPromoCode = false;
      state.validatePromoCodeResponse = action.payload;
    },

    updateDetailsStart(state) {
      state.loading = true;
    },
    updateDetailsEnd(state) {
      state.loading = true;
    },
    updateDetailsError(state, action: PayloadAction<UpdateDetailsResponse>) {
      state.loading = false;
      state.updateDetailsResponse = action.payload;
    },
    setUpdateDetailsResponse(
      state,
      action: PayloadAction<UpdateDetailsResponse>,
    ) {
      state.loading = false;
      state.updateDetailsResponse = action.payload;
    },

    activateProfileStart(state) {
      state.loading = true;
    },
    activateProfileEnd(state) {
      state.loading = false;
    },
    activateProfileError(
      state,
      action: PayloadAction<ActivateProfileResponse>,
    ) {
      state.loading = false;
      state.activateProfileResponse = action.payload;
    },
    setActivateProfileResponse(
      state,
      action: PayloadAction<ActivateProfileResponse>,
    ) {
      state.loading = false;
      state.activateProfileResponse = action.payload;
    },
    setTermsListResponse(state, action: PayloadAction<UserTermDto[]>) {
      state.acceptedTermsList = action.payload;
    },
  },
});

export const {
  registerUserStart,
  registerUserEnd,
  setRegisterUserResponse,
  registerUserError,
  setRegisterWithSsoSuccess,

  validateEmailTokenStart,
  validateEmailTokenEnd,
  validateEmailTokenError,
  setValidateEmailTokenResponse,

  validatePromoCodeStart,
  validatePromoCodeEnd,
  validatePromoCodeError,
  setValidatePromoCodeResponse,
  resetValidateEmail,

  setUpdateDetailsResponse,
  updateDetailsEnd,
  updateDetailsError,
  updateDetailsStart,

  activateProfileStart,
  activateProfileEnd,
  activateProfileError,
  setActivateProfileResponse,
  setTermsListResponse,
} = register.actions;

export default register.reducer;
